import React, { useEffect, useState } from 'react';
import { Calendar, Popover } from 'antd';
import meta from './meta';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getData } from '../_common/odata/entityodata';
import { toODataString } from '../_common/tables/date';
import { ExpandAltOutlined } from '@ant-design/icons';
import './style.css'

const EventCalendar = () => {
    const [currentDate, setCurrentDate] = useState(moment());
    const [records, setRecords] = useState();
    useEffect(() => {
        const currentMonth = moment(currentDate).startOf('month');
        getData({
            metadata: meta,
            extFilter: `(state/code ne 'CANCELED' and startDate ge ${toODataString(moment(currentMonth).subtract(14, 'days')._d)} and startDate lt ${toODataString(moment(currentMonth).add(1, 'M').add(14, 'days')._d)})`,
            callback: (data) => {
                setRecords(data);
            },
            isGlobalProcessing: true,
        })
    }, [currentDate]);

    const dateCellRender = (v) => {
        if (!records) return null;
        const value = moment(v);
        const events = records.filter(_ => moment(_.startDate).startOf('day').isSame(value.startOf('day')));
        if (events.length === 0) return null;
        const fullContent = <React.Fragment>
            {events.map((ev, idx) => <div key={idx} className='calendar-full'>
                {ev.name}<br />
                <Link to={`/Event/${ev.id}`}>{moment(ev.startDate).format('HH:mm')}</Link><br />
                {ev.location || ev.url}
            </div>)}
        </React.Fragment>
        const ev = events[0];
        return <React.Fragment>
            <div className='calendar-big'>{events.length > 1
                ? <div>Всего: {events.length}<br />
                    <Popover content={fullContent} trigger={'click'}>
                        <ExpandAltOutlined style={{ fontSize: 24 }} />
                    </Popover>
                </div>
                : <div>
                    {ev.name}<br />
                    <Link to={`/Event/${ev.id}`}>{moment(ev.startDate).format('HH:mm')}</Link><br />
                    {ev.location || ev.url}
                </div>
            }
            </div>
            <div className='calendar-small'>{events.length > 1
                ? <div>+{events.length}</div>
                : <div>
                    <Link to={`/Event/${ev.id}`}>{moment(ev.startDate).format('HH:mm')}</Link><br />
                </div>
            }
                <Popover content={fullContent} trigger={'click'}>
                    <ExpandAltOutlined style={{ fontSize: 24 }} />
                </Popover>
            </div>
        </React.Fragment>

    };

    return <Calendar dateCellRender={dateCellRender} value={currentDate} onPanelChange={(value) => setCurrentDate(value)} />
}

export default EventCalendar;
