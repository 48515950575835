import React, { useState } from 'react';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import FileDialog from '../_common/FileDialog';
import { post } from '../_common/utils/fetch';
import { Checkbox, message } from 'antd';
import { prepareMetadata } from '../_common/odata/entityodata';
import NotifyCommon from '../notifications/NotifyCommon';
import fileExport from '../_common/utils/fileExport';
import EmployeeTable from './EmployeeTable';

const metadata = {
    entity: 'position',
    key: 'id',
    properties: [
        {
            title: 'ФИО',
            dataIndex: 'user',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            editable: {
                updating: false
            },
            link: true
        },
        {
            title: 'Email',
            dataIndex: ['user','email'],
            width: 150,
            editable: false
        },
        {
            title: 'Актуальная',
            dataIndex: ['isActive'],
            type: 'boolean',
            width: 150,
            editable: false
        },
        {
            title: 'Кафедры',
            dataIndex: 'departments',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'department2',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false
        },
        {
            title: 'Направления подготовки',
            dataIndex: 'specialties',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'specialty',
            entityKey: 'id',
            entityLabel: ['code','name'],
            editable: false
        },
        {
            title: 'Образовательные программы',
            dataIndex: 'educationalPrograms',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'educationalProgram',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false
        }
    ]
};

const addMetadata = {
    entity: 'position/employee',
    key: 'id',
    properties: [
        {
            title: 'ФИО',
            dataIndex: 'userFullName',
            width: 150,
            editable: true,
            required: true
        },
        {
            title: 'Email',
            dataIndex: 'userEmail',
            width: 150,
            type: 'email',
            editable: true,
            required: true
        },
        {
            title: 'Образовательные программы',
            dataIndex: 'educationalProgramIds',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'educationalProgram',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true
        },
        {
            title: 'Направления подготовки',
            dataIndex: 'specialtyIds',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'specialty',
            entityKey: 'id',
            entityLabel: ['code','name'],
            editable: true
        },
        {
            title: 'Кафедры',
            dataIndex: 'departmentIds',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'department2',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true
        },
        {
            title: 'Отправить письмо с приглашением',
            dataIndex: 'needToSendInvitationEmail',
            type: 'boolean',
            editable: true,
            defaultValue: true,
            allowClear: false,
            required: true
        }
    ]
};

const Employee = (props) => {
    const [tableApi, setTableApi] = useState();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [env] = useEnv();
    const [sendEmail, setSendEmail] = useState(false);
    const [fileFormat, setFileFormat] = useState('projectus');
    const [uploadRegime, setUploadRegime] = useState('delta');
    const [selectedRows, setSelectedRows] = useState();
    const [notifyCommonIds, setNotifyCommonIds] = useState();
    prepareMetadata({metadata});

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Position/UploadEmployeeExcel`,
            formData: {
                File: file,
                Pars: JSON.stringify({
                    sendEmail: sendEmail ? "true" : "false",
                    fileFormat,
                    uploadRegime,
                    uploadFirstStudyYear: ''
                })
            },
            callback: () => {
                if (tableApi) tableApi.refresh();
                message.success("Файл загружен");
            }
        });
    }

    const resendInvitation = () => {
        if (!selectedRows || selectedRows.length === 0) return;
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Position/ResendInvitation`,
            json: {
                Ids: selectedRows.map(_ => _.id)
            },
            callback: ({requestCnt, resendCnt}) => {
                let info = resendCnt < requestCnt ? "Приглашения разосланы не всем, т.к. часть участников уже входили в систему." : "";
                message.success(`Приглашения разосланы ${resendCnt} из ${requestCnt}. ${info}`);
            }
        });
    }

    const notifyCommon = (id) => {
        let ids = id
            ? [id]
            : (tableApi ? tableApi.getSelectedRows().map(_ => _.user.id) : null);
        if (!ids || !ids.length) {
            message.warning('Должен быть выделен хотя бы один участник');
            return;
        }

        setNotifyCommonIds(ids);
    }

    const getExcel = () => {
        fileExport(`${window.APPCFG.apiPath}/Position/GetExcel${tableApi.lastUrlSearchParams}&type=EMPLOYEE`);
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Сотрудники"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
            { label: 'Загрузить из Excel', key: 'uploadExcel', onClick: () => setUploadOpen(true) },
            env.isSuperAdmin && { label: 'Выгрузить в Excel', key: 'getExcel', onClick: getExcel },  
            { label: 'Разослать приглашения выбранным участникам', disabled: !selectedRows || selectedRows.length === 0, key: 'resendInvitation', onClick: resendInvitation },
            { label: 'Послать уведомления выбранным участникам', key: 'notifyCommon', onClick: () => notifyCommon() },        
        ]}
    >
        <EmployeeTable
            columns={metadata.properties}
            addMetadata={addMetadata}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            editable={true}
            onInit={setTableApi}
            history={props.history}
            onEdit={false}
            onCopy={false}
            odataFilter={`clientId eq ${env.userClientId} and typeId eq 'EMPLOYEE'`}
            beforeSaveData={({record}) => {record.typeId = 'EMPLOYEE'; return record;}}
            rowSelection={{
                onChange: (newSelectedRowKeys, newSelectedRows) => setSelectedRows(newSelectedRows)
            }}
        />
        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel}>
            <br/><Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}>Разослать приглашения новым участникам</Checkbox>
        </FileDialog>
        {notifyCommonIds && <NotifyCommon ids={notifyCommonIds} open={Boolean(notifyCommonIds)} onOk={() => setNotifyCommonIds(null)} onCancel={() => setNotifyCommonIds(null)} />}
    </MainContent>
}

export default withRouter(Employee);
