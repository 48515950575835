import React from 'react';
import moment from 'moment';
import { DATEFORMAT } from '../utils/date';

const PropertyViewer = ({ record, property, showNull, idx }) => {
    const renderedText = property.render ? property.render(record[property.dataIndex], record, idx) : record[property.dataIndex];
    const isNull = renderedText === null || renderedText === undefined;

    return !showNull && isNull
        ? null
        : <div className='property' key={property.key || idx}>
            <div className='label' title={property.description}>{property.title}</div>
            <div className='value'>{renderedText}</div>
        </div>
}

export default PropertyViewer;