import React, { useState } from 'react';
import { EntityViewer, EntityForm } from '../_libs/antd-ext';
import { message, Modal } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import Actions from '../_common/layout/Actions';
import { post } from '../_common/utils/fetch';
import { useHistory } from 'react-router-dom';
import { saveData } from '../_common/odata/entityodata';
import ChangeRegimeDialog from './dialogs/ChangeRegimeDialog';
import moment from 'moment';

const EventCommon = ({ metadata, event, regime, hasRoles, forceUpdate }) => {
    const history = useHistory();
    const [saveState, setSaveState] = useState();

    const changeState = (id, state) => {
        if (!id) return;
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Event/${state}`,
            json: {
                ids: [id]
            },
            callback: () => {
                message.success("Состояние мероприятия изменено");
                forceUpdate();
            }
        })
    }

    const save = ({ metadata, value, creating, editingKey, regime }) => {
        setSaveState(null);
        saveData({
            metadata, value, creating, editingKey,
            afterGetPath: (path) => regime ? `${path}?patchRegime=${regime}` : path,
            onSave: (id) => {
                forceUpdate();
                history.push(`/Event/${id}`)
            }
        });
    }

    const onSaveInternal = ({ metadata, value, creating, editingKey }) => {
        if (!event || !event.id || event.state.code === 'DRAFT'
            || (moment(event.startDate).isSame(moment(value.startDate)) && event.location === value.location)) {
            save({ metadata, value, creating, editingKey })
        } else {
            setSaveState({ metadata, value, creating, editingKey })
        }
    }

    const sendNotificationChange = () => {
        Modal.confirm({
            title: `Вы действительно хотите разослать уведомление об изменении времени/места?`,
            onOk: () => post({
                isGlobalProcessing: true,
                url: `${window.APPCFG.apiPath}/Event/SendEventChangeNotification`,
                json: {
                    ids: [event.id]
                },
                callback: () => {
                    message.success("Уведомление отправлено");
                }
            })
        });
    }

    const createGlobalGradeCampaign = () => {
        history.push(`/GlobalGradeCampaign/0/create`, {
            request: {
                context: 'EVENT',
                id: event.id
            }
        });
    }

    return <React.Fragment>
        {event && event.id && <Actions items={[
            hasRoles('ADMIN') && regime !== 'view' && {
                key: 'edit',
                label: 'Вернуться к просмотру',
                onClick: () => history.push(`/Event/${event.id}`)
            },
            hasRoles('ADMIN') && regime === 'view' && {
                key: 'edit',
                label: 'Изменить',
                onClick: () => history.push(`/Event/${event.id}/edit`)
            },
            hasRoles('ADMIN') && regime === 'view' && {
                key: 'toAgreed',
                label: 'Начать согласование',
                disabled: !event.isTimeCuratorApprovedRequired || event.state.code !== 'DRAFT',
                onClick: () => changeState(event.id, 'ToAgreed')
            },
            hasRoles('ADMIN') && regime === 'view' && {
                key: 'toPlanned',
                label: 'Опубликовать',
                disabled: event.state.code === 'PLANNED' || event.state.code === 'HELD',
                onClick: () => changeState(event.id, 'ToPlanned')
            },
            hasRoles('ADMIN') && regime === 'view' && {
                key: 'toCancelled',
                label: 'Отменить',
                disabled: event.state.code === 'CANCELED' || event.state.code === 'HELD',
                onClick: () => changeState(event.id, 'ToCancelled')
            },
            hasRoles('ADMIN') && {
                key: 'sendNotificationChange',
                label: 'Разослать участникам уведомление об изменении времени/места',
                onClick: () => sendNotificationChange()
            },
            hasRoles('ADMIN') && {
                key: 'createGlobalGradeCampaign',
                label: 'Создать глобальную оценочную кампанию по мероприятию',
                onClick: createGlobalGradeCampaign
            }
        ]}
        />}
        {regime === 'view'
            ? <React.Fragment><EntityViewer
                metadata={metadata}
                value={event}
            />
                <CalendarOutlined /> <a href={`${window.APPCFG.apiPath}/Event/GetICal?ids=${event.id}`}>Добавить в свой календарь</a>
            </React.Fragment>
            : (regime === 'edit' || regime === 'create' || regime === 'copy')
                ? <React.Fragment>
                    <EntityForm
                        visible={true}
                        card={false}
                        regime={regime === 'edit' ? 'updating' : 'creating'}
                        metadata={metadata}
                        value={event}
                        defaults={metadata.defaults}
                        editable={true}
                        onSaveInternal={onSaveInternal}
                    />
                </React.Fragment>
                : null}
        {saveState && <ChangeRegimeDialog
            open={Boolean(saveState)}
            onCancel={() => setSaveState(null)}
            onOk={(regime) => save({ ...saveState, regime })}
            isTimeCuratorApprovedRequired={event.isTimeCuratorApprovedRequired}
            oldValue={event}
            newValue={saveState.value} />}
    </React.Fragment>
}

export default EventCommon;
