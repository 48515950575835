import React from 'react';
import moment from 'moment';
import { DATEFORMAT } from '../utils/date';

const PropertyViewer = ({ entityValue, propertyMeta, showNull }) => {
    const { key, type, dataIndex, title, description, renderFunc = _ => _ } = propertyMeta;

    const getValue = () => {
        return type === 'date'
            ? !entityValue[dataIndex] ? null : moment(entityValue[dataIndex]).format(DATEFORMAT)
            : type === 'entity'
                ? renderFunc(entityValue[dataIndex], entityValue, 0, 'card')
                : type === 'number'
                    ? entityValue[dataIndex]
                    : type === 'boolean'
                        ? entityValue[dataIndex] === true ? 'Да' : entityValue[dataIndex] === false ? 'Нет' : null
                        : type === 'string'
                            ? renderFunc(entityValue[dataIndex])
                            : type === 'text'
                                ? entityValue[dataIndex]
                                : type === 'file'
                                    ? (entityValue[dataIndex] && entityValue[dataIndex].length > 0 && entityValue[dataIndex].map((_, idx) => <React.Fragment key={idx}><a href={_.url}>{_.name}</a><br /></React.Fragment>))
                                    : type === 'extended'
                                        ? entityValue[dataIndex]
                                        : type === 'treeentity'
                                            ? entityValue[dataIndex]
                                            : renderFunc(entityValue[dataIndex]);
    }

    return (!showNull && (entityValue[dataIndex] === null || entityValue[dataIndex] === undefined || entityValue[dataIndex] === "" || entityValue[dataIndex].length === 0)) || propertyMeta.hidden
        ? null
        : <div className='property' key={key}>
            <div className='label' title={description}>{title}</div>
            <div className='value'>{getValue()}</div>
        </div>
}

export default PropertyViewer;