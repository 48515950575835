import React, { useEffect, useState } from 'react';
import MainContent from '../_common/layout/MainContent';
import { EntityViewer, EntityForm } from '../_libs/antd-ext';
import { get, post } from '../_common/utils/fetch';
import { EditOutlined, ArrowLeftOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Tabs, message } from 'antd';
import ProjectApprovalStep from './ProjectApprovalStep';
import ProjectRoles from './ProjectRoles';
import ParticipationRequests from './ParticipationRequests'
import ProjectMembers from './ProjectMembers';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { getEntity, getEntityNew } from '../_common/odata/entityodata';
import { useEnv } from '../_common/hooks/useEnv';
import { useMetadata } from '../_common/hooks/useMetadata';
import meta from './meta';
import ProjectSettings from './ProjectSettings';
import Actions from '../_common/layout/Actions';
import ChangeTypeDialog from './Dialogs/ChangeTypeDialog';
import GradeCompany from '../grades/projectgradecampaigns/GradeCompany';
import { usePrintDocumentTypes, PrintDocumentComponent } from '../printdocuments/PrintDocumentComponent';

const Project = (props) => {
    const [entityData, setEntityData] = useState();
    const [rolesTabTableApi, setRolesTabTableApi] = useState();
    const [participationRequestsTabTableApi, setParticipationRequestsTabTableApi] = useState();
    const [approvalStepsTabTableApi, setApprovalStepsTabTableApi] = useState();
    const [membersApi, setMembersApi] = useState();
    const [tabKey, setTabKey] = useState();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [env, { hasRoles }] = useEnv();
    const [userPermissions, setUserPermissions] = useState({});
    const [loadUserPermissions, setLoadUserPermissions] = useState();
    const [changeTypeDialogOpen, setChangeTypeDialogOpen] = useState(false);
    const [printDocumentTypes] = usePrintDocumentTypes('PROJECT');
    const [printDocumentDialogOpen, setPrintDocumentDialogOpen] = useState(false);
    const [commonTabActions, setCommonTabActions] = useState([]);

    useEffect(() => {
        if (env && entityData && loadUserPermissions) {
            let isProjectCurator = entityData.projectCurator && entityData.projectCurator.id === env.userId;
            let isExecutor = entityData.executor && env.userActiveClientId === entityData.executor.id;
            let loadUserPermissionsMap = loadUserPermissions.length > 0 ? loadUserPermissions.reduce((a, v) => ({ ...a, [v]: true }), {}) : {};
            setUserPermissions({
                ...loadUserPermissionsMap,
                isProjectCurator: isProjectCurator,
                addMember: isProjectCurator,
                changeStateToExecuted: isProjectCurator,
                returnStateToAccepted: isProjectCurator,
                changeGradeCompany: isProjectCurator,
                changeType: isExecutor && (isProjectCurator || hasRoles('PROJECT_CREATOR')),
                edit: isExecutor && (isProjectCurator || hasRoles('PROJECT_CREATOR')),
                editCommonOnly: loadUserPermissionsMap.sr_CURATOR_ASSISTANT
            })
        }
    }, [entityData, env, loadUserPermissions]);

    useEffect(() => {
        if (entityData && userPermissions) {
            setCommonTabActions([
                userPermissions.changeType && { label: 'Изменить тип проекта', key: "changeType", onClick: () => setChangeTypeDialogOpen(true) },
                userPermissions.changeStateToExecuted && entityData.state.code === 'ACCEPTED' && { label: 'Начать исполнение', key: "toExecuted", onClick: () => toExecuted({ ids: [id] }) },
                userPermissions.returnStateToAccepted && entityData.state.code === 'EXECUTED' && { label: 'Вернуть в состояние Согласовано', key: "retutnToAccepted", onClick: () => returnToAccepted({ ids: [id] }) },
                (hasRoles('ADMIN') || userPermissions.changeStateToExecuted) && entityData.state.code === 'EXECUTED' && { label: 'Завершить', key: "toClosed", onClick: () => toClosed({ ids: [id] }) },
                userPermissions.returnStateToAccepted && entityData.state.code === 'CLOSED' && { label: 'Вернуть в состояние Исполняется', key: "returnToExecuted", onClick: () => returnToExecuted({ ids: [id] }) },
                (hasRoles('ADMIN') || userPermissions.isProjectCurator) && printDocumentTypes && printDocumentTypes.length > 0 && { label: 'Выгрузить печатную форму', key: "getDoc", onClick: () => setPrintDocumentDialogOpen(true) },
            ]);
        }
    }, [entityData, userPermissions]);

    let pathParts = props.history.location.pathname.split('/');
    let regime = !pathParts[2]
        ? 'list'
        : pathParts[3] === 'edit'
            ? 'edit'
            : pathParts[3] === 'create'
                ? 'create'
                : pathParts[3] === 'copy'
                    ? 'copy'
                    : 'view';
    pathParts[3] = regime;
    let id = pathParts[2] ? parseInt(pathParts[2]) : null;
    let pathTabKey = pathParts[4] || undefined;

    useEffect(() => {
        if (id) {
            get({
                url: `${window.APPCFG.apiPath}/Project/GetPermissions?id=${id}`,
                callback: setLoadUserPermissions
            });
        }
    }, [id, forceUpdateRequired]);

    const [metadata] = useMetadata(meta, { projectId: id, clientId: id ? null : env.userClientId });
    if (metadata && env) {
        for (let property of metadata.properties.filter(_ => _.type === 'file')) {
            property.action = `${window.APPCFG.apiPath}/File/Upload?projectId=${id}`;
        }
        metadata.propertyMap.type.entityFilter = `clientId eq ${env.userClientId}`;
        if (!hasRoles('PROJECT_CREATOR')) {
            metadata.propertyMap.projectCurator.editable = false;
            for (let property of metadata.properties) {
                if (property.isDynamic) {
                    if (property.accessLevel && property.accessLevel < 2) {
                        property.editable = false;
                    }
                }
            }
        }
        if (userPermissions.editCommonOnly) {
            let editableProperties = ['name', 'description', 'goal', 'teamName', 'result', 'acceptanceCriteria'];
            for (let property of metadata.properties) {
                if (property.isDynamic) {
                    if (property.accessLevel && property.accessLevel < 3) property.editable = false;
                }
                else if (editableProperties.indexOf(property.key) < 0) property.editable = false;
            }
        }
    }

    let canEdit = userPermissions.edit || userPermissions.editCommonOnly;

    if (!canEdit && regime === 'edit') regime = 'view';

    useEffect(() => {
        if (id && metadata) getEntityNew({ metadata, id, callback: setEntityData })
        else if (!props.location.state) setEntityData();
        // eslint-disable-next-line
    }, [id, metadata])

    useEffect(() => {
        if (pathTabKey !== tabKey) {
            setTabKey(pathTabKey);
        }
    }, [pathTabKey, tabKey])

    const toExecuted = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/ToExecuted`,
            json: {
                ids
            },
            callback: () => {
                message.success("Проект переведен в состояние Исполняется");
                refresh();
            }
        })
    }

    const returnToAccepted = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/ReturnToAccepted`,
            json: {
                ids
            },
            callback: () => {
                message.success("Проект возвращен в состояние Согласован");
                refresh();
            }
        })
    }

    const toClosed = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/ToClosed`,
            json: {
                ids
            },
            callback: () => {
                message.success("Проект переведен в состояние Закрыт");
                refresh();
            }
        })
    }

    const returnToExecuted = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/ReturnToExecuted`,
            json: {
                ids
            },
            callback: () => {
                message.success("Проект возвращен в состояние Исполняется");
                refresh();
            }
        })
    }

    const onChangeType = (json) => {
        setChangeTypeDialogOpen(false);
        post({
            url: `${window.APPCFG.apiPath}/Project/ChangeType`,
            json: json,
            callback: () => {
                message.success("Тип проекта изменен");
                refresh();
            }
        })
    }

    const changeTabKey = (key) => {
        setTabKey(key);
        let newPathParts = [...pathParts];
        newPathParts[4] = key;
        props.history.push(newPathParts.join('/'));
    }

    const refresh = () => {
        if (approvalStepsTabTableApi) approvalStepsTabTableApi.refresh();
        if (rolesTabTableApi) rolesTabTableApi.refresh();
        if (participationRequestsTabTableApi) participationRequestsTabTableApi.refresh();
        if (membersApi) membersApi.refresh();
        if (id) getEntityNew({ metadata, id, callback: setEntityData })
        setLoadUserPermissions();
        forceUpdate();
    };

    useEffect(() => {
        if (regime === 'create' && props.location.state) {
            if (props.location.state.request) {
                let project = {
                    ...props.location.state.request,
                    request: {
                        id: props.location.state.request.id,
                        name: props.location.state.request.name
                    },
                    state: { id: 'DRAFT' },
                    id: undefined
                }
                setEntityData(project);
            }
        }
    }, [props.location.state, regime])

    const commonTab = !metadata
        ? null
        : <>
            <Actions items={commonTabActions} />
            <ChangeTypeDialog
                open={changeTypeDialogOpen}
                onCancel={() => setChangeTypeDialogOpen(false)}
                onOk={onChangeType}
                type={entityData && entityData.type}
                id={entityData && entityData.id} />
            {printDocumentDialogOpen && <PrintDocumentComponent open={printDocumentDialogOpen} onCancel={() => setPrintDocumentDialogOpen(false)}
                path={`${window.APPCFG.apiPath}/Project/GetProjectDocument?projectId=${entityData.id}`}
                printDocumentTypes={printDocumentTypes} />}
            {regime === 'view' ? <EntityViewer
                metadata={metadata}
                value={entityData}
            /> : <EntityForm
                card={false}
                regime={regime === 'edit' ? 'updating' : 'creating'}
                metadata={metadata}
                title={'Проект'}
                visible={true}
                value={entityData}
                editable={regime !== 'view'}
                onSave={(data) => {
                    if (id !== data.id) props.history.push(`/Project/${data.id}/edit`);
                    else refresh();
                }}
            />}
        </>;

    const rolesTab = <ProjectRoles userPermissions={userPermissions} projectId={id} onInit={setRolesTabTableApi} refresh={refresh} forceUpdateRequired={forceUpdateRequired} regime={regime} project={entityData} />;
    const approvalStepsTab = <ProjectApprovalStep userPermissions={userPermissions} id={id} project={entityData} refresh={refresh} onInit={setApprovalStepsTabTableApi} />;
    const participationRequestsTab = <ParticipationRequests projectId={id} onInit={setParticipationRequestsTabTableApi} refresh={refresh} />
    const projectMembersTab = <ProjectMembers projectId={id} onInit={setMembersApi} refresh={refresh} regime={regime} project={entityData} refreshRequired={forceUpdateRequired} />
    const settingsTab = <ProjectSettings project={entityData} regime={regime} refresh={refresh} />;
    const gradeCompanyTab = <GradeCompany userPermissions={userPermissions} projectId={id} onInit={setMembersApi} refresh={refresh} regime={regime} project={entityData} refreshRequired={forceUpdateRequired} hasRoles={hasRoles} />

    const tabs = !entityData
        ? [
            { label: 'Описание', key: 'common', children: commonTab }
        ]
        : regime === 'edit'
            ? [
                (userPermissions.edit || userPermissions.editCommonOnly) && { label: 'Описание', key: 'common', children: commonTab },
                userPermissions.edit && { label: 'Роли', key: 'roles', children: rolesTab },
                userPermissions.edit && { label: 'Согласование', key: 'approvalSteps', children: approvalStepsTab },
                userPermissions.edit && { label: 'Заявки на участие', key: 'participationRequests', children: participationRequestsTab },
                userPermissions.edit && { label: 'Участники', key: 'projectMembers', children: projectMembersTab },
                userPermissions.edit && { label: 'Настройки', key: 'settings', children: settingsTab },
                ((userPermissions.projectHasGrade && userPermissions.isActiveMember) || (userPermissions.changeGradeCompany || hasRoles('ADMIN'))) && { label: 'Оценочные кампании', key: 'gradeCompany', children: gradeCompanyTab },
            ]
            : [
                { label: 'Описание', key: 'common', children: commonTab },
                { label: 'Роли', key: 'roles', children: rolesTab },
                { label: 'Участники', key: 'projectMembers', children: projectMembersTab },
                userPermissions.approvalStepsIsResponsibleAny && { label: 'Согласование', key: 'approvalSteps', children: approvalStepsTab },
                ((userPermissions.projectHasGrade && userPermissions.isActiveMember) || (userPermissions.changeGradeCompany || hasRoles('ADMIN'))) && { label: 'Оценочные кампании', key: 'gradeCompany', children: gradeCompanyTab },
            ]

    let tabBarExtraContentItems = [
        canEdit && regime === 'view' && <EditOutlined key='edit' className='menu-icon' title="В режим редактирования" onClick={() => props.history.push(`/${pathParts[1]}/${pathParts[2]}/edit/${pathParts[4] ? pathParts[4] : ''}`)} />,
        canEdit && regime !== 'view' && <EditOutlined key='view' className='menu-icon' style={{ color: 'gray' }} title="В режим просмотра" onClick={() => props.history.push(`/${pathParts[1]}/${pathParts[2]}/view/${pathParts[4] ? pathParts[4] : ''}`)} />,
        <UndoOutlined key='refresh' title='Обновить' className='menu-icon' onClick={refresh} />
    ].filter(_ => _);

    return !entityData && id ? null : <MainContent layoutProps={props.layoutProps}
        title={`Проект: ${regime === 'view' ? (entityData && entityData.name) : regime === 'edit' && id ? `${entityData && entityData.name} - Редактирование` : 'Создание'}`}
        headerRightButtons={
            <React.Fragment>
                <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => props.history.push(`/Projects`)} />
            </React.Fragment>
        }
    >
        <Tabs activeKey={tabKey} items={tabs}
            tabBarExtraContent={tabBarExtraContentItems && tabBarExtraContentItems.length > 0
                ? <React.Fragment>{tabBarExtraContentItems}</React.Fragment>
                : undefined}
            onChange={changeTabKey} />
    </MainContent>
}

export default Project;
