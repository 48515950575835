import React, { useState } from 'react';
import { EntityForm, EntityViewer } from '../_libs/antd-ext';
import Table from '../_common/tables/TableOData';

const EmployeeTable = ({ metadata: propMetadata, addMetadata, getDefaultData, title, onAdd, onEdit, onCopy, onInit, columns, odataEntity, rowKey, beforeSaveData, ...rest }) => {
    const [regime, setRegime] = useState();
    const [entityData, setEntityData] = useState();
    const [tableApi, setTableApi] = useState();
    const metadata = propMetadata || {
        entity: odataEntity,
        properties: columns,
        key: rowKey
    }
    return <React.Fragment>
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={(tableApi) => {setTableApi(tableApi); if (onInit) onInit(tableApi);}}
            onAdd={() => {setRegime('add'); setEntityData(getDefaultData ? getDefaultData(setEntityData) : undefined)}}
            onEdit={false}
            onCopy={false}
            {...rest}
        /> 
        {regime === 'add' 
                ? <EntityForm
                    card={true}
                    regime={'creating'}
                    metadata={addMetadata}
                    title={typeof title === 'function' ? title({regime, entityData}) : title}
                    visible={true}
                    defaults={addMetadata.defaults}
                    editable={true}
                    onSave={() => {
                        setRegime();
                        tableApi.refresh();
                    }}
                    onCancel={() => setRegime()}
                    beforeSaveData={beforeSaveData}
                />
                : null}
    </React.Fragment>
}

export default EmployeeTable;
