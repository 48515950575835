import React, { useEffect, useRef } from 'react';
import { Row, Col, Space, Divider, Button, Dropdown, Typography, Menu } from 'antd';
import { UndoOutlined, MoreOutlined} from '@ant-design/icons'
import ClearFilterButton from './ClearFilterButton';
import LongProcessPanel from './LongProcessPanel';
import { toFavicon } from '../utils/antIconsConv';
import { isTouchDevice } from '../utils/browserCapabilities';

const { Title } = Typography;

export const topHeight = 42;

export const LayoutContext = React.createContext("LayoutContext");

export default function MainContent(props) {
    const { rightPanel, layoutProps, headerLeft, headerRight, headerRightButtons, onClickRefresh, menu, title, clearFilterProps, isProcessing, icon, card } = props;
    useEffect(() => {
        document.title = `${title}${window.APPCFG.titlePostfix}`;
        if (icon) toFavicon(icon)
    }, [icon, title]);
    const mainPanelRef = useRef();
    const menuTag = !menu
        ? menu
        : Array.isArray(menu)
            ? (menu.filter(_ => _).length > 0 ? <Menu items={menu.filter(_ => _)} /> : undefined)
            : menu;
    const mainPanel = <div ref={mainPanelRef} style={{ padding: '10px', overflow: 'auto', height: card ? undefined : `calc(100vh - ${topHeight+6}px)`, ...props.style }}>
        {props.children}
    </div>
    return <LayoutContext.Provider value={{mainPanelRef}}>
    <LongProcessPanel isProcessing={isProcessing}>
        <Row wrap={false} style={{ padding: '8px 0px 0px 16px', height: topHeight }}>
            <Col>{layoutProps.menuButton}</Col>
            <Col flex="auto">{headerLeft || (title ? <Title ellipsis={true} level={3}>{title}</Title> : null)}</Col>
            <Col style={{ textAlign: 'right', paddingLeft: 10 }}>
                {headerRight
                    ? headerRight
                    : <Space>
                        {headerRightButtons}
                        {clearFilterProps ? <ClearFilterButton key="ClearFilterButton" {...clearFilterProps} /> : null}
                        {onClickRefresh ? <Button key="Refresh" shape="circle" type="text" icon={<UndoOutlined style={{ fontSize: '20px' }} />} onClick={onClickRefresh} /> : null}
                        {menuTag ? <Dropdown trigger={isTouchDevice() ? ['click'] : ['hover']} key="Menu" overlay={menuTag} placement="bottomRight">
                            <Button shape="circle" type="text" icon={<MoreOutlined style={{ fontSize: '20px' }} />} />
                        </Dropdown> : null}
                        {(layoutProps || {}).systemButtons}
                        </Space>
                }
            </Col>
        </Row>
        <Divider style={{ margin: '5px 0px 0px 0px' }} />
        {rightPanel 
            ? <Row wrap={false}>
                <Col flex="auto">{mainPanel}</Col>
                <Col flex="250px"><div style={{ overflow: 'auto', height: `calc(100vh - ${topHeight+6}px)`, borderLeft: '1px solid #f1f1f1' }}>
                {rightPanel}
    </div></Col>
            </Row>
            : mainPanel}
    </LongProcessPanel>
    </LayoutContext.Provider>
}