import { useState, useEffect } from "react";
import { prepareMetadata } from "../odata/entityodata";
import { getProperties } from "../tables/dynamicAttributeHelper";
import { get } from "../utils/fetch";
import cloneDeep from "clone-deep";

const loadDynamicAttributeMeta = ({ callback, clientId, projectId }) => {
    const filter = projectId
        ? `?projectId=${projectId}`
        : clientId
            ? `?$filter=clientId eq ${clientId}`
            : '';
    get({
        url: `${window.APPCFG.odataPath}/DynamicAttributeMeta${filter}`,
        callback: (data) => {
            callback(data.value)
        }
    })
}

const sortByAfterProperty = (properties) => {
    let withAfter = properties.filter(_ => _.after).reverse();
    if (withAfter.length === 0) return properties;
    properties = properties.filter(_ => !_.after);
    let isChanged = false;
    do {
        isChanged = false;
        for (let pi in withAfter) {
            let p = withAfter[pi];
            let indexAfterE = properties.map((p,i) => ({p, i})).find(_ => _.p.title === p.after);
            if (indexAfterE) {
                isChanged = true;
                let indexAfter = indexAfterE.i;
                properties.splice(indexAfter+1, 0, p);
                withAfter.splice(pi, 1);
            }
        }
    } while (isChanged);
    if (withAfter.length > 0) properties = [...properties, ...withAfter];
    return properties;
}

const getMeta = ({ metadata, dynamicAttributes }) => {
    const meta = cloneDeep(metadata);
    if (dynamicAttributes) meta.properties = sortByAfterProperty([...meta.properties, ...(getProperties(dynamicAttributes) || [])]);
    prepareMetadata({ metadata: meta });
    return meta;
}

export function useMetadata(metadata, { initDynamic, clientId, projectId } = {}) {
    const [meta, setMeta] = useState();

    useEffect(() => {
        if (initDynamic || clientId || projectId) {
            loadDynamicAttributeMeta({
                callback: (dynamicAttributes) => {
                    setMeta(getMeta({ metadata, dynamicAttributes }));
                },
                clientId,
                projectId
            })
        } else {
            setMeta(getMeta({ metadata }));
        }
    }, [initDynamic, clientId, projectId, metadata])

    return [meta, setMeta];
}