import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom"
import Table from '../../_common/tables/TableWithCard';
import { EntityForm } from '../../_libs/antd-ext';
import { meta } from '../projectgradecampaigns/GradeCompany';
import { post } from '../../_common/utils/fetch';
import Actions from '../../_common/layout/Actions';
import { message } from 'antd';

const GlobalGradeCampaignTab = ({ globalGradeCampaign, metadata, hasRoles, forceUpdate, forceUpdateRequired }) => {
    const history = useHistory();
    const open = () => {
        post({
            url: `${window.APPCFG.apiPath}/GlobalGradeCampaign/Open`,
            json: {
                Ids: [globalGradeCampaign.id]
            },
            callback: () => {
                message.success("Действие выполнено");
                forceUpdate();
            }
        })
    }

    const close = () => {
        post({
            url: `${window.APPCFG.apiPath}/GlobalGradeCampaign/Close`,
            json: {
                Ids: [globalGradeCampaign.id]
            },
            callback: () => {
                message.success("Действие выполнено");
                forceUpdate();
            }
        })
    }

    return <React.Fragment>
        <Actions items={[
            hasRoles('ADMIN') && {
                key: 'open',
                disabled: globalGradeCampaign.state.code == 'OPEN',
                label: 'Открыть',
                onClick: open
            },
            hasRoles('ADMIN') && {
                key: 'close',
                disabled: globalGradeCampaign.state.code == 'CLOSED',
                label: 'Закрыть',
                onClick: close
            }
        ]} />
        <EntityForm
            metadata={metadata}
            value={globalGradeCampaign}
            card={false}
            visible={true}
            showDisabledNull={true}
            onSave={(id) => {
                history.push(`/GlobalGradeCampaign/${id}`);
                forceUpdate();
            }}
        />
    </React.Fragment>;
}

export default GlobalGradeCampaignTab;