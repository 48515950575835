export function getSearchByTextPath({ entityPath, entityName, entityKey = 'id', entityLabel = 'name', entityFilter, top, value, exact }) {
    const entityLabels = Array.isArray(entityLabel) ? entityLabel : [entityLabel];
    const expandPars = entityLabels.filter(_ => _.indexOf('/') > 0).map(_ => {
        let parts = _.split('/');
        return `${parts[0]}($select=${parts[1]})`;
    })
    const selectPar = [entityKey, ...entityLabels.filter(_ => _.indexOf('/') <= 0)].join(',');
    const values = Array.isArray(value) ? value : [value];
    let filterPar = exact
        ? entityLabels.map(l => values.map(v => `${l} eq '${v.trim().replaceAll('\'', '\'\'')}'`).join(' or ')).join(' or ')
        : entityLabels.map(l => values.map(v => `contains(tolower(${l}),'${v.toLowerCase().trim().replaceAll('\'', '\'\'')}')`).join(' or ')).join(' or ');
    if (entityFilter) filterPar = `(${filterPar}) and (${entityFilter})`;
    const pathPar = `${entityPath ? entityPath : `${window.APPCFG.odataPath}/${entityName}?`}`;
    const topPar = top || 20;
    return `${pathPar}$select=${selectPar}${expandPars.length > 0 ? `&$expand=${expandPars.join(',')}` : ''}&$filter=(${filterPar})&$top=${topPar}`;
}

export function getAllPath({ entityPath, entityName, entityKey = 'id', entityLabel = 'name', entityFilter, entityOrder }) {
    const entityLabels = Array.isArray(entityLabel) ? entityLabel : [entityLabel];
    const selectPar = [entityKey, ...entityLabels].join(',');
    const pathPar = `${entityPath ? entityPath : `${window.APPCFG.odataPath}/${entityName}?`}`;
    const filter = entityFilter ? `&$filter=${entityFilter}` : '';
    const order = entityOrder ? `&$orderby=${entityOrder} asc` : '';
    return `${pathPar}$select=${selectPar}${filter}${order}`;
}

export function getByIdPath({ entityPath, entityName, entityKey = 'id', entityLabel = 'name', ids }) {
    const entityLabels = Array.isArray(entityLabel) ? entityLabel : [entityLabel];
    const selectPar = [entityKey, ...entityLabels.filter(_ => _.indexOf('/') < 0)].join(',');
    const expandPar = entityLabels.filter(_ => _.indexOf('/') >= 0).map(_ => {
        const parts = _.split('/');
        return `${parts[0]}($select=${parts[1]})`;
    }).join(',')
    const filterPar = ids.map(v => `${entityKey} eq ${v}`).join(' or ');
    const pathPar = `${entityPath ? entityPath : `${window.APPCFG.odataPath}/${entityName}?`}`;
    return `${pathPar}$select=${selectPar}&$expand=${expandPar}&$filter=(${filterPar})`;
}

export function inList(name, list, getter) {
    return !list || list.length === 0
        ? null
        : `(${(getter ? getter(list) : list).map(_ => `${name} eq ${_}`).join(' or ')})`;
}