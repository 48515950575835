import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { message, Modal } from 'antd';
import { useStoredState } from '../_common/hooks/useStoredState';
import ProjectSimpleCardList from './ProjectSimpleCardList';
import { useDebounceState } from '../_common/hooks/useDebounceState';
import { getViewRootPath } from '../_common/hooks/useViewRoot';
import { useEnv } from '../_common/hooks/useEnv';
import { useMetadata } from '../_common/hooks/useMetadata';
import meta from './meta';
import { post } from '../_common/utils/fetch';
import ProjectsFilterPanel from './ProjectsFilterPanel';
import CreateRoleDialog from './Dialogs/CreateRoleDialog';
import DeleteRoleDialog from './Dialogs/DeleteRoleDialog';
import ChangeSelectionDialog from './Dialogs/ChangeSelectionDialog';
import ChangeCuratorDialog from './Dialogs/ChangeCuratorDialog';
import fileExport from '../_common/utils/fileExport';
import { usePrintDocumentTypes, PrintDocumentComponent } from '../printdocuments/PrintDocumentComponent';
import { useLocationState } from '../_common/hooks/useLocationState';

const { confirm } = Modal;

const Projects = (props) => {
    const [tableApi, setTableApi] = useState();
    const [search, setSearch, debounceSearch] = useDebounceState(useStoredState(undefined, { code: 'search' }));
    const [listRegime, setListRegime] = useState('card');
    const [env, { hasRoles }] = useEnv();
    const [onlyMy, setOnlyMy] = useLocationState(!props.env.userRoles || (props.env.userRoles.length === 1 && hasRoles('PROJECT_MANAGER')) ? ['available'] : undefined, {code: 'onlyMy'});
    const [metadata] = useMetadata(meta, { initDynamic: true });
    const [selectedRows, setSelectedRows] = useState();
    const [changeSelectionSettingDialogOpen, setChangeSelectionSettingDialogOpen] = useState(false);
    const [total, setTotal] = useState();
    const [createRoleDialogOpen, setCreateRoleDialogOpen] = useState(false);
    const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = useState(false);
    const [changeCuratorDialogOpen, setChangeCuratorDialogOpen] = useState(false);
    const [printDocumentDialogOpen, setPrintDocumentDialogOpen] = useState(false);
    const [cardFilters, setCardFilters] = useState();
    const [printDocumentTypes] = usePrintDocumentTypes('PROJECTS');

    useEffect(() => {
        if (hasRoles('PROJECT_CREATOR')) setListRegime('list');
    }, [hasRoles])

    if (metadata && env) metadata.propertyMap.type.entityFilter = `clientId eq ${env.userClientId}`;

    const refresh = () => {
        if (tableApi) tableApi.refresh();
    }

    const sync = () => {
        post({
            url: `${window.APPCFG.apiPath}/Project/Sync`,
            isGlobalProcessing: true,
            callback: () => {
                refresh();
            }
        })
    }

    const getSelected = (states) => {
        const selected = selectedRows;
        if (!selected || selected.length === 0) return null;
        const states_ = states || ['DRAFT', 'READYFORAGREED', 'AGREED', 'ACCEPTED']
        return selected.filter(_ => _.executor.id === env.userClientId
            && states_.indexOf(_.state.code) >= 0);
    }

    const isSelected = (states) => {
        const selected = getSelected(states);
        return selected && selected.length > 0;
    }

    const changeSelectionSetting = (value) => {
        const ids = selectedRows.map(_ => _.id);
        setChangeSelectionSettingDialogOpen(false);
        post({
            url: `${window.APPCFG.apiPath}/Project/ChangeSelectionSettings`,
            json: {
                ids,
                attributes: value
            },
            callback: () => {
                message.success("Настройки выбора изменены");
                refresh();
            }
        })
    }

    const createMultiple = (json, selectedRecords) => {
        setCreateRoleDialogOpen(false);
        json.id = undefined;
        json.projectId = 0;
        json.ids = selectedRecords && selectedRecords.map(_ => _.id);
        post({
            url: `${window.APPCFG.apiPath}/ProjectRole/CreateMultiple`,
            isGlobalProcessing: true,
            json: json,
            callback: (result) => {
                message.success(`Роли успешно созданы в ${result.create} проектах, изменены в ${result.update} проектах, не затронуты в ${result.none} проектах`);
                tableApi.refresh();
            }
        });
    }

    const deleteMultiple = (json, selectedRecords) => {
        setDeleteRoleDialogOpen(false);
        json.ids = selectedRecords && selectedRecords.map(_ => _.id);
        post({
            url: `${window.APPCFG.apiPath}/ProjectRole/DeleteMultiple`,
            isGlobalProcessing: true,
            json: json,
            callback: (result) => {
                message.success(`Роли успешно удалены в ${result.delete} проектах`);
                tableApi.refresh();
            }
        });
    }

    const changeCuratorMultiple = (json, selectedRecords) => {
        setChangeCuratorDialogOpen(false);
        json.ids = selectedRecords && selectedRecords.map(_ => _.id);
        post({
            url: `${window.APPCFG.apiPath}/Project/ChangeCuratorMultiple`,
            isGlobalProcessing: true,
            json: json,
            callback: (result) => {
                message.success(`Куратор успешно изменен в ${result.update} проектах, не затронут в ${result.none} проектах`);
                tableApi.refresh();
            }
        });
    }

    const toDeferred = (selectedRecords) => {
        if (!selectedRecords) return;
        let ids = selectedRecords.map(_ => _.id);
        confirm({
            title: `При переводе в Отложено будут удалены все шаги согласования и сброшены куратор и куратор от заказчика. Проекты, в которых есть активные участники не будут затронуты, сначала необходимо удалить из них участников. Вы действительно хотите перевести ${selectedRecords.length} проектов в состояние Отложено? `,
            onOk: () => {
                post({
                    url: `${window.APPCFG.apiPath}/Project/ToDeferred`,
                    isGlobalProcessing: true,
                    json: {
                        ids: ids
                    },
                    callback: (result) => {
                        message.success(`Изменено состояние в ${result.updated} проектах; пропущено ${result.none} проектов${result.none > 0 ? ', т.к. в них были активные участники' : ''}`);
                        tableApi.refresh();
                    }
                });
            }
        });
    }

    const fromDeferred = (selectedRecords) => {
        if (!selectedRecords) return;
        let ids = selectedRecords.filter(_ => _.state.code === 'DEFERRED').map(_ => _.id);
        if (ids.length === 0) message.warning('Не выделено ни одного проекта в состояние Отложено');
        confirm({
            title: `Вы действительно хотите перевести ${ids.length} проектов из состояние Отложено в состояние Черновик? `,
            onOk: () => {
                post({
                    url: `${window.APPCFG.apiPath}/Project/FromDeferred`,
                    isGlobalProcessing: true,
                    json: {
                        ids: ids
                    },
                    callback: () => {
                        message.success(`Операция выполнена`);
                        tableApi.refresh();
                    }
                });
            }
        });
    }

    const getExcel = () => {
        fileExport(`${window.APPCFG.apiPath}/Project/GetExcel${tableApi.lastUrlSearchParams}`);
    }

    const getExcelWithMembers = () => {
        fileExport(`${window.APPCFG.apiPath}/Project/GetExcelWithMembers${tableApi.lastUrlSearchParams}`);
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Проекты"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
        env.isSuperAdmin && { label: 'Синхронизация', key: 'sync', onClick: sync },
        hasRoles('PROJECT_CREATOR') && { label: 'Задать настроки выбора', disabled: !isSelected(), key: "setSelectionSettings", onClick: () => setChangeSelectionSettingDialogOpen(true) },
        hasRoles('PROJECT_CREATOR') && { label: 'Создать роль в выбранных проектах', disabled: !isSelected(), key: "createRole", onClick: () => setCreateRoleDialogOpen(true) },
        hasRoles('PROJECT_CREATOR') && { label: 'Удалить роль в выбранных проектах', disabled: !isSelected(), key: "deleteRole", onClick: () => setDeleteRoleDialogOpen(true) },
        hasRoles('PROJECT_CREATOR') && { label: 'Изменить куратора в выбранных проектах', disabled: !isSelected(), key: "changeCurator", onClick: () => setChangeCuratorDialogOpen(true) },  
        hasRoles('PROJECT_CREATOR') && { label: 'Изменить состояние на Отложено', disabled: !isSelected(), key: "toDeferred", onClick: () => toDeferred(getSelected()) },
        hasRoles('PROJECT_CREATOR') && { label: 'Вернуть отложенные в черновик', disabled: !isSelected(['DEFERRED']), key: "fromDeferred", onClick: () => fromDeferred(getSelected(['DEFERRED'])) },
        hasRoles('ADMIN') && { label: 'Выгрузить в Excel', key: "getExcel", onClick: getExcel }, 
        hasRoles('ADMIN') && { label: 'Выгрузить в Excel с участниками', key: "getExcelWithMembers", onClick: getExcelWithMembers }, 
        hasRoles('ADMIN') && printDocumentTypes && printDocumentTypes.length > 0 && { label: 'Выгрузить печатную форму', key: "getDoc", onClick: () => setPrintDocumentDialogOpen(true) },                       
    ]}
    >
        {metadata && listRegime === 'list' && <React.Fragment>
            <ProjectsFilterPanel
                env={env}
                search={search}
                setSearch={setSearch}
                listRegime={listRegime}
                setListRegime={setListRegime}
                onlyMy={onlyMy}
                setOnlyMy={setOnlyMy}
                total={total}
            />
            <Table
                columns={metadata.properties}
                odataEntity={metadata.name}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={props.history}
                editable={env.userActiveClientId && hasRoles('PROJECT_CREATOR')}
                onAdd={() => props.history.push(getViewRootPath({ mainRoot: 'Project', regime: 'create' }))}
                onEdit={{
                    action: (record) => props.history.push(getViewRootPath({ mainRoot: 'Project', id: record[metadata.key], regime: 'edit' })),
                    disabled: ({ record }) => record.executor.id !== env.userClientId || !hasRoles('PROJECT_CREATOR', 'PROJECT_MANAGER')
                }}
                onCopy={{
                    action: (record) => props.history.push(getViewRootPath({ mainRoot: 'Project', id: record[metadata.key], regime: 'copy' })),
                    disabled: ({ record }) => record.executor.id !== env.userClientId || !hasRoles('PROJECT_CREATOR')
                }}
                onDelete={{
                    disabled: ({ record }) => record.executor.id !== env.userClientId || !hasRoles('PROJECT_CREATOR')
                }}
                afterGetPath={(path) => `${path}${search ? `&fullTextSearch=${search}` : ''}${onlyMy ? `&${onlyMy.map(_ => `onlyMy=${_}`).join('&')}` : ''}`}
                rowSelection={{
                    onChange: (newSelectedRowKeys, newSelectedRows) => setSelectedRows(newSelectedRows)
                }}
                onLoad={({ total }) => setTotal(total)}
            />
            {changeSelectionSettingDialogOpen && <ChangeSelectionDialog
                selectedRows={selectedRows}
                changeSelectionSettingDialogOpen={changeSelectionSettingDialogOpen}
                setChangeSelectionSettingDialogOpen={setChangeSelectionSettingDialogOpen}
                changeSelectionSetting={changeSelectionSetting} />}
            {createRoleDialogOpen && <CreateRoleDialog open={createRoleDialogOpen} onCancel={() => setCreateRoleDialogOpen(false)}
                selectedRecords={getSelected()}
                onOk={createMultiple} />}
            {deleteRoleDialogOpen && <DeleteRoleDialog open={deleteRoleDialogOpen} onCancel={() => setDeleteRoleDialogOpen(false)}
                selectedRecords={getSelected()}
                onOk={deleteMultiple} />}
            {changeCuratorDialogOpen && <ChangeCuratorDialog open={changeCuratorDialogOpen} onCancel={() => setChangeCuratorDialogOpen(false)}
                selectedRecords={getSelected()}
                onOk={changeCuratorMultiple} />}
            {printDocumentDialogOpen && <PrintDocumentComponent open={printDocumentDialogOpen} onCancel={() => setPrintDocumentDialogOpen(false)}
                path={`${window.APPCFG.apiPath}/Project/GetProjectsDocument${tableApi.lastUrlSearchParams}`}
                printDocumentTypes={printDocumentTypes} />}
        </React.Fragment>}
        {metadata && listRegime === 'card' && <React.Fragment>
            <ProjectsFilterPanel
                env={env}
                search={search}
                setSearch={setSearch}
                listRegime={listRegime}
                setListRegime={setListRegime}
                onlyMy={onlyMy}
                setOnlyMy={setOnlyMy}
                total={total}
                metadata={metadata}
                onAdditionalConfirm={setCardFilters}
            />
            <ProjectSimpleCardList
                editable={hasRoles('PROJECT_CREATOR')}
                visible={listRegime === 'card'}
                onlyMy={onlyMy}
                search={debounceSearch}
                onLoad={({ total }) => setTotal(total)}
                filters={cardFilters}
                metadata={metadata}
            />
        </React.Fragment>}
    </MainContent>
}

export default withRouter(Projects);