import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
//import Table from './Test';
import MainContent, { topHeight } from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import TreePanel from '../_common/TreePanel';

const systemEnv = 2;

const SystemEnv = (props) => {
    const [tableApi, setTableApi] = useState();
    const [selectedGroupKey, setSelectedGroupKey] = useState(systemEnv);
    const [prevSelectedGroupKey, setPrevSelectedGroupKey] = useState(systemEnv);
    const [selectedGroup, setSelectedGroup] = useState();

    const beforeSaveData = ({record}) => {
        record.groupId = selectedGroupKey;
        return record;
    }

    const beforeAddData = ({record}) => {
        record.isSystem = false;
        return record;
    }

    const onSelect = (selectedKeys, e, {node}) => {
        setSelectedGroupKey(selectedKeys && selectedKeys[0] ? selectedKeys[0] : systemEnv);
        setSelectedGroup(node);
    }

    useEffect(() => {
        if (selectedGroupKey !== prevSelectedGroupKey) {
            setPrevSelectedGroupKey(selectedGroupKey);
            tableApi.refresh();
        }
    // eslint-disable-next-line
    }, [selectedGroupKey, prevSelectedGroupKey])

    return <MainContent layoutProps={props.layoutProps}
        title={`${(selectedGroup ? `${selectedGroup.name} - ` : '')}Системные переменные`}
        onClickRefresh={tableApi && tableApi.refresh}
        menu={tableApi && tableApi.getMainContentMenuItems()}
    >
        <div style={{ margin: '0px' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <div style={{ height: `calc(100vh - ${topHeight + 16}px)`, marginBottom: '-10px', overflow: 'auto', borderRight: '1px solid #f1f1f1' }}>
                    <TreePanel 
                        entityName="systemGroup" 
                        id={systemEnv}
                        selectedKeys={[selectedGroupKey]}
                        onSelect={onSelect}
                    />
                </div>
                <div style={{ padding: '0px' }}>
                    <Table
                        columns={[
                            {
                                title: 'Код',
                                dataIndex: 'code',
                                width: 150,
                                editable: {
                                    updating: ({record}) => !record.isSystem
                                }
                            },
                            {
                                title: 'Название',
                                dataIndex: 'name',
                                width: 150,
                                editable: true
                            },
                            {
                                title: 'Значение',
                                dataIndex: 'value',
                                width: 150,
                                editable: true
                            },
                            {
                                title: 'Системная',
                                dataIndex: 'isSystem',
                                type: 'boolean',
                                width: 150,
                                editable: false
                            }]}
                        odataEntity='systemEnv'
                        odataFilter={`groupId eq ${selectedGroupKey}`}
                        onInit={setTableApi}
                        history={props.history}
                        beforeSaveData={beforeSaveData}
                        beforeCopyData={beforeAddData}
                        beforeAddData={beforeAddData}
                    />
                </div>
            </div>
        </div>
    </MainContent>
}

export default withRouter(SystemEnv);
