import React, { useRef } from 'react';
import FieldDatePeriod from '../form/FieldDatePeriod';
import FieldBoolean from '../form/FieldBoolean';
import FilterEntity from './FilterEntity';
import FilterString from './FilterString';
import FilterNumber from './FilterNumber';
import { Checkbox, Button, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { AntdExtContext } from '..';
import locale_en_US from '../locale/en_US';

const Filter = ({ showButtons = true, showEmpty = true, type, componentRender, setSelectedKeys, selectedKeys, confirm, clearFilters, ...rest }) => {
    const context = React.useContext(AntdExtContext);
    const locale = (context && context.locale) || locale_en_US;

    const okRef = useRef();

    const change = (filter, setSelectedKeys) => {
        filter = (filter.value === null || filter.value === undefined) && !filter.empty && !filter.exclude ? null : filter;
        setSelectedKeys(filter);
        if (okRef.current && type !== 'number') okRef.current.focus();
    }

    let component = ({ value, onChange }) => {
        return componentRender
            ? componentRender({ value, onChange, confirm, ...rest })
            : type === 'number'
                ? <FilterNumber confirm={confirm} value={value} onChange={onChange} />
                : type === 'date'
                    ? <FieldDatePeriod value={value} onChange={onChange} allowClear={true} />
                    : type === 'datetime'
                        ? <FieldDatePeriod value={value} onChange={onChange} allowClear={true} showTime={{ format: 'HH:mm' }} />
                        : type === 'boolean'
                            ? <FieldBoolean value={value} onChange={onChange} />
                            : type === 'entity'
                                ? <FilterEntity {...rest} confirm={confirm} value={value} onChange={onChange} />
                                : <FilterString confirm={confirm} value={value} onChange={onChange} />
    }
    const filter = selectedKeys || {};
    return <div
        style={{
            padding: 8,
            width: 300
        }}
        onKeyDown={e => {
            if (e.code === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                confirm();
            }
        }}
    >
        <div style={{ display: 'block', marginBottom: 10 }}>
            {component({ value: filter.value, onChange: (value) => change({ ...filter, value }, setSelectedKeys) })}
        </div>
        {showEmpty && <React.Fragment>
            <Checkbox checked={filter.empty} onChange={e => change({ ...filter, empty: e.target.checked }, setSelectedKeys)}>{locale.Table.empty}</Checkbox>
            <Checkbox checked={filter.exclude} onChange={e => change({ ...filter, exclude: e.target.checked }, setSelectedKeys)}>{locale.Table.exclude}</Checkbox>
            <br /></React.Fragment>}
        {showButtons && <Row style={{ marginTop: 10 }}>
            <Col span={12} align="left">
                <Button ref={okRef} className="b" type="primary" onClick={(e) => { confirm(); }} onKeyDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    confirm();
                }} icon={<SearchOutlined />} size="small">
                    {locale.Table.filter}
                </Button>
            </Col>
            <Col span={12} align="right">
                <Button onClick={() => { clearFilters(); confirm(); }} size="small">
                    {locale.Table.reset}
                </Button>
            </Col>
        </Row>}
    </div >
}

export default Filter;