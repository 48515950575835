import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import FileDialog from '../_common/FileDialog';
import { message } from 'antd';
import { post } from '../_common/utils/fetch';

const metadata = {
    entity: 'SpecialtyDTO',
    key: 'id',
    properties: [
        {
            title: 'Код',
            dataIndex: 'code',
            width: 150,
            editable: true
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true
        },
        {
            title: 'Уровень образования',
            dataIndex: 'educationLevel',
            type: 'entity',
            editable: true,
            entityName: 'educationLevel',
            entityKey: 'id',
            entityOrder: 'ord',
            entityLabel: 'name',
            loadAll: true
        }
    ]
};

const Specialty = (props) => {
    const [tableApi, setTableApi] = useState();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [env] = useEnv();

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/SpecialtyDTO/UploadExcel`,
            formData: {
                File: file
            },
            callback: () => {
                tableApi.refresh();
                message.success("Файл загружен");
            }
        });
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Направления подготовки"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
            env && env.isSuperAdmin && { label: 'Загрузить из Excel', key: 'uploadExcel', onClick: () => setUploadOpen(true) }
        ]}
    >
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
        />
        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel} />
    </MainContent>
}

export default withRouter(Specialty);
