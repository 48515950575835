import React from 'react';
import { InputNumber, Space } from 'antd';
import FilterString from './FilterString';
import locale_en_US from '../locale/en_US';

const FilterNumber = ({ value, onChange, confirm, style = {}, size }) => {
    const locale = locale_en_US;
    const getValue = (value) => {
        if (!value) return value;
        if (value.values && value.values.length > 0) {
            value.values = value.values.map(_ => _.replace(',','.')).filter(_ => !Number.isNaN(Number.parseFloat(_)))
                .map(_ => Number.parseFloat(_).toString());
        }
        if (!value.values || value.values.length === 0) value.values = undefined;
        if (!value.from) value.from = undefined;
        if (!value.till) value.till = undefined;
        return (!value.values && !value.from && !value.till) ? null : { ...value };
    }
    return <React.Fragment>
        <FilterString value={value && value.values} onChange={values => onChange(getValue({ ...value, values: values }))}
            tokenSeparators={[' ',';']} size={size}
        /><br/>
        {locale.Table.orSetInterval}<br/>
        <Space>
        {locale.Table.from} <InputNumber size={size} value={value && value.from} onChange={from => onChange(getValue({ ...value, from: from }))} />
        {locale.Table.till} <InputNumber size={size} value={value && value.till} onChange={till => onChange(getValue({ ...value, till: till }))} />
        </Space>
    </React.Fragment>
}

export default FilterNumber;