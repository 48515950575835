import React, { useState } from 'react';
import { withRouter, Link } from "react-router-dom"
import Table from '../../_common/tables/TableWithCard';
import MainContent from '../../_common/layout/MainContent';
import { meta } from '../projectgradecampaigns/GradeCompany';

const cloneDeep = require('clone-deep');

const ProjectGradeCampaigns = (props) => {
    const [tableApi, setTableApi] = useState();
    const metadata = {
        entity: 'gradeCompany',
        properties: [
            {
                title: '',
                dataIndex: 'id',
                width: 100,
                filterable: false,
                sortable: false,
                render: (text, record) => <Link to={`/Project/${record.project.id}/view/gradeCompany/?selected=${record.id}`}>Посмотреть</Link>,
            },
            {
                title: 'Проект',
                dataIndex: 'project',
                width: 150,
                type: 'entity',
                entityName: 'project',
                entityKey: 'id',
                entityLabel: 'name'
            },
            ...cloneDeep(meta).properties
        ]
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Проектные оценочные кампании"
        onClickRefresh={tableApi && tableApi.refresh}
    >
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={'id'}
            onInit={setTableApi}
            editable={false}
        />
    </MainContent>;
}

export default withRouter(ProjectGradeCampaigns);