import React, { useState, useCallback, useEffect } from 'react';
import { Select } from 'antd';
import { get } from '../utils/fetch'
import useDataProvider from '../dataProviders/useDataProvider';

var debounce = require('lodash.debounce');

const { Option } = Select;

const FieldEntity = ({
    value,
    entityPath, entityName, entityKey = 'id', entityLabel = 'name', entityLabelFunc, loadAll,
    debounceTimeout,
    onChange,
    required,
    style = {},
    mode,
    maxTagCount,
    forwardedRef,
    disabled,

    // trash
    dataIndex,
    editable,
    defaultFilteredValue,
    onCell,
    sortOrder,
    filteredValue,
    onHeaderCell,
    filterDropdown,
    ellipsis,
    render,
    entityFilter,
    entityOrder,
    link,
    context,
    placeholder,
    size,
    // end trash

    ...rest
}) => {
    const dataProvider = useDataProvider(rest);
    const handleSearch = ({value, exact, callback}) => {
        if (!value || loadAll) return;
        let entityFilterValue = entityFilter && typeof entityFilter === 'function'
            ? entityFilter({...context, record: context && context.form && context.form.getFieldsValue()})
            : entityFilter;
        get({
            url: dataProvider.getSearchByTextPath({ entityPath, entityName, entityKey, entityFilter: entityFilterValue, entityLabel, value, exact }),
            callback: (json) => {
                setOptions(json.value);
                if (callback) callback(json.value)
            }
        })
    }

    // eslint-disable-next-line
    const debounceHandleSearch = useCallback(
        debounceTimeout !== 0
            ? debounce(handleSearch, debounceTimeout || 500)
            : handleSearch
        , [entityFilter]);

    const paste = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        let values = pastedData.split("\n").map(_ => _.trim()).filter(_ => _);
        handleSearch({value: values,
            exact: true, 
            callback: (options) => onChange(!value || value.length === 0 ? options : [...value, ...options])
        });
    }

    const [options, setOptions] = useState();

    let vOptions = options ||
        (value ? (Array.isArray(value) ? value : [value]) : null);

    useEffect(() => {
        setOptions()
        let entityFilterValue = entityFilter && typeof entityFilter === 'function'
            ? entityFilter({...context, record: context && context.form && context.form.getFieldsValue()})
            : entityFilter;
        if (loadAll) {
            get({
                url: dataProvider.getAllPath({ entityPath, entityName, entityKey, entityLabel, entityFilter: entityFilterValue, entityOrder }),
                callback: (json) => {
                    setOptions(json.value);
                }
            })
        } else if (value !== null && value !== undefined) {
            let values = Array.isArray(value) ? value : [value];
            if (values.length === 0 || values.filter(_ => _).length === 0) return;
            if (values[0][Array.isArray(entityLabel) ? entityLabel[0] : entityLabel] !== undefined) return;
            let ids = values.map(_ => _[entityKey]);
            get({
                url: dataProvider.getByIdPath({ entityPath, entityName, entityKey, entityLabel, ids }),
                callback: (json) => {
                    setOptions(json.value);
                }
            })
        }
        // eslint-disable-next-line
    }, [entityPath, entityName, entityKey, entityLabel, entityFilter])

    const getLabel = (data) => {
        if (entityLabelFunc) return entityLabelFunc(data);
        const labels = Array.isArray(entityLabel) ? entityLabel : [entityLabel];
        return labels.map(_ => data[_]).join(' ');
    }

    return <Select
        disabled={disabled}
        mode={mode}
        allowClear={!required}
        style={{ ...style, width: '100%' }}
        showSearch
        value={value === null || value === undefined || value.length === 0 ? (mode === 'multiple' ? [] : null)
            : (mode === 'multiple' ? value.filter(_ => _).map(_ => _[entityKey]) : value[entityKey])}
        placeholder={placeholder || (loadAll ? undefined : 'Начните вводить для поиска ...')}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        //filterOption={false}
        onSearch={(value) => { debounceHandleSearch({value}) }}
        onChange={(value, valueOption) => {
            onChange(valueOption === null || valueOption === undefined ? null
                : mode === 'multiple' ? valueOption.map(_ => _.data) : valueOption.data);
        }}
        notFoundContent={'Пока ничего не найдено ...'}
        maxTagCount={maxTagCount || 5}
        ref={forwardedRef}
        onPaste={paste}
        size={size}
    >
        {!vOptions ? null : vOptions.filter(d => d).map(d => { return <Option key={d[entityKey]} value={d[entityKey]} data={d}>{getLabel(d)}</Option> })}
    </ Select>
}

export default React.forwardRef((props, ref) => <FieldEntity {...props} forwardedRef={ref} />)