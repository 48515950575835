import React, { useRef } from 'react';
import { Checkbox, Button, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import locale_en_US from '../locale/en_US';

const Filter = ({ value, onChange, onOk, onClear, locale: propsLocale = {}, filterRender, showButtons = true, showEmpty = true, style, ...rest }) => {
    const locale = {...locale_en_US, ...propsLocale};
    const okRef = useRef();

    const change = (value) => {
        value = (value.value === null || value.value === undefined) && !value.empty && !value.exclude ? undefined : value;
        onChange(value);
        //if (okRef.current) okRef.current.focus();
    }

    const onOkInternal = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (onOk) onOk();
    }

    const component = ({value, onChange}) => filterRender({ value, onChange, size: 'small', ...rest });

    const filter = value || {};
    return <div
        style={style}
        onKeyDown={e => {
            if (e.code === 'Enter') {
                onOkInternal(e);
            }
        }}
    >
        <div>
            {component({ value: filter.value, onChange: (value) => change({ ...filter, value }) })}
        </div>
        {showEmpty && <div style={{marginTop: 5}}>
            <Checkbox checked={filter.empty} onChange={e => change({ ...filter, empty: e.target.checked })}>{locale.Table.empty}</Checkbox>
            <Checkbox checked={filter.exclude} onChange={e => change({ ...filter, exclude: e.target.checked })}>{locale.Table.exclude}</Checkbox>
            <br /></div>}
        {showButtons && <Row style={{ marginTop: 10 }}>
            <Col span={12} align="left">
                <Button ref={okRef} className="b" type="primary" onClick={onOkInternal} onKeyDown={onOkInternal} icon={<SearchOutlined />} size="small">
                    {locale.Table.filter}
                </Button>
            </Col>
            <Col span={12} align="right">
                <Button onClick={() => { onClear(); onOkInternal(); }} size="small">
                    {locale.Table.reset}
                </Button>
            </Col>
        </Row>}
    </div >
}

export default Filter;