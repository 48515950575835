import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import FileDialog from '../_common/FileDialog';
import { post } from '../_common/utils/fetch';
import { message, Checkbox, Select, Row, Col } from 'antd';
import { prepareMetadata } from '../_common/odata/entityodata';
import NotifyCommon from '../notifications/NotifyCommon';
import fileExport from '../_common/utils/fileExport';
const { Option } = Select;

const metadata = {
    entity: 'position',
    key: 'id',
    properties: [
        {
            title: 'ФИО',
            dataIndex: 'user',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            editable: {
                updating: false
            },
            link: true
        },
        {
            title: 'Email',
            dataIndex: ['user', 'email'],
            width: 150,
            editable: false
        },
        {
            title: 'Актуальная',
            dataIndex: ['isActive'],
            type: 'boolean',
            width: 150,
            editable: false
        },
        {
            title: 'Группа',
            dataIndex: 'academicGroup',
            type: 'entity',
            entityName: 'academicGroup',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false,
            width: 150
        },
        {
            title: 'Год начала обучения',
            dataIndex: 'startYear',
            width: 150,
            type: 'number',
            editable: true
        },
        {
            title: 'Кафедры',
            dataIndex: 'departments',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'department2',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false
        },
        {
            title: 'Направления подготовки',
            dataIndex: 'specialties',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'specialty',
            entityKey: 'id',
            entityLabel: ['code', 'name'],
            editable: false
        },
        {
            title: 'Образовательные программы',
            dataIndex: 'educationalPrograms',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'educationalProgram',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false
        }
    ]
};

const getCurrentAcademicYear = () => {
    let dt = new Date();
    return dt.getMonth() >= 8 ? dt.getFullYear() : dt.getFullYear() - 1;
}

const getStudyYears = () => {
    let dt = new Date();
    return [
        dt.getFullYear() - 1,
        dt.getFullYear()
    ]
}

const Student = (props) => {
    const [tableApi, setTableApi] = useState();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [env] = useEnv();
    const [sendEmail, setSendEmail] = useState(false);
    const [fileFormat, setFileFormat] = useState('projectus');
    const [uploadRegime, setUploadRegime] = useState('delta');
    const [uploadFirstStudyYear, setUploadFirstStudyYear] = useState(getCurrentAcademicYear());
    const [selectedRows, setSelectedRows] = useState();
    const [notifyCommonIds, setNotifyCommonIds] = useState();

    prepareMetadata({ metadata });

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Position/UploadStudentExcel`,
            formData: {
                File: file,
                Pars: JSON.stringify({ 
                    sendEmail: sendEmail ? "true" : "false",
                    fileFormat,
                    uploadRegime,
                    uploadFirstStudyYear: `${uploadFirstStudyYear}`
                })
            },
            callback: () => {
                if (tableApi) tableApi.refresh();
                message.success("Файл загружен");
            }
        });
    }

    const resendInvitation = () => {
        if (!selectedRows || selectedRows.length === 0) return;
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Position/ResendInvitation`,
            json: {
                Ids: selectedRows.map(_ => _.id)
            },
            callback: ({ requestCnt, resendCnt }) => {
                let info = resendCnt < requestCnt ? "Приглашения разосланы не всем, т.к. часть участников уже входили в систему." : "";
                message.success(`Приглашения разосланы ${resendCnt} из ${requestCnt}. ${info}`);
            }
        });
    }

    const notifyCommon = (id) => {
        let ids = id
            ? [id]
            : (tableApi ? tableApi.getSelectedRows().map(_ => _.user.id) : null);
        if (!ids || !ids.length) {
            message.warning('Должен быть выделен хотя бы один участник');
            return;
        }

        setNotifyCommonIds(ids);
    }

    const getExcel = () => {
        fileExport(`${window.APPCFG.apiPath}/Position/GetExcel${tableApi.lastUrlSearchParams}&type=STUDENT`);
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Студенты"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
        { label: 'Загрузить из Excel', key: 'uploadExcel', onClick: () => setUploadOpen(true) },
        { label: 'Выгрузить в Excel', key: 'getExcel', onClick: getExcel },
        { label: 'Разослать приглашения в систему выбранным участникам', disabled: !selectedRows || selectedRows.length === 0, key: 'resendInvitation', onClick: resendInvitation },
        { label: 'Послать уведомления выбранным участникам', key: 'notifyCommon', onClick: () => notifyCommon() },
        ]}
    >
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            editable={true}
            onAdd={false}
            onEdit={false}
            onCopy={false}
            onInit={setTableApi}
            history={props.history}
            odataFilter={`clientId eq ${env.userClientId} and typeId eq 'STUDENT'`}
            beforeSaveData={({ record }) => { record.typeId = 'STUDENT'; return record; }}
            rowSelection={{
                onChange: (newSelectedRowKeys, newSelectedRows) => setSelectedRows(newSelectedRows)
            }}
        />
        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel}>
            <Row wrap={false}>
                <Col flex="none" style={{ paddingRight: 10 }}>
                    Формат файла:
                </Col>
                <Col flex="auto"><Select value={fileFormat} onChange={setFileFormat} size='small' style={{ width: '100%' }}>
                    <Option value="projectus">Проектная деятельность</Option>
                    <Option value="tandem">Тандем</Option>
                </Select></Col>
            </Row>
            <Row wrap={false}>
                <Col flex="none" style={{ paddingRight: 10 }}>
                    Режим обновления:
                </Col>
                <Col flex="auto"><Select value={uploadRegime} onChange={setUploadRegime} size='small' style={{ width: '100%' }}>
                    <Option value="delta">Делта: не менять те, которых нет в файле</Option>
                    <Option value="full">Полный: те, которых нет, сделать не актуальными</Option>
                </Select></Col>
            </Row>
            <Row wrap={false}>
                <Col flex="none" style={{ paddingRight: 10 }}>
                    Год старта обучения первого курса:
                </Col>
                <Col flex="auto"><Select value={uploadFirstStudyYear} onChange={setUploadFirstStudyYear} size='small' style={{ width: '100%' }}>
                    {getStudyYears().map(year => <Option key={year} value={year}>{year}</Option>)}
                </Select></Col>
            </Row>
            <Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}>Разослать приглашения новым участникам</Checkbox>

        </FileDialog>
        {notifyCommonIds && <NotifyCommon ids={notifyCommonIds} open={Boolean(notifyCommonIds)} onOk={() => setNotifyCommonIds(null)} onCancel={() => setNotifyCommonIds(null)} />}
    </MainContent>
}

export default withRouter(Student);
