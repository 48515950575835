import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import { useViewRoot } from '../_common/hooks/useViewRoot';
import { useEntity } from '../_common/hooks/useEntity';
import { metadata as metaDocumentType } from './PrintDocumentTypes';
import { EntityForm } from '../_libs/antd-ext';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';

const metadata = {
    entity: 'printDocumentVersion',
    key: 'id',
    properties: [
        {
            title: 'Номер',
            dataIndex: 'num',
            width: 150,
            type: 'number',
            editable: false,
            defaultSortOrder: 'descend'
        },
        {
            title: 'Шаблон',
            dataIndex: 'template',
            width: 250,
            type: 'file',
            required: true,
            editable: {
                updating: false
            }
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: 150,
            type: 'string',
            editable: true
        },
    ]
};

const PrintDocumentType = (props) => {
    let { regime, id } = useViewRoot();
    const [tableApi, setTableApi] = useState();
    const [env] = useEnv();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();

    const [entityData] = useEntity({ metadata: metaDocumentType, id, forceUpdateRequired });

    return <MainContent layoutProps={props.layoutProps}
        title={`Печатная форма: ${entityData ? entityData.name : 'Создание'}`}
        onClickRefresh={tableApi && tableApi.refresh}
    >
        <EntityForm
            metadata={metaDocumentType}
            value={entityData}
            card={false}
            visible={true}
            onSave={(requestId) => {
                if (id !== requestId) props.history.push(`/PrintDocumentType/${requestId}/edit`);
                else forceUpdate();
            }}
        />
        {entityData && <>
            <br />
            <div style={{fontWeight: 'bold', paddingBottom: 5}}>Версии</div>
            <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={props.history}
                odataFilter={`typeId eq ${entityData.id}`}
                onCopy={false}
                rowSelection={false}
                beforeSaveData={({ record }) => {
                    record.typeId = entityData.id;
                    return record;
                }}
                onSave={(requestId) => {
                    forceUpdate();
                }}
            /></>}
    </MainContent>
}

export default withRouter(PrintDocumentType);
