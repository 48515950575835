import React from 'react';
import Chart from '../../_common/utils/Chart';
import './style.css'
import moment from 'moment';
import { DATEFORMAT } from '../../_libs/antd-ext/utils/date';

const GradeChart = ({ gradeCompany, gradeCompanyResult, env, onSave }) => {
    if (!gradeCompanyResult.aggregateAll) return null;

    const memberMapByUser = {}
    const memberGrades = {};
    for (let member of gradeCompanyResult.members) {
        memberMapByUser[member.userId] = member;
        memberGrades[member.userId] = []
    }
    memberGrades[0] = [];
    const labels = [];
    for (let i in gradeCompanyResult.companies) {
        let c = gradeCompanyResult.companies[i];
        labels.push(moment(c.startDate).format(DATEFORMAT));
        let cResults = gradeCompanyResult.aggregateAll[c.id];
        if (cResults) {
            for (let member of gradeCompanyResult.members) {
                var cResult = cResults.filter(_ => _.userId === member.userId);
                memberGrades[member.userId].push(cResult.length > 0 ? cResult[0].grade : NaN);
            }
            if (c.visibleAll) memberGrades[0].push(cResults.reduce((a, b) => a + b.grade, 0) / cResults.length)
        }
    }
    const toStr = (num) => {
        return num.toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(',','.');
    }

    const data = {
        labels: labels,
        datasets: [{
                label: 'Средняя по команде',
                data: memberGrades[0],
                borderWidth: 3,
                borderRadius: 10,
            },
            ...gradeCompanyResult.members.map(g => ({
                label: g.name,
                data: memberGrades[g.userId],
                borderRadius: 10,
            }))
        ]
    };
    const config = {
        type: 'bar',
        data: data,
        options: {
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: 'Оценки'
                },
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Средняя оценка'
                    },
                    suggestedMin: gradeCompany.settings.gradeMin,
                    suggestedMax: gradeCompany.settings.gradeMax
                }
            }
        },
    };
    return <div className='panel'>
        <table className='gradeTable'>
            <tr><td>Участник</td>{labels.map((label, idx) => <td key={idx}>{label}</td>)}</tr>
            {gradeCompanyResult.members.map((r, idx) => (<tr key={idx}>
                <td>{memberMapByUser[r.userId].name}</td>
                {memberGrades[r.userId].map((grade, idx2) => <td key={idx2} className='right'>{toStr(grade)}</td>)}
            </tr>))}
            <tr className='footer'><td>Средняя по команде</td>{memberGrades[0].map((grade, idx2) => <td key={idx2} className='right'>{toStr(grade)}</td>)}</tr>
        </table>
        <Chart
            width={'100%'}
            height={500}
            {...config}
        />
    </div>
}

export default GradeChart;