export function removeItemFirst(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

export function groupByToMap(xs, keyFunc) {
    const result = xs.reduce(function (rv, x) {
        let key = keyFunc(x);
        (rv[key] = rv[key] || []).push(x);
        return rv;
    }, {});
    return result;
};

export function groupByToArray(xs, keyFunc) {
    const result = Object.entries(groupByToMap(xs, keyFunc)).map(_ => [_[0], _[1]]);
    return result;
};

export function sum(xs, keyFunc) {
    var initialValue = 0;
    return xs.reduce(
        (accumulator, currentValue) => accumulator + keyFunc(currentValue),
        initialValue
    );
}

export function setRecordValue(record, dataIndex, value, createSubLevels = true, level = 0) {
    if (!Array.isArray(dataIndex)) {
        record[dataIndex] = value;
        return;
    }
    if (level === dataIndex.length - 1) {
        record[dataIndex[level]] = value;
        return;
    }
    if (!record[dataIndex[level]]) {
        if (!createSubLevels) return;
        record[dataIndex[level]] = {};
    }
    setRecordValue(record[dataIndex[level]], dataIndex, value, createSubLevels, level + 1);
}

export function getRecordValue(record, dataIndex, level = 0) {
    return !record
        ? record
        : !Array.isArray(dataIndex)
            ? record[dataIndex]
            : level === dataIndex.length
                ? record
                : getRecordValue(record[dataIndex[level]], dataIndex, level + 1);
}

export function changeRecordValue(record, dataIndex, valueChanger, createSubLevels = false, level = 0) {
    if (!Array.isArray(dataIndex)) {
        record[dataIndex] = valueChanger(record[dataIndex]);
        return;
    }
    if (level === dataIndex.length - 1) {
        record[dataIndex[level]] = valueChanger(record[dataIndex[level]]);
        return;
    }
    if (!record[dataIndex[level]]) {
        if (!createSubLevels) return;
        record[dataIndex[level]] = {};
    }
    changeRecordValue(record[dataIndex[level]], dataIndex, valueChanger, createSubLevels, level + 1);
}