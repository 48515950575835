import { useState, useEffect } from "react";
import { getEntity } from "../odata/entityodata";

export function useEntity({metadata, id, forceUpdateRequired, extFilter, defaults}) {
    const [entity, setEntityData] = useState();
    useEffect(() => {
        if ((id || extFilter) && metadata) {
            getEntity({ metadata, id, extFilter, callback: (entity) => setEntityData(entity) });
        }
        else setEntityData(defaults);
    }, [metadata, id, extFilter, forceUpdateRequired])
    return [entity, setEntityData];
}