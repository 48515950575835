import React from "react";
import { Button, Row, Col, Select } from 'antd';
import { SearchOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import Filter from '../filters/Filter';
import './style.css';

const Filters = ({ metadata, value: propsValue, onChange, visibleFilters, onChangeVisibleFilters, onFilter, onReset, showEmpty = false }) => {
    const value = propsValue || {};

    const onChangeInternal = ({ key, propertyValue }) => {
        onChange({ ...value, [key]: propertyValue });
    }

    const resetFilters = () => {
        if (onReset) onReset();
        onChange(visibleFilters.map(_ => metadata.map[_]).map(_ => ({ key: _.key, value: null })).reduce((a, v) => ({ ...a, [v.key]: v.value }), {}))
    }

    const add = (key) => {
        onChangeVisibleFilters(visibleFilters => { visibleFilters.push(key); return [...visibleFilters] });
    }

    const up = (key) => {
        const index = visibleFilters.indexOf(key);
        if (index <= 0) return;
        onChangeVisibleFilters(visibleFilters => {
            visibleFilters.splice(index, 1);
            visibleFilters.splice(index - 1, 0, key);
            return [...visibleFilters]
        });
    }

    const down = (key) => {
        const index = visibleFilters.indexOf(key);
        if (index >= visibleFilters.length - 1) return;
        onChangeVisibleFilters(visibleFilters => {
            visibleFilters.splice(index, 1);
            visibleFilters.splice(index + 1, 0, key);
            return [...visibleFilters]
        });
    }

    const remove = (key) => {
        const index = visibleFilters.indexOf(key);
        onChangeVisibleFilters(visibleFilters => {
            visibleFilters.splice(index, 1);
            return [...visibleFilters]
        });
        onChange({ ...value, [key]: null });
    }

    const renderedFilters = !visibleFilters || !Array.isArray(visibleFilters)
        ? null
        : visibleFilters.map(_ => metadata.map[_])
            .map((property, idx) => <div key={idx}>
                <Row><Col flex="auto">{property.title}</Col><Col flex="none">
                    <ArrowUpOutlined title="Поднять выше" onClick={() => up(property.key)} className='protonus-up' />
                    <ArrowDownOutlined title="Опустить ниже" onClick={() => down(property.key)} className='protonus-down' />
                    <DeleteOutlined title="Убрать из списка" onClick={() => remove(property.key)} className='protonus-remove' />
                </Col></Row>
                <div style={{ paddingLeft: 10, paddingBottom: 6 }}>
                    <Filter value={value[property.key]} onChange={(value) => onChangeInternal({ key: property.key, propertyValue: value })}
                        showButtons={false}
                        showEmpty={showEmpty}
                        filterRender={property.filterRender}
                        {...property}
                    />
                </div></div>);

    const propertiesToAdd = metadata.dataProperties.filter(_ => visibleFilters.indexOf(_.key) < 0);

    return <div className='protonus-params'>
        {renderedFilters}
        <Row style={{ marginTop: 5 }} wrap={false}>
            <div className='protonus-add'>Добавьте фильтр<div style={{ paddingLeft: 10 }}>
                <Select size="small" style={{ width: '288px' }} onChange={add} options={propertiesToAdd.map(_ => ({ label: _.title, value: _.key }))} />
            </div></div>
        </Row>
        {(onFilter || onReset) && <Row style={{ marginTop: 10 }} wrap={false}>
            <Col span={12} align="left">
                {onFilter && <Button className="b" type="primary" onClick={(e) => { onChange({ ...value }); if (onFilter) onFilter(); }} onKeyDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onChange({ ...value });
                    if (onFilter) onFilter();
                }} icon={<SearchOutlined />} size="small">
                    Фильтровать
                </Button>}
            </Col>
            <Col span={12} align="right" style={{ paddingLeft: 30 }}>
                {onReset && <Button onClick={resetFilters} size="small">
                    Очистить
                </Button>}
            </Col>
        </Row>}
    </div>;
}

export default Filters;