import React, { useReducer } from 'react';
import { useList, Table, useMetadata, globalDataProvider } from '../lib';
import { UndoOutlined } from '@ant-design/icons';

const metaCategories = {
    entity: 'Categories',
    key: 'CategoryID',
    properties: [
        {
            title: 'CategoryName',
            dataIndex: 'CategoryName',
            width: 250
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            sortable: false,
            filterable: false,
            type: 'text',
            width: 250
        }
    ]
}

const metaProducts = {
    entity: 'Products',
    key: 'ProductID',
    properties: [
        {
            title: 'ProductName',
            dataIndex: 'ProductName',
            width: 250
        },
        {
            title: 'UnitPrice',
            dataIndex: 'UnitPrice',
            type: 'number',
            width: 250
        },
        {
            title: 'Discontinued',
            dataIndex: 'Discontinued',
            type: 'boolean',
            width: 250
        }
    ]
}

const metaOrders = {
    entity: 'Orders',
    key: 'OrderID',
    properties: [
        {
            title: 'OrderDate',
            dataIndex: 'OrderDate',
            type: 'date',
            width: 250
        },
        {
            title: 'Header',
            children: [
                {
                    title: 'Freight',
                    dataIndex: 'Freight',
                    type: 'number',
                    width: 250,
                    //editable: false
                },
                {
                    title: 'ShipAddress',
                    dataIndex: 'ShipAddress',
                    width: 250
                },
            ]
        },
        {
            title: 'Customer',
            dataIndex: ['Customer', 'CompanyName'],
            width: 250
        },
        {
            title: 'Employee',
            dataIndex: 'Employee',
            type: 'entity',
            entityName: 'Employees',
            entityKey: 'EmployeeID',
            entityLabel: ['LastName', 'FirstName'],
            width: 250
        },
        {
            title: 'Products',
            dataIndex: 'Order_Details',
            key: 'Products',
            type: 'entity',
            multiple: true,
            multipleWrapperIndex: 'Product',
            entityName: 'Products',
            entityKey: 'ProductID',
            entityLabel: 'ProductName',
            width: 250
        },
        /*{
            title: 'Order_Details',
            dataIndex: 'Order_Details',
            key: 'Order_Details',
            type: 'entity',
            multiple: true,
            entityName: 'Order_Details',
            entityKey: 'ProductID',
            entityLabel: ['Product/ProductName'],
            width: 250
        }*/
    ]
}

const Northwind = () => {
    const [updateRequired, forceUpdate] = useReducer(x => x + 1, 1);
    globalDataProvider.path = 'https://services.odata.org/V4/Northwind/Northwind.svc';
    return <div style={{padding: 10}}>
        Data from <a href="https://services.odata.org/V4/Northwind/Northwind.svc/">https://services.odata.org/V4/Northwind/Northwind.svc/</a><br/>
        Metadata: <a href="https://services.odata.org/V4/Northwind/Northwind.svc/$metadata">https://services.odata.org/V4/Northwind/Northwind.svc/$metadata</a><br/>
        <UndoOutlined onClick={forceUpdate} /><br/>
        <Orders updateRequired={updateRequired} />
        {/*<Categories />
        <Products />*/}
    </div>
}

const Orders = ({updateRequired}) => {
    const metadata = useMetadata(metaOrders);
    const list = useList({metadata, refresh: updateRequired});
    return <React.Fragment>
        Orders<br/>
        <Table metadata={metadata} list={list} fullscreen />
    </React.Fragment>
}

export default Northwind;