import React, { useState } from "react";
import { Button, Dropdown, Badge, Tabs } from 'antd';
import { ControlOutlined } from "@ant-design/icons";
import Filters from './Filters';
import Sorters from './Sorters';
import { useLocalStorageState, getLocalStorageKey } from '../hooks/useLocalStorageState';
import './style.css';

const Params = ({ title = 'Параметры', params = ['filters', 'sorters'], defaultVisibleFilters = [], metadata, filters, onChangeFilters, sorters, onChangeSorters, storagePostfix = 'params' }) => {
    const [open, setOpen] = useState(false);
    const [visibleFilters, setVisibleFilters] = useLocalStorageState(defaultVisibleFilters, { code: getLocalStorageKey(storagePostfix) })
    const button = <Button icon={<ControlOutlined />} title={title} />;
    const filtersLength = !filters ? 0 : visibleFilters.filter(_ => filters[_] !== null && filters[_] !== undefined).length;
    const sortersLength = !sorters ? 0 : sorters.length;
    const buttonComponent = filtersLength + sortersLength > 0
        ? <Badge count={filtersLength + sortersLength} size="small">{button}</Badge>
        : button;
    const items = params.map((p) => ({
        key: p,
        label: p === 'filters'
            ? `Фильтры${filtersLength > 0 ? ` (${filtersLength})` : ''}`
            : p === 'sorters'
                ? `Сортировки${sortersLength > 0 ? ` (${sortersLength})` : ''}`
                : '',
        children: p === 'filters'
            ? <Filters visibleFilters={visibleFilters} onChangeVisibleFilters={setVisibleFilters} dropdown={false} metadata={metadata} value={filters} onChange={onChangeFilters} onFilter={() => setOpen(false)} onReset={() => setOpen(false)} />
            : p === 'sorters'
                ? <Sorters dropdown={false} metadata={metadata} value={sorters} onChange={onChangeSorters} onSort={() => setOpen(false)} onReset={() => setOpen(false)} />
                : null,
    }))
    const component = items.length === 0
        ? <div><b>{items[0].label}</b><br/>{items[0].children}</div>
        : <Tabs items={items} />
    return <Dropdown open={open} onOpenChange={() => setOpen(!open)} trigger={['click']} placement="bottomRight" dropdownRender={() => <div className='protonus-params-panel'>
        {component}
    </div>}>
        {buttonComponent}
    </Dropdown>
}

export default Params;