import { Link } from "react-router-dom";

const meta = {
    entity: 'request',
    key: 'id',
    properties: [
        {
            title: 'Название',
            required: true,
            dataIndex: 'name',
            width: 250,
            description: 'Укажите название проекта, раскрывающее его суть',
            render: (text, record) => record ? <Link to={`/Request/${record.id}`}>{text}</Link> : text
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'requestState',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            editable: false,
            loadAll: true,
            responsive: ["sm"],
            render: (text, record) => {
                let color = record.state.code === 'OPEN'
                    ? 'black'
                    : record.state.code === 'DRAFT'
                        ? 'lightgreen'
                        : record.state.code === 'CLOSED'
                            ? 'lightgray'
                            : record.state.code === 'ACCEPTED'
                                ? 'green'
                                : record.state.code === 'AGREED' || record.state.code === 'READYFORAGREED'
                                    ? 'brown'
                                    : record.state.code === 'REJECTED'
                                        ? 'lightgray'
                                        : 'black';
                return <div style={{ color: color }}>{text.name}</div>
            }
        },
        {
            title: 'Заказчик',
            dataIndex: 'customer',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            editable: false,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            type: 'text',
            editable: true,
            required: true,
            showInTable: false,
            description: 'Полно и подробно опишите суть проекта, откуда появилась потребность, какие проблемы он решает',
        },
        {
            title: 'Цель',
            dataIndex: 'goal',
            type: 'text',
            editable: true,
            showInTable: false,
            description: 'Опишите цели и задачи проекта. Целью проекта может быть разработка продукта или услуги, прирост научного знания и т.п.'
        },
        {
            title: 'Результат/продукт',
            dataIndex: 'result',
            type: 'text',
            editable: true,
            showInTable: false,
            description: 'Опишите требования к продукту/результату'
        },
        {
            title: 'Критерии приемки',
            dataIndex: 'acceptanceCriteria',
            type: 'text',
            editable: true,
            showInTable: false,
            description: 'Опишите необходимый формат представления результатов, который подлежит оцениванию'
        },
        {
            title: 'Видимость',
            dataIndex: 'requestVisibility',
            width: 150,
            type: 'entity',
            entityName: 'requestVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            editable: true,
            loadAll: true,
            required: true,
            showInTable: false,
            description: <span>Укажите, кто может просматривать заявку на проект.<br />
                1. Все: Заявка будет видна и доступна для рассмотрения всем организациям, которые подключены к системе. <br />
                2. Партнеры: Заявка будет видна и доступна для рассмотрения для организаций, с которыми заключено партнерство. <br />
                3. Организация: Заявка, по умолчанию, будет видна только вашей организации.</span>
        },
        {
            title: 'Создатель заявки',
            dataIndex: 'customerUser',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Инициатива',
            dataIndex: 'isInitiative',
            width: 150,
            type: 'boolean',
            editable: false,
            responsive: ["sm"]
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            width: 150,
            type: 'date',
            editable: false,
            responsive: ["sm"],
            defaultSortOrder: 'descend'
        }
    ],
    defaults: {
        //required: true,
        editable: true,
        //sortable: true
    }
};

export default meta;