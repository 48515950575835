import React, { PureComponent } from 'react';

export default class LoginPanel extends PureComponent {
    componentDidMount() {
        var prevLocation = window.location.href;
        if (prevLocation.indexOf("/Login")) prevLocation = prevLocation.replace("/Login", "");
        window.location.href = `${window.APPCFG.apiPath}/System/Login?returnUrl=` + encodeURIComponent(prevLocation);
    }

    render = () => {
        return <React.Fragment>
            <div align="center" style={{ width: '100%', justifyContent: 'center' }}>
                {"Перенаправление к Identity Provider..."}
            </div>
        </React.Fragment>;
    }
}