import React from 'react';
import Field from './Field';
import { Collapse, Tabs } from 'antd';
import { groupByToArray } from '../utils/array';
import CardBlock from '../card/CardBlock';

const Fields = ({ metadata, regime, record, disabled, context, showDisabledNull = false }) => {
    const creating = regime === 'creating';

    return <CardBlock properties={metadata.properties} metadata={metadata} creating={creating} record={record} 
        disabled={disabled} context={context} showDisabledNull={showDisabledNull}
        singlePropertyRender={(props) => <SingleField {...props} />} />
}

const SingleField = ({ prefix, property, metadata, creating, record, disabled, context, showDisabledNull = false }) => {
    let propertyDisabled = disabled || metadata.getPropertyDisabled({ sourceRecord: record, property: property, creating });
    let hidden = (!showDisabledNull && (propertyDisabled && record && (record[property.dataIndex] === null || record[property.dataIndex] === undefined))) || property.hidden;
    if (hidden) return null;
    return <Field
        key={property.dataIndex}
        name={property.dataIndex}
        label={prefix ? `${prefix}${property.title}` : property.title}
        type={property.type}
        {...property}
        disabled={propertyDisabled}
        sourceRecord={record}
        context={context}
    />
}

export default Fields;