import { get, post, patch } from '../../utils/fetch';
import { changeRecordValue, getRecordValue } from '../../utils/array';
import { getAllPath } from './odata';

export let globalApiPath = window.APPCFG.apiPath;

/*export function prepareMetadata({ metadata }) {
    if (metadata._prepared) return metadata;

    metadata.types = metadata.types || types || {};

    for (let property of metadata.properties) {
        property.key = getPropertyKey(property);
        property.odataName = getPropertyODataName(property.dataIndex);
        property.odataConvertBeforePostFunc = property.odataConvertBeforePostFunc || (metadata.types[property.type] && metadata.types[property.type].odataConvertBeforePostFunc);
        property.renderFunc = property.render || (metadata.types[property.type] && metadata.types[property.type].render && metadata.types[property.type].render({column: property, metadata: metadata}));
    }

    metadata.hasDynamicProperties = metadata.properties.filter(_ => _.isDynamic).length > 0;

    if (metadata.key && metadata.properties.map(_ => _.key).indexOf(metadata.key) < 0) {
        const keyProperty = {
            title: 'id',
            dataIndex: metadata.key,
            hidden: true,
            editable: false
        };
        metadata.properties = [...metadata.properties, keyProperty];
    }

    metadata.propertyMap = Object.assign({}, ...metadata.properties.map(_ => ({ [_.key]: _ })));

    metadata._prepared = true;

    return metadata;
}*/

export function getPropertyKey(property) {
    if (property.key) return property.key;
    if (property.dataIndex) return Array.isArray(property.dataIndex) ? property.dataIndex.join('.') : property.dataIndex;
}

export function getPropertyODataName(dataIndex) {
    return Array.isArray(dataIndex) ? dataIndex.join('/') : dataIndex;
}

export function getOrderby({ metadata, sorters }) {
    if (!metadata || !sorters || sorters.length === 0) return '';
    let orderbyPars = [];
    for (let sorter of sorters) {
        const property = metadata.map[sorter.key];
        const odataName = getPropertyODataName(property.dataIndex);
        const odataOrderByFunc = property.dataProviderProps && property.dataProviderProps.order;
        const propertyODataNames = odataOrderByFunc
            ? odataOrderByFunc({ property, odataName })
            : [getPropertyODataName(property.dataIndex)];
        let notExisting = propertyODataNames.filter(n => !orderbyPars.some(_ => _.startsWith(n)));
        if (notExisting.length > 0) {
            orderbyPars.push(notExisting.map(_ => `${_} ${(sorter.order === 'descend' ? 'desc' : 'asc')}`).join(','));
        }
    }
    orderbyPars = orderbyPars.filter((v, i, a) => a.indexOf(v) === i);
    return orderbyPars.length === 0 ? '' : `&$orderby=${orderbyPars.join(',')}`;
}

function getExtFilter({ metadata, filters, filter }) {
    if (!filter) return [];
    const f = filter instanceof Function ? filter({ filters }) : filter;
    const fs = !f ? [] : Array.isArray(f) ? f : [f];
    return fs;
}

export function getFilter({ metadata, filters, extFilter }) {
    if (!metadata) return '';
    let filterPars = [];

    let extFilters = getExtFilter({ metadata, filters, filter: metadata.filter });
    if (extFilters.length > 0) filterPars = [...filterPars, ...extFilters];
    extFilters = getExtFilter({ metadata, filters, filter: extFilter });
    if (extFilters.length > 0) filterPars = [...filterPars, ...extFilters]

    if (filters) for (let property of metadata.properties) {
        if (!property.dataIndex && (!property.dataProviderProps || !property.dataProviderProps.filter)) continue;

        const filterName = property.key;
        const filter = filters[filterName];
        let columnFilterPars = []
        if (filter) {
            const filterValue = filter.value;
            const odataName = property.isDynamic
                ? `a/${property.metaColumn}`
                : getPropertyODataName(property.dataIndex);
            const filterPrefix = '';//property.isDynamic ? `${dynamicAttributesCode}/any(a: a/metaId eq ${property.metaId} and ` : '';
            const filterPostfix = '';//property.isDynamic ? ')' : '';

            const odataFilterFunc = property.dataProviderProps && property.dataProviderProps.filter;

            if (odataFilterFunc) {
                odataFilterFunc({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars });
                if (columnFilterPars.length > 0) {
                    let f = `(${columnFilterPars.join(' or ')})`;
                    if (filter.exclude) f = `(not ${f})`;
                    filterPars.push(f);
                }
            }
            else {
                if (filterValue && filterValue.length > 0) columnFilterPars.push(`(${filterValue.map(fv => `${filterPrefix}contains(tolower(${odataName}),'${fv.toLowerCase().replace("'","''")}')${filterPostfix}`).join(' or ')})`);
                if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
                if (columnFilterPars.length > 0) {
                    let f = `(${columnFilterPars.join(' or ')})`;
                    if (filter.exclude) f = `(not ${f})`;
                    filterPars.push(f);
                }
            }
        }
    }
    return filterPars.length === 0 ? '' : `&$filter=${filterPars.join(' and ')}`;
}

function getSelectExpand({ metadata }) {

    const getFullDataIndex = (property, dataIndex) => {
        let index = Array.isArray(dataIndex)
            ? dataIndex
            : dataIndex.indexOf('/') > 0
                ? dataIndex.split('/')
                : [dataIndex];
        if (property.multipleWrapperIndex) {
            index = index.concat(property.multipleWrapperIndex);
        }   
        return index;
    }

    const getSelectExpandInternal = (property, pars, dataIndexPar, level) => {
        let dataIndex = dataIndexPar;

        if (property.isDynamic) return pars;

        if (property.selectable === false) return pars;

        let name = !Array.isArray(dataIndex) 
            ? dataIndex
                : property.isNavigation === false
                    ? dataIndex.join('/')
                    : dataIndex[level];
        if (dataIndex.length === level + 1 || property.isNavigation === false) {
            const odataSelectExpandFunc = property.dataProviderProps && property.dataProviderProps.select
            if (odataSelectExpandFunc) {
                odataSelectExpandFunc({ pars, property, name });
            }
            else {
                if (!pars.select.includes(name)) pars.select.push(name);
            }
        } else {
            if (!pars.expand[name]) {
                pars.expand[name] = { select: [], expand: {} }
            }
            getSelectExpandInternal(property, pars.expand[name], dataIndex, level + 1)
        }

        if (property.extSelect) {
            for (let es of property.extSelect) {
                if (!pars.select.includes(es)) pars.select.push(es);
            }
        }

        return pars;
    }

    const getSelectExpandParsInternal = (pars, level) => {
        const select = pars.select && pars.select.filter(_ => _).length > 0 ? `$select=${pars.select.filter(_ => _).join(',')}` : null;
        const expand = Object.entries(pars.expand).length > 0 ? `$expand=${Object.entries(pars.expand).map(_ => `${_[0]}${getSelectExpandParsInternal(_[1], level + 1)}`).join(',')}` : null;
        var parts = [select, expand].filter(_ => _);
        return level === 0 ? parts.join('&') : parts.length === 0 ? '' : `(${parts.join(';')})`;
    }

    let pars = {
        select: [],
        expand: {}
    }
    if (metadata.key) pars.select.push(metadata.key);
    /*if (metadata.hasDynamicProperties) {
        pars.expand[dynamicAttributesCode] = { select: [dynamicAttributesSelect], expand: {} };
    }*/
    for (let property of metadata.properties) {
        if (!property.dataIndex) continue;
        pars = getSelectExpandInternal(property, pars, getFullDataIndex(property, property.dataIndex), 0);
    }
    const selectExpandPars = getSelectExpandParsInternal(pars, 0);
    return selectExpandPars;
}

export function getBasePath({ metadata, globalPath }) {
    if (metadata.path) return metadata.path;
    if (metadata.entity) {
        let path = `${metadata.basePath || globalPath}/${metadata.entity}?`;
        const selectExpandPars = getSelectExpand({ metadata });
        path = `${path}${selectExpandPars}`;
        return path;
    }
}

export function getPath({ globalPath, metadata, sorters, filters, extFilter, afterGetPath, afterGetPathParts, pageSize, currentPage, count = true }) {
    //metadata = prepareMetadata({ metadata });
    const skip = currentPage > 1 ? (currentPage - 1) * pageSize : 0;
    let pathParts = {
        path: getBasePath({ metadata, globalPath }),
        orderbyPar: getOrderby({ metadata, sorters }),
        filterPar: getFilter({ metadata, filters, extFilter }),
        skipPar: skip !== 0 ? `&$skip=${skip}` : '',
        topPar: pageSize ? `&$top=${pageSize}` : '',
        countPar: count ? `&$count=true` : ''
    }
    if (afterGetPathParts) pathParts = afterGetPathParts(pathParts);
    let fullPath = `${pathParts.path}${pathParts.filterPar}${pathParts.orderbyPar}${pathParts.skipPar}${pathParts.topPar}${pathParts.countPar}`;
    if (afterGetPath) fullPath = afterGetPath(fullPath);
    return fullPath;
}

export function getData({ globalPath, isGlobalProcessing, metadata, sorters, filters, extFilter, afterGetPath, pageSize, currentPage, count, total, callback, errorCallback, asyncInternalCallback, path }) {
    //metadata = prepareMetadata({ metadata });
    //this.setLoading(true);
    //this.cancelEdit();
    let url = path || getPath({ globalPath, metadata, sorters, filters, extFilter, afterGetPath, pageSize, currentPage, count: count === undefined ? (total === undefined || total === null) : count });
    //this.lastUrl = url;
    //this.lastUrlSearchParams = this.getUrlSearchParams(this.lastUrl);
    get({
        url: url,
        isGlobalProcessing,
        callback: (data) => {
            //this.setLoading(false);
            //this.setDataSource(this.convertData(data.value));
            const value = data.value ? convertData({ metadata, data: data.value }) : convertData({ metadata, data: [data] });
            let newTotal = data['@odata.count'];
            var internals = metadata.properties.filter(_ => _.isDynamic && _.type === 'entity');
            if (internals.length === 0) {
                callback(value, { total: newTotal || total })
            } else {
                refreshInternals({ metadata, value, internals, callback: value => callback(value, { total: newTotal || total }) });
            }
        },
        errorCallback: errorCallback
    })
};

export function getEntity({ metadata, id, callback, extFilter }) {
    getData({
        metadata,
        count: false,
        extFilter: extFilter || `${metadata.key} eq ${id}`,
        callback: (data) => callback(data[0])
    });
}

/*export function getEntityNew({metadata, id, callback, extFilter}) {
    getData({
        metadata,
        count: false,
        path: `${globalPath}/${metadata.entity}(${id})`,
        //extFilter: extFilter || `${metadata.key} eq ${id}`,
        callback: (data) => {
            callback(data[0]);
        }
    });
}*/

function convertData({ metadata, data }) {
    if (!data) return data;
    for (let record of data) {
        convertDataRecord({ metadata, record });
    }
    return data;
}

function convertDataRecord({ metadata, data, record }) {
    //record = toEntity({ entity: record, properties: metadata.properties });
    for (let property of metadata.properties) {
        const odataConvertAfterGetFunc = property.dataProviderProps && property.dataProviderProps.deserialize

        if (odataConvertAfterGetFunc) {
            const valueChanger = odataConvertAfterGetFunc({ property });
            changeRecordValue(record, property.dataIndex, valueChanger)
        }
    }
    return record;
}

function refreshInternals({ metadata, value, internals, callback }) {
    if (internals.length === 0) {
        callback(value);
        return;
    }
    const c = internals[0];
    get({
        url: getAllPath({ entityPath: c.entityPath, entityKey: c.entityKey, entityLabel: c.entityLabel, entityFilter: c.entityFilter }),
        callback: (data) => {
            for (let record of value) {
                if (record[c.dataIndex]) {
                    let v = data.value.filter(_ => _[c.entityKey] === record[c.dataIndex][c.entityKey]);
                    if (v && v.length > 0) {
                        record[c.dataIndex][c.entityLabel] = v[0][c.entityLabel];
                    }
                }
            }
            refreshInternals({ metadata, value, internals: internals.slice(1), callback });
        }
    });
}

export function prepareToSave({ metadata, value, beforeSaveData, creating, editingKey }) {
    //value = fromEntity({ entity: value, properties: metadata.properties });
    if (value) for (let property of metadata.properties) {
        const odataConvertBeforePostFunc = property.dataProviderProps && property.dataProviderProps.serialize
        if (odataConvertBeforePostFunc) {
            let _value = getRecordValue(value, property.dataIndex);
            if (_value !== null && _value !== undefined) { 
                let _parentNode = Array.isArray(property.dataIndex) ? getRecordValue(value, property.dataIndex.slice(0, -1)) : value;
                let _key = Array.isArray(property.dataIndex) ? property.dataIndex[property.dataIndex.length - 1] : property.dataIndex;
                odataConvertBeforePostFunc({ property: property, json: _parentNode, key: _key })
            }
        }
    }

    value[metadata.key] = editingKey;
    if (beforeSaveData) value = beforeSaveData({ record: value, creating, editingKey });
    return value;
}

export function saveData({ metadata, value, creating, editingKey, beforeSaveData, onSave, afterGetPath }) {
    value = prepareToSave({ metadata, value, beforeSaveData, creating, editingKey });
    let path = `${metadata.basePath || globalApiPath}/${metadata.entity}`;
    //if (!creating) path = `${path}(${editingKey})`;
    if (afterGetPath) path = afterGetPath(path);
    if (creating) {
        post({
            url: path,
            json: value,
            callback: (data) => {
                if (onSave) onSave(data, { request: value });
            }
        })
    } else {
        patch({
            url: path,
            json: value,
            callback: (data) => {
                if (onSave) onSave(data, { request: value });
            }
        });
    }
}
