import { addTo } from "../grades/projectgradecampaigns/GradeCompanySettings";

const meta_ = {
    entity: 'projectSettings',
    key: 'id',
    properties: [
        {
            title: 'Workflow',
            dataIndex: 'workflow',
            width: 150,
            editable: true,
            multiple: true
        },
        {
            title: 'Видимость проектов',
            dataIndex: 'projectVisibility',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'projectVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true
        },
        {
            title: 'Ссылка на проект во внешнем трекере',
            dataIndex: 'externalIssueTrackerUrl',
            width: 150,
            editable: true
        },
        {
            title: 'Частота проведения оценки, в днях',
            dataIndex: 'gradeCompanyInterval',
            type: 'number',
            width: 150,
            editable: true,
            description: 'Если задано число, то оценочные кампании будет запускаться автоматически через заданный интервал, если ничего не задано необходимо запускать оценочные кампании вручную'
        },
        {
            title: 'Длительность оценки, в днях',
            dataIndex: 'gradeCompanyDuration',
            type: 'number',
            width: 150,
            editable: true,
            description: 'Если задано число, то кампания автоматически закроется через заданное число дней, если ничего не задано необходимо закрывать кампанию вручную'
        }
    ]
};

const metadata = addTo({metadata: meta_, settingsName: 'gradeCampaignSettings', clone: true, required: false});

export default metadata;