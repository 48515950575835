import React from 'react';
import FieldDate from './FieldDate';

const FieldDatePeriod = ({ value, onChange, style={}, allowClear=true, ...rest }) => {
    const getValue = (value) => {
        return (!value.from && !value.till) ? null : value;
    }
    return <React.Fragment>
        <FieldDate
            value={value && value.from}
            onChange={(date) => onChange(getValue({ ...value, from: date }))}
            placeholder="Выберите дату, от"
            style={{
                ...style,
                marginBottom: 8,
                width: '100%',
                display: 'block',
            }}
            allowClear={allowClear}
            {...rest}
        />
        <FieldDate
            value={value && value.till}
            onChange={(date) => onChange(getValue({ ...value, till: date }))}
            placeholder="Выберите дату, до"
            style={{
                ...style,
                width: '100%',
                display: 'block',
            }}
            allowClear={allowClear}
            {...rest}
        />
    </React.Fragment>
}

export default FieldDatePeriod;