import React, { useEffect, useState } from 'react';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { EntityForm, EntityViewer } from '../_libs/antd-ext';
import { get, post } from '../_common/utils/fetch';
import { EditOutlined, CreditCardOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Button, message, notification } from 'antd';
import RequestInfo from './RequestInfo';
import ConsiderationAddDialog from './ConsiderationAddDialog';
import { getEntity } from '../_common/odata/entityodata';
import { useViewRoot, getViewRootPath } from '../_common/hooks/useViewRoot';
import { useEnv } from '../_common/hooks/useEnv';
import metadata from './meta';
import CommentDialog from '../_common/CommentDialog';

const Request = (props) => {
    const [entityData, setEntityData] = useState();
    const [considerationAddDialogOpen, setConsiderationAddDialogOpen] = useState(false);
    const [requestId, setRequestId] = useState();
    let { mainRoot, regime, id } = useViewRoot();
    const [env, { hasRoles }] = useEnv();
    const [requestConsideration, setRequestConsideration] = useState();
    const [notFound, setNotFound] = useState(false);
    const [requestRegime, setRequestRegime] = useState();
    const [requestConsiderationRegime, setRequestConsiderationRegime] = useState();

    const refreshEntityData = (data) => {
        setEntityData(data);
        setNotFound(!data);
    }

    useEffect(() => {
        if (id) {
            getEntity({ metadata, id, callback: refreshEntityData });
            setRequestId(id);
        }
        else setEntityData();
    }, [id])

    const refresh = () => {
        getEntity({ metadata, id, callback: refreshEntityData });
        setRequestId(null);
        setRequestId(id);
    }

    const changeState = ({ ids, state, rejectionComment}) => {
        post({
            url: `${window.APPCFG.apiPath}/Request/ChangeState`,
            json: {
                ids,
                state,
                rejectionComment
            },
            callback: () => {
                message.success("Состояние изменено");
                refresh();
            }
        })
    }

    const proposeForConsideration = ({ ids, clientIds }) => {
        post({
            url: `${window.APPCFG.apiPath}/Request/ProposeForConsideration`,
            json: {
                ids,
                clientIds
            },
            callback: () => {
                message.success("Предложения к рассмотрению созданы");
                refresh();
            }
        })
    }

    const takeIntoConsideration = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Request/TakeIntoConsideration`,
            json: {
                ids
            },
            callback: () => {
                message.success("Заявка взята на рассмотрение");
                refresh();
            }
        })
    }

    const rejectRequestConsideration = (requestId, rejectionComment) => {
        post({
            url: `${window.APPCFG.apiPath}/Request/Reject`,
            json:
                [
                    { 
                        requestId,
                        rejectionComment 
                    }
                ],
            callback: () => {
                message.success("Заявка отклонена");
                refresh();
            }
        })
        setRequestConsiderationRegime(null);
    }

    const createProject = () => {
        props.history.push(`/Project/0/create`, {
            request: { ...entityData }
        });
    }

    let permissions = {};
    let isCustomer = null;

    if (entityData) {
        isCustomer = (env && entityData && entityData.customer)
            ? env.userActiveClientId === entityData.customer.id
            : null;

        permissions.canEditOwn = entityData.customerUser.id === env.userId 
            && (entityData.state.code === 'DRAFT' || entityData.state.code === 'READYFORAGREED');
        permissions.canEdit = isCustomer && hasRoles('PROJECT_CREATOR');
        permissions.canAnyEdit = permissions.canEdit || permissions.canEditOwn;
    }

    if (regime === 'edit' && !permissions.canAnyEdit) regime = 'view';

    useEffect(() => {
        if (isCustomer !== false || !hasRoles('PROJECT_CREATOR')) {
            setRequestConsideration(undefined);
            return;
        }
        get({
            url: `${window.APPCFG.odataPath}/RequestConsideration?$filter=RequestId eq ${entityData.id} and ExecutorId eq ${env.userActiveClientId}`,
            callback: (data) => {
                if (data.value.length > 0) setRequestConsideration(data.value[0]);
            }
        })
    }, [isCustomer, entityData, env.userActiveClientId])

    const title = notFound
        ? 'Не существует или недоступна по настройкам видимости'
        : regime === 'view'
            ? (entityData && entityData.name)
            : regime === 'edit' && id
                ? `${entityData && entityData.name} - Редактирование`
                : 'Создание';
    
    let menu = entityData && [
        isCustomer === true && hasRoles('PROJECT_CREATOR') && entityData.state.code !== 'OPEN' && { label: 'Открыть', key: "changeStateOpen", onClick: () => changeState({ ids: [id], state: 'OPEN' }) },
        isCustomer === true && hasRoles('PROJECT_CREATOR') && entityData.state.code !== 'CLOSED' && { label: 'Закрыть', key: "changeStateClosed", onClick: () => changeState({ ids: [id], state: 'CLOSED' }) },
        isCustomer === true && hasRoles('PROJECT_CREATOR') && entityData.state.code !== 'DRAFT' && { label: 'Вернуть в черновик', key: "changeStateDraft", onClick: () => changeState({ ids: [id], state: 'DRAFT' }) },
        isCustomer === true && hasRoles('PROJECT_CREATOR') && (entityData.state.code === 'OPEN' || entityData.state.code === 'CONSIDERED') &&  { label: 'Предложить к рассмотрению', key: "proposeForConsideration", onClick: () => setConsiderationAddDialogOpen(true) },
        isCustomer === false && hasRoles('PROJECT_CREATOR') && (!requestConsideration || requestConsideration.stateId === 'NEW' || requestConsideration.stateId === 'REJECTED') && { label: 'Взять на рассмотрение', key: "takeIntoConsideration", onClick: () => takeIntoConsideration({ ids: [id] }) },
        hasRoles('PROJECT_CREATOR') && (entityData.state.code === 'OPEN' || entityData.state.code === 'CONSIDERED') && { label: 'Согласовать и создать проект', key: "createProject", onClick: () => createProject({ ids: [id] }) },
        isCustomer === false && hasRoles('PROJECT_CREATOR') && requestConsideration && (requestConsideration.stateId === 'CONSIDERED' || requestConsideration.stateId === 'NEW') && { label: 'Отклонить', key: "reject", onClick: () => setRequestConsiderationRegime('reject') },
        permissions.canEditOwn && !permissions.canEdit && entityData.state.code === 'DRAFT' && { label: 'Передать на согласование', key: "changeStateReadyForAgreed", onClick: () => changeState({ ids: [id], state: 'READYFORAGREED' }) },
        permissions.canEditOwn && !permissions.canEdit && entityData.state.code === 'READYFORAGREED' && { label: 'Вернуть в черновик', key: "changeStateDraft", onClick: () => changeState({ ids: [id], state: 'DRAFT' }) },
        permissions.canEdit && entityData.state.code === 'READYFORAGREED' && { label: 'Согласовать', key: "changeStateAccepted", onClick: () => changeState({ ids: [id], state: 'ACCEPTED' }) },
        permissions.canEdit && entityData.state.code === 'READYFORAGREED' && { label: 'Отклонить', key: "changeStateRejected", onClick: () => changeState({ ids: [id], state: 'REJECTED' }) },
    ]

    return <MainContent layoutProps={props.layoutProps}
        title={`Заявка: ${title}`}
        menu={menu}
        headerRightButtons={
            <React.Fragment>
                <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => props.history.push('/Requests')} />
                {permissions.canAnyEdit && regime === 'view' && <Button key="edit" shape="circle" type="text" icon={<EditOutlined style={{ fontSize: '20px' }} />} title="Редактировать" onClick={() => props.history.push(getViewRootPath({ mainRoot, regime: 'edit', id }))} />}
                {permissions.canAnyEdit && regime !== 'view' && <Button key="edit" shape="circle" type="text" icon={<CreditCardOutlined style={{ fontSize: '20px' }} />} title="Просмотреть" onClick={() => props.history.push(getViewRootPath({ mainRoot, regime: 'view', id }))} />}
            </React.Fragment>
        }
        rightPanel={hasRoles('PROJECT_CREATOR') && requestId && <RequestInfo requestId={requestId} />}
    >
        {regime === 'view'
            ? <EntityViewer
                metadata={metadata}
                value={entityData}
            />
            : <EntityForm
                card={false}
                regime={regime === 'edit' ? 'updating' : 'creating'}
                metadata={metadata}
                title={'Проект'}
                visible={true}
                value={entityData || { requestVisibility: { code: 'ORGANIZATION' } }}
                defaults={metadata.defaults}
                editable={regime !== 'view'}
                onSave={({id}) => {
                    refresh();
                    props.history.push(getViewRootPath({ mainRoot, regime: 'view', id: id}));
                    if (regime !== 'edit' && !hasRoles('PROJECT_CREATOR')) notification.success({ message: `Спасибо! Ваша заявка создана. Для создания проекта передайте ее на согласование.` });
                }}
            />}
        <ConsiderationAddDialog
            visible={considerationAddDialogOpen}
            onCancel={() => setConsiderationAddDialogOpen(false)}
            onOk={(value) => {
                if (value) proposeForConsideration({ ids: [id], clientIds: value.map(_ => _.id) })
                setConsiderationAddDialogOpen(false);
            }} />
        {requestRegime || requestConsiderationRegime && <CommentDialog
                open={Boolean(requestRegime) || Boolean(requestConsiderationRegime)}
                title="Комментарий"
                placeholder="Укажите причину отклонения заявки."
                onCancel={() => requestConsiderationRegime ? setRequestConsiderationRegime(null) : setRequestRegime(null)}
                onOk={(comment) => requestConsiderationRegime ? rejectRequestConsideration(id, comment) : changeState({ids: [id], state: 'REJECTED', comment: comment})}
            />}
    </MainContent>
}

export default withRouter(Request);
