const meta = {
    entity: 'client',
    key: 'id',
    properties: [
        {
            title: 'Короткое название',
            dataIndex: 'shortName',
            width: 150,
            editable: true,
            required: true,
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true,
            required: true
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            width: 150,
            editable: {
                updating: false
            },
            type: 'entity',
            entityName: 'clientType',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            loadAll: true,
            required: true,
            //defaultFilteredValue: [{value: {ids: [{Id: 1}]}}],
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            editable: false,
            type: 'entity',
            entityName: 'clientState',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            loadAll: true
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            type: 'text',
            width: 250,
            editable: true,
            showInTable: false
        },
        {
            title: 'Размер',
            dataIndex: 'clientSize',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'clientSize',
            entityKey: 'id',
            entityLabel: 'name',
            loadAll: true
        },
        /*{
            title: 'Форма собственности',
            dataIndex: 'clientOwnershipForm',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'clientOwnershipForm',
            entityKey: 'id',
            entityLabel: 'name',
            loadAll: true
        },*/
        {
            title: 'ИНН',
            dataIndex: 'inn',
            width: 150,
            editable: true,
            type: 'number'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            type: 'string',
            editable: true,
            showInTable: false
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            type: 'string',
            editable: true,
            showInTable: false
        }
    ]
};

export default meta;