import React, {  } from "react";
import Params from "../components/Params";

const TableParams = ({ metadata, list, ...rest }) => {
    return <Params metadata={metadata} 
        filters={list.state.filters} 
        onChangeFilters={(filters) => list.setState(state => {state.filters = {...state.filters, ...filters}; return {...state};})}
        sorters={list.state.sorters} 
        onChangeSorters={(sorters) => list.setState(state => {state.sorters = sorters; console.log('sorters', sorters); return {...state};})} 
        {...rest}
        />
} 

export default TableParams;