import React, { useEffect } from 'react';
import MainContent from '../../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useForceUpdate } from '../../_common/hooks/useForceUpdate';
import { useEntity } from '../../_common/hooks/useEntity';
import { Tabs, Button } from 'antd';
import { useStoredState } from '../../_common/hooks/useStoredState';
import { useViewRoot } from '../../_common/hooks/useViewRoot';
import { useEnv } from '../../_common/hooks/useEnv';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ProjectGradeCampaignsTab from './ProjectGradeCampaignsTab';
import GlobalGradeCampaignTab from './GlobalGradeCampaignTab';
import { metadata } from './GlobalGradeCampaigns';
import { getInitialRecord } from '../projectgradecampaigns/GradeCompanySettings';
const GlobalGradeCampaign = (props) => {
    const { regime, id } = useViewRoot();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [entity, setEntity] = useEntity({
        metadata, id: id, forceUpdateRequired
    });
    const [tabKey, setTabKey] = useStoredState(undefined, { code: 'tab' });
    const [env, { hasRoles }] = useEnv();

    useEffect(() => {
        if (regime === 'create' && props.location.state && props.location.state.request) {
            setEntity({
                ...getInitialRecord(),
                state: {code: 'NOTACTIVE'},
                context: {code: props.location.state.request.context},
                event: {id: props.location.state.request.id}
            });
        }
    }, [regime, props.location.state])

    const commonTab = entity && <GlobalGradeCampaignTab env={env} hasRoles={hasRoles} metadata={metadata} globalGradeCampaign={entity} regime={regime} forceUpdate={forceUpdate} forceUpdateRequired={forceUpdateRequired} />

    const projectCampaignsTab = entity && entity.id && <ProjectGradeCampaignsTab globalGradeCampaign={entity} env={env} hasRoles={hasRoles} forceUpdate={forceUpdate} forceUpdateRequired={forceUpdateRequired} />

    const tabs = [
        entity && { label: 'Описание', key: 'common', children: commonTab },
        entity && entity.id && { label: 'Проектные кампании', key: 'projectCampaigns', children: projectCampaignsTab },
    ]

    return <MainContent layoutProps={props.layoutProps}
        title={`Глобальная оценочная кампания: ${(regime === 'create' ? 'Создание' : entity ? entity.name : '?')}`}
        headerRightButtons={
            <React.Fragment>
                <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => props.history.push(`/GlobalGradeCampaigns`)} />
            </React.Fragment>
        }
    >
        <Tabs items={tabs} activeKey={tabKey} onChange={setTabKey} />
    </MainContent>
}

export default withRouter(GlobalGradeCampaign);