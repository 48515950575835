import { Tree, Dropdown, Menu, Modal, Form, Input, message } from 'antd';
import { FormItem } from '../_libs/antd-ext'
import React, { useEffect, useState } from 'react';
import { get, post, patch } from './utils/fetch';
import { treeConvert } from './utils/utils';

const { confirm } = Modal;

const TreePanel = ({ entityName, id, selectedKeys, onSelect, title = 'name', ordered = true, selectFirst = false, ...rest }) => {
    const [treeData, setTreeData] = useState([]);
    const [selectedNode, setSelectedNode] = useState();
    const [nodeFormVisible, setNodeFormVisible] = useState(false);
    const [editableNode, setEditableNode] = useState();
    const [action, setAction] = useState();
    const [action2, setAction2] = useState()

    const refresh = () => {
        get({
            url: `${window.APPCFG.odataPath}/${entityName}?id=${id}`,
            callback: (data) => {
                const tree = treeConvert(data.value.map(v => ({ ...v, title: v[title], key: v.id })));
                setTreeData(tree);
                //setSelectedNode(tree[0]);
                onRightClickInternal({node: tree[0]})
            }
        });
    }

    useEffect(() => {
        refresh();
    // eslint-disable-next-line
    }, [entityName, id])

    const onAdd = () => {
        if (!selectedNode) {
            message.warning("Не выделено ни одного узла");
            return;
        }
        let node = {
            id: 0,
            isSystem: false,
            code: '',
            parentId: selectedNode.id
        }
        setAction('Создание');
        setAction2('создан');
        setEditableNode(node);
        setNodeFormVisible(true);
    }

    const onEdit = () => {
        if (!selectedNode) {
            message.warning("Не выделено ни одного узла");
            return;
        }
        let node = { ...selectedNode };
        setAction('Редактирование');
        setAction2('изменен');
        setEditableNode(node);
        setNodeFormVisible(true);
    }

    const onCopy = () => {
        if (!selectedNode) {
            message.warning("Не выделено ни одного узла");
            return;
        }
        let node = { ...selectedNode };
        setAction('Копирование');
        setAction2('скопирован')
        node.id = 0;
        node.IsSystem = false;
        setEditableNode(node);
        setNodeFormVisible(true);
    }

    const save = (fields) => {
        setNodeFormVisible(false);
        if (!selectedNode) {
            message.warning("Не выделено ни одного узла");
            return;
        }
        let json = fields;
        let id = editableNode.id;
        json.parentId = editableNode.parentId;
        json.id = id;
        if (id !== 0) {
            patch({
                url: `${window.APPCFG.apiPath}/${entityName}`,
                json: json,
                callback: () => {
                    refresh();
                    message.success(`Узел ${action2}`);
                }
            });
        } else {
            post({
                url: `${window.APPCFG.apiPath}/${entityName}`,
                json: json,
                callback: () => {
                    refresh();
                    message.success(`Узел ${action2}`);
                }
            });
        }
    }

    const onChangePosition = (info) => {
        patch({
            url: `${window.APPCFG.apiPath}/${entityName}`,
            json: {
                id: info.dragNode.id,
                ParentId: info.node.id,
                DisplayOrd: info.dropPosition
            },
            callback: () => {
                refresh();
            }
        });
    }

    const onDelete = () => {
        if (!selectedNode) {
            message.warning("Не выделено ни одного узла");
            return;
        }
        confirm({
            title: `Вы действительно хотите удалить узел [${selectedNode.title}]?`,
            onOk: () => {
                post({
                    url: `${window.APPCFG.apiPath}/${entityName}/Delete`,
                    json: {
                        Ids: [selectedNode.id]
                    },
                    callback: () => {
                        refresh();
                        message.success("Узел удален");
                    }
                });
            }
        });
    }

    const onSelectInternal = (selectedKeys, e) => {
        setSelectedNode(e.node);
        if (onSelect) onSelect(selectedKeys, e, { node: e.node });
    }

    const onRightClickInternal = ({ event, node }) => {
        setSelectedNode(node);
        if (onSelect) onSelect([node.key], null, { node: node });
    }

    return <React.Fragment>
        <Dropdown overlay={<Menu
            items={[
                {
                    label: 'Создать',
                    key: 'onAdd',
                    onClick: onAdd
                },
                {
                    label: 'Редактировать',
                    key: 'onEdit',
                    onClick: onEdit
                },
                {
                    label: 'Копировать',
                    key: 'onCopy',
                    onClick: onCopy
                },
                {
                    label: 'Удалить',
                    key: 'onDelete',
                    onClick: onDelete
                }
            ]} />} trigger={['contextMenu']}>
            <Tree
                showLine={{
                    showLeafIcon: false,
                }}
                treeData={treeData}
                selectedKeys={selectedKeys}
                onSelect={onSelectInternal}
                onRightClick={onRightClickInternal}
                draggable={ordered}
                blockNode
                onDrop={onChangePosition}
                {...rest}
            />
        </Dropdown>
        <NodeForm 
            node={editableNode}
            action={action}
            visible={nodeFormVisible}
            onCancel={() => setNodeFormVisible(false)} 
            onOk={save}/>
    </React.Fragment>;
};

export default TreePanel;

const NodeForm = ({ node, action, onOk, onCancel, visible }) => {
    const [form] = Form.useForm();
    form.resetFields();
    form.setFieldsValue(node);
    const onOkInternal = async () => {
        let fields = null;
        try {
            fields = await form.validateFields();
        } catch (e) { }
        onOk(fields);
    }
    return visible && <Modal
        closable={false}
        open={visible}
        title={`${action} [${(node && node.name ? node.name : '...')}]`}
        onCancel={onCancel}
        onOk={form.submit}
    >
        <Form form={form} onFinish={onOkInternal}>
            <FormItem
                label="Название"
                name="name"
                rules={[
                    {
                        required: true
                    },
                ]}
            >
                <Input />
            </FormItem>
            <FormItem
                label="Код"
                name="code"
                rules={[
                    {
                        required: true
                    },
                ]}
            >
                <Input disabled={node.IsSystem} />
            </FormItem>
        </Form>
    </Modal>
}