import React from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Form, Input } from 'antd';
const { confirm } = Modal;

const DeleteRoleDialog = ({ open, onCancel, onOk, selectedRecords }) => {
    const [form] = Form.useForm();

    const onOkInternal = async () => {
        let row = await form.validateFields();
        confirm({
            title: `Вы действительно хотите удалить роль в проектах (${selectedRecords.length})?`,
            onOk: () => {
                let json = { ...row };
                onOk(json, selectedRecords);
            }
        });
    }

    if (!selectedRecords || selectedRecords.length === 0) return null;

    return <Modal open={open} onOk={onOkInternal} onCancel={onCancel}
        title={`Удаление роли для всех выбранных проектов (${selectedRecords.length})`}
        okText={`Удалить роль в проектах (${selectedRecords.length})`}
        width={'90%'}
        heigth={'400px'}
        bodyStyle={{ overflowY: 'scroll', height: '60vh' }}>
        <Form
            form={form}
            autoComplete="off"
            layout="horizontal"
        >
            <Form.Item name="name" label="Название роли">
                <Input />
            </Form.Item>
        </Form>
    </Modal>
}

export default withRouter(DeleteRoleDialog);