import React, { useEffect, useState, useMemo, useContext } from 'react';
import ProjectSimpleCard from './ProjectSimpleCard';
import { Col, Row } from 'antd';
import { getData } from '../_common/odata/entityodata';
import InfiniteScroll from 'react-infinite-scroller';
import { LayoutContext } from '../_common/layout/MainContent';
import meta from './meta';
import cloneDeep from 'clone-deep';

const selectable = ['name', 'type', 'customer', 'executor', 'state', 'startDate', 'quantity', 'availableQuantity', 'description']
const pageSize = 20;

const ProjectSimpleCardList = ({ visible, onlyMy, search, editable, filter, onLoad, filters, metadata: meta }) => {
    const [data, setData] = useState();
    const context = useContext(LayoutContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const items = useMemo(() => data && <Row>{data.map((project, idx) => <Col key={idx} style={{ padding: '10px 20px 10px 0px' }}>
        <ProjectSimpleCard project={project} editable={editable} />
    </Col>)}
    </Row>, [data, editable]);
    useEffect(() => {
        setHasMore(false);
        setCurrentPage(1);
    }, [search, filter, onlyMy])
    const metadata = useMemo(() => {
        if (!meta) return null;
        let metadata = cloneDeep(meta);
        for (let p of metadata.properties) {
            if (selectable.indexOf(p.dataIndex) < 0) p.selectable = false;
        }
        return metadata;
    }, [meta])
    useEffect(() => {
        if (!metadata) return;
        let _filters = {};
        _filters['state'] = [{value: {ids: [{code: 'DEFERRED'}]}, exclude: true}];
        if (filters) for (let kv of Object.entries(filters).filter(_ => _[1])) {
            _filters[kv[0]] = [{value: kv[1]}];
        }

        if (visible) {
            setHasMore(false);
            getData({
                metadata,
                filters: _filters,
                extFilter: filter,
                afterGetPath: (path) => `${path}${search ? `&fullTextSearch=${search}` : ''}${onlyMy ? `&${onlyMy.map(_ => `onlyMy=${_}`).join('&')}` : ''}`,
                callback: (pageData, { total }) => {
                    setData((data) => (currentPage === 1 ? pageData : [...data, ...pageData]));
                    setHasMore(pageSize * currentPage < total);
                    if (onLoad) onLoad({data: pageData, total})
                },
                isGlobalProcessing: true,
                pageSize: pageSize,
                currentPage: currentPage,
                count: true
            });
        }
    }, [visible, search, filter, currentPage, filters, metadata, onlyMy])
    return !data
        ? null
        : <div>
            <InfiniteScroll
                pageStart={0}
                loadMore={() => { setHasMore(false); setCurrentPage(currentPage + 1); }}
                hasMore={hasMore}
                loader={<div className="loader" key={0} style={{ paddingTop: 100 }}>Загружается ...</div>}
                useWindow={false}
                getScrollParent={() => context.mainPanelRef.current}
            >
                {items}
            </InfiniteScroll>
        </div>
}

export default ProjectSimpleCardList;
