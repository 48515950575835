import React from 'react';
import { toDefaultString } from '../../_common/tables/date'
import TableFilter from './table/TableFilter';
import { Link } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { toODataString, addDays } from './utils/date';
import { fromODataString, toDate } from './utils/date';
import { getRecordValue } from './utils/array'
import { DATETIMEFORMAT } from '../../_common/tables/date';

function getKeyValue(value, entityKeyType) {
    return entityKeyType === 'string'
        ? `'${value}'`
        : entityKeyType === 'number'
            ? value
            : typeof value === 'string' || value instanceof String
                ? `'${value}'`
                : value;
}

const defaultFilterDropdown = ({ column }) => {
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <TableFilter
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        {...column}
    />
};

const getValueFromEntity = (entity, dataIndex) => {
    return getRecordValue(entity, dataIndex.split('/'));
}

const renderSingleEntity = ({entity, property, record, index}) => {
    if (!entity) return null;
    const entityLabels = Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel];
    let renderedText = property.entityLabelFunc 
        ? property.entityLabelFunc(entity) 
        : entityLabels.map(_ => getValueFromEntity(entity, _)).join(' ');
    return property.link
        ? renderLink({property, record, index, text: renderedText, entity})
        : renderedText;
}

const renderLink = ({property, record, index, text, entity}) => {
    return property.link instanceof Function
        ? property.link({ text, record, index, entity })
        : <Link to={`/${property.entityName}/${record[property.dataIndex][property.entityKey]}`}>{text}</Link>
}

let types = {
    string: {
        filterDropdown: defaultFilterDropdown,
        render: ({ column }) => (text, record, index, regime) => {
            return column.link
                ? (column.link instanceof Function
                    ? column.link({ text, record, index })
                    : !text 
                        ? text 
                        : text.startsWith('http') ? <a href={text} target="_blank" rel="noopener noreferrer">{text}</a> : <Link to={text}>{text}</Link>
                )
                : text;
        }
    },
    number: {
        align: ({ column }) => 'right',
        filterDropdown: defaultFilterDropdown,
        odataFilterFunc: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
            const filterValue = filter.value;
            const values = filterValue && filterValue.values ? filterValue.values.map(v => `${filterPrefix}${odataName} eq ${v.replace(',','.')}${filterPostfix}`).join(' or ') : '';
            const from = filterValue && filterValue.from ? `${filterPrefix}${odataName} ge ${filterValue.from}${filterPostfix}` : '';
            const till = filterValue && filterValue.till ? `${filterPrefix}${odataName} lt ${filterValue.till}${filterPostfix}` : '';
            const fromtill = (from || till) ? `(${[from, till].filter(_ => _).join(' and ')})` : null;
            if (fromtill || values) columnFilterPars.push(`(${[values, fromtill].filter(_ => _).join(' or ')})`);
            if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
        }
    },
    date: {
        render: ({ column }) => (text, record, index) => (text ? toDefaultString(text, column.format) : text),
        filterDropdown: defaultFilterDropdown,
        odataFilterFunc: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
            const filterValue = filter.value;
            const from = filterValue && filterValue.from ? `${filterPrefix}${odataName} ge ${toODataString(toDate(filterValue.from))}${filterPostfix}` : '';
            const till = filterValue && filterValue.till ? `${filterPrefix}${odataName} lt ${toODataString(addDays(toDate(filterValue.till), 1))}${filterPostfix}` : '';
            if (from || till) columnFilterPars.push(`(${[from, till].filter(_ => _).join(' and ')})`);
            if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
        },
        odataConvertAfterGetFunc: ({ property }) => value => !value ? value : fromODataString(value)
    },
    datetime: {
        render: ({ column }) => (text, record, index) => (text ? toDefaultString(text, column.format || DATETIMEFORMAT) : text),
        filterDropdown: defaultFilterDropdown,
        odataFilterFunc: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
            const filterValue = filter.value;
            const from = filterValue && filterValue.from ? `${filterPrefix}${odataName} ge ${toODataString(toDate(filterValue.from))}${filterPostfix}` : '';
            const till = filterValue && filterValue.till ? `${filterPrefix}${odataName} lt ${toODataString(addDays(toDate(filterValue.till), 1))}${filterPostfix}` : '';
            if (from || till) columnFilterPars.push(`(${[from, till].filter(_ => _).join(' and ')})`);
            if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
        },
        odataConvertAfterGetFunc: ({ property }) => value => !value ? value : fromODataString(value)
    },
    boolean: {
        render: ({ column }) => column.required
            ? (text, record, index) => {return (text === true ? <CheckOutlined /> : null)}
            : (text, record, index) => {return (text === true ? <CheckOutlined /> : text === false ? <CloseOutlined /> : null)},
        filterDropdown: defaultFilterDropdown,
        odataFilterFunc: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
            const filterValue = filter.value;
            if (filterValue === true || filterValue === false) columnFilterPars.push(`${filterPrefix}${odataName} eq ${filterValue}${filterPostfix}`);
            if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
        },
        odataConvertAfterGetFunc: ({ property }) => value => !value ? value : value === true
    },
    entity: {
        render: ({ column }) => (text, record, index, regime) => {
            if (!text) return null;
            const separator = regime === 'card' ? <br/> : ', ';
            const values = column.multiple ? text : [text];
            let renderedValues = values.map(_ => renderSingleEntity({entity: _, property: column, record, index}));
            let title = renderedValues.every(v => typeof v === 'string') ? renderedValues.join(separator) : undefined;
            let result = renderedValues.map((_, idx) => <React.Fragment key={idx}>{idx > 0 ? separator : ""}{_}</React.Fragment>)
            result = <span title={title}>{result}</span>;
            return result;
        },
        filterDropdown: defaultFilterDropdown,
        odataOrderByFunc: ({ property }) => property.entityOrder
            ? (Array.isArray(property.entityOrder) ? property.entityOrder : [property.entityOrder]).map(_ => `${property.odataName}/${_}`)
            : (Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel]).map(_ => `${property.odataName}/${_}`),
        odataFilterFunc: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
            const filterValue = filter.value;
            if (!filterPrefix) filterPrefix = !property.isDynamic && property.multiple ? `${property.odataName}/any(a: ` : '';
            if (!filterPostfix) filterPostfix = !property.isDynamic && property.multiple ? `)` : '';
            odataName = !property.isDynamic && property.multiple && !property.newMultiple 
                ? `a/${property.entityName}` 
                : property.newMultiple
                    ? 'a'
                    : odataName;
            if (filterValue) {
                if (filterValue.tags && filterValue.tags.length > 0) {
                    const entityLabels = Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel];
                    columnFilterPars.push(`(${entityLabels.map(l => filterValue.tags.map(fv => `${filterPrefix}contains(tolower(${odataName}/${l}),'${fv.toLowerCase()}'${filterPostfix})`).join(' or ')).join(' or ')})`);
                }
                if (filterValue.ids && filterValue.ids.length > 0) {
                    const entityKeys = [property.entityKey];
                    columnFilterPars.push(`(${entityKeys.map(l => filterValue.ids.map(fv => `${filterPrefix}${odataName}${property.isDynamic ? '' : `/${l}`} eq ${getKeyValue(fv[property.entityKey], property.entityKeyType)}${filterPostfix}`).join(' or ')).join(' or ')})`);
                }
            }
            if (filter.empty) columnFilterPars.push(property.isDynamic
                    ? `not ${filterPrefix}true${filterPostfix}`
                    : property.multiple || property.newMultiple
                        ? `not ${property.odataName}/any()`
                        : `${filterPrefix}${odataName}${Array.isArray(property.dataIndex) ? '/' : ''}${property.isDynamic ? '' : property.entityKey} eq null${filterPostfix}`);
        },
        odataSelectExpandFunc: ({ pars, property, name }) => {
            if (!pars.expand[name]) {
                pars.expand[name] = { select: [], expand: {} }
            }
            //($select=EducationalProgram;$expand=EducationalProgram($select=Id,Name))
            let selects = [
                property.entityKey,
                ...(Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel]),
                ...(property.select || [])
            ]
            let expandValues = selects.filter(_ => _.indexOf('/') > 0);
            let expands = null;
            if (expandValues.length > 0) {
                expands = {}
                for (let e of expandValues) {
                    let parts = e.split('/');
                    if (!expands[parts[0]]) expands[parts[0]] = { select: [], expand: {} };
                    expands[parts[0]].select = [...(expands[parts[0]].select || []), parts[1]];
                }
            }
            selects = selects.filter(_ => _.indexOf('/') <= 0)

            let entityPar = pars.expand[name];
            if (property.multiple && !property.newMultiple) {
                entityPar.expand[property.entityName] = { select: [], expand: {} };
                if (!entityPar.select.includes(property.entityName)) entityPar.select.push(property.entityName);
                entityPar = entityPar.expand[property.entityName];
            }
            for (var s of selects) if (!entityPar.select.includes(s)) entityPar.select.push(s);
            if (expands) {
                entityPar.expand = expands;
            }
        },
        odataConvertBeforePostFunc: ({ property, json, key }) => {
            if (property.multiple) {
                if (property.newMultiple) {
                    json[key] = json[key].map(ei => ei[property.entityKey]);
                } else {
                    for (let ei in json[key]) {
                        json[key][ei] = { [`${property.entityName}Id`]: json[key][ei][property.entityKey] }
                    }
                }
            } else {
                json[`${key}Id`] = json[key][property.entityKey]
                json[key] = undefined;
            }
        },
    },
    file: {
        render: ({ column }) => (text, record, index) => (text
            ? Array.isArray(text) ? <a href={text[0].url}>{text[0].name}</a> : <a href={text.url}>{text.name}</a>
            : text),
        odataConvertAfterGetFunc: ({ property }) => property.isDynamic
            ? value => value
            : property.multiple === true
                ? value => !value ? value : value.list.map(file => ({
                    name: file.name,
                    url: file.url,
                    size: file.length,
                    uid: file.fileId
                }))
                : value => !value ? value : [{
                    name: value.name,
                    url: value.url,
                    size: value.length,
                    uid: value.fileId
                }],
        odataConvertBeforePostFunc: ({ property, json, key }) => {
            if (property.multiple) {
                json[key] = {
                    List: json[key].map(file => ({
                        Name: file.name,
                        Url: file.url,
                        Length: file.size,
                        FileId: file.uid
                    }))
                };
            } else {
                let file = json[key][0];
                json[key] = {
                    Name: file.name,
                    Url: file.url,
                    Length: file.size,
                    FileId: file.uid
                };
            }
        }
    }
}
types['?'] = types['string'];
/*types['enumEntity'] = {
    ...types['entity'],
    entityKey: 'code',
    entityLabel: 'name',
    entityOrder: 'order',
    loadAll: true,
    required: false,
    editable: false
}*/
export default types; 