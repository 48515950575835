import React from 'react';
import FormItem from './FormItem';

const FormItems = ({ metadata, regime, record, sourceRecord, editable = true, context, showDisabledNull = false }) => {
    const creating = regime === 'creating';
    const notEditable = ({ editableDefault, field }) =>
        (!editableDefault && !field.editable)
        || (field.editable === false)
        || (creating && field.editable && (field.editable.creating === false || (field.editable.creating instanceof Function && field.editable.creating({ record }) === false)))
        || (!creating && field.editable && (field.editable.updating === false || (field.editable.updating instanceof Function && field.editable.updating({ record }) === false)))

    let children = [];

    const getChildren = (field, level) => {
        let children = []
        if (!field.children) return [field];
        for (let c of field.children) {
            children = [...children, ...getChildren(c, level + 1)];
        }
        return children;
    }
    let dataFields = []
    for (let property of metadata.properties) {
        dataFields = [...dataFields, ...getChildren(property, 0)];
    }

    for (let field of dataFields) {
        let disabled = editable === false || notEditable({ editableDefault: metadata.defaults && metadata.defaults.editable, field: field });
        let hidden = (!showDisabledNull && (disabled && record && (record[field.dataIndex] === null || record[field.dataIndex] === undefined))) || field.hidden;
        if (hidden) continue;
        children.push(<FormItem
            key={field.dataIndex}
            name={field.dataIndex}
            label={field.title}
            type={field.type}
            {...field}
            disabled={disabled}
            sourceRecord={sourceRecord}
            context={context}
        />)
    }
    return children;
}

export default FormItems;