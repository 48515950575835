import React, { useState } from 'react';
import { post } from '../_common/utils/fetch';
import FileDialog from '../_common/FileDialog'
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { message } from 'antd';

const File = (props) => {
    const [tableApi, setTableApi] = useState();
    const [uploadOpen, setUploadOpen] = useState()

    const upload = (file, pars) => {
        //this.setState({ audLastUpdatedTs: moment(moment.now()).add(-3, 'hours').format('YYYY-MM-DDTHH:mm:ss') + "Z", uploadOpen: false })
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/File/Upload`,
            formData: {
                File: file,
                Pars: pars ? JSON.stringify(pars.split(/[\s,]+/).filter(n => n)) : undefined
            },
            callback: () => {
                tableApi.refresh();
                message.success("Файл загружен");
            }
        });
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Файлы"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
        { label: 'Загрузить файл', key: "upload", onClick: () => setUploadOpen(true) }
        ]}
    >
        <Table
            columns={[
                {
                    title: 'Название',
                    dataIndex: 'name',
                },
                {
                    title: 'Размер',
                    dataIndex: 'length'
                },
                {
                    title: 'Скачать',
                    dataIndex: 'url',
                    render: (text, record, index) => <a href={text}>Скачать</a>
                }]}
            odataEntity="file"
            onInit={setTableApi}
            history={props.history}
        />
        <FileDialog title="Загрузить через браузер" titlePars="Номера заявок" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={upload} />
    </MainContent>;
}

export default withRouter(File);
