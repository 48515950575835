import React from 'react';
import {Spin} from 'antd';

export default function LongProcessPanel(props) {
    let style = {width: '100%', height: '100%'}
    if (props.isProcessing) style = {...style, opacity: .1, pointerEvents: 'none'};
    return (
        <React.Fragment>
            {props.isProcessing ? <div style={{position: 'absolute', width: '100%', paddingTop: '50px', textAlign: 'center'}}>
                <Spin size="large" />
            </div> : (null)}
            <div style={style}>
                {props.children}
            </div>
        </React.Fragment>
    );
}
