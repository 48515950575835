import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
import { withRouter } from "react-router-dom";
import { Collapse } from 'antd';

const { Panel } = Collapse;

const RequestInfo = ({ requestId }) => {
    const [tableApi, setTableApi] = useState();
    useEffect(() => {
        if (tableApi) tableApi.refresh();
    }, [requestId, tableApi])
    return requestId && <Collapse bordered={false} defaultActiveKey={['1']}>
        <Panel header="Рассмотрения заявки" key="1">
            <Table
                columns={[
                    {
                        title: 'Исполнитель',
                        dataIndex: 'executor',
                        width: 120,
                        type: 'entity',
                        entityName: 'executor',
                        entityKey: 'id',
                        entityLabel: 'name',
                        editable: false
                    },
                    {
                        title: 'Состояние',
                        dataIndex: 'state',
                        width: 100,
                        type: 'entity',
                        entityName: 'requestConsiderationState',
                        entityKey: 'code',
                        entityLabel: 'name',
                        editable: false
                    }]}
                odataEntity={"requestConsideration"}
                odataFilter={`requestId eq ${requestId}`}
                rowKey={'id'}
                editable={false}
                selectable={false}
                sortable={false}
                filterable={false}
                pagination={false}
                fullScreen={false}
                onInit={setTableApi}
            />
        </Panel>
    </Collapse>
}

export default withRouter(RequestInfo);
