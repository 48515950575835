import React from 'react';
import { QuestionCircleOutlined, MailOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import { ReactComponent as TelegramSvg } from '../icons/telegram_icon.svg';
import { useEnv } from '../hooks/useEnv';

export const helpHref = 'https://cloud.custis.ru/s/4fwKq3dGjcBjei2';
export const mailto = "mailto:project@modeus.org"

export const getItems = (env) => [
    {
        key: '1',
        label: (
            <a style={{ color: 'black' }} href={helpHref} target="_blank" rel="noopener noreferrer" title="Инструкции">
                <Button shape="circle" type="text" icon={<QuestionCircleOutlined />}>Инструкции</Button>
            </a>
        ),
    },
    {
        key: '2',
        label: (
            <a style={{ color: 'black' }} href={mailto} target="_blank" rel="noopener noreferrer" title="Напишите в поддержку">
                <Button shape="circle" type="text" icon={<MailOutlined />}>Напишите в поддержку</Button>
            </a>
        ),
    },
    {
        key: '3',
        label: (
            <a style={{ color: 'black' }} href={env.telegramBot} target="_blank" rel="noopener noreferrer" title='Бот в Telegram. Для взаимодействия с ботом нужно в своем профиле в поле "Телефон" указать номер телефона, к которому привязан ваш telegram-аккаунт и выставить галочку "Ув. telegram", после этого необходимо подключить Telegram-бота, для этого внутри чата Telegram-бота (который открывается по ссылке на данный пункт меню) нажимаем "Представиться", отсылаем контакт, если бот вас правильно идентифицирует, то вы получите ответ в виде "Здравствуйте, ФИО", это все, вы подключены.'>
                <Button shape="circle" type="text" icon={<Icon component={TelegramSvg} />}>Бот в Telegram</Button>
                <Icon component={QuestionCircleOutlined} style={{ marginLeft: 5, margitBottom: 20 }} />
            </a>
        ),
    }];

export default function InfoButton(props) {
    const [env] = useEnv();
    const items = getItems(env);
    return <Dropdown overlay={<Menu items={items} />} trigger={['click']}><Button shape="circle" type="text" icon={<QuestionCircleOutlined style={{ fontSize: '20px' }} />} /></Dropdown>
}

/*
<a style={{ color: 'black' }} href="https://t.me/projectus_support" target="_blank" rel="noopener noreferrer" title="Поддержка в Telegram">
            <Button shape="circle" type="text" icon={<Icon component={TelegramSvg} style={{ fontSize: 20 }} />} />
        </a>
        <a style={{ color: 'black' }} href="https://cloud.custis.ru/s/4fwKq3dGjcBjei2" target="_blank" rel="noopener noreferrer" title="Инструкции">
            <Button shape="circle" type="text" icon={<QuestionCircleOutlined style={{ fontSize: '20px' }} />} />
        </a>
*/