import { get, post } from './fetch'
import { removeItemFirst} from './array'
import moment from 'moment'

class SystemInfo {
    systemInfo = null;
    notifications = {};
    subscribers = [];
    subscribersNotification = [];
    updateNotificationInterval = 60;

    updateSystemInfo = () => {
        this.updateSystemInfoOnServer({
            callback: (systemInfo) => {
                window.ym(91098847, 'userParams', {
                    UserID: systemInfo.userId,
                    ClientId: systemInfo.userClientId
                });
                this.systemInfo = systemInfo;

                this.systemInfo.hasRolesInternal = function ({roles, withoutSuperadmin}) {
                    const env = this;
                    if (!env) return false;
                    if (!withoutSuperadmin && env.isSuperAdmin) return true;
                    if (!env.userRoles) return false;
                    for (let role of roles) {
                        if (env.userRoles.indexOf(role) >= 0) return true;
                    }
                    return false;
                }
                this.systemInfo.hasRolesWithoutSuperadmin = function () {
                    return this.hasRolesInternal({roles: arguments, withoutSuperadmin: true});
                }
                // eslint-disable-next-line
                this.systemInfo.hasRoles = function () {
                    return this.hasRolesInternal({roles: arguments, withoutSuperadmin: false});
                }


                if (systemInfo.viewDateIsSpecial) {
                    let viewDate = moment.utc(systemInfo.viewDate);
                    moment.now = function () {
                        return viewDate;
                    }
                } else {
                    moment.now = function () {
                        return Date.now();
                    } 
                }
                for (let s of this.subscribers) {
                    s(systemInfo);
                }
            }
        });
    }

    updateSystemInfoOnServer = (props) => {
        get({
            url: `${window.APPCFG.apiPath}/System/SystemInfo`,
            callback: props.callback
        });
    }

    changeClient = ({clientId, callback}) => {
        post({
            url: `${window.APPCFG.apiPath}/System/ChangeClient`,
            json: clientId,
            callback: () => {
                if (callback) callback();
            }
        });
    }

    subscribe = (func) => {
        this.subscribers.push(func)
    }
    unsubscribe = (func) => {
        removeItemFirst(this.subscribers, func)
    }

    updateNotifications = (props) => {
        this.updateNotificationsOnServer({
            callback: (notifications) => {
                this.notifications = notifications;
                for (let s of this.subscribersNotification) {
                    s(notifications);
                }
            },
            commonCallback: () => {
                if (props && props.timeout) setTimeout(() => this.updateNotifications({timeout: true}), this.updateNotificationInterval * 1000);
            }
        });
    }

    updateNotificationsOnServer = ({callback, commonCallback}) => {
        get({
            url: `${window.APPCFG.apiPath}/System/GetNotifications`,
            notShowError: true,
            callback: callback,
            commonCallback: commonCallback
        });
    }

    subscribeNotification = (func) => {
        this.subscribersNotification.push(func)
    }
    unsubscribeNotification = (func) => {
        removeItemFirst(this.subscribersNotification, func)
    }
}

export let systemInfo = new SystemInfo();