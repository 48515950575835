import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import React from "react";
import { DATETIMEFORMAT } from "../_common/tables/date";
import { prepareMetadata } from '../_common/odata/entityodata';

const meta = {
    name: 'event',
    entity: 'event',
    key: 'id',
    properties: [
        {
            title: 'Название',
            width: 250,
            editable: false,
            required: true,
            render: (text, record) => record ? <React.Fragment>
                <Link to={`/Event/${record.id}`}>{text}</Link><br />
                {dayjs(record.startDate).format(DATETIMEFORMAT)}<br />
                {record.location || record.url}
            </React.Fragment> : text,
            responsive: ["xs"],
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true,
            required: true,
            render: (text, record) => record ? <Link to={`/Event/${record.id}`}>{text}</Link> : text,
            responsive: ["sm"],
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'eventState',
            entityKey: 'code',
            entityLabel: 'name',
            editable: false,
            loadAll: true,
            entityOrder: 'order',
            responsive: ["sm"],
            defaultFilteredValue: [
                { value: { ids: [{ code: 'CANCELED' }, { code: 'HELD' }] }, exclude: true }
            ],
            render: (text, record) => {
                let color = record.state.code === 'DRAFT'
                    ? 'lightgreen'
                    : record.state.code === 'ACCEPTED'
                        ? 'green'
                        : record.state.code === 'CANCELED'
                            ? 'lightgray'
                            : 'black';
                return <div style={{ color: color }}>{text.name}</div>
            }
        },
        {
            title: 'Начало',
            dataIndex: 'startDate',
            width: 150,
            type: 'datetime',
            editable: true,
            required: true,
            showInTable: true,
            showTime: { minuteStep: 15, hideDisabledOptions: true, disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 22, 23] },
            responsive: ["sm"],
        },
        {
            title: 'Окончание',
            dataIndex: 'finishDate',
            width: 150,
            type: 'datetime',
            editable: true,
            showInTable: true,
            showTime: { minuteStep: 15, hideDisabledOptions: true, disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 22, 23] },
            responsive: ["sm"],
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            required: true,
            width: 150,
            type: 'entity',
            entityName: 'eventType',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true,
            loadAll: true,
            responsive: ["sm"],
        },
        {
            title: 'Тип проведения',
            dataIndex: 'attendanceType',
            width: 150,
            type: 'entity',
            entityName: 'attendanceType',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            editable: true,
            entityOrder: 'order',
            responsive: ["sm"],
        },
        {
            title: 'Место проведения',
            dataIndex: 'location',
            width: 150,
            editable: true,
            responsive: ["sm"],
        },
        {
            title: 'Ссылка на online',
            dataIndex: 'url',
            width: 150,
            editable: true,
            responsive: ["sm"],
            link: true
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            type: 'text',
            editable: true,
            showInTable: false,
            responsive: ["sm"],
        },
        {
            title: 'Видимость',
            dataIndex: 'visibility',
            width: 150,
            type: 'entity',
            entityName: 'eventVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            editable: true,
            loadAll: true,
            entityOrder: 'order',
            responsive: ["sm"],
        },
        {
            title: 'Кол-во проектов',
            dataIndex: 'projectCnt',
            width: 150,
            editable: false,
            responsive: ["sm"],
        },
        {
            title: 'Кол-во участников',
            dataIndex: 'memberCnt',
            width: 150,
            editable: false,
            responsive: ["sm"],
        },
        {
            title: 'Кол-во согласованных проектов',
            dataIndex: 'projectApprovedCnt',
            width: 150,
            editable: false,
            responsive: ["sm"],
        },
        {
            title: 'Кол-во отклоненных проектов',
            dataIndex: 'projectRejectedCnt',
            width: 150,
            editable: false,
            responsive: ["sm"],
        },
        {
            title: 'Требуется подтверждение',
            description: 'Требуется подтверждение времени куратором',
            type: 'boolean',
            dataIndex: 'isTimeCuratorApprovedRequired',
            width: 150,
            editable: true,
            responsive: ["sm"],
        },
        {
            title: 'Автоотметка о проведении',
            description: 'При наступлении даты проведения состояние меняется автоматически',
            type: 'boolean',
            dataIndex: 'heldAuto',
            width: 150,
            editable: true,
            responsive: ["sm"],
        },
        {
            title: 'Первое напоминание',
            description: 'Кол-во дней до начала мероприятия, за которое будет разослано первое напоминание, мб не заполнено или, например, при рассылке за неделю равно 7',
            type: 'number',
            dataIndex: 'notificationInterval1',
            width: 150,
            editable: true,
            responsive: ["sm"],
        },
        {
            title: 'Второе напоминание',
            description: 'Кол-во дней до начала мероприятия, за которое будет разослано второе напоминание, мб не заполнено или, например, при рассылке за день равно 1',
            type: 'number',
            dataIndex: 'notificationInterval2',
            width: 150,
            editable: true,
            responsive: ["sm"],
        }
    ]
}

const preparedMeta = prepareMetadata({ metadata: meta })

export default preparedMeta;