import React from 'react';
import { withRouter } from "react-router-dom";
import { useEntity } from '../_common/hooks/useEntity';
import { EntityForm, EntityViewer } from '../_libs/antd-ext';
import metadata from './metaProjectSettings';

const ProjectSettings = ({ regime, project, refresh }) => {
    const [data] = useEntity({metadata, extFilter: project && `projectId eq ${project.id}`})
    return !project
        ? null
        : regime === 'view'
            ? <React.Fragment>
                <EntityViewer
                    metadata={metadata}
                    value={data}
                />
            </React.Fragment>
            : <EntityForm
                card={false}
                regime={regime === 'edit' ? 'updating' : 'creating'}
                metadata={metadata}
                visible={true}
                value={data}
                beforeSaveData={({record}) => {record.projectId = project.id; return record;}}
                onSave={refresh}
            />
}

export default withRouter(ProjectSettings);
