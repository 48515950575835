import React, { useEffect, useState } from 'react';
import { post, get } from '../_common/utils/fetch';
import { message, Modal, Col, Row, Button } from 'antd';
import Table from '../_common/tables/TableOData';
import { useEnv } from '../_common/hooks/useEnv';
import Actions from '../_common/layout/Actions';
import ProjectsSelector from '../projects/ProjectsSelector';
import CommentDialog from '../_common/CommentDialog';
import NotifyCommon from '../notifications/NotifyCommon';
import ExtMemberDialog from './dialogs/ExtMemberDialog';
import { Link } from 'react-router-dom';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { patch } from '../_common/utils/fetch';

const EventProjects = ({ event, env, hasRoles, forceUpdate, forceUpdateRequired }) => {
    const [tableApi, setTableApi] = useState();
    const [selectProjectDialogOpen, setSelectProjectDialogOpen] = useState(false);
    const [selected, setSelected] = useState();
    const [projectRegime, setProjectRegime] = useState();
    const [notifyCommonIds, setNotifyCommonIds] = useState();
    const [extMemberDialogOpen, setExtMemberDialogOpen] = useState(false);
    const [total, setTotal] = useState();

    const changeNum = (id, num) => {
        patch({
            url: `${window.APPCFG.apiPath}/EventProject`,
            json: {id, num},
            callback: () => {
                forceUpdate();
            }
        })
    }

    useEffect(() => {
        if (tableApi) tableApi.refresh();
    }, [forceUpdateRequired]);

    const meta = {
        name: 'eventProject',
        entity: 'eventProject',
        key: 'id',
        properties: [
            {
                title: '№',
                dataIndex: 'num',
                type: 'number',
                width: 85,
                responsive: ["sm"],
                editable: true,
                defaultSortOrder: 'ascend',
                render: hasRoles('ADMIN')
                    ? (text, record) => <span>{text}
                        <Button style={{marginLeft: 5}} type='text' size='small' className='iconbutton' icon={<ArrowUpOutlined />} onClick={() => changeNum(record.id, parseInt(record.num) - 1)} />
                        <Button type='text' size='small' className='iconbutton' icon={<ArrowDownOutlined />} onClick={() => changeNum(record.id, parseInt(record.num) + 1)} />
                        </span>
                    : undefined
            },
            {
                title: 'Проект',
                dataIndex: 'project',
                width: 250,
                type: 'entity',
                entityName: 'project',
                entityKey: 'id',
                entityLabel: 'name',
                link: true,
                responsive: ["xs", "sm"]
            },
            {
                title: 'Внешние эксперты',
                dataIndex: 'extMembers',
                width: 250,
                type: 'entity',
                multiple: true,
                newMultiple: true,
                entityName: 'extMember',
                entityKey: 'user/id',
                entityLabel: 'user/name',
                link: ({text, entity}) => <Link to={`/User/${entity.user.id}`}>{text}</Link>,
                responsive: ["sm"]
            },
            {
                title: 'Согласовано',
                description: 'Время и место согласовано куратором',
                type: 'boolean',
                dataIndex: 'isTimeCuratorApproved',
                width: 150,
                responsive: ["sm"]
            },
            {
                title: 'Комментарий',
                description: 'Комментарий куратора ко времени и месту проведения',
                dataIndex: 'timeCuratorComment',
                width: 250,
                responsive: ["sm"]
            }
        ]
    }

    const addProjects = (ids) => {
        setSelectProjectDialogOpen(false);
        if (!ids || ids.length === 0) return;
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Event/AddProjects`,
            json: {
                objectId: event.id,
                ids: ids
            },
            callback: () => {
                forceUpdate();
                message.success("Проекты добавлены");
            }
        })
    }

    const removeProjects = (ids) => {
        setSelectProjectDialogOpen(false);
        if (!ids || ids.length === 0) return;
        Modal.confirm({
            title: `Вы действительно хотите удалить (${ids.length})?`,
            onOk: () => post({
                isGlobalProcessing: true,
                url: `${window.APPCFG.apiPath}/Event/RemoveProjects`,
                json: {
                    objectId: event.id,
                    ids: ids
                },
                callback: () => {
                    forceUpdate();
                    message.success("Проекты удалены");
                }
            })
        });
    }

    const addExtMembers = (record) => {
        setExtMemberDialogOpen(false);
        let json = record;
        json.eventId = event.id;
        json.attendanceTypeId = record.attendanceType ? record.attendanceType.code : undefined;
        json.userId = record.user ? record.user.id : undefined;
        delete json.event;
        delete json.attendanceType;
        delete json.user;

        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Event/PatchExtMember`,
            json: {
                ...json,
                ids: selected
            },
            callback: () => {
                forceUpdate();
                message.success("Внешний эксперт добавлен/изменен");
            }
        })
    }

    const projectDecision = (comment) => {
        const regime = projectRegime;
        setProjectRegime(null);
        if (!selected || selected.length === 0) {
            message.warning("Не выделено ни одной записи");
            return;
        }
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/EventProject/${regime === 'accept' ? 'Accept' : 'Reject'}`,
            json: {
                ids: selected,
                text: comment
            },
            callback: () => {
                forceUpdate();
                if (regime === 'accept') message.success("Проекты согласованы")
                else message.success("Проекты отклонены");
            }
        })
    }

    const sendRequest = (selected) => {
        Modal.confirm({
            title: `Вы действительно хотите разослать запрос (${selected.length})?`,
            onOk: () => post({
                isGlobalProcessing: true,
                url: `${window.APPCFG.apiPath}/EventProject/SendRequest`,
                json: {
                    ids: selected
                },
                callback: () => {
                    message.success("Запрос отправлен");
                }
            })
        });
    }

    const recalc = () => {
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Event/Recalc`,
            json: {
                ids: [event.id]
            },
            callback: () => {
                forceUpdate();
                message.success('Информация обновлена');
            }
        })
    }

    const sendNotification = (selected) => {
        if (!selected || selected.length === 0) {
            message.warning("Не выделено ни одной записи");
            return;
        }
        get({
            url: `${window.APPCFG.apiPath}/EventProject/GetMemberIds?${selected.map(_ => `ids=${_}`).join('&')}`,
            callback: (ids) => {
                setNotifyCommonIds(ids);
            }
        })
    }

    return env && event && event.id
        ? <React.Fragment>
            <Row>
                <Col><Actions
                    style={{ marginRight: 30 }}
                    items={[
                        hasRoles('ADMIN') && {
                            key: 'addProjects',
                            label: 'Добавить проекты',
                            onClick: () => setSelectProjectDialogOpen(true)
                        },
                        hasRoles('ADMIN') && {
                            key: 'removeProjects',
                            disabled: !selected || selected.length === 0,
                            label: 'Удалить проекты',
                            onClick: () => removeProjects(selected)
                        },
                        hasRoles('ADMIN') && {
                            key: 'patchExtMembers',
                            label: 'Добавить внешнего эксперта',
                            onClick: () => setExtMemberDialogOpen(true)
                        },
                        hasRoles('ADMIN') && {
                            key: 'sendRequest',
                            label: 'Разослать кураторам запрос на согласование',
                            disabled: !selected || selected.length === 0,
                            onClick: () => sendRequest(selected)
                        },
                        hasRoles('ADMIN') && {
                            key: 'sendNotification',
                            label: 'Разослать участникам сообщение',
                            disabled: !selected || selected.length === 0,
                            onClick: () => sendNotification(selected)
                        },
                        hasRoles('ADMIN') && {
                            key: 'recalc',
                            label: 'Обновить информацию по участникам',
                            onClick: () => recalc()
                        },
                        hasRoles('PROJECT_MANAGER') && event.isTimeCuratorApprovedRequired && {
                            key: 'acceptProjects',
                            label: 'Согласовать',
                            disabled: !selected || selected.length === 0,
                            onClick: () => setProjectRegime('accept')
                        },
                        hasRoles('PROJECT_MANAGER') && event.isTimeCuratorApprovedRequired && {
                            key: 'rejectProjects',
                            label: 'Отклонить',
                            disabled: !selected || selected.length === 0,
                            onClick: () => setProjectRegime('reject')
                        }
                    ]}
                /></Col>
                <Col style={{ paddingTop: 5 }}>
                    <span title={event.isTimeCuratorApprovedRequired ? 'Всего/Согласовано/Отклонено' : undefined}>Проектов: {event.isTimeCuratorApprovedRequired ? `${event.projectCnt}/${event.projectApprovedCnt}/${event.projectRejectedCnt}` : event.projectApprovedCnt}. </span>
                    <span>Участников: {event.memberCnt}</span>
                </Col>
            </Row>
            <Table
                columns={meta.properties}
                odataEntity={meta.entity}
                rowKey={meta.key}
                onInit={setTableApi}
                odataFilter={`eventId eq ${event.id}`}
                sortable={false}
                onDelete={false}
                onCopy={false}
                onAdd={false}
                editable={hasRoles('ADMIN')}
                rowSelection={hasRoles('PROJECT_MANAGER', 'ADMIN')
                    ? {
                        onChange: (newSelectedRowKeys, newSelectedRows) => setSelected(newSelectedRowKeys)
                    } : false}
                onLoad={({total}) => setTotal(total)}
            />
            {selectProjectDialogOpen && <ProjectsSelector
                open={selectProjectDialogOpen}
                onCancel={() => setSelectProjectDialogOpen(false)}
                onOk={({ selectedIds }) => addProjects(selectedIds)}
                onlyChecked={true}
            />}
            {extMemberDialogOpen && <ExtMemberDialog
                open={extMemberDialogOpen}
                onCancel={() => setExtMemberDialogOpen(false)}
                onOk={(data) => addExtMembers(data)}
                selected={selected}
            />}
            {projectRegime && <CommentDialog
                open={Boolean(projectRegime)}
                title="Комментарий (необязательный)"
                placeholder="Вы можете предложить новое время или как-то иначе прокомментировать свое решение, либо просто оставьте поле пустым и нажмите OK"
                onCancel={() => setProjectRegime(null)}
                onOk={(comment) => projectDecision(comment)}
            />}
            {notifyCommonIds && <NotifyCommon ids={notifyCommonIds} open={Boolean(notifyCommonIds)} onOk={() => setNotifyCommonIds(null)} onCancel={() => setNotifyCommonIds(null)} />}
        </React.Fragment>
        : null;
}

export default EventProjects;
