import React, { PureComponent } from 'react';
import { Modal, Button, Divider } from 'antd'
import { post } from './utils/fetch'
import { helpHref, mailto } from './layout/InfoButton';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { ReactComponent as TelegramSvg } from './icons/telegram_icon.svg';

export var showError = null;
const additionalLinks = <div style={{marginTop: 10}}>Также вы можете:<br />
    <a href={helpHref} target="_blank" rel="noopener noreferrer"><QuestionCircleOutlined /> Прочитать инструкции</a><br />
    <a href={mailto} target="_blank" rel="noopener noreferrer" title="Поддержка в Telegram"><Icon component={TelegramSvg} />Задать вопрос в техподдержку</a>
</div>

export default class ErrorDialog extends PureComponent {
    constructor(props) {
        super(props);
        showError = (e, props) => {
            console.error(e);
            this.error(e, props);
        }
        this.state = {
            open: false,
            confirmationProps: null,
            isLoading: false
        }
    }

    error = (e, props) => {
        if (e && e.toString().startsWith('Error: 401')) {
            this.setState({ error: { SystemError: { Text: 'Вход не выполнен. Возможно, истек срок действия токена авторизации и вам нужно перезайти в систему. Если это не поможет - обратитесь к администратору.', Title: 'Вход не выполнен' } }, open: true, confirmationProps: props });
        } else if (e && (e.toString().startsWith('Error: 403') || e.status == 403)) {
            this.setState({ error: { SystemError: { Text: 'У вас недостаточно прав для совершения действия. Обратитесь к администратору.', Title: 'Недостаточно прав' } }, open: true, confirmationProps: props });
        } else if (e && e.error && e.error.title === 'Доступ запрещен') {
            this.setState({ error: { SystemError: { Text: `${e.error.text} У вас недостаточно прав для совершения действия. Обратитесь к администратору.`, Title: 'Недостаточно прав' } }, open: true, confirmationProps: props });
        } else if (e && e.error && e.error.message) {
            this.setState({ error: { UserError: { Text: e.error.message } }, open: true });
        } else if (e && e.Error && e.Error.Text) {
            this.setState({ error: { UserError: e.Error }, open: true });
        } else {
            this.setState({ error: e, open: true, confirmationProps: props });
        }
    }

    onClose = () => {
        this.setState({ error: null, open: false, confirmationProps: null, isLoading: false });
    }

    onConfirm = (option) => {
        let props = this.state.confirmationProps;
        let nProps = (props.json)
            ? { ...props, json: { ...props.json, _confirmOption: option }, _isGlobalLoading: true }
            : { ...props, formData: { ...props.formData, _confirmOption: option }, _isGlobalLoading: true };
        this.onClose();
        post(nProps);
    }

    render() {
        let isUserError = Boolean(this.state.error && this.state.error.UserError);
        let isUserConfirmation = Boolean(this.state.error && this.state.error.UserConfirmation);
        let isSystemError = Boolean(this.state.error && this.state.error.SystemError);
        let text = isUserError
            ? this.state.error.UserError.Text
            : isUserConfirmation
                ? this.state.error.UserConfirmation.Text
                : isSystemError
                    ? this.state.error.SystemError.Text
                    : null;
        let title = isUserError
            ? this.state.error.UserError.Title
            : isUserConfirmation
                ? this.state.error.UserConfirmation.Title
                : isSystemError
                    ? this.state.error.SystemError.Title
                    : null;
        return (
            <Modal zIndex={10000} title={title ? title : 'Что-то пошло не так'} open={this.state.open} onCancel={() => this.setState({ open: false })}
                footer={[
                    isUserConfirmation ? <React.Fragment key="1">
                        <Button onClick={this.onClose}>Отмена</Button>
                        {Object.keys(this.state.error.UserConfirmation.Options).map(key => (<Button style={{ marginLeft: '20px' }} key={key} onClick={() => this.onConfirm(key)}>{this.state.error.UserConfirmation.Options[key]}</Button>))}
                    </React.Fragment> : <Button key="2" onClick={this.onClose}>OK</Button>
                ]}>
                {text
                    ? <div dangerouslySetInnerHTML={{ __html: text }} />
                    : this.state.error && this.state.error.toString()}
                <Divider />
                {isUserConfirmation ? 'Подтвердите ваше решение'
                    : isUserError
                        ? <React.Fragment>
                            Исправьте данные и попробуйте снова.<br />
                            {additionalLinks}
                        </React.Fragment>
                        : <React.Fragment>
                            Попробуйте перезагрузить приложение (Ctrl+R или Ctrl+F5 в браузере), войти чуть позже, <a href="/api/System/Logout">перелогиниться в системе</a> или обратитесь к администратору<br />
                            {additionalLinks}
                        </React.Fragment>}
            </Modal>
        );
    }
}