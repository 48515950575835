import React, { useState } from 'react';
import { post } from '../_common/utils/fetch';
import { Modal, Input, message } from 'antd';
import Table from '../_common/tables/TableOData';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const ParticipationRequests = ({ projectId, refresh, onInit }) => {
    const [signDialogOpen, setSignDialogOpen] = useState(false);
    const [signId, setSignId] = useState();
    const [signText, setSignText] = useState();

    const accept = ({ id }) => {
        post({
            url: `${window.APPCFG.apiPath}/ParticipationRequest/Accept`,
            json: {
                Ids: [id]
            },
            callback: () => {
                message.success("Заявка на участие в проекте согласована");
                refresh();
            }
        })
    }

    const reject = () => {
        setSignDialogOpen(false);
        post({
            url: `${window.APPCFG.apiPath}/ParticipationRequest/Reject`,
            json: {
                Ids: [signId],
                Text: signText
            },
            callback: () => {
                message.success("Заявка на участие в проекте отклонена");
                refresh();
            }
        })
    }

    return <React.Fragment>
        <Table
            columns={[
                {
                    title: 'Роль',
                    dataIndex: 'projectRole',
                    width: 150,
                    type: 'entity',
                    entityName: 'projectRole',
                    entityKey: 'id',
                    entityLabel: 'name',
                    editable: false
                },
                {
                    title: 'Участник',
                    dataIndex: 'user',
                    width: 150,
                    type: 'entity',
                    entityName: 'user',
                    entityKey: 'id',
                    entityLabel: 'name',
                    editable: false,
                    link: true
                },
                {
                    title: 'Состояние',
                    dataIndex: 'state',
                    width: 150,
                    type: 'entity',
                    entityName: 'participationRequestState',
                    entityKey: 'code',
                    entityLabel: 'name',
                    entityOrder: 'order',
                    defaultSortOrder: 'ascend',
                    editable: false,
                    loadAll: true
                },
                {
                    title: 'Комментарий участника',
                    required: true,
                    dataIndex: 'memberDescription',
                    width: 250,
                    editable: true
                },
                {
                    title: 'Комментарий куратора',
                    required: true,
                    dataIndex: 'projectCuratorDescription',
                    width: 250,
                    editable: true
                },
            ]}
            defaults={{
                editable: true
            }}
            editable={false}
            odataEntity={'participationRequest'}
            rowKey={'id'}
            onInit={onInit}
            fullScreen={false}
            odataFilter={`projectId eq ${projectId}`}
            pagination={false}
            actions={[
                { visible: ({ record }) => record.state.code === 'CONSIDERED', icon: <CheckOutlined />, title: 'Согласовать', onClick: (record) => accept({ id: record.id }) },
                { visible: ({ record }) => record.state.code === 'CONSIDERED', icon: <CloseOutlined />, title: 'Отклонить', onClick: (record) => { setSignId(record.id); setSignDialogOpen(true); } },
            ]}
            rowSelection={false}
        />
        <Modal title="Причина отклонения" open={signDialogOpen} onOk={reject} onCancel={() => setSignDialogOpen(false)}>
            <TextArea value={signText} onChange={e => setSignText(e.target.value)} />
        </Modal>
    </React.Fragment>;
}

export default ParticipationRequests;
