import React, { useEffect, useState } from 'react';
import { get, post } from '../_common/utils/fetch';
import { message, Input, Modal, Row, Col, Button, Tag, Typography } from 'antd';
import Table from '../_common/tables/TableOData';
import { PlusOutlined } from '@ant-design/icons';
import { useEnv } from '../_common/hooks/useEnv';
import { EntityForm, Form } from '../_libs/antd-ext';
import Actions from '../_common/layout/Actions';
import { prepareMetadata } from '../_common/odata/entityodata';
import { inList } from '../_common/odata/odata';
import metaRole from './metaRole';

const { TextArea } = Input;
const { confirm } = Modal;

const ProjectRoles = ({ projectId, refresh, forceUpdateRequired, onInit, regime, project, userPermissions }) => {
    const [signDialogOpen, setSignDialogOpen] = useState(false);
    const [signId, setSignId] = useState();
    const [signText, setSignText] = useState();
    const [env] = useEnv();
    const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false);
    const [currentProjectRole, setCurrentProjectRole] = useState();
    const [tableApi, setTableApi] = useState();
    const [data, setData] = useState();
    const [roleCardVisible, setRoleCardVisible] = useState(false);
    const [roleData, setRoleData] = useState();

    useEffect(() => {
        if (regime === 'view' && projectId) {
            get({
                url: `${window.APPCFG.apiPath}/ProjectRole/GetForUser?projectId=${projectId}`,
                callback: setData
            })
        }
        // eslint-disable-next-line
    }, [projectId, regime, forceUpdateRequired])

    const onInitInternal = (tableApi) => {
        setTableApi(tableApi);
        if (onInit) onInit(tableApi);
    }

    const signInCheck = (id) => {
        post({
            url: `${window.APPCFG.apiPath}/ProjectRole/SignInCheck`,
            json: {
                Ids: [id],
                Text: signText
            },
            callback: () => {
                setSignId(id);
                setSignDialogOpen(true);
            }
        })
    }

    const autoGenerate = () => {
        post({
            url: `${window.APPCFG.apiPath}/ProjectRole/AutoGenerate`,
            isGlobalProcessing: true,
            json: {
                Ids: [projectId],
            },
            callback: () => {
                message.success("Роли созданы");
                refresh();
            }
        })
    }

    const signIn = () => {
        setSignDialogOpen(false);
        post({
            url: `${window.APPCFG.apiPath}/ProjectRole/SignIn`,
            json: {
                Ids: [signId],
                Text: signText
            },
            callback: () => {
                message.success("Ваша заявка на участие в проекте зафиксирована");
                refresh();
            }
        })
    }

    const signOut = (id) => {
        confirm({
            title: `Повторная запись на эту роль в проекте будет невозможна. Вы действительно хотите отписаться от этой роли?`,
            onOk: () => {
                post({
                    url: `${window.APPCFG.apiPath}/ProjectRole/SignOut`,
                    json: {
                        Ids: [id]
                    },
                    callback: () => {
                        message.success("Ваша заявка на участие в проекте отменена");
                        refresh();
                    }
                })
            }
        });
    }

    const addMember = ({ value }) => {
        setAddMemberDialogOpen(false);
        post({
            url: `${window.APPCFG.apiPath}/ProjectRole/AddMember`,
            json: {
                MemberId: value.member.id,
                ProjectRoleId: currentProjectRole.id
            },
            callback: () => {
                message.success("Участник добавлен");
                refresh();
            }
        })
    }

    let metadata = prepareMetadata({metadata: metaRole});

    return !project
        ? null
        : regime === 'view'
            ? !data ? null : <React.Fragment>
                <Row>{data.map((role, idx) => <Col style={{padding: 10}}>
                    <ProjectRoleCard
                        key={idx}
                        data={role}
                        signIn={signInCheck}
                        signOut={signOut}
                        canSign={project.state.code === 'ACCEPTED' || project.state.code === 'EXECUTED'} />
                </Col>)}</Row>
                <Modal title="Почему именно я?" open={signDialogOpen} onOk={signIn} onCancel={() => setSignDialogOpen(false)}>
                    <TextArea maxLength={10000} value={signText} onChange={e => setSignText(e.target.value)} />
                </Modal>
            </React.Fragment>
            : <React.Fragment>
                <Actions items={[
                    ...(tableApi ? tableApi.getMainContentMenuItems() : []),
                    env.isSuperAdmin && { label: 'Автогенерация', key: "autoGenerate", onClick: autoGenerate },
                ]} />
                <Table
                    key='edit'
                    columns={metadata.properties}
                    defaults={{
                        editable: true
                    }}
                    odataEntity={"ProjectRole"}
                    //odataPath={`${window.APPCFG.odataPath}/ProjectRole?$expand=ParticipationRequests($select=MemberId,ProjectRoleId,ProjectCuratorDescription;$expand=State($select=Code,Name))`}
                    rowKey={'id'}
                    onInit={onInitInternal}
                    editable={true}
                    fullScreen={false}
                    odataFilter={`ProjectId eq ${projectId}`}
                    beforeSaveData={({ record }) => { record.projectId = projectId; return record; }}
                    pagination={false}
                    onAdd={() => {setRoleData({workload: 1}); setRoleCardVisible(true);}}
                    onEdit={{
                        action: (record) => {setRoleData(record); setRoleCardVisible(true);}
                    }}
                    actions={[
                        { visible: ({ record }) => userPermissions.addMember && record.id > 0 && record.availableQuantity > 0, icon: <PlusOutlined />, title: 'Записать участника', onClick: (record) => { setCurrentProjectRole(record); setAddMemberDialogOpen(true) } },
                    ]}
                    afterDelete={() => {refresh(); return true;}}
                />
                <Form
                    metadata={{
                        properties: [
                            {
                                title: 'Участник',
                                dataIndex: 'member',
                                width: 150,
                                type: 'entity',
                                entityName: 'position',
                                entityKey: 'id',
                                entityLabel: ['user/name'],
                                entityLabelFunc: (record) => record['user']['name'],
                                editable: true
                            },
                        ]
                    }}
                    card={true}
                    title={`Выберите добавляемого участника`}
                    visible={addMemberDialogOpen}
                    onSave={addMember}
                    onCancel={() => setAddMemberDialogOpen(false)}
                    value={{}}
                />
                <EntityForm
                    metadata={metadata}
                    value={roleData}
                    card={true}
                    regime={roleData && roleData.id ? 'updating' : 'creating'}
                    visible={roleCardVisible}
                    onCancel={() => setRoleCardVisible(false)}
                    onSave={refresh}
                    beforeSaveData={({ record }) => { record.projectId = projectId; return record; }}
                />
            </React.Fragment>;
}

export default ProjectRoles;

const ProjectRoleCard = ({ data, signIn, signOut, canSign }) => {
    let stateId = data.isMember
        ? (data.stateId === 'ACCEPTED' ? data.stateId : 'CURATOR')
        : data.stateId;
    let stateName = stateId === 'CURATOR' ? 'Записан куратором' : data.stateName;
    let action = !canSign
        ? undefined
        : data.stateId && data.stateId !== 'CANCELED' && data.stateId !== 'REJECTED'
            ? <Button onClick={() => signOut(data.id)}>Отписаться</Button>
            : !data.stateId && !data.isMember && data.availableQuantity > 0
                ? <Button onClick={() => signIn(data.id)}>Записаться</Button>
                : undefined;
    return <div className='card'>
        <div className='card-header' style={data.isMember ? { backgroundColor: 'green' } : !action ? { backgroundColor: '#e1e1e1' } : undefined}></div>
        <div className='card-name'><Typography.Paragraph ellipsis={{ rows: 4, expandable: true, onExpand: (e) => {e.stopPropagation(); e.preventDefault()} }}>{data.name}</Typography.Paragraph></div>
        <div className='card-description'><Typography.Paragraph ellipsis={{ rows: 8, expandable: true, onExpand: (e) => {e.stopPropagation(); e.preventDefault()} }}>{data.description}</Typography.Paragraph></div>
        <div className='card-info'>
            <Tag color='blue'className='custom-tag'>Ценность: {data.workload || 'не задана'}</Tag>
            <Tag color='purple'className='custom-tag'>Вакансий: {data.availableQuantity} из {data.quantity}</Tag>
            {stateName ? <Tag color='green'className='custom-tag'>Состояние: {stateId === 'CURATOR' ? 'Записан куратором' : data.stateName}</Tag> : null}
        </div>
        {!data.projectCuratorDescription ? null : <div className='card-comment'>Комментарий куратора: {data.projectCuratorDescription}</div>}
        {action ? <div className='card-actions'>{action}</div> : null}
    </div>;
}
