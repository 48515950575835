import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Radio, Space, Form } from 'antd';
import moment from 'moment';
import { DATETIMEFORMAT } from '../../_common/tables/date';
import FieldEntity from '../../_libs/antd-ext/form/FieldEntity';
import { meta as metaUser } from '../../users/User';
import FormItems from '../../_libs/antd-ext/form/FormItems';
import { cloneDeep } from 'lodash';

const ExtMemberDialog = ({ open, onCancel, onOk, selected }) => {
    const [form] = Form.useForm();
    let metadataUser = cloneDeep(metaUser);
    var fields = ['name', 'email', 'phone', 'mainOrganization', 'mainPosition'];
    metadataUser.properties = metadataUser.properties.filter(_ => fields.indexOf(_.dataIndex) >= 0);
    let email = metadataUser.properties.filter(_ => _.dataIndex == 'email')[0];
    email.editable = true;
    email.required = true;

    const onOkInternal = async () => {
        let row = await form.validateFields();
        let json = { ...row };
        onOk(json);
    }

    return <Modal open={open} onOk={onOkInternal} onCancel={onCancel}
        title={`Добавление внешнего эксперта`}>
        <div style={{ marginBottom: 10 }}>Внешний эксперт будет добавлен {!selected || selected.length === 0 ? 'в меропритие (во все проекты)' : `в выбранные проекты (${selected.length})`}</div>
        <Form
            form={form}
            autoComplete="off"
            layout="horizontal"
        >
            <Form.Item name="attendanceType" label="Тип участия">
                <FieldEntity
                    entityName='attendanceType'
                    entityKey='code'
                    entityLabel='name'
                    loadAll={true} />
            </Form.Item>
            <b>Выберите</b>
            <Form.Item name="user" label="Существующего пользователя">
                <FieldEntity
                    entityName='user'
                    entityKey='id'
                    entityLabel='name' />
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.user !== currentValues.user}
            >
                {({ getFieldValue, setFieldValue }) =>
                    getFieldValue('user') == null ? (
                        <React.Fragment>
                            <b>или добавьте нового</b>
                            <FormItems
                                metadata={metadataUser}
                            />
                        </React.Fragment>
                    ) : null
                }
            </Form.Item>
        </Form>
    </Modal>
}

export default withRouter(ExtMemberDialog);