import React from 'react';
import { withRouter } from "react-router-dom";
import { useEntity } from '../_common/hooks/useEntity';
import { EntityForm, EntityViewer } from '../_libs/antd-ext';

const metadata = {
    entity: 'ClientSettings',
    key: 'id',
    properties: [
        {
            title: 'Workflow',
            dataIndex: 'workflow',
            width: 150,
            editable: true,
            multiple: true
        },
        {
            title: 'Видимость проектов',
            dataIndex: 'projectVisibility',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'projectVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true
        },
        {
            title: 'Запись в Исполняется',
            description: 'Разрешена запись на проект в состояние Исполняется',
            dataIndex: 'canSignInInExecuted',
            type: 'boolean',
            width: 150,
            editable: true
        }
    ]
};

const ClientSettings = ({ regime, client }) => {
    const [data] = useEntity({metadata, extFilter: client && `clientId eq ${client.id}`})
    return !client
        ? null
        : regime === 'view'
            ? <React.Fragment>
                <EntityViewer
                    metadata={metadata}
                    value={data}
                />
            </React.Fragment>
            : <EntityForm
                card={false}
                regime={regime === 'edit' ? 'updating' : 'creating'}
                metadata={metadata}
                visible={true}
                value={data}
            />
}

export default withRouter(ClientSettings);
