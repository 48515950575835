import React, { useRef } from "react";
import { Select as AntdSelect } from 'antd';

const Select = ({onChange, blur, ...rest}) => {
    const ref = useRef();
    return <AntdSelect
        ref={ref}
        onChange={(value, option) => {
            onChange(value, option); 
            if (blur) ref.current.blur();
        }}
        {...rest}
    />
}

export default Select;