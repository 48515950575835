import React, { useState } from 'react';
import { post } from '../_common/utils/fetch';
import { message, Col, Row, Modal } from 'antd';
import Table from '../_common/tables/TableOData';
import { useEnv } from '../_common/hooks/useEnv';
import Actions from '../_common/layout/Actions';

const EventExtMembers = ({ event, metadata, extMembers, forceUpdate }) => {
    const [env, { hasRoles }] = useEnv();
    const [selected, setSelected] = useState();

    const removeExtMembers = () => {
        if (!selected || selected.length === 0) message.warning('Не заданы записи для удаления');
        Modal.confirm({
            title: `Выбранные эксперты будут удалены из всех проектов. Если вы хотите удалить эксперта только из части проектов, то на вкладке Проекты выделите те, на которых нужно оставить и добавьте эксперта заново, он будет оставлен только на выделенных проектах. Вы действительно удалить (${selected.length})?`,
            onOk: () => {
                post({
                    isGlobalProcessing: true,
                    url: `${window.APPCFG.apiPath}/Event/RemoveExtMember`,
                    json: {
                        eventId: event.id,
                        memberIds: selected,
                    },
                    callback: () => {
                        forceUpdate();
                        message.success("Внешние эксперты удалены из мероприятия");
                    }
                })
            }
        })
    }

    return env && event && event.id
        ? <React.Fragment>
            <Row>
                <Col><Actions
                    style={{ marginRight: 30 }}
                    items={[
                        hasRoles('ADMIN') && {
                            key: 'removeExtMembers',
                            label: 'Удалить выбранных внешних экспертов из всех проектов',
                            onClick: removeExtMembers
                        }
                    ]}
                /></Col>
            </Row>
            <Table
                columns={metadata.properties}
                rowKey={metadata.key}
                dataSource={extMembers}
                rowSelection={hasRoles('PROJECT_MANAGER', 'ADMIN')
                    ? {
                        onChange: (newSelectedRowKeys, newSelectedRows) => setSelected(newSelectedRowKeys)
                    } : false}
            />
        </React.Fragment>
        : null;
}

export default EventExtMembers;
