export function useViewRoot() {
    let pathParts = window.location.pathname.split('/');
    const mainRoot = pathParts[1];
    const regime = !pathParts[2]
        ? 'list'
        : pathParts[3] === 'edit'
            ? 'edit'
            : pathParts[3] === 'create'
                ? 'create'
                : pathParts[3] === 'copy'
                    ? 'copy'
                    : 'view';
    const id = pathParts[2] ? parseInt(pathParts[2]) : null;
    const subRoot = pathParts[4];
    const isCurrent = (regime === 'edit' || regime === 'view') && id === 0;
    return {mainRoot, id, regime, subRoot, isCurrent};
}

export function getViewRootPath({mainRoot, id, regime, subRoot}) {
    if (subRoot && !regime) regime = 'view';
    if (regime && !id) id = 0;
    return '/' + [mainRoot, id, regime, subRoot].filter(_ => _ !== null && _ !== undefined).join('/');
}