import React, { useEffect } from 'react';
import { Form, Input, Button, InputNumber } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './style.css'

const cloneDeep = require('clone-deep');

const GradeCompanyOwnResults = ({ gradeCompany, gradeCompanyResult, env, onSave, userPermissions }) => {
    const disabled = gradeCompany.state.code !== 'OPEN';

    const [form] = Form.useForm();
    useEffect(() => {
        if (!gradeCompanyResult) return;
        let results = {};
        if (gradeCompanyResult.grades) {
            for (let grade of gradeCompanyResult.grades.filter(_ => _.evaluatingUserId === env.userId)) {
                results[grade.userId] = {...(results[grade.userId] || {}), grade: grade.grade} 
            }
        }
        if (gradeCompanyResult.comments) {
            for (let comment of gradeCompanyResult.comments.filter(_ => _.evaluatingUserId === env.userId)) {
                results[comment.userId] = {...(results[comment.userId] || {}), comment: comment.comment} 
            }
        }
        form.resetFields();
        form.setFieldsValue(results);
    }, [gradeCompanyResult]);

    const onFinishInternal = async () => {
        let row = null;
        try {
            row = await form.validateFields();
        } catch (e) {
            return;
        }
        let json = cloneDeep(row);
        let results = [];
        for (let id in json) {
            let result = json[id];
            result.userId = id;
            results.push(result);
        }
        onSave({results, gradeCompanyId: gradeCompany.id});
    }
    return <div className='panel'>
        <div className='info'>Оценки: Другие участники (кроме куратора) {
            gradeCompany.settings.gradeVisibilityOwn.code == 'NONE'
                ? 'не увидят вашу оценку'
                : gradeCompany.settings.gradeVisibilityOwn.code == 'AVERAGE'
                    ? 'увидят только усредненную от всех участников оценку'
                    : gradeCompany.settings.gradeVisibilityOwn.code == 'IMPERSONAL'
                        ? 'увидят вашу оценку, но без указания кто ее поставил'
                        : 'увидят вашу оценку с указанием кто ее поставил'
        }</div>
        <div className='info'>Комментарии: Другие участники (кроме куратора) {
            gradeCompany.settings.gradeCommentVisibilityOwn.code == 'NONE'
                ? 'не увидят ваши комментарии'
                : gradeCompany.settings.gradeCommentVisibilityOwn.code == 'IMPERSONAL'
                    ? 'увидят ваши комментарии, но без указания кто их написал'
                    : 'увидят ваши комментарии с указанием кто их написал'
        }</div>
        <Form
            form={form}
            name="basic"
            style={{ maxWidth: 800 }}
            onFinish={onFinishInternal}
            size='small'
            layout='inline'
        >
            {gradeCompanyResult.members.map((member, idx) =>
                <React.Fragment key={idx}>
                    <div className='memberName'><UserOutlined style={{color: 'purple'}} /> {member.name}{member.userId === env.userId ? <b style={{color: 'purple'}}> (самооценка)</b> : ''}</div>
                    <div>
                    <Form.Item
                        label='Оценка'
                        tooltip={`Оценка в интервале от ${gradeCompany.settings.gradeMin} до ${gradeCompany.settings.gradeMax}`}
                        key={member.userId + 'grade'}
                        name={[member.userId, 'grade']}
                        rules={[{ required: true, message: 'Оценка обязательна' }]}
                    >
                        <InputNumber disabled={disabled} min={gradeCompany.settings.gradeMin} max={gradeCompany.settings.gradeMax} precision={0} />
                    </Form.Item></div>
                    <Form.Item
                        key={member.userId + 'comment'}
                        name={[member.userId, 'comment']}
                        rules={[{ required: gradeCompany.settings.gradeNeedComment, message: 'Комментарий обязателен' }]}
                    >
                        <Input.TextArea disabled={disabled} allowClear={true} rows={4} placeholder='Комментарий' />
                    </Form.Item>
                </React.Fragment>)
            }
            {!disabled && <div style={{width: '100%', paddingTop: 10}}>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Сохранить
                </Button>
            </Form.Item></div>}
        </Form>
    </div>
}

export default GradeCompanyOwnResults;
