import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { get } from '../_common/utils/fetch';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const metadata = {
    entity: 'DynamicListItem',
    key: 'id',
    properties: [
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true
        },
        {
            title: 'Порядок',
            dataIndex: 'ord',
            type: 'number',
            width: 100,
            editable: true
        },
    ],
    default: {
        //required: true,
        //editable: true,
        //sortable: true
    }
};

const DynamicListItem = (props) => {
    const [tableApi, setTableApi] = useState();
    const [listName, setListName] = useState();

    let pathParts = props.history.location.pathname.split('/');
    let listId = pathParts[2] ? parseInt(pathParts[2]) : null;

    useEffect(() => {
        get({
            url: `${window.APPCFG.odataPath}/dynamicList?$filter=id eq ${listId}&$select=name`,
            callback: (data) => {
                setListName(data.value[0].name)
            },
        });
    }, [listId])

    return <MainContent layoutProps={props.layoutProps}
        title={`Значения из списка ${listName}`}
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
            //{ label: 'Редактировать в карточке', key: "editInCard", onClick: () => setFormVisible(true) },
            ]}
            headerRightButtons={<Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} onClick={() => props.history.push(`/DynamicList`)} />}
    >
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
            odataFilter={`listId eq ${listId}`}
            beforeSaveData={({record, creating, editingKey}) => {record.listId = listId; return record;}}
        />
    </MainContent>
}

export default withRouter(DynamicListItem);
