import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import { useMetadata } from '../_common/hooks/useMetadata';
import FieldEntity from '../_libs/antd-ext/form/FieldEntity';
import { message, Modal } from 'antd';
import { post } from '../_common/utils/fetch';

const meta = {
    entity: 'projectApprovalStepSetting',
    key: 'id',
    properties: [
        {
            title: 'Тип проекта',
            dataIndex: 'projectType',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'projectType',
            entityKey: 'id',
            entityLabel: 'name',
            loadAll: true,
        },
        {
            title: 'Порядок',
            dataIndex: 'ord',
            type: 'number',
            width: 100,
            editable: true,
            required: true
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true
        },
        {
            title: 'Тип согласующего',
            dataIndex: 'responsibleType',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'projectApprovalResponsibleType',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            required: true,
        },
        {
            title: 'Согласующее подразделение',
            dataIndex: 'responsibleDepartment',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'department',
            entityKey: 'id',
            entityLabel: 'name',
            loadAll: true,
        },
        {
            title: 'Код',
            dataIndex: 'code',
            width: 150,
            editable: true
        },
        {
            title: 'Нормативная длительность, дней',
            dataIndex: 'duration',
            type: 'number',
            width: 100,
            editable: true
        },
        {
            title: 'Кол-во, требующееся для согласования',
            description: 'Кол-во, требующееся для согласования (если 0 - то все)',
            dataIndex: 'acceptedCnt',
            type: 'number',
            width: 100,
            render: (text) => text === 0 ? 'Все' : text,
            editable: true
        },
        {
            title: 'Кол-во, требующееся для отклонения',
            description: 'Кол-во, требующееся для отклонения (если 0 - то все, если null - отклонение игнорируется)',
            dataIndex: 'rejectedCnt',
            type: 'number',
            width: 100,
            render: (text) => text === 0 ? 'Все' : (!text ? 'Не контролируется' : text),
            editable: true
        },
    ]
};

const ProjectApprovalStepSetting = (props) => {
    const [tableApi, setTableApi] = useState();
    const [projectType, setProjectType] = useState();
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [selected, setSelected] = useState();
    const [env] = useEnv();
    const [metadata] = useMetadata(meta);
    if (metadata && env) {
        metadata.propertyMap.projectType.entityFilter = `clientId eq ${env.userClientId}`;
        metadata.propertyMap.responsibleDepartment.entityPath = `${window.APPCFG.odataPath}/Department?id=${env.userClientId}&`;
    }

    const copy = () => {
        setCopyDialogOpen(false);
        post({
            url: `${window.APPCFG.apiPath}/ProjectApprovalStepSetting/Copy`,
            json: {
                Steps: selected,
                ProjectTypeId: projectType.id
            },
            callback: () => {
                message.success('Шаги скопированы');
                tableApi.refresh();
            }
        })
    }

    return !metadata ? null : <React.Fragment>
        <MainContent layoutProps={props.layoutProps}
            title="Настройки согласования проекта"
            onClickRefresh={tableApi && tableApi.refresh}
            menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
                { label: 'Копировать шаги', key: "copy", disabled: !selected || selected.length === 0, onClick: () => setCopyDialogOpen(true) },
            ]}
        >
            {env && <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={props.history}
                odataFilter={`clientId eq ${env.userClientId}`}
                rowSelection={{
                    onChange: (newSelectedRowKeys, newSelectedRows) => setSelected(newSelectedRowKeys)
                }}
            />}
        </MainContent>
        <Modal title={"Тип проекта"}
            open={copyDialogOpen}
            onCancel={() => setCopyDialogOpen(false)}
            onOk={copy}>
            <FieldEntity value={projectType}
                entityName="projectType"
                entityKey='id'
                entityLabel="name"
                entityFilter={`clientId eq ${env.userClientId}`}
                loadAll={true}
                onChange={setProjectType} />
        </Modal>
    </React.Fragment>
}

export default withRouter(ProjectApprovalStepSetting);
