import React, { useState } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import FileDialog from '../_common/FileDialog';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { post } from '../_common/utils/fetch';
import { message } from 'antd';
import { useEnv } from '../_common/hooks/useEnv';
import { useViewRoot, getViewRootPath } from '../_common/hooks/useViewRoot';
import { useEntity } from '../_common/hooks/useEntity';
import MainContent from '../_common/layout/MainContent';
import { CreditCardOutlined } from '@ant-design/icons';
import Table from '../_common/tables/TableOData';
import metadata from './_meta';

const Clients = (props) => {
    const [uploadOpen, setUploadOpen] = useState(false);
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [env] = useEnv();
    const history = useHistory();
    const [tableApi, setTableApi] = useState();
    let { mainRoot, regime, id, isCurrent } = useViewRoot();
    if (isCurrent) id = env && env.userClientId;
    const [entityData] = useEntity({ metadata, id, forceUpdateRequired });
    const [selectedRows, setSelectedRows] = useState();

    let canEdit = Boolean(env && env.userClientId === id);

    if (regime === 'edit' && !entityData) regime = 'create';
    if (!canEdit && regime !== 'list') regime = 'view';

    const refresh = () => {
        forceUpdate();
        if (tableApi) tableApi.refresh();
    }

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Client/InitFromExcel`,
            formData: {
                File: file,
                Pars: pars ? JSON.stringify(pars.split(/[\s,]+/).filter(n => n)) : undefined
            },
            callback: () => {
                refresh();
                message.success("Файл загружен");
            }
        });
    }

    const toActive = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Client/ToActive`,
            json: {
                ids
            },
            callback: () => {
                refresh();
                message.success("Организации активированы");
            }
        })
    }

    const toNotActive = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Client/ToNotActive`,
            json: {
                ids
            },
            callback: () => {
                refresh();
                message.success("Организации деактивированы");
            }
        })
    }

    return <React.Fragment>
        <MainContent layoutProps={props.layoutProps}
            title="Организации"
            onClickRefresh={tableApi && tableApi.refresh}
            menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
            env && env.isSuperAdmin && { label: 'Активировать организации', disabled: selectedRows === null || selectedRows === undefined || selectedRows.length === 0, key: 'toActive', onClick: () => toActive({ ids: selectedRows.map(_ => _.id) }) },
            env && env.isSuperAdmin && { label: 'Деактивировать организации', disabled: selectedRows === null || selectedRows === undefined || selectedRows.length === 0, key: 'toNotActive', onClick: () => toNotActive({ ids: selectedRows.map(_ => _.id) }) }
            ]}
        >
            <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={history}
                editable={Boolean(env && env.isSuperAdmin)}
                onAdd={false}
                onEdit={(record) => history.push(getViewRootPath({ mainRoot: 'client', id: record[metadata.key], regime: 'edit' }))}
                onCopy={(record) => history.push(getViewRootPath({ mainRoot: 'client', id: record[metadata.key], regime: 'copy' }))}
                actions={[
                    { key: 'view', icon: <CreditCardOutlined />, title: 'Просмотреть', onClick: (record) => history.push(getViewRootPath({ mainRoot: 'client', id: record[metadata.key] })) }
                ]}
                rowSelection={{
                    onChange: (newSelectedRowKeys, newSelectedRows) => setSelectedRows(newSelectedRows)
                }}
            />
        </MainContent>
        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel} />
    </React.Fragment>
}

export default withRouter(Clients);
