const cloneDeep = require('clone-deep');

export const metaSettings = {
    entity: 'settings',
    properties: [
        {
            title: 'Автооткрытие',
            dataIndex: 'startAuto',
            type: 'boolean',
            width: 150,
            editable: true
        },
        {
            title: 'Автозакрытие',
            dataIndex: 'finishAuto',
            type: 'boolean',
            width: 150,
            editable: true
        },
        {
            title: 'Обязательность комментария',
            dataIndex: 'gradeNeedComment',
            type: 'boolean',
            width: 150,
            editable: true
        },
        {
            title: 'Минимальное значение оценки',
            defaultValue: 1,
            dataIndex: 'gradeMin',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Максимальное значение оценки',
            dataIndex: 'gradeMax',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Видимость своей оценки',
            dataIndex: 'gradeVisibilityOwn',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'gradeVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            description: 'Как участник видит оценки, которые другие участники проставили ему: Не видимо - не видит вообще; Среднее - видит одну среднюю оценку и кол-во оценок; Обезличенное детальное - видит список оценок, но не видит кто их проставлял; Не обезличенное - видит все оценки, в том числе кто их поставил'
        },
        {
            title: 'Видимость комментария своей оценки',
            dataIndex: 'gradeCommentVisibilityOwn',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'gradeCommentVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            description: 'Как участник видит комментарии, которые другие участники проставили ему: Не видимо - не видит вообще; Обезличенное - видит список комментариев, но не видит кто их проставлял; Не обезличенное - видит все комментарии, в том числе кто их поставил'
        },
        {
            title: 'Видимость чужой оценки',
            dataIndex: 'gradeVisibilityOthers',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'gradeVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            description: 'Как участник видит оценки, которые получили другие участники (см. Видимость своей оценки)'
        },
        {
            title: 'Видимость комментария чужой оценки',
            dataIndex: 'gradeCommentVisibilityOthers',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'gradeCommentVisibility',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            description: 'Как участник видит комментарии, которые получили другие участники (см. Видимость комментария своей оценки)'
        },
        {
            title: 'Видимость оценок до закрытия',
            description: 'Будут ли оценки видны участникам в момент заполнения или только после закрытия кампании',
            dataIndex: 'gradeVisibilityOpen',
            type: 'boolean',
            width: 150,
            editable: true
        },
    ]
};

export const addTo = ({metadata, settingsName = 'settings', clone = false, required = true}) => {
    let settings = cloneDeep(metaSettings);
    let meta = clone ? cloneDeep(metadata) : metadata;
    for (let p of settings.properties) {
        p.dataIndex = [settingsName, p.dataIndex];
        p.isNavigation = false;
        p.required = required;
        p.responsive = ["sm"];
        meta.properties.push(p);
    }
    return meta;
}

export const getInitialRecord = (settings) => {
    let record = {
    };
    record.startAuto = record.startAuto || false;
    record.finishAuto = record.finishAuto || false;
    record.gradeNeedComment = record.gradeNeedComment || true;
    record.gradeMin = record.gradeMin || 1;
    record.gradeMax = record.gradeMax || 5;
    record.gradeVisibilityOwn = record.gradeVisibilityOwn || { code: 'NONE' }
    record.gradeCommentVisibilityOwn = record.gradeCommentVisibilityOwn || { code: 'NONE' }
    record.gradeVisibilityOthers = record.gradeVisibilityOther || { code: 'NONE' }
    record.gradeCommentVisibilityOthers = record.gradeCommentVisibilityOther || { code: 'NONE' }
    record.gradeVisibilityOpen = record.gradeVisibilityOpen || false;
    return {settings: record};
}