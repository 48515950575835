import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Radio, Space } from 'antd';
import moment from 'moment';
import { DATETIMEFORMAT } from '../../_common/tables/date';

const ChangeRegimeDialog = ({ open, onCancel, onOk, isTimeCuratorApprovedRequired, oldValue, newValue }) => {
    const [regime, setRegime] = useState();

    return <Modal open={open} onOk={() => onOk(regime)} onCancel={onCancel}
        title={`Выберите режим применения изменений`}>
        <div>Произошло изменение:</div>
        {!moment(oldValue.startDate).isSame(moment(newValue.startDate)) && <div style={{paddingLeft: 10}}>времени проведения с <b>{oldValue.startDate ? moment(oldValue.startDate).format(DATETIMEFORMAT) : '<Пустая>'}</b> на <b>{newValue.startDate ? moment(newValue.startDate).format(DATETIMEFORMAT) : '<Пустая>'}</b></div>}
        {oldValue.location !== newValue.location && <div style={{paddingLeft: 10}}>места проведения с <b>{oldValue.location}</b> на <b>{newValue.location}</b></div>}
        <div style={{marginTop: 10}}>Выберите режим применения изменений</div>
        <Radio.Group onChange={e => setRegime(e.target.value)} value={regime}>
            <Space direction="vertical">
                <Radio value={'NONE'}>Ничего (например, в случае небольшой правки адреса в связи с ошибкой)</Radio>
                <Radio value={'NOTIFY'}>Уведомления без пересогласования</Radio>
                {isTimeCuratorApprovedRequired && <Radio value={'TOAGREED'}>Пересогласование</Radio>}
            </Space>
        </Radio.Group>
    </Modal>
}

export default withRouter(ChangeRegimeDialog);