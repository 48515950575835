import Filter from "./Filter";

const TableFilter = ({ setSelectedKeys, selectedKeys, ...rest }) => {
    return <Filter
        selectedKeys={selectedKeys && selectedKeys[0] ? selectedKeys[0] : undefined}
        setSelectedKeys={(value) => setSelectedKeys(value === null || value === undefined ? value : [value])}
        {...rest}
    />
}

export default TableFilter;