import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
//import Table from './Test';
import MainContent, { topHeight } from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import TreePanel from '../_common/TreePanel';
import { useEnv } from '../_common/hooks/useEnv';
import { systemInfo } from '../_common/utils/systemInfo';

const departmentId = null;

const Department = (props) => {
    const [tableApi, setTableApi] = useState();
    const [selectedGroupKey, setSelectedGroupKey] = useState(departmentId);
    const [prevSelectedGroupKey, setPrevSelectedGroupKey] = useState(departmentId);
    const [selectedGroup, setSelectedGroup] = useState();
    const [env] = useEnv();

    const beforeSaveData = ({record}) => {
        record.departmentId = selectedGroupKey;
        return record;
    }

    const beforeAddData = ({record}) => {
        return record;
    }

    const onSelect = (selectedKeys, e, {node}) => {
        setSelectedGroupKey(selectedKeys && selectedKeys[0] ? selectedKeys[0] : departmentId);
        setSelectedGroup(node);
    }

    useEffect(() => {
        if (selectedGroupKey !== prevSelectedGroupKey) {
            setPrevSelectedGroupKey(selectedGroupKey);
            tableApi.refresh();
        }
    // eslint-disable-next-line
    }, [selectedGroupKey, prevSelectedGroupKey])

    return <MainContent layoutProps={props.layoutProps}
        title={`${(selectedGroup ? `${selectedGroup.name} - ` : '')}Участники`}
        onClickRefresh={() => {
            if (tableApi) tableApi.refresh();
        }}
        menu={tableApi && tableApi.getMainContentMenuItems()}
    >
        {env && <div style={{ margin: '0px' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <div style={{ height: `calc(100vh - ${topHeight + 16}px)`, marginBottom: '-10px', overflow: 'auto', borderRight: '1px solid #f1f1f1' }}>
                    <TreePanel 
                        entityName="Department" 
                        id={env.userClientId}
                        selectedKeys={[selectedGroupKey]}
                        onSelect={onSelect}
                        title={'name'}
                        ordered={false}
                        expandable={{defaultExpandAllRows: true}}
                    />
                </div>
                <div style={{ padding: '0px' }}>
                    <Table
                        columns={[
                            {
                                title: 'Пользователь',
                                dataIndex: 'user',
                                width: 150,
                                type: 'entity',
                                entityName: 'user',
                                entityKey: 'id',
                                entityLabel: 'name',
                                editable: {
                                    updating: false
                                },
                                required: true
                            }]}
                        odataEntity='departmentMember'
                        odataFilter={`departmentId eq ${selectedGroupKey}`}
                        onInit={setTableApi}
                        history={props.history}
                        beforeSaveData={beforeSaveData}
                        beforeCopyData={beforeAddData}
                        beforeAddData={beforeAddData}
                        onEdit={false}
                        onCopy={false}
                    />
                </div>
            </div>
        </div>}
    </MainContent>
}

export default withRouter(Department);
