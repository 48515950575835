import React, { PureComponent } from 'react';
import { BellOutlined } from '@ant-design/icons'
import { Button, Dropdown, Badge, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { systemInfo } from '../utils/systemInfo'
import { isTouchDevice } from '../utils/browserCapabilities';
export default class NotificationButton extends PureComponent {
    state = {
        notifications: null,
    }

    updateNotifications = (notifications) => {
        this.setState({ notifications });
    }

    componentDidMount() {
        systemInfo.subscribeNotification(this.updateNotifications);
        systemInfo.updateNotifications({ timeout: true });
    }

    componentWillUnmount() {
        systemInfo.unsubscribeNotification(this.updateNotifications);
    }

    render() {
        let notifications = this.state.notifications;
        if (!notifications || !notifications.notifications || notifications.notifications.length === 0) return null;
        const getLabel = (notification) => {
            return notification.url && (notification.url.startsWith('http') || notification.urlType === 1)
                ? <a href={notification.url}>{notification.text}</a>
                : <Link to={notification.url}>{notification.text}</Link>;
        }
        const items = notifications.notifications.map((notification, idx) => ({
            key: idx,
            label: getLabel(notification),
        }));
        let count = notifications.notifications.reduce((partialSum, a) => partialSum + (a.cnt || 1), 0);
        return <Dropdown trigger={isTouchDevice() ? ['click'] : ['hover']} overlay={<Menu items={items} />} placement="bottomRight" arrow>
            <Badge count={count} size="small">
                <Button shape="circle" type="text" icon={<BellOutlined style={{ fontSize: '20px' }} />} />
            </Badge>
        </Dropdown>
    }
}