import React, { useRef } from 'react';
import { Select } from 'antd';

const FilterString = ({ value, onChange, confirm, style = {}, tokenSeparators }) => {
    const ref = useRef();
    const paste = (e, onChange) => {
        e.stopPropagation();
        e.preventDefault();
        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        let values = pastedData.split("\n").flatMap(_ => _.split("\t")).map(_ => _.trim()).filter(_ => _);
        onChange(!value || value.length === 0 ? values : [...value, ...values]);
        if (ref && ref.current) ref.current.blur();
        confirm();
    }
    return <Select
        style={{
            ...style,
            width: '100%'
        }}
        mode="tags"
        value={value}
        onChange={value => onChange(!value || value.length === 0 ? null : value)}
        onPaste={(e) => paste(e, onChange)}
        maxTagCount={5}
        ref={ref}
        allowClear={true}
        tokenSeparators={tokenSeparators}
    />
}

export default FilterString;