import { Modal, Select } from "antd";
import React, {useState, useEffect} from "react";
import { useEnv } from "../_common/hooks/useEnv";
import { get } from "../_common/utils/fetch";
import fileExport from "../_common/utils/fileExport";

export const usePrintDocumentTypes = (dataType) => {
    const [state, setState] = useState();
    const [env, {hasRoles}] = useEnv();
    useEffect(() => {
        if (env && hasRoles()) get({
            url: `${window.APPCFG.apiPath}/PrintDocumentType/GetByType?dataType=${dataType}`,
            callback: (data) => {
                setState(data);
            }
        })
    }, [dataType]);
    return [state, setState];
}

export const PrintDocumentComponent = ({printDocumentTypes, path, open, onCancel}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [typeId, setTypeId] = useState(false);
    const onCloseInternal = () => {
        setDialogOpen(false);
        onCancel();
    }
    const getDocument = (typeId) => {
        onCloseInternal();
        fileExport(`${path}&printDocumentTypeId=${typeId}`);
    }
    useEffect(() => {
        if (open) {
            if (!printDocumentTypes || printDocumentTypes.length === 0) onCloseInternal();
            if (printDocumentTypes.length > 1) setDialogOpen(true);
            else getDocument(printDocumentTypes[0].id);
        } else {
            setDialogOpen(false);
        }
    }, [printDocumentTypes, open]);

    if (!open || !dialogOpen) return null;

    return <Modal open={true} onOk={() => getDocument(typeId)} onCancel={onCloseInternal}
        title={'Выберите тип печатной формы'}
        okText={`Выбрать`}>
        <Select value={typeId} onChange={setTypeId} style={{width: '100%'}}>
            {printDocumentTypes.map(d => <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>)}
        </Select>
    </Modal>
}