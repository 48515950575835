//import { showError } from '../ErrorDialog';
//import { changeIsContextProcessing } from '../layout/Layout';
const showError = null;
const changeIsContextProcessing = null;

export function getPromise(props) {
    return new Promise((resolve, reject) => {
        let lProps = {
            ...props,
            callback: (data) => {
                resolve(data);
            },
            errorCallback: (error) => {
                reject(error)
            }
        }
        get(lProps);
    })
}

export async function get(props) {
    let { url, params, callback, errorCallback, commonCallback, isGlobalProcessing, notShowError} = props;
    customFetch({
        url,
        params: params || {},
        //params: {...(params || {}), ...(json ? {body: JSON.stringify(json)} : {})},
        callback, errorCallback, commonCallback, isGlobalProcessing, notShowError
    });
}

export async function patch(props) {
    post({...props, method: 'PATCH'})
}

export async function put(props) {
    post({...props, method: 'PUT'})
}

export async function post(props) {
    let { method, url, params, callback, errorCallback, commonCallback, isGlobalProcessing, json, formData, notShowError } = props;
    var formDataObj = new FormData();
    params = params || {};
    params.method = method || "POST";
    params.headers = params.headers || {};

    if (formData) {
        for (var name in formData) {
            formDataObj.append(name, formData[name]);
        }
    } else {
        params.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...params.headers
        }
    }

    params.body = json
        ? JSON.stringify(json)
        : formData
            ? formDataObj
            : null;

    customFetch({
        url,
        params,
        callback, errorCallback, commonCallback, isGlobalProcessing, notShowError
    });
}

function customFetch(props) {
    let { url, params, callback, errorCallback, commonCallback, isGlobalProcessing, notShowError } = props;
    if (isGlobalProcessing && changeIsContextProcessing) changeIsContextProcessing(true);
    fetch(url, params)
        .then(response => processResponse({ response, callback, errorCallback, commonCallback, isGlobalProcessing, notShowError }))
        .catch(error => handleError({ error, errorCallback, commonCallback, isGlobalProcessing, notShowError }));
}

function processResponse(props) {
    let { response, callback, errorCallback, commonCallback, isGlobalProcessing, notShowError } = props;
    if (isGlobalProcessing && changeIsContextProcessing) changeIsContextProcessing(false);
    const contentType = response.headers.get("content-type");
    if (!response.ok) {
        if (!contentType || !contentType.includes("application/json")) {
            throw new Error(`${response.status} ${response.statusText}`);
        } else {
            response
                .json()
                .then(data => handleError({ error: data, errorCallback, commonCallback, isGlobalProcessing, notShowError }))
                .catch(error => handleError({ error, errorCallback, commonCallback, isGlobalProcessing, notShowError }));
        }
    } else {
        if (contentType && contentType.includes("application/json")) {
            return response
                .json()
                .then(data => {
                    if (callback) callback(data);
                    if (commonCallback) commonCallback();
                })
                .catch(error => handleError({ error, errorCallback, commonCallback, isGlobalProcessing, notShowError }));
        } else {
            return response
                .text()
                .then(data => {
                    if (callback) callback(data);
                    if (commonCallback) commonCallback();
                })
                .catch(error => handleError({ error, errorCallback, commonCallback, isGlobalProcessing, notShowError }));
        }
    }
}

function handleError(props) {
    let { error, errorCallback, commonCallback, isGlobalProcessing, notShowError } = props;
    if (isGlobalProcessing && changeIsContextProcessing) changeIsContextProcessing(false);
    if (showError && !notShowError) showError(error);
    if (errorCallback) errorCallback(error);
    if (commonCallback) commonCallback();
}