import React, { useState } from 'react';
import Table from './TableOData';
import { EntityForm, EntityViewer } from '../../_libs/antd-ext';

const TableWithCard = ({ metadata: propMetadata, getDefaultData, title, onAdd, onEdit, onCopy, onInit, columns, odataEntity, rowKey, beforeSaveData, ...rest }) => {
    const [regime, setRegime] = useState();
    const [entityData, setEntityData] = useState();
    const [tableApi, setTableApi] = useState();
    const metadata = propMetadata || {
        entity: odataEntity,
        properties: columns,
        key: rowKey
    }
    return <React.Fragment>
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={(tableApi) => {setTableApi(tableApi); if (onInit) onInit(tableApi);}}
            onAdd={() => {setRegime('add'); setEntityData(getDefaultData ? getDefaultData(setEntityData) : undefined)}}
            onEdit={{...(onEdit || {}), action: (record) => {setRegime('edit'); setEntityData(record)}}}
            onCopy={{...(onCopy || {}), action: (record) => {setRegime('copy'); setEntityData(record)}}}
            {...rest}
        />
        {regime === 'view'
            ? <EntityViewer
                metadata={metadata}
                value={entityData}
            />
            : (regime === 'edit' || regime === 'add' || regime === 'copy')
                ? <EntityForm
                    card={true}
                    regime={regime === 'edit' ? 'updating' : 'creating'}
                    metadata={metadata}
                    title={typeof title === 'function' ? title({regime, entityData}) : title}
                    visible={true}
                    value={entityData}
                    defaults={metadata.defaults}
                    editable={true}
                    onSave={() => {
                        setRegime();
                        tableApi.refresh();
                    }}
                    onCancel={() => setRegime()}
                    beforeSaveData={beforeSaveData}
                />
                : null}
    </React.Fragment>
}

export default TableWithCard;
