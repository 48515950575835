import React from 'react';
import { withRouter } from "react-router-dom";
import PropertyViewer from './PropertyViewer';
import './entityViewer.css'

const EntityViewer = ({ value, metadata, showNull = false }) => {
    if (!value) return null;
    return <div className='entity'>
        {metadata.properties.map((property, idx) => <PropertyViewer key={idx} entityValue={value} propertyMeta={property} showNull={showNull} />)}
    </div>
}

export default withRouter(EntityViewer);