import React, { useState } from 'react';
import { withRouter, useHistory, Link } from "react-router-dom";
import FileDialog from '../_common/FileDialog';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { post } from '../_common/utils/fetch';
import { message, Tabs } from 'antd';
import { useEnv } from '../_common/hooks/useEnv';
import { useViewRoot, getViewRootPath } from '../_common/hooks/useViewRoot';
import { useEntity } from '../_common/hooks/useEntity';
import { useStoredState } from '../_common/hooks/useStoredState';
import MainContent from '../_common/layout/MainContent';
import { EntityForm, EntityViewer } from '../_libs/antd-ext';
import { EditOutlined, ArrowLeftOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { systemInfo } from '../_common/utils/systemInfo';
import ClientSettings from './ClientSettings';
import ClientAgreements from './ClientAgreements';
import metadata from './_meta';
import Actions from '../_common/layout/Actions';

const Client = (props) => {
    const [uploadOpen, setUploadOpen] = useState(false);
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [env, { hasRoles }] = useEnv();
    const history = useHistory();
    const [tableApi, setTableApi] = useState();
    let { mainRoot, regime, id, isCurrent } = useViewRoot();
    if (isCurrent) id = env && env.userClientId;
    const [entityData] = useEntity({ metadata, id, forceUpdateRequired });
    const [tabKey, setTabKey] = useStoredState(undefined, { code: 'tab' });

    let canEdit = Boolean(env && env.userClientId === id);

    if (regime === 'edit' && !entityData) regime = 'create';
    if (!canEdit && regime !== 'list') regime = 'view';

    const toActive = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Client/ToActive`,
            json: {
                ids
            },
            callback: () => {
                refresh();
                message.success("Организация активирована");
            }
        })
    }

    const toNotActive = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Client/ToNotActive`,
            json: {
                ids
            },
            callback: () => {
                refresh();
                message.success("Организация деактивирована");
            }
        })
    }

    const refresh = () => {
        forceUpdate();
        if (tableApi) tableApi.refresh();
    }

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Client/InitFromExcel`,
            formData: {
                File: file,
                Pars: pars ? JSON.stringify(pars.split(/[\s,]+/).filter(n => n)) : undefined
            },
            callback: () => {
                refresh();
                message.success("Файл загружен");
            }
        });
    }

    const commonTab = !metadata
        ? null
        : regime === 'view'
            ? <React.Fragment>
                <Actions items={[
                    env && env.isSuperAdmin && entityData && entityData.state.code == 'NOTACTIVE' && { label: 'Активировать организацию', key: 'toActive', onClick: () => toActive({ ids: [entityData.id] }) },
                    env && env.isSuperAdmin && entityData && entityData.state.code == 'ACTIVE' &&  { label: 'Деактивировать организацию', key: 'toNotActive', onClick: () => toNotActive({ ids: [entityData.id] }) }
                ]} />
                <EntityViewer
                    metadata={metadata}
                    value={entityData}
                />
                {hasRoles('PARTNERSHIP_CURATOR') && <div className='property'>
                    <div className='label'><Link to={`/PartnershipSetting/${entityData && entityData.id}`}>Заключить партнерство</Link></div>
                </div>}
            </React.Fragment>
            : <EntityForm
                card={false}
                regime={regime === 'edit' ? 'updating' : 'creating'}
                metadata={metadata}
                title={'Организация'}
                visible={true}
                value={entityData}
                defaults={metadata.defaults}
                editable={regime !== 'view'}
                onSave={(data) => {
                    if (regime === 'create') {
                        let id = data && data.id;
                        if (id) systemInfo.changeClient({
                            clientId: id,
                            callback: () => {
                                window.location.reload();
                            }
                        });
                    } else if (!isCurrent) {
                        history.push(getViewRootPath({ mainRoot }));
                    } else {
                        forceUpdate();
                        history.push(getViewRootPath({ mainRoot, id: 0 }));
                    }
                }}
            />

    const settingsTab = <ClientSettings client={entityData} regime={regime} />;
    const agreementsTab = <ClientAgreements/>;

    const tabs = canEdit && entityData && entityData.state.code == 'ACTIVE'
        ? [
            { label: 'Описание', key: 'common', children: commonTab },
            { label: 'Настройки', key: 'settings', children: settingsTab },
            { label: 'Соглашения', key: 'agreements', children: agreementsTab },
        ]
        : [
            { label: 'Описание', key: 'common', children: commonTab }
        ]

    return <React.Fragment>
        <MainContent layoutProps={props.layoutProps}
            title={`Организация: ${regime === 'view' ? (entityData && entityData.name) : regime === 'edit' && id ? `${entityData && entityData.name} - Редактирование` : 'Создание'}`}
            headerRightButtons={
                <React.Fragment>
                    <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => history.push(getViewRootPath({ mainRoot: 'Clients' }))} />
                    {canEdit && regime === 'view' && <Button key="edit" shape="circle" type="text" icon={<EditOutlined style={{ fontSize: '20px' }} />} title="Редактировать" onClick={() => history.push(getViewRootPath({ mainRoot, id: isCurrent ? 0 : id, regime: 'edit' }))} />}
                    {canEdit && regime !== 'view' && <Button key="edit" shape="circle" type="text" icon={<CreditCardOutlined style={{ fontSize: '20px' }} />} title="Просмотреть" onClick={() => history.push(getViewRootPath({ mainRoot, id: isCurrent ? 0 : id }))} />}
                </React.Fragment>
            }
            menu={[
                regime === 'view' && isCurrent && { label: 'Инициализировать из Excel', key: 'initFromExcel', onClick: () => setUploadOpen(true) }
            ]}
        >
            <Tabs activeKey={tabKey} items={tabs}
                onChange={setTabKey} />
        </MainContent>

        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel} />
    </React.Fragment>
}

export default withRouter(Client);
