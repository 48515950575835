import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { useList, Table, useMetadata, globalDataProvider } from '../lib';
import { UndoOutlined } from '@ant-design/icons';

const meta = {
    entity: 'PartnershipSetting',
    key: 'id',
    properties: [
        {
            title: 'Организация',
            dataIndex: 'client',
            width: 150,
            editable: false,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            link: true
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            type: 'text',
            width: 250,
            editable: true,
            showInTable: false
        },
        {
            title: 'Шаблоны документов',
            dataIndex: 'documentTemplates',
            type: 'file',
            multiple: true,
            editable: true,
            showInTable: false
        },
        {
            title: 'Email',
            dataIndex: 'email',
            type: 'string',
            editable: true,
            showInTable: false
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            type: 'string',
            editable: true,
            showInTable: false
        }
    ]
};

const PartnershipSetting = () => {
    const [updateRequired, forceUpdate] = useReducer(x => x + 1, 1);
    globalDataProvider.path = 'https://localhost:5000/odata';
    const metadata = useMetadata(meta);
    const list = useList({metadata, refresh: updateRequired});
    return <div style={{padding: 10}}>
        Проекты <UndoOutlined onClick={forceUpdate} /><br/>
        <Table metadata={metadata} list={list} onApply={'remote'} />
    </div>
}

export default PartnershipSetting;

