import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter, Link } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import cloneDeep from 'clone-deep';

export const metadata = {
    entity: 'printDocumentType',
    key: 'id',
    properties: [
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true,
            required: true,
            render: (text, record) => record ? <Link to={`/PrintDocumentType/${record.id}`}>{text}</Link> : text,
        },
        {
            title: 'Тип данных',
            dataIndex: 'dataType',
            width: 150,
            type: 'entity',
            entityName: 'commonDataType',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            required: true,
            editable: true
        },
        {
            title: 'Версия',
            dataIndex: 'currentVersion',
            width: 150,
            type: 'entity',
            entityName: 'printDocumentVersion',
            entityKey: 'id',
            entityLabel: 'name',
            loadAll: true,
            editable: true,
            entityFilter: ({record, sourceRecord}) => `typeId eq ${sourceRecord && sourceRecord.id || 0}`
        }
    ]
};

const PrintDocumentTypes = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env] = useEnv();

    return <MainContent layoutProps={props.layoutProps}
        title="Типы печатных форм"
        onClickRefresh={tableApi && tableApi.refresh}
    >
        {env && <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
            onAdd={() => props.history.push(`/PrintDocumentType/0/create`)}
            onEdit={false}
            onCopy={false}
        />}
    </MainContent>
}

export default withRouter(PrintDocumentTypes);
