import React, { useEffect, useState } from "react";
import { FormItems } from "../../_libs/antd-ext";
import meta from '../_meta';
import { prepareMetadata } from "../../_common/odata/entityodata";
import cloneDeep from "clone-deep";

export default function StepRegister({type}) {
    const [metadata, setMetadata] = useState();
    useEffect(() => {
        let clonedMeta = cloneDeep(meta);
        let removed = ['type', 'state'];
        if (type === 'Univercity') removed = [...removed, 'clientOwnershipForm', 'inn'];
        clonedMeta.properties = clonedMeta.properties.filter(_ => removed.indexOf(_.dataIndex) < 0);
        setMetadata(prepareMetadata({metadata: clonedMeta}));
    }, [type])
    return !metadata
        ? null
        : <FormItems
            metadata={metadata}
            editable={true}
        />
}
