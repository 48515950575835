import React from 'react';
import { withRouter } from "react-router-dom";
import Form from './Form';
import { saveData } from '../../../_common/odata/entityodata';

const EntityForm = ({ onSave, onSaveInternal: onSaveInternalProps, onCancel, value, card, beforeSaveData, ...rest }) => {
    const onSaveInternal = ({metadata, value, creating, editingKey}) => {
        saveData({metadata, value, creating, editingKey, beforeSaveData,
            onSave: (data) => {if (onCancel) onCancel(); if (onSave) onSave(data);}});
    }
    return <Form
        value={value}
        card={card === undefined ? true : card}
        onCancel={onCancel}
        onSave={onSaveInternalProps || onSaveInternal}
        {...rest}
    >
    </Form>
}

export default withRouter(EntityForm);