import React from 'react';
import { Modal, Form } from 'antd';
import FieldEntity from '../../_libs/antd-ext/form/FieldEntity';
const { confirm } = Modal;

const ChangeTypeDialog = ({ open, onCancel, onOk, type, id }) => {
    const [form] = Form.useForm();
    form.setFieldsValue({
        type: type
    })

    const onOkInternal = async () => {
        let row = await form.validateFields();
        confirm({
            title: `Вы действительно хотите изменить тип проекта?`,
            onOk: () => {
                let json = { ...row };
                if (json.type) {
                    json.typeId = json.type.id;
                    json.type = undefined;
                }
                json.id = id
                onOk(json);
            }
        });
    }

    return <Modal open={open} onOk={onOkInternal} onCancel={onCancel}
        title={`Изменение типа проекта`}
        okText={`Изменить тип проекта`}
        width={'50%'}
        heigth={'100px'}>
        <Form
            form={form}
            autoComplete="off"
            layout="horizontal"
        >

            <Form.Item name="type" label="Тип">
                <FieldEntity
                    entityName='projectType'
                    entityKey='id'
                    entityLabel='name'
                    loadAll={true} />
            </Form.Item>
        </Form>
    </Modal>
}

export default ChangeTypeDialog;