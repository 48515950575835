import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';

const DynamicAttributeMeta = (props) => {
    const meta = {
        entity: 'DynamicAttributeMeta',
        key: 'id',
        properties: [
            {
                title: 'Название',
                dataIndex: 'name',
                width: 250,
                editable: true
            },
            {
                title: 'Тип',
                dataIndex: 'type',
                width: 150,
                editable: {
                    updating: false
                },
                type: 'entity',
                entityName: 'dynamicAttributeType',
                entityKey: 'code',
                entityLabel: 'name',
                loadAll: true,
                required: true,
            },
            {
                title: 'Обязательность',
                dataIndex: 'required',
                type: 'boolean',
                width: 100,
                editable: true
            },
            {
                title: 'Множественность',
                dataIndex: 'multiple',
                type: 'boolean',
                width: 100,
                editable: true
            },
            {
                title: 'В таблице',
                dataIndex: 'showInTable',
                type: 'boolean',
                width: 100,
                editable: true
            },
            {
                title: 'Порядок',
                dataIndex: 'ord',
                type: 'number',
                width: 100,
                editable: true
            },
            {
                title: 'Список',
                dataIndex: 'list',
                width: 150,
                type: 'entity',
                entityName: 'dynamicList',
                entityKey: 'id',
                entityLabel: 'name',
                loadAll: true,
                editable: true
            },
            {
                title: 'Описание/Подсказка',
                dataIndex: 'description',
                width: 250,
                editable: true
            },
            {
                title: 'После атрибута',
                dataIndex: 'after',
                width: 150,
                editable: true
            },
            {
                title: 'Код',
                dataIndex: 'code',
                width: 150,
                editable: true
            },
            {
                title: 'Уровень доступа',
                description: '1 - только администратор, 2 - и куратор, 3 - и заместитель куратора, пусто - все',
                dataIndex: 'accessLevel',
                type: 'number',
                width: 100,
                editable: true
            },
        ],
        default: {
            //required: true,
            //editable: true,
            //sortable: true
        }
    };
    const [tableApi, setTableApi] = useState();
    const [env] = useEnv();
    const [metadata, setMetadata] = useState();

    useEffect(() => {
        let _metadata = meta;
        _metadata.properties.filter(_ => _.dataIndex === 'list')[0].entityFilter = `clientId eq ${env.userClientId}`;
        setMetadata(_metadata);
    }, [env])

    return !metadata
        ? null
        : <MainContent layoutProps={props.layoutProps}
            title="Атрибуты проекта"
            onClickRefresh={tableApi && tableApi.refresh}
            menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
                //{ label: 'Редактировать в карточке', key: "editInCard", onClick: () => setFormVisible(true) },
            ]}
        >
            <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={props.history}
                odataFilter={`clientId eq ${env.userClientId}`}
            />
        </MainContent>
}

export default withRouter(DynamicAttributeMeta);
