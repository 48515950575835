const locale = {
    Table: {
        column: 'Column',
        fix: 'Fix',
        onLeft: 'left',
        onRight: 'right',
        undefined: 'undefined',
        visibility: 'Visibility',
        common: 'Common',
        resetToDefault: 'Reset to default',
        empty: '(Empty)',
        exclude: '(Except)',
        filter: 'OK',
        reset: 'Reset',
        orTryToFind: 'or try to find specific',
        from: 'from',
        till: 'till',
        orSetInterval: 'or set interval'
    }
}
export default locale;