import { Button, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Table from '../_common/tables/TableOData';
import { message, Row, Col } from 'antd';
import { post, get } from '../_common/utils/fetch';
import FileDialog from '../_common/FileDialog';
import { red, yellow, green } from './colors';
import UsersSelector from '../users/UsersSelector';
import { Select } from '../_libs/antd-ext';
import TableFilter from '../_libs/antd-ext/table/TableFilter';
import Chart from '../_common/utils/Chart';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { useEnv } from '../_common/hooks/useEnv';
import fileExport from '../_common/utils/fileExport';
import Actions from '../_common/layout/Actions';

const SelectionCompanyMembers = ({ selectionCompany }) => {
    const [tableApi, setTableApi] = useState();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [usersSelectorOpen, setUsersSelectorOpen] = useState(false);
    const [addType, setAddType] = useState('STUDENT');
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [stat, setStat] = useState();
    const [env, { hasRoles }] = useEnv();
    const history = useHistory();

    useEffect(() => {
        get({
            url: `${window.APPCFG.apiPath}/SelectionCompany/GetStat?id=${selectionCompany.id}${tableApi ? '&' + tableApi.lastUrlSearchParams.substring(1) : ''}`,
            callback: setStat
        });
    }, [selectionCompany.id, forceUpdateRequired])
    const metadata = {
        entity: 'selectionCompanyMember',
        key: 'id',
        properties: [
            {
                title: 'Участник',
                dataIndex: 'member',
                width: 150,
                type: 'entity',
                entityName: 'position',
                entityKey: 'id',
                entityLabel: ['user/name'],
                entityLabelFunc: (record) => record['user']['name'],
                required: true,
                editable: true,
                extSelect: ['userId'],
                link: ({ record, text }) => <Link to={`/User/${record['userId']}`}>{record['member']['user']['name']}</Link>
            },
            {
                title: 'Кол-во проектов',
                dataIndex: 'projectCnt',
                width: 150,
                type: 'number'
            },
            {
                title: 'Кол-во ролей',
                dataIndex: 'roleCnt',
                width: 150,
                type: 'number'
            },
            {
                title: 'Ценность',
                dataIndex: 'workload',
                width: 150,
                type: 'number'
            },
            {
                title: 'Выбор',
                width: 150,
                type: 'string',
                key: 'selectionResult',
                //filterable: false,
                sortable: false,
                render: (text, record) => {
                    return !record.workload
                        ? <div style={{ color: red }}>Не сделан</div>
                        : record.workload < selectionCompany.requiredMemberWorkload
                            ? <div style={{ color: yellow }}>Сделан частично</div>
                            : <div style={{ color: green }}>Сделан полностью</div>
                },
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <TableFilter
                    selectedKeys={selectedKeys}
                    setSelectedKeys={setSelectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    componentRender={({ value, onChange, confirm }) => <Select mode='multiple' blur={true} allowClear={true} value={value} onChange={onChange} style={{ width: '100%' }}
                        options={[
                            { value: 'none', label: 'Не сделан' },
                            { value: 'partly', label: 'Сделан частично' },
                            { value: 'full', label: 'Сделан полностью' }
                        ]}
                    />} />,
                odataFilterFunc: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
                    if (filter && filter.value && filter.value.length > 0) {
                        let f = [];
                        if (filter.value.some(_ => _ === 'none')) f.push('workload eq 0');
                        if (filter.value.some(_ => _ === 'partly')) f.push(`(workload gt 0 and workload lt ${selectionCompany.requiredMemberWorkload})`);
                        if (filter.value.some(_ => _ === 'full')) f.push(`workload ge ${selectionCompany.requiredMemberWorkload}`);
                        if (f.length > 0) columnFilterPars.push(f.join(' or '));
                    }
                }
            },
            {
                title: 'Рассматривается',
                dataIndex: 'requestConsideredCnt',
                width: 150,
                type: 'number'
            },
            {
                title: 'Email',
                dataIndex: ['user', 'email'],
                width: 150
            },
            {
                title: 'Тип',
                dataIndex: ['member', 'type'],
                type: 'entity',
                entityName: 'positionType',
                entityKey: 'code',
                entityLabel: 'name',
                editable: false,
                width: 150,
                loadAll: true
            },
            {
                title: 'Группа',
                dataIndex: ['member', 'academicGroup'],
                type: 'entity',
                entityName: 'academicGroup',
                entityKey: 'id',
                entityLabel: 'name',
                width: 150
            },
            {
                title: 'Год начала обучения',
                dataIndex: ['member', 'startYear'],
                width: 150,
                type: 'number'
            },
            {
                title: 'Кафедры',
                dataIndex: ['member', 'departments'],
                type: 'entity',
                multiple: true,
                newMultiple: true,
                entityName: 'department2',
                entityKey: 'id',
                entityLabel: 'name',
                editable: false,
                width: 150
            },
            {
                title: 'Направления подготовки',
                dataIndex: ['member', 'specialties'],
                type: 'entity',
                multiple: true,
                newMultiple: true,
                entityName: 'specialty',
                entityKey: 'id',
                entityLabel: ['code', 'name'],
                editable: false,
                width: 150
            },
            {
                title: 'Образовательные программы',
                dataIndex: ['member', 'educationalPrograms'],
                type: 'entity',
                multiple: true,
                newMultiple: true,
                entityName: 'educationalProgram',
                entityKey: 'id',
                entityLabel: 'name',
                editable: false,
                width: 150
            }
        ]
    };

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/SelectionCompanyMember/UploadExcel`,
            formData: {
                File: file,
                Pars: JSON.stringify({selectionCompanyId: `${selectionCompany.id}`})
            },
            callback: () => {
                if (tableApi) tableApi.refresh();
                message.success("Файл загружен");
            }
        });
    }

    const addSelected = ({ filter }) => {
        setUsersSelectorOpen(false);
        get({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/SelectionCompanyMember/AddSelected${filter}&selectionCompanyId=${selectionCompany.id}`,
            callback: ({ all, added, existing }) => {
                if (tableApi) tableApi.refresh();
                message.success(`Добавлено участников: ${added}${all !== added ? ` (было выбрано: ${all}, но из них ${existing} уже было добавлено ранее)` : ''}`);
            }
        });
    }

    const roundTo = (num) => {
        return Math.round((num + Number.EPSILON) * 10) / 10;
    }
    const toStr = (num) => {
        return num.toLocaleString(undefined, { maximumFractionDigits: 1 });
    }
    const getProcent = (stat) => {
        if (!stat) return null;
        let procent = {
            partially: stat.all > 0 ? roundTo(100 * stat.partially / stat.all) : 0,
            fully: stat.all > 0 ? roundTo(100 * stat.fully / stat.all) : 0
        }
        procent.null = stat.all > 0 ? roundTo(100 - procent.partially - procent.fully) : 0;
        return procent;
    }

    let procent = getProcent(stat);

    const getExcel = () => {
        fileExport(`${window.APPCFG.apiPath}/SelectionCompany/GetExcel${tableApi.lastUrlSearchParams}&id=${selectionCompany.id}`);
    }

    const getParticipationRequestExcel = () => {
        fileExport(`${window.APPCFG.apiPath}/SelectionCompany/GetParticipationRequestExcel${tableApi.lastUrlSearchParams}&id=${selectionCompany.id}`);
    }

    return <React.Fragment>
        <Row style={{ padding: '10px 0px' }} wrap={true}>
            <Col>
                <Actions items={[
                    hasRoles('ADMIN') && {
                        key: 'addStudents',
                        label: 'Добавить студентов',
                        onClick: () => { setAddType('STUDENT'); setUsersSelectorOpen(true) }
                    },
                    hasRoles('ADMIN') && {
                        key: 'addEmployees',
                        label: 'Добавить сотрудников',
                        onClick: () => { setAddType('EMPLOYEE'); setUsersSelectorOpen(true) }
                    },
                    hasRoles('ADMIN') && {
                        key: 'addFromExcel',
                        label: 'Загрузить участников из Excel',
                        onClick: () => setUploadOpen(true)
                    },
                    {
                        key: 'getExcel',
                        label: 'Выгрузить результаты в Excel',
                        onClick: () => getExcel()
                    },
                    {
                        key: 'getParticipationRequestExcel',
                        label: 'Выгрузить заявки на участие в Excel',
                        onClick: () => getParticipationRequestExcel()
                    },
                ]} />
            </Col>
            <Col style={{ textAlign: 'right' }} flex="auto">
                <div style={{ float: 'right', height: '100px' }}>
                    {stat && <Chart
                        type='doughnut'
                        width={460}
                        height={100}
                        data={{
                            labels: [
                                `Не сделали (${stat.null} / ${toStr(procent.null)}%)`,
                                `Частично (${stat.partially} / ${toStr(procent.partially)}%)`,
                                `Полностью (${stat.fully} / ${toStr(procent.fully)}%)`
                            ],
                            datasets: [{
                                data: [stat.null, stat.partially, stat.fully],
                                backgroundColor: [
                                    red,
                                    yellow,
                                    green
                                ],
                                hoverOffset: 4
                            }]
                        }}
                        options={{
                            plugins: {
                                title1: {
                                    display: true,
                                    text: `Статистика выбора (всего: ${stat.all})`
                                },
                                legend: {
                                    position: 'right',
                                    title: {
                                        display: true,
                                        text: `Статистика выбора (всего: ${stat.all})`
                                    }
                                }
                            },
                        }} />}
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <Table
                    columns={metadata.properties}
                    odataEntity={metadata.entity}
                    rowKey={metadata.key}
                    onInit={setTableApi}
                    editable={true}
                    history={history}
                    onAdd={false}
                    onEdit={false}
                    onCopy={false}
                    odataFilter={`selectionCompanyId eq ${selectionCompany.id}`}
                    beforeSaveData={({ record }) => { record.selectionCompanyId = selectionCompany.id; return record; }}
                    onLoad={forceUpdate}
                />
            </Col>
        </Row>
        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel} />
        <UsersSelector open={usersSelectorOpen} onCancel={() => setUsersSelectorOpen(false)} onOk={addSelected} type={addType} />
    </React.Fragment>
}

export default SelectionCompanyMembers;
