import defaultTypes from '../defaults/types';
import useDataProvider from '../dataProviders/useDataProvider';
import { useState } from 'react';

export class metadata {
    entity;
    key;
    properties;
    dataProperties;
    map;
    constructor(metadata, props) {
        const {userTypes, dataProvider} = props || {};
        this.entity = metadata.entity;
        this.key = metadata.key;
        this.properties = metadata.properties;
        this.dataProperties = [];
        this.map = [];
        this.maxLevel = 0;
        this.applyTypesToProperties(this.properties, userTypes, dataProvider && dataProvider.types);
    }
    getPropery = (key) => {
        return this.map[key];
    }
    getPropertyKey = (property) => {
        if (property.key) return property.key;
        if (property.dataIndex) return Array.isArray(property.dataIndex) ? property.dataIndex.join('.') : property.dataIndex;
        return property.title;
        //return `${parent ? parent.key : ''}_${index || 0}`;
    }
    applyTypesToProperties = (properties, userTypes, dataProviderTypes, level = 0) => {
        if (!properties) return properties;
        const types = userTypes || defaultTypes;

        if (level > this.maxLevel) this.maxLevel = level;
    
        for (let property of properties) {
            property.key = this.getPropertyKey(property);
            this.map[property.key] = property;
            if (property.children) {
                this.applyTypesToProperties(property.children, userTypes, dataProviderTypes, level + 1)
            } else {
                this.dataProperties.push(property);
                var type = property.type || types.defaultType;
                if (type) {
                    let typeDef = types && types[type];
                    if (typeDef) {
                        Object.assign(property, { ...typeDef, ...property })
                    }
                    // odata
                    let dataProviderTypeDef = dataProviderTypes && dataProviderTypes[type];
                    if (dataProviderTypeDef) {
                        Object.assign(property, { ...dataProviderTypeDef, ...property })
                    }
                }
                if (!property.filterDropdown && property.filterable !== false && property.getFilterDropdown) {
                    property.filterDropdown = (props) => property.getFilterDropdown({property})(props);
                }
                if (!property.render && property.getRender) {
                    property.render = (text, record, index) => property.getRender({property})(text, record, index);
                }
                if (property.sortable === undefined) property.sortable = true;
            }
        }
    }

    getPropertyDisabled = ({record, property, creating}) => {
        return property.editable === false
            || (creating && property.editable && (property.editable.creating === false || (property.editable.creating instanceof Function && property.editable.creating({ record }) === false)))
            || (!creating && property.editable && (property.editable.updating === false || (property.editable.updating instanceof Function && property.editable.updating({ record }) === false)));
    }
}

export function useMetadata(metadataInitial, props) {
    const dataProvider = useDataProvider(props);
    const [metadataState, setMetadataState] = useState(() => new metadata(metadataInitial, {dataProvider, ...props}));
    return metadataState;
}