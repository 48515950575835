import React, {  } from 'react';
import { Form as AntdForm, Modal, Button } from 'antd';
import Fields from '../fields/Fields';
import useDataProvider from '../dataProviders/useDataProvider';
const cloneDeep = require('clone-deep');

const useForm = AntdForm.useForm;

const Editor = ({ card, open, ...rest }) => {
    if (card && !open) return null;
    return <EditorInner card={card} open={open} {...rest} />
}

const EditorInner = ({ value, metadata, card, title, open, onCancel, regime, onFinish, onSave, onAfterSave, showDisabledNull, disabled, staticContext, blockLayout, ...rest }) => {
    const [form] = useForm();
    const dataProvider = useDataProvider({});

    const onFinishInternal = async () => {
        let row = null;
        try {
            row = await form.validateFields();
        } catch (e) {
            return;
        }
        let newRecord = cloneDeep(row);

        let editingKey = form.getFieldValue(metadata.key);
        let creating = editingKey <= 0;
        if (creating) editingKey = undefined;
        dataProvider.changeData({
            metadata: metadata,
            value: newRecord,
            creating: creating,
            editingKey: editingKey,
            onSave: (data) => {
                if (onAfterSave) onAfterSave(data);
            }
        })
    }

    const formComponent = (
        <AntdForm
            form={form}
            size={card ? 'small' : undefined}
            labelCol={card ? undefined : { span: 6 }}
            wrapperCol={card ? undefined : { span: 16 }}
            onFinish={onFinish || onFinishInternal}
            initialValues={value}
            {...rest}
        >
            <Fields blockLayout={blockLayout} metadata={metadata} regime={regime} disabled={disabled} record={value} showDisabledNull={showDisabledNull} />
            {!card && !disabled && <center><Button onClick={form.submit}>Сохранить</Button></center>}
        </AntdForm>
    );
    return card
        ? <Modal title={title}
            open={open}
            onCancel={onCancel}
            onOk={form.submit}
            footer={disabled ? [] : undefined}>
            {formComponent}
        </Modal>
        : formComponent;
}

export default Editor;

export { useForm }