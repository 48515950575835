import React from 'react';
import MainContent from './MainContent';
import { withRouter } from "react-router-dom";
import { getItems } from './InfoButton';
import { useEnv } from '../hooks/useEnv';

const Instructions = (props) => {
    const [env] = useEnv();
    const items = getItems(env);

    return <MainContent layoutProps={props.layoutProps}
        title="Инструкции"
    >
        {items.map((item, idx) => <div key={idx}>{item.label}</div>)}
    </MainContent>;
}

export default withRouter(Instructions);
