import React, { useState, useEffect } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from './layout/MainContent';
import { withRouter, useHistory } from "react-router-dom";
import { EntityForm, EntityViewer } from '../_libs/antd-ext';
import { EditOutlined, ArrowLeftOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getEntity } from '../_common/odata/entityodata';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { useViewRoot, getViewRootPath } from './hooks/useViewRoot';
import { useEnv } from './hooks/useEnv';

const TableWithCard = ({ editableFunc, getEntityFunc, metadata, title, titles, entityTitleFunc = (entityData) => entityData && entityData['name'], childrenFunc, layoutProps,
    menuFunc }) => {
    const history = useHistory();
    const [tableApi, setTableApi] = useState();
    const [entityData, setEntityData] = useState();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    let { mainRoot, regime, id, isCurrent } = useViewRoot();
    const [env] = useEnv();

    if (isCurrent) id = env && env.userClientId;

    let canEdit = Boolean((env && env.isSuperAdmin) || (editableFunc && editableFunc({entityData})));

    useEffect(() => {
        if (id) {
            if (getEntityFunc) getEntityFunc({ metadata, id, isCurrent, callback: (entity) => setEntityData(entity) })
            else getEntity({ metadata, id, callback: (entity) => setEntityData(entity) });
        }
        else setEntityData();
    // eslint-disable-next-line
    }, [id, forceUpdateRequired])

    if (regime === 'edit' && !entityData) regime = 'create';
    if (!canEdit && regime !== 'list') regime = 'view';

    const menu = menuFunc ? menuFunc({regime}) : [];

    return regime !== 'list'
        ? <MainContent layoutProps={layoutProps}
            title={`${title}: ${regime === 'view' ? entityTitleFunc(entityData) : regime === 'edit' && id ? `${entityData && entityTitleFunc(entityData)} - Редактирование` : 'Создание'}`}
            headerRightButtons={
                <React.Fragment>
                    <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => history.push(getViewRootPath({ mainRoot }))} />
                    {canEdit && regime === 'view' && <Button key="edit" shape="circle" type="text" icon={<EditOutlined style={{ fontSize: '20px' }} />} title="Редактировать" onClick={() => history.push(getViewRootPath({ mainRoot, id: isCurrent ? 0 : id, regime: 'edit' }))} />}
                    {canEdit && regime !== 'view' && <Button key="edit" shape="circle" type="text" icon={<CreditCardOutlined style={{ fontSize: '20px' }} />} title="Просмотреть" onClick={() => history.push(getViewRootPath({ mainRoot, id: isCurrent ? 0 : id }))} />}
                </React.Fragment>
            }
            menu={menu}
        >
            {regime === 'view'
                ? <EntityViewer
                    metadata={metadata}
                    value={entityData}
                />
                : <EntityForm
                    card={false}
                    regime={regime === 'edit' ? 'updating' : 'creating'}
                    metadata={metadata}
                    title={title}
                    visible={true}
                    value={entityData}
                    defaults={metadata.defaults}
                    editable={regime !== 'view'}
                    onSave={() => {
                        if (!isCurrent) {
                            history.push(getViewRootPath({ mainRoot }));
                        } else {
                            forceUpdate();
                            history.push(getViewRootPath({ mainRoot, id: 0 }));
                        }
                    }}
                />}
            {childrenFunc && childrenFunc({ regime, entityData })}
        </MainContent>
        : <MainContent layoutProps={layoutProps}
            title={titles || title}
            onClickRefresh={tableApi && tableApi.refresh}
            menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
            ...menu]}
        >
            <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={history}
                editable={canEdit}
                onAdd={() => history.push(getViewRootPath({ mainRoot, regime: 'create' })) }
                onEdit={(record) => history.push(getViewRootPath({ mainRoot, id: record[metadata.key], regime: 'edit' })) }
                onCopy={(record) => history.push(getViewRootPath({ mainRoot, id: record[metadata.key], regime: 'copy' })) }
                actions={[
                    { key: 'view', icon: <CreditCardOutlined />, title: 'Просмотреть', onClick: (record) => history.push(getViewRootPath({ mainRoot, id: record[metadata.key] })) }
                ]}
            />
            {childrenFunc && childrenFunc({ regime, entityData })}
        </MainContent>
}

export default withRouter(TableWithCard);
