let consoleLog = console.log;
let consoleWarn = console.warn;
let consoleError = console.error;
let consoleInfo = console.info;
export function consoleLogOnOff(on) {
  if (on) {
    console.log = consoleLog;
    console.warn = consoleWarn;
    console.error = consoleError;
    console.info = consoleInfo;
  } else {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
  }
}