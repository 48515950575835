import { Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

const Actions = ({ items: propItems, direction = 'left', title = 'Действия', style }) => {
    if (!propItems || propItems.length === 0) return null;
    const items = propItems.filter(_ => _);
    if (!items || items.length === 0) return null;
    let component = <Dropdown.Button
        style={{ marginBottom: 10, ...style }}
        icon={<DownOutlined />}
        menu={{items}}
    >
        {title}
    </Dropdown.Button>;
    return direction === 'right'
            ? <div style={{textAlign: 'right'}}>{component}</div>
            : component;
}

export default Actions;