import { Form } from "antd";
import React, {  } from "react";
import { Modal, message } from "antd";
import { Table as AntdExtTable } from 'antd-table-ext';
import EditableCell from "./EditableCell";
import cloneDeep from "clone-deep";
import { getRecordValue } from "../utils/array";
import { getActionsColumn } from "./actionsColumn";
const { confirm } = Modal;

const Table = ({ metadata, list, pagination = {}, editable, onChange, onApply, onDelete, onEdit, onCreate, onCopy, extActions, ...rest }) => {
    const state = list.state;
    const setState = list.setState;
    const [form] = Form.useForm();
    
    if (!metadata || !list) return null;

    const prepareFilters = (filters) => {
        if (!filters) return filters;
        const keys = Object.keys(filters);
        let fs = {}
        for (let k of keys) {
            if (filters[k] !== null && filters[k] !== undefined) {
                fs[k] = filters[k][0];
            }
        }
        return fs;
    }

    const change = (newPagination, filters, sorter, props) => {
        if (props.action === 'filter') {
            setState(state => { state.filters = prepareFilters(filters); state.total = null; state.current = 1; return { ...state } });
        }
        if (props.action === 'sort') {
            setState(state => { state.sorters = !sorter ? sorter : (Array.isArray(sorter) ? sorter : [sorter]).filter(_ => _.order).map(_ => ({ key: _.columnKey, order: _.order })); return { ...state } });
        }
        if (props.action === 'paginate') {
            setState(state => { state.current = newPagination.current; if (state.pageSize !== newPagination.pageSize) { state.total = null; state.current = 1 }; state.pageSize = newPagination.pageSize; return { ...state } });
        }
        if (onChange) onChange(newPagination, filters, sorter, props);
    }

    const edit = ({ record }) => {
        form.resetFields()
        form.setFieldsValue(cloneDeep(record));
    }
    const onApplyInt = ({ record, newRecord }) => {
        newRecord[metadata.key] = state.editingKey;
        let creating = newRecord[metadata.key] <= 0;
        const callback = () => {
            message.success(creating ? "Запись создана" : "Запись изменена");
        }
        if (onApply instanceof Function) {
            onApply({ record, newRecord, callback });
        }
        else if (onApply === 'remote') {
            list.applyRemote({ record, newRecord, callback });
        }
        else {
            list.applyLocal({ record, newRecord, callback });
        }
    };
    const onCancel = ({ record }) => {
        setState(state => {
            if (state.editingKey === 0) {
                state.dataSource = state.dataSource.slice(1);
            }
            state.editingKey = null;
            return { ...state }
        })
    };
    const onEditInt = ({ record }) => {
        edit({ record });
        setState(state => { state.editingKey = record[metadata.key]; return { ...state } })
    };
    const onCreateOrCopy = ({ record }) => {
        let newRecord = record ? cloneDeep(record) : {};
        newRecord[metadata.key] = 0;
        edit({ record: newRecord });
        setState(state => { state.editingKey = 0; state.dataSource = [newRecord, ...state.dataSource]; return { ...state } })
    };
    const onDeleteInt = ({ record }) => {
        let editingKey = record[metadata.key];
        let keys = [editingKey];
        let isSingle = keys.length === 1;
        const callback = () => message.success(isSingle ? "Запись удалена" : "Записи удалены");
        confirm({
            title: `Вы действительно хотите удалить ${isSingle ? "запись" : `${keys.length} записей`}?`,
            onOk: () => {
                if (onDelete instanceof Function) {
                    onDelete({ keys: [editingKey], callback });
                }
                else if (onDelete === 'remote') {
                    list.deleteRemote({ keys: [editingKey], callback });
                }
                else {
                    list.deleteLocal({ keys: [editingKey], callback });
                }
            }
        });
    };
    const onCellClick = ({ record, rowIndex, column, event }) => {
        if (!event.altKey) return;
        if (!column.convertToFilter) return;
        let value = getRecordValue(record, column.dataIndex);
        if (!value) return;
        let columnKey = column.key;
        let filterValue = column.convertToFilter({ property: column, value })
        setState(state => { state.filters = { ...state.filters, [columnKey]: filterValue }; state.total = null; state.current = 1; return { ...state }; })
    }
    const getColumns = () => {
        let columns = metadata.properties;
        for (let column of metadata.dataProperties) {
            column.onCell = (record, rowIndex) => {
                return {
                    onClick: event => onCellClick({ record, rowIndex, column, event }),
                    record,
                    //creating: record[this.rowKey] === this.newKeyValue,
                    column: column,
                    editing: record[metadata.key] === state.editingKey,
                    metadata: metadata
                };
            }

            // обнуляем сортировку
            column.sortOrder = null;

            // фильтры
            column.filteredValue = null;
            if (state.filters && state.filters[column.key]) {
                column.filteredValue = [state.filters[column.key]];
            }
        }
        // сортировка
        if (state.sorters) {
            for (let i in state.sorters) {
                let sorter = state.sorters[i];
                let column = metadata.map[sorter.key];
                if (column) {
                    column.sortOrder = sorter.order;
                    column.currentSortIndex = Number(i) + 1;
                }
            }
        }
        return [getActionsColumn({
            editingKey: state.editingKey,
            form, 
            editable: editable !== false, 
            metadata, 
            onEditExt: onEdit, 
            onEditInt, 
            onCopyExt: onCopy, 
            onCopyInt: onCreateOrCopy,
            onDeleteExt: onDelete, 
            onDeleteInt, 
            onCreateExt: onCreate, 
            onCreateInt: onCreateOrCopy,
            onApplyExt: onApply,
            onApplyInt,
            onCancelInt: onCancel,
            extActions: extActions
        }), ...columns].filter(_ => _);
    }
    return <Form form={form}>
        <AntdExtTable
            rowKey={metadata.key}
            columns={getColumns()}
            loading={state.loading}
            dataSource={state.dataSource}
            pagination={pagination === false ? false : {
                position: 'bottomRight',
                current: state.current,
                pageSize: state.pageSize,
                total: state.total,
                showTotal: pagination.showTotal === undefined ? ((total) => `Всего: ${total}`) : pagination.showTotal,
                showSizeChanger: pagination.showSizeChanger === undefined ? true : pagination.showSizeChanger,
                pageSizeOptions: pagination.pageSizeOptions === undefined ? [10, 20, 50, 100, 1000] : pagination.pageSizeOptions
            }}
            onChange={change}
            components={{
                body: {
                    cell: EditableCell
                }
            }}
            showSorterTooltip={false}
            bordered={true}
            size='small'
            stateStorable={{ location: false }}
            onResetColumnSettings={() => setState(state => { state.sorters = null; state.filters = null; state.total = null; state.current = 1; return { ...state }; })}
            {...rest}
        />
    </Form>
}

export default Table;