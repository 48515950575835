import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import TableFilter from '../table/TableFilter';
import FilterString from '../filters/FilterString';
import FilterNumber from '../filters/FilterNumber';
import FilterBoolean from '../fields/FieldBoolean';
import FilterEntity from '../filters/FilterEntity';
import FilterDate from '../fields/FieldDatePeriod';
import FieldString from '../fields/FieldString';
import FieldNumber from '../fields/FieldNumber';
import FieldBoolean from '../fields/FieldBoolean';
import FieldEntity from '../fields/FieldEntity';
import FieldDate from '../fields/FieldDate';
import FieldFile from '../fields/FieldFile';
import { Link } from 'react-router-dom';
import { getRecordValue } from '../utils/array';
import { toDefaultString } from '../utils/date';

const getValueFromEntity = ({ entity, dataIndex }) => {
    let dataIndexArray = dataIndex.split('/');
    return getRecordValue(entity, dataIndexArray);
}

const renderSingleEntity = ({ entity: propsEntity, property, record, index }) => {
    if (!propsEntity) return null;
    const entity = propsEntity;

    const entityLabels = Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel];
    let renderedText = property.entityLabelFunc
        ? property.entityLabelFunc(entity)
        : entityLabels.map(_ => getValueFromEntity({ entity, property, dataIndex: _ })).join(' ');
    return property.link
        ? renderLink({ property, record, index, text: renderedText })
        : renderedText;
}

const renderLink = ({ property, record, index, text }) => {
    return property.link instanceof Function
        ? property.link({ text, record, index })
        : <Link to={`/${property.entityName}/${record[property.dataIndex][property.entityKey]}`}>{text}</Link>
}

let types = {
    defaultType: 'string',
    string: {
        //filterRenderWrapper: (props) => ...
        filterRender: (props) => <FilterString {...props} />,
        convertToFilter: ({ property, value }) => ({ value: [value] }),
        fieldRender: (props) => <FieldString {...props} />,
        getRender: ({ property }) => (text, record, index, regime) => {
            return property.link
                ? (property.link instanceof Function
                    ? property.link({ text, record, index })
                    : !text
                        ? text
                        : text.startsWith('http') ? <a href={text} target="_blank" rel="noopener noreferrer">{text}</a> : <Link to={text}>{text}</Link>
                )
                : text;
        }
    },
    text: {
        filterRender: (props) => <FilterString {...props} />,
        convertToFilter: ({ property, value }) => ({ value: [value] }),
        fieldRender: (props) => <FieldString {...props} />,
    },
    boolean: {
        getRender: ({ property }) => property.required
            ? (text, record, index) => (text === true ? <CheckOutlined /> : null)
            : (text, record, index) => (text === true ? <CheckOutlined /> : text === false ? <CloseOutlined /> : null),
        filterRender: (props) => <FilterBoolean {...props} />,
        convertToFilter: ({ property, value }) => ({ value: [value] }),
        fieldRender: (props) => <FieldBoolean {...props} />,
    },
    number: {
        filterRender: (props) => <FilterNumber {...props} />,
        convertToFilter: ({ property, value }) => ({ value: { values: [value.toString()] } }),
        fieldRender: (props) => <FieldNumber {...props} />,
        align: 'right'
    },
    entity: {
        filterRender: (props) => <FilterEntity {...props} />,
        convertToFilter: ({ property, value }) => {
            return (property.multiple === true
                ? { value: { ids: value } }
                : { value: { ids: [value] } });
        },
        fieldRender: (props) => <FieldEntity {...props} mode={props.multiple ? 'multiple' : undefined} />,
        getRender: ({ property, regime }) => (text, record, index) => {
            if (!text) return text;
            const separator = regime === 'card' ? <br /> : ', ';
            const values = property.multiple ? text : [text];
            let renderedValues = values.map(_ => renderSingleEntity({ entity: _, property: property, record, index }));
            let title = renderedValues.every(v => typeof v === 'string') ? renderedValues.join(separator) : undefined;
            let result = renderedValues.map((_, idx) => <React.Fragment key={idx}>{idx > 0 ? separator : ""}{_}</React.Fragment>)
            result = <span title={title}>{result}</span>;
            return result;
        },
    },
    date: {
        filterRender: (props) => <FilterDate {...props} />,
        convertToFilter: ({ property, value }) => ({ value: { from: value, till: value } }),
        fieldRender: (props) => <FieldDate {...props} />,
        getRender: ({ property }) => (text, record, index) => (text ? toDefaultString(text, property.format) : text),
    },
    datetime: {
        filterRender: (props) => <FilterDate showTime={{ format: 'HH:mm' }} {...props} />,
        convertToFilter: ({ property, value }) => ({ value: { from: value, till: value } }),
        fieldRender: (props) => <FieldDate showTime={{ format: 'HH:mm' }} {...props} />,
        getRender: ({ property }) => (text, record, index) => (text ? toDefaultString(text, property.format) : text),
    },
    file: {
        filterable: false,
        sortable: false,
        convertToFilter: undefined,
        fieldRender: (props) => <FieldFile {...props} />,
        getRender: ({ property, regime }) => (text, record, index) => 
            (text ? text.map((file, idx) => <>{idx > 0 ? ', ' : ''}<a key={idx} href={file.url}>{file.name}</a></>) : text)
    }
}

// init
for (let kv of Object.entries(types)) {
    const value = kv[1];
    if (!value.filterDropdown && (value.filterRender)) {
        value.getFilterDropdown = ({ property }) => ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <TableFilter
            {...{ setSelectedKeys, selectedKeys, confirm, clearFilters }}
            {...property}
        />
    }
}

export default types; 