import React from 'react';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useMetadata } from '../_common/hooks/useMetadata';
import meta from './meta';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { useEntity } from '../_common/hooks/useEntity';
import { EntityViewer } from '../_libs/antd-ext';
import { Tabs } from 'antd';
import SelectionCompanyMembers from './SelectionCompanyMembers';
import { useStoredState } from '../_common/hooks/useStoredState';

const SelectionCompany = (props) => {
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [metadata] = useMetadata(meta);
    const [entityData] = useEntity({ metadata, id: props.id, forceUpdateRequired });
    const [tabKey, setTabKey] = useStoredState(undefined, {code: 'tab'});

    const commonTab = <EntityViewer
        metadata={metadata}
        visible={true}
        value={entityData}
    />

    const membersTab = entityData && <SelectionCompanyMembers
        selectionCompany={entityData}
        forceUpdate={forceUpdate}
        forceUpdateRequired={forceUpdateRequired}
    />

    const tabs = [
        { label: 'Описание', key: 'common', children: commonTab },
        { label: 'Участники', key: 'members', children: membersTab },
    ]

    return <MainContent layoutProps={props.layoutProps}
        title={`Выборная кампания: ${(entityData && entityData.name)}`}
    >
        <Tabs items={tabs} activeKey={tabKey} onChange={setTabKey} />
    </MainContent>
}

export default withRouter(SelectionCompany);