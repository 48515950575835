import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { UnorderedListOutlined } from '@ant-design/icons';
import { useEnv } from '../_common/hooks/useEnv';

const metadata = {
    entity: 'dynamicList',
    key: 'id',
    properties: [
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true
        }
    ],
    default: {
        //required: true,
        //editable: true,
        //sortable: true
    }
};

const DynamicList = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env] = useEnv();

    return <MainContent layoutProps={props.layoutProps}
        title="Списки"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || [])]}
    >
        {env && <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
            actions={[
                { icon: <UnorderedListOutlined />, title: 'Редактировать значения', onClick: (record) => props.history.push(`/DynamicListItem/${record.id}`) },
            ]}
            odataFilter={`clientId eq ${env.userClientId}`}
        />}
    </MainContent>
}

export default withRouter(DynamicList);
