import { Form } from "../../_libs/antd-ext";
const ChangeSelectionDialog = ({ selectedRows, changeSelectionSettingDialogOpen, setChangeSelectionSettingDialogOpen, changeSelectionSetting }) => {
    let metadata = {
        properties: [
            {
                title: 'Выборная кампания',
                dataIndex: 'selectionCompany',
                type: 'entity',
                entityName: 'selectionCompany',
                entityKey: 'id',
                entityLabel: 'name',
                editable: true
            },
            {
                title: 'Начало записи на проект',
                dataIndex: 'selectionStartDate',
                type: 'date',
                editable: true
            },
            {
                title: 'Окончание записи на проект',
                dataIndex: 'selectionFinishDate',
                type: 'date',
                editable: true
            },
        ]
    };
    return <Form
        metadata={metadata}
        card={true}
        title={`Изменение настроек выбора для ${selectedRows && selectedRows.length} проект(а,ов)`}
        visible={changeSelectionSettingDialogOpen}
        onSave={({value}) => {
            let json = {...value}
            json.selectionCompanyId = json.selectionCompany.id;
            json.selectionCompany = null;
            changeSelectionSetting(json);
        }}
        onCancel={() => setChangeSelectionSettingDialogOpen(false)}
    />
}

export default ChangeSelectionDialog;