import React, { useState } from 'react';
import { Modal } from 'antd';
import FieldEntity from '../_libs/antd-ext/form/FieldEntity';

const ConsiderationAddDialog = ({ visible, onCancel, onOk }) => {
    const [clients, setClients] = useState();
    return <Modal title={'Выберите организации'}
        open={visible}
        onCancel={onCancel}
        onOk={() => onOk(clients)}>
        <FieldEntity value={clients} mode='multiple'
            entityName='client'
            entityKey='id'
            entityLabel='shortName'
            onChange={setClients} />
    </Modal>
}

export default ConsiderationAddDialog;