import React from 'react';
import { Input, Select } from 'antd';

const FieldString = ({ value, onChange, style = {}, disabled, multiple, maxCount, separator="; ", placeholder }) => {
    if (multiple) {
        const valueInternal = !value ? undefined : value.split(separator);
        const paste = (e) => {
            e.stopPropagation();
            e.preventDefault();
            let clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('Text');
            let values = pastedData.split("\n").map(_ => _.trim()).filter(_ => _);
            onChange([...(valueInternal || []), ...values].join(separator));
        }
        const onChangeInternal = (value) => {
            if (onChange) onChange(!value || !Array.isArray(value) ? undefined : value.join(separator))
        }
        return <Select
        mode="tags"
        style={{ ...style, width: '100%' }}
        value={valueInternal}
        onChange={onChangeInternal}
        onPaste={paste}
        allowClear={true}
        placeholder={placeholder}
        disabled={disabled}
    />
    } else {
        return <Input placeholder={placeholder} style={{ ...style, width: '100%' }} value={value} onChange={e => onChange(e.target.value === "" ? null : e.target.value)} disabled={disabled} allowClear={true} />
    }
}

export default FieldString;