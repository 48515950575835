import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons'

export default function FileSelectionPanel(props) {
    return (
        <Upload multiple={false} fileList={props.file ? [props.file] : null}
            onChange={info => props.onChange(!info.fileList || info.fileList.length === 0 ? null : info.fileList[info.fileList.length - 1].originFileObj)}
            beforeUpload={() => { return false }}>
            <Button icon={<UploadOutlined />}>Выберите файл ...</Button>
        </Upload>
    )
}