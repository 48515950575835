import React from 'react';
import { Select } from 'antd';

const FieldBoolean = ({ value, onChange, style = {}, allowClear, disabled, placeholder }) => {
    return <Select
        value={value}
        onChange={onChange}
        style={{ ...style, width: '100%' }}
        allowClear={allowClear}
        disabled={disabled}
        placeholder={placeholder}
    >
        <Select.Option value={true}>Да</Select.Option>
        <Select.Option value={false}>Нет</Select.Option>
    </Select>
}

export default FieldBoolean;