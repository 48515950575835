import React, { useEffect } from 'react';
import { Form as AntdForm, Modal, Button } from 'antd';
import FormItem from './FormItem';
import { prepareMetadata } from '../../../_common/odata/entityodata';
import FormItems from './FormItems';
import { useEnv } from '../../../_common/hooks/useEnv';
import { useForceUpdate } from '../../../_common/hooks/useForceUpdate';
const cloneDeep = require('clone-deep');

const useForm = AntdForm.useForm;

const Form = ({ value, metadata, card, title, visible, onCancel, regime, onFinish, onSave, showDisabledNull, editable = true, staticContext, ...rest }) => {
    const [form] = useForm();
    const [env] = useEnv();
    const [, forceUpdate] = useForceUpdate();
    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(value);
        forceUpdate();
    }, [form, value])
    metadata = prepareMetadata({metadata});
    
    if (!visible) return <AntdForm form={form} />;
    const creating = regime === 'creating';
    const record = form.getFieldsValue(true)

    const onFinishInternal = async () => {
        let row = null;
        try {
            row = await form.validateFields();
        } catch (e) {
            return;
        }
        const editingKey = form.getFieldValue(metadata.key);
        //this.cancelEdit();
        let json = cloneDeep(row);

        onSave({metadata, value: json, creating: creating || !editingKey, editingKey});
    }

    const formComponent = (
        <AntdForm
            form={form}
            size={card ? 'small' : undefined}
            labelCol={card ? undefined : { span: 6 }}
            wrapperCol={card ? undefined : { span: 16 }}
            onFinish={onFinish || onFinishInternal}
            {...rest}
        >
            <FormItems metadata={metadata} regime={regime} editable={editable} record={record} sourceRecord={value} context={{form: form, env: env}} showDisabledNull={showDisabledNull} /> 
            {!card && editable && <center><Button onClick={form.submit}>Сохранить</Button></center>}
        </AntdForm>
    );
    return card
        ? <Modal title={title}
            open={visible}
            onCancel={onCancel}
            onOk={form.submit}
            closable={false}>
            {formComponent}
        </Modal>
        : formComponent;
}

export default Form;

export { FormItem };
export { useForm }