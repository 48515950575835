import React, { useEffect, useState } from 'react';
import { Layout, Menu, Spin, Drawer, Row } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { StarOutlined, CalendarOutlined, CarryOutOutlined, TeamOutlined, ProjectOutlined, SettingOutlined, ExperimentOutlined, FormOutlined, ToolOutlined, MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import InfoButton from './InfoButton';
import SystemInfoButton from './SystemInfoButton';
import NotificationButton from './NotificationButton';
import { systemInfo } from '../utils/systemInfo'

import './style.css'
import { useEnv } from '../hooks/useEnv';

const { Content, Sider } = Layout;

export let changeIsContextProcessing;

const MainLayout = (props) => {
    const [isContextProcessing, setIsContextProcessing] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [drawerCollapsed, setDrawerCollapsed] = useState(true);
    const [env, { hasRoles }] = useEnv();

    const logout = () => {
        window.location.href = `${window.APPCFG.apiPath}/System/Logout`;
    }

    useEffect(() => {
        changeIsContextProcessing = setIsContextProcessing;
        systemInfo.updateSystemInfo();
    }, [])

    const getSelectedKeys = () => {
        var pathname = props.location.pathname;
        return pathname;
    }

    if (!env) {
        return null;
    }

    const systemButtons = [
        <InfoButton key="InfoButton" />,
        <NotificationButton key="NotificationButton" />,
        <SystemInfoButton key="SystemInfoButton" systemInfo={env} onLogout={logout} />
    ];

    const menu = [
        {
            label: <Link to="/Requests">Заявки на проект</Link>,
            key: '/Requests',
            icon: <FormOutlined />
        },
        {
            label: <Link to="/Projects">Проекты</Link>,
            key: '/Projects',
            icon: <ProjectOutlined />
        },
        env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env && (hasRoles('ADMIN') || env.isEducationalProgramHead) && {
            label: <Link to="/SelectionCompany">Выборные кампании</Link>,
            key: '/SelectionCompany',
            icon: <CarryOutOutlined />
        },
        env && hasRoles('ADMIN') && env.userClient.typeId === "UNIVERCITY" && {
            label: 'Оценки',
            icon: <StarOutlined />,
            children: [
                {
                    label: <Link to="/GlobalGradeCampaigns">Глобальные оценочные кампании</Link>,
                    key: 'GlobalGradeCampaigns'
                },
                {
                    label: <Link to="/ProjectGradeCampaigns">Проектные оценочные кампании</Link>,
                    key: '/ProjectGradeCampaigns'
                }
            ]
        },
        env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && {
            label: <Link to="/Events">Мероприятия</Link>,
            key: '/Events',
            icon: <CalendarOutlined />
        },
        env && hasRoles('ADMIN', 'PROJECT_CREATOR', 'PROJECT_MANAGER', 'PARTNERSHIP_CURATOR') && {
            label: <Link to="/Clients">Организации</Link>,
            key: '/Clients',
            icon: <TeamOutlined />
        },
        {
            label: 'Настройки',
            icon: <SettingOutlined />,
            children: [
                {
                    label: <Link to="/User/0/edit">Профиль пользователя</Link>,
                    key: '/User/0/edit'
                },
                {
                    label: <Link to="/Notifications">Уведомления</Link>,
                    key: '/Notifications'
                },
                (!env.userActiveClientId || (env.userRoles && env.userRoles.indexOf('ADMIN') >= 0)) && {
                    label: <Link to="/Client/0/edit">Профиль организации</Link>,
                    key: '/Client/0'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && hasRoles('ADMIN') && {
                    label: <Link to="/Student">Студенты</Link>,
                    key: '/Student'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && hasRoles('ADMIN') && {
                    label: <Link to="/Employee">Сотрудники</Link>,
                    key: '/Employee'
                },
                (env.isSuperAdmin || (env.userActiveClientId && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0)) && {
                    label: <Link to="/UserRole">Роли и права</Link>,
                    key: '/UserRole'
                },
                (env.userActiveClientId && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0) && {
                    label: <Link to="/Department">Подразделения</Link>,
                    key: '/Department'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('PROJECT_CREATOR') >= 0 && {
                    label: <Link to="/ProjectApprovalStepSetting">Настройки согласования проекта</Link>,
                    key: '/ProjectApprovalStepSetting'
                },
                env.userActiveClientId && hasRoles('ADMIN', 'PARTNERSHIP_CURATOR') && {
                    label: <Link to="/Partnership">Партнеры</Link>,
                    key: '/Partnership'
                },
                env.userActiveClientId && env.userRoles && env.userRoles.indexOf('PARTNERSHIP_CURATOR') >= 0 && {
                    label: <Link to="/PartnershipRequest">Заявки на партнерство</Link>,
                    key: '/PartnershipRequest'
                },
                env.userActiveClientId && env.userRoles && env.userRoles.indexOf('PARTNERSHIP_CURATOR') >= 0 && {
                    label: <Link to="/PartnershipSetting/0">Настройки партнерства</Link>,
                    key: '/PartnershipSetting/0'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/ProjectType">Типы проекта</Link>,
                    key: '/ProjectType'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/EventTypes">Типы мероприятий</Link>,
                    key: '/EventTypes'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/EducationalProgram">Образовательные программы</Link>,
                    key: '/EducationalProgram'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/Institute">Институты</Link>,
                    key: '/Institute'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/Department2">Кафедры</Link>,
                    key: '/Department2'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/AcademicGroups">Академические группы</Link>,
                    key: '/AcademicGroups'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/DynamicAttributeMeta">Атрибуты проекта</Link>,
                    key: '/DynamicAttributeMeta'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/DynamicList">Списки</Link>,
                    key: '/DynamicList'
                },
                env.userActiveClientId && env.userClient.typeId === "UNIVERCITY" && env.userRoles && env.userRoles.indexOf('ADMIN') >= 0 && {
                    label: <Link to="/PrintDocumentTypes">Типы печатных форм</Link>,
                    key: '/PrintDocumentTypes'
                },
            ]
        },
        env.isSuperAdmin && {
            label: 'Администрирование',
            icon: <ToolOutlined />,
            children: [
                {
                    label: <Link to="/User">Пользователи</Link>,
                    key: '/User'
                },
                {
                    label: <Link to="/SystemEnv">Системные переменные</Link>,
                    key: '/SystemEnv'
                },
                {
                    label: <Link to="/ClientSize">Размеры организации</Link>,
                    key: '/ClientSize'
                },
                {
                    label: <Link to="/Specialty">Направления подготовки</Link>,
                    key: '/Specialty'
                },
                {
                    label: <Link to="/EducationLevel">Уровни образования</Link>,
                    key: '/EducationLevel'
                },
                {
                    label: <Link to="/StudyYear">Года обучения</Link>,
                    key: '/StudyYear'
                },
                {
                    label: <Link to="/File">Файлы</Link>,
                    key: '/File'
                }
            ]
        },
        {
            label: <Link to="/Instructions">Инструкции</Link>,
            key: '/Instructions',
            icon: <QuestionCircleOutlined />,
        },
        env.isSuperAdmin && {
            label: 'Эксперименты',
            icon: <ExperimentOutlined />,
            children: [
                {
                    label: <Link to="/Tests">Тесты</Link>,
                    key: '/Tests'
                }
            ]
        }
    ];

    const menuButton = <React.Fragment>
        <Drawer placement="left" onClose={() => setDrawerCollapsed(true)} open={!drawerCollapsed}>
            <Menu mode="inline" items={menu} style={{ border: '0px' }} onClick={() => setDrawerCollapsed(true)} />
        </Drawer>
        <div className='layout-menu-dropdown-small' style={{ padding: '5px 15px 0 0px', marginLeft: '-10px' }}>
            {drawerCollapsed
                ? <MenuUnfoldOutlined onClick={() => setDrawerCollapsed(!drawerCollapsed)} />
                : <MenuFoldOutlined onClick={() => setDrawerCollapsed(!drawerCollapsed)} />}
        </div>
        <div className='layout-menu-dropdown-big' style={{ padding: '5px 15px 0 0px', marginLeft: '-10px' }}>
            {collapsed
                ? <MenuUnfoldOutlined onClick={() => setCollapsed(!collapsed)} />
                : <MenuFoldOutlined onClick={() => setCollapsed(!collapsed)} />}
        </div>
    </React.Fragment>

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    return (
        <Layout style={{ height: "100vh" }}>
            <Sider style={{ height: "100vh" }}
                className="sider22"
                collapsed={collapsed}
                onCollapse={setCollapsed}
                breakpoint="lg"
                collapsedWidth={40}>
                <Menu
                    className='leftmenu'
                    style={{ height: "calc(100vh - 100px)" }}
                    defaultSelectedKeys={['Project']}
                    selectedKeys={[getSelectedKeys()]}
                    items={menu}
                />
                <div className="supportInfo">
                    <span>Служба поддержки</span>
                    <span>Эл. почта: project@modeus.org</span>
                    <a href="http://www.custis.ru/" target='_blank' rel="noreferrer">© CUSTIS.{getCurrentYear()}</a>
                </div>
            </Sider>
            <Content style={{ backgroundColor: 'white' }}>
                <Spin spinning={isContextProcessing}>
                    {props.children({ systemButtons: systemButtons, menuButton: menuButton })}
                </Spin>
            </Content>
        </Layout>
    );
}

export default withRouter(MainLayout);
