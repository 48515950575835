import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import { post } from '../_common/utils/fetch';
const { confirm } = Modal;

const NotifyCommon = ({ids, open, onCancel, onOk}) => {
    const [form] = Form.useForm();

    const onOkInternal = async () => {
        let row = null;
        try {
            row = await form.validateFields();
        } catch (e) {
            return;
        }
        let json = {...row};
        json.ids = ids;

        confirm({
            title: `Вы действительно хотите разослать уведомление выбранным участникам (${ids.length})?`,
            onOk: () => {
                post({
                    isGlobalProcessing: true,
                    url: `${window.APPCFG.apiPath}/Notification/NotifyCommon`,
                    json: json,
                    callback: () => {
                        message.success("Уведомления разосланы");
                        onOk();
                    }
                });
            }
        });
    }

    if (!open || !ids || ids.length === 0) return null;

    return <Modal open={open} onOk={onOkInternal} onCancel={onCancel}
        title={`Рассылка уведомлений выбранным участникам (${ids.length})`}
        okText={`Разослать выбранным участникам (${ids.length})`}
        width={'90%'}
        heigth={'400px'}
        bodyStyle={{ overflowY: 'scroll', height: '60vh' }}>
        <Form
            form={form}
            autoComplete="off"
            layout="horizontal"
        >
            <Form.Item
                    name={'text'}
                    rules={[{ required: true, message: 'Текст уведомления обязателен' }]}
                >
                    <Input.TextArea allowClear={true} rows={4} placeholder='Комментарий' />
            </Form.Item>
            <Form.Item name="url" label="Ссылка">
                <Input allowClear={true} />
            </Form.Item>
        </Form>
    </Modal>
}

export default NotifyCommon;