import React from 'react';
import { Collapse, Tabs } from 'antd';
import { groupByToArray } from '../utils/array';

const CardBlock = ({ properties, level, metadata, singlePropertyRender, ...rest }) => {
    let children = [];
    let propertyByTab = groupByToArray(properties, _ => _.childrenLayout === 'tab' ? _.title : '_common');
    if (propertyByTab.length > 1) {
        let tabs = [];
        let _common = propertyByTab.filter(_ => _.key === '_common');
        if (_common.length !== 0) {
            tabs.push({ label: 'Общее', key: '_common', children: <CardBlock singlePropertyRender={singlePropertyRender} properties={_common[0].values} level={level + 1}
                metadata={metadata} {...rest} /> },)
        }
        for (let t of propertyByTab.filter(_ => _.key !== '_common')) {
            let tt = t.values[0];
            tabs.push({ label: tt.title, key: tt.key, children: <CardBlock singlePropertyRender={singlePropertyRender} properties={tt.children} level={level + 1}
                metadata={metadata} {...rest} /> },)
        }
        return <Tabs items={tabs} />
    } else {
        for (let property of properties) {
            if (property.children) {
                if (property.childrenLayout === 'collapse') {
                    children.push(<Collapse key={property.key} defaultActiveKey={['1']} style={{marginBottom: 10}}><Collapse.Panel style={{backgroundColor: 'white'}} header={<span className='card-block-header'>{property.title}</span>} key="1">
                        <div style={{padding: '5px 5px 0px 5px'}}>
                        <CardBlock singlePropertyRender={singlePropertyRender} properties={property.children} level={level + 1}
                            metadata={metadata} {...rest} /></div>
                        </Collapse.Panel></Collapse>);
                } else if (property.childrenLayout === 'prefix') {
                    children.push(<CardBlock singlePropertyRender={singlePropertyRender} properties={property.children} level={level + 1}
                        metadata={metadata} prefix={`${property.title}. `} {...rest} />)
                } else {
                    children.push(<CardBlock singlePropertyRender={singlePropertyRender} properties={property.children} level={level + 1}
                            metadata={metadata} {...rest} />)
                }
            } else {
                children.push(singlePropertyRender({key: property.key, property: property, metadata: metadata, ...rest}));
            }
        }
    }
    return children;
}


export default CardBlock;