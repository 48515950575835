import { changeIsContextProcessing } from '../layout/Layout'

export default function fileExport(path, callback) {
    if (changeIsContextProcessing) changeIsContextProcessing(true);
    document.cookie = "waitingCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    checkCookie(callback);
    document.location.href = path;
}

var intervalId = undefined;

function checkCookie(callback){
    intervalId = setInterval(isWaitingCookie, 1000, callback);
}

function isWaitingCookie(callback){
    var loadState = getCookie("waitingCookie");
    if (loadState === "done"){
        document.cookie = "waitingCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        clearInterval(intervalId);
        if (changeIsContextProcessing) changeIsContextProcessing(false);
        if (callback) callback();
    }
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}
