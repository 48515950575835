import React from "react";
import { Form, Radio, Space } from "antd";

export default function StepRegime() {
    return <React.Fragment>
        <Form.Item name="regime">
            <Radio.Group>
                <Space direction="vertical">
                    <Radio value='view'>Просто посмотреть</Radio>
                    <Radio value='regUnivercity'>Зарегистрироваться как Университет</Radio>
                    <Radio value='regBusiness'>Зарегистрироваться как Партнер</Radio>
                </Space>
            </Radio.Group>
        </Form.Item>
    </React.Fragment>
}
