import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
var equal = require('deep-equal');

// storeIn = location / localStorage
const checkEqual = (value1, value2, exact) => {
    if (value1 && value2 && typeof value1 === 'object') return equal(value1, value2);
    // eslint-disable-next-line
    return ((exact && value1 === value2) || (!exact && value1 == value2));
}

const getLocationParOrInitial = ({location, code, deserialize, initinalState}) => {
    const params = new URLSearchParams(location.search);
    if (code) {
        const param = params.get(code);
        const result = param === null || param === undefined
            ? initinalState
            : (deserialize ? deserialize(param, params) : JSON.parse(param));
        return result;
    } else {
        return deserialize(null, params)
    }
}

const getLocalStorageParOrInitial = ({code, deserialize, initinalState}) => {
    if (code) {
        const param = localStorage.getItem(code);
        const result = param === null || param === undefined
            ? initinalState
            : (deserialize ? deserialize(param, localStorage) : param);
        return result;
    } else {
        return deserialize(null, localStorage);
    }
}

const getInitialState = ({history, initinalState, code, storage, deserialize}) => {
    if (storage === 'location') {
        let location = history ? history.location : window.location;
        return getLocationParOrInitial({location, code, deserialize, initinalState});
    } else {
        return getLocalStorageParOrInitial({code, deserialize, initinalState});
    }
}

export function useStoredState(initinalState, {code, exact, 
        storage = 'location', serialize, deserialize}) {
    const history = useHistory();
    const [state, setState] = useState(getInitialState({history, initinalState, code, storage, deserialize}));;

    const currentState = useMemo(() => ({ state: initinalState }), [initinalState]);

    useEffect(()=> {
        if (storage !== 'location') return;

        let location = history ? history.location : window.location;
        let changeLocation = (location) => history ? history.push(location) : window.location = location;

        const searchPar = getLocationParOrInitial({location, code, deserialize, initinalState});
        if (!checkEqual(searchPar, currentState.state, exact)) {
            currentState.state = state;
            if (!equal(state, searchPar))
            {
                setState(searchPar);
                return;
            }
        }
        currentState.state = state;
        
        if (!checkEqual(searchPar, state, exact)) {
            const params = new URLSearchParams(location.search);
            const newState = checkEqual(state, initinalState, exact)
                ? undefined
                : (serialize ? serialize(state) : JSON.stringify(state));
            const newParams = newState !== null && newState !== undefined && typeof newState === 'object'
                ? newState
                : {[code]: newState}
            let modify = false;
            for (let kv of Object.entries(newParams)) {
                const key = kv[0];
                const newState = kv[1];
                const oldState = params.get(key);
                if (newState !== oldState) {
                    if (newState === null || newState === undefined) params.delete(key);
                        else params.set(key, newState);
                    modify = true;
                }
            }
            if (modify) changeLocation(`${location.pathname.endsWith('/') ? location.pathname : `${location.pathname}/`}?${params.toString()}`);
        }
    }, [storage, history, history.location, state, setState, code, currentState, initinalState, serialize, deserialize, exact]);

    useEffect(()=> {
        if (storage !== 'localStorage') return;
        
        const checkEqual = (value1, value2) => {
            if (value1 && value2 && typeof value1 === 'object') return equal(value1, value2);
            // eslint-disable-next-line
            return ((exact && value1 === value2) || (!exact && value1 == value2));
        }

        const searchPar = getLocalStorageParOrInitial({code, deserialize, initinalState});
        if (!checkEqual(searchPar, currentState.state)) {
            currentState.state = state;
            setState(searchPar);
            return;
        }
        currentState.state = state;
        
        if (!checkEqual(searchPar, state)) {
            const newState = checkEqual(state, initinalState)
                ? undefined
                : (serialize ? serialize(state) : state);
            const newParams = newState !== null && newState !== undefined && typeof newState === 'object'
                ? newState
                : {[code]: newState}
            for (let kv of Object.entries(newParams)) {
                const key = kv[0];
                const newState = kv[1];
                const oldState = localStorage.getItem(key);
                if (!checkEqual(newState, oldState)) {
                    localStorage.setItem(key, newState)
                }
            }
        }
    }, [storage, state, setState, code, currentState, initinalState, serialize, deserialize, exact])

    return [state, setState];
  }