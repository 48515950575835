import React, { useState, useEffect } from 'react';
import Table from '../../_common/tables/TableWithCard';
import { message } from 'antd';
import Actions from '../../_common/layout/Actions';
import { post, get } from '../../_common/utils/fetch';
import metaProjectSettings from '../../projects/metaProjectSettings';
import { getEntity } from '../../_common/odata/entityodata';
import { useStoredState } from '../../_common/hooks/useStoredState';
import { Collapse } from 'antd';
import { useForceUpdate } from '../../_common/hooks/useForceUpdate';
import { DATEFORMAT } from '../../_libs/antd-ext/utils/date';
import moment from 'moment';
import './style.css'
import { useEnv } from '../../_common/hooks/useEnv';
import GradeCompanyOwnResults from './GradeCompanyOwnResults';
import GradeCompanyAggregateResults from './GradeCompanyAggregateResults';
import GradeCompanyDetailResults from './GradeCompanyDetailResults';
import GradeChart from './GrageChart';
import { addTo } from './GradeCompanySettings';
import { addTo as addStatsTo } from './GradeCompanyStats';

const { Panel } = Collapse;

const meta_ = {
    entity: 'gradeCompany',
    properties: [
        {
            title: 'Дата начала',
            dataIndex: 'startDate',
            width: 150,
            type: 'date',
            editable: true,
            required: true,
            defaultSortOrder: 'descend'
        },
        {
            title: 'Дата окончания',
            dataIndex: 'finishDate',
            width: 150,
            type: 'date',
            editable: true,
            required: true,
            responsive: ["sm"]
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'gradeCompanyState',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            editable: false,
            responsive: ["sm"],
            render: (text, record, index, {renderedText}) => {
                return record.state.code === 'NOTACTIVE'
                    ? <div style={{ color: 'gray' }}>{renderedText}</div>
                    : record.state.code === 'OPEN'
                        ? <div style={{ color: 'green' }}>{renderedText}</div>
                        : <div style={{ color: 'black' }}>{renderedText}</div>
            },
        },
        {
            title: 'Контекст',
            dataIndex: 'context',
            width: 150,
            type: 'entity',
            entityName: 'gradeCampaignContext',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            editable: true,
            responsive: ["sm"],
            description: 'По результатам чего происходит оценка, если это просто периодическая оценка, то нужно оставить значение по умолчанию, если оценка работы в рамках мероприятия, то выбрать Мероприятие и т.п.'
        },
        {
            title: 'Глобальная кампания',
            dataIndex: 'globalGradeCampaign',
            width: 200,
            type: 'entity',
            entityName: 'globalGradeCampaign',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false,
            responsive: ["sm"],
            description: 'Если оценка инициирована администрацией в рамках глобальной оценочной кампании, то такая оценочная кампания является обязательной и ее настройка невозможна в рамках проекта'
        }
    ]
};

const prepareMetadata = (metadata) => {
    let index = metadata.properties.indexOf(metadata.properties.filter(_ => _.dataIndex === 'state')[0]);
    let ins = metadata.properties.filter(_ => _.title === 'Оценка')[0];
    let insIndex = metadata.properties.indexOf(ins);
    metadata.properties.splice(insIndex, 1);
    metadata.properties.splice(index + 1, 0, ins);
    return metadata;
}

export const meta = prepareMetadata(addStatsTo({metadata: addTo({metadata: meta_, clone: true}), clone: false}));

const GradeCompany = ({ projectId, userPermissions, refresh, onInit, regime, project, refreshRequired, hasRoles }) => {
    let showStats = userPermissions.isProjectCurator || hasRoles('PROJECT_CREATOR');
    if (!showStats) meta.properties = meta.properties.filter(_ => _.dataIndex[0] !== 'stats');
    const [selected, setSelected] = useState();
    const [tableApi, setTableApi] = useState();
    const [projectSettings, setProjectSettings] = useState();
    const [selectedId, setSelectedId] = useStoredState(undefined, { code: 'selected' })
    const [gradeCompanyResult, setGradeCompanyResult] = useState();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [env] = useEnv();

    const getInitialRecord = (projectSettings) => {
        let record = {
            startAuto: true,
            finishAuto: true,
            context: {
                code: 'COMMON' 
            },
            settings: {}
        };
        record.settings.startAuto = projectSettings.gradeCampaignSettings.startAuto || false;
        record.settings.finishAuto = projectSettings.gradeCampaignSettings.finishAuto || false;
        record.settings.gradeNeedComment = projectSettings.gradeCampaignSettings.gradeNeedComment !== false;
        record.settings.gradeMin = projectSettings.gradeCampaignSettings.gradeMin || 1;
        record.settings.gradeMax = projectSettings.gradeCampaignSettings.gradeMax || 5;
        record.settings.gradeVisibilityOwn = projectSettings.gradeCampaignSettings.gradeVisibilityOwn || { code: 'NONE' }
        record.settings.gradeCommentVisibilityOwn = projectSettings.gradeCampaignSettings.gradeCommentVisibilityOwn || { code: 'NONE' }
        record.settings.gradeVisibilityOthers = projectSettings.gradeCampaignSettings.gradeVisibilityOther || { code: 'NONE' }
        record.settings.gradeCommentVisibilityOthers = projectSettings.gradeCampaignSettings.gradeCommentVisibilityOther || { code: 'NONE' }
        record.settings.gradeVisibilityOpen = projectSettings.gradeCampaignSettings.gradeVisibilityOpen || false;
        return record;
    }

    const onInitInternal = (tableApi) => {
        setTableApi(tableApi);
        if (onInit) onInit(tableApi);
    }

    useEffect(() => {
        if (tableApi) {
            tableApi.setSelectedRows([selectedId]);
        }
    }, [selectedId, tableApi])

    useEffect(() => {
        if (selectedId) get({
            url: `${window.APPCFG.apiPath}/GradeCompany/GetResult?id=${selectedId}`,
            callback: (data) => {
                setGradeCompanyResult(data);
            }
        });
    }, [selectedId, forceUpdateRequired])

    const open = () => {
        if (!selectedId) message.warning("Выделите кампанию");
        post({
            url: `${window.APPCFG.apiPath}/GradeCompany/Open`,
            json: {
                Ids: [selectedId]
            },
            callback: () => {
                tableApi.refresh();
                message.success("Действие выполнено");
                if (refresh) refresh();
            }
        })
    }

    const close = () => {
        if (!selectedId) message.warning("Выделите кампанию");
        post({
            url: `${window.APPCFG.apiPath}/GradeCompany/Close`,
            json: {
                Ids: [selectedId]
            },
            callback: () => {
                tableApi.refresh();
                message.success("Действие выполнено");
                if (refresh) refresh();
            }
        })
    }

    const resultOnSave = (json) => {
        post({
            url: `${window.APPCFG.apiPath}/GradeCompany/SetResult`,
            json: json,
            callback: () => {
                message.success("Результаты сохранены");
                forceUpdate();
            }
        })
    }

    if (userPermissions.changeGradeCompany == null) return null;

    return <React.Fragment>
        <Actions items={[
            userPermissions.changeGradeCompany && {
                key: 'open',
                label: 'Открыть',
                disabled: !selected || !selected.state || selected.state.code === 'OPEN' || selected.globalGradeCampaign,
                onClick: open
            },
            userPermissions.changeGradeCompany && {
                key: 'close',
                label: 'Закрыть',
                disabled: !selected || !selected.state || selected.state.code === 'CLOSED' || selected.globalGradeCampaign,
                onClick: close
            },
        ]} />
        <Table
            columns={meta.properties}
            odataEntity={meta.entity}
            rowKey={'id'}
            onInit={onInitInternal}
            editable={Boolean(userPermissions.changeGradeCompany)}
            fullScreen={false}
            odataFilter={`projectId eq ${projectId}`}
            afterGetPath={path => `${path}&projectId=${projectId}`}
            pagination={false}
            onCopy={{disabled: ({ record }) => record.globalGradeCampaign}}
            onEdit={{disabled: ({ record }) => record.globalGradeCampaign}}
            onDelete={{disabled: ({ record }) => record.globalGradeCampaign}}
            rowSelection={{
                onChange: (newSelectedRowKeys, newSelectedRows) => {
                    setSelectedId(newSelectedRowKeys[0]);
                    setSelected(newSelectedRows[0])
                },
                type: 'radio'
            }}
            onLoad={({ data }) => {
                if (data && data.length > 0) {
                    if (!selectedId) {
                        setSelectedId(data[0].id);
                        setSelected(data[0]);
                    } else {
                        setSelected(data.filter(_ => _.id === selectedId)[0]);
                    }
                } else {
                    setSelectedId(undefined);
                    setSelected(undefined);
                }
            }}
            getDefaultData={(callback) => {
                if (projectSettings) return getInitialRecord(projectSettings);
                getEntity({
                    metadata: metaProjectSettings, id: null, callback: (data) => {
                        callback(getInitialRecord(data));
                        setProjectSettings(data);
                    },
                    extFilter: `projectId eq ${project.id}`
                })
            }}
            beforeSaveData={({ record }) => {
                record.projectId = project.id;
                return record;
            }}
        />
        {selected && gradeCompanyResult && <div className='gradeCompany'>
            <div className='mainTitle'>Оценочная кампания: {moment(selected.startDate).format(DATEFORMAT)} - {moment(selected.finishDate).format(DATEFORMAT)} ({selected.state.name})</div>
            {(selected.state.code === 'OPEN' || selected.state.code === 'CLOSED') && <Collapse defaultActiveKey={['1', '2', '3', '4']}>
                {selected.state.code === 'OPEN' && (userPermissions.isActiveMember || userPermissions.isProjectCurator) && <Panel header="Оценки, поставленные мной" key="1">
                    <GradeCompanyOwnResults userPermissions={userPermissions} env={env} gradeCompany={selected} gradeCompanyResult={gradeCompanyResult} onSave={resultOnSave} />
                </Panel>}
                {gradeCompanyResult.aggregate && gradeCompanyResult.aggregate.length > 0 && <Panel header="Средние оценки" key="2">
                    <GradeCompanyAggregateResults env={env} gradeCompany={selected} gradeCompanyResult={gradeCompanyResult} /> 
                </Panel>}
                <Panel header="Детальные оценки" key="3">
                    <GradeCompanyDetailResults env={env} gradeCompany={selected} gradeCompanyResult={gradeCompanyResult} /> 
                </Panel>
                {gradeCompanyResult.aggregateAll && <Panel header="Сводная по всем кампаниям" key="4">
                    <GradeChart env={env} gradeCompany={selected} gradeCompanyResult={gradeCompanyResult} /> 
                </Panel>}
            </Collapse>}
        </div>}
    </React.Fragment>;
}

export default GradeCompany;