import React, { useState } from "react";
import { Modal, Input } from "antd";

const CommentDialog = ({title, open, onOk, onCancel, placeholder}) => {
    const [comment, setComment] = useState();
    return <Modal title={title} open={open} onOk={() => onOk(comment)} onCancel={onCancel}>
        <Input.TextArea rows={3} placeholder={placeholder} allowClear={true} maxLength={10000} value={comment} onChange={e => setComment(e.target.value)} />
    </Modal>
}

export default CommentDialog;