import React, { PureComponent } from 'react';
import { Modal, Button, Form, message, style } from 'antd';
import { EditOutlined, CloseOutlined, CheckOutlined, CopyOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
//import Table from './Table';
import { TypedTable as Table, getColumnKey, getColumnsHeadersCnt, getAllInitializedColumns, getColumnsMap } from '../../_libs/antd-ext';
import { post } from '../utils/fetch';
import { ProtonState } from 'proton-state';
import AntdTableStateProvider from './AntdTableStateProvider';
import { getRecordValue, changeRecordValue } from '../utils/array';
import moment from 'moment';
import { FormItem } from '../../_libs/antd-ext';
import "./style.css"
import { getData, getPath, saveData } from '../odata/entityodata';
import { Table as AntdTable } from 'antd';

const { confirm } = Modal;
var equal = require('deep-equal');
const cloneDeep = require('clone-deep');

export default class TableOData extends PureComponent {
    constructor(props) {
        super(props);
        this.props = props;

        this.editable = this.props.editable !== undefined
            ? Boolean(this.props.editable)
            : this.props.columns.filter(_ => _.editable).length > 0;

        this.columns = cloneDeep(this.props.columns.filter(_ => _.showInTable !== false).filter(_ => _));
        this.columnsMap = {}
        this.rowKey = this.props.rowKey || 'id';

        this.hasDynamicColumns = this.columns.filter(_ => _.isDynamic).length > 0;

        if (this.columns.map(_ => _.dataIndex).indexOf(this.rowKey) < 0) {
            const keyColumn = {
                title: 'id',
                type: 'number',
                dataIndex: this.rowKey,
                hidden: true
            };
            this.columns = [keyColumn, ...this.columns];
        }

        this.allColumns = getAllInitializedColumns(this.columns);
        this.headers = getColumnsHeadersCnt(this.allColumns);
        this.dataColumns = this.allColumns.filter(_ => !_.children);
        this.columnsMap = getColumnsMap(this.allColumns);

        for (let column of this.dataColumns) {
            column.key = getColumnKey(column);
            // TODO Пока не придумал, как сортировать динамические колонки
            if (column.isDynamic || column.multiple) column.sortable = false;
            else if (props.sortable === false) column.sortable = false;
            else if (column.sortable === false || column.sorter === false) column.sortable = false;
            else column.sorter = { multiple: 0 };
            if (column.defaultFilteredValue) {
                this.state.filters[column.key] = column.defaultFilteredValue;
            }
            if (column.defaultSortOrder) this.state.sorter.push({ column: column, columnKey: column.key, order: column.defaultSortOrder })
            const columnOnCell = column.onCell;
            column.onCell = (record, rowIndex) => {
                return {
                    onClick: event => this.onCellClick({ record, rowIndex, column, event }),
                    record,
                    creating: record[this.rowKey] === this.newKeyValue,
                    column: column,
                    editing: this.isEditing(record),
                    editableDefault: this.editable && this.editable.default,
                    ...(columnOnCell ? columnOnCell(record, rowIndex) : {})
                };
            }
        }

        this.metadata = {
            entity: this.props.odataEntity,
            key: this.props.rowKey,
            properties: this.dataColumns,
            path: this.props.odataPath
        }

        this.selectorRef = React.createRef(null);

        if (this.props.history) {
            this.protonState = new ProtonState(
                {
                    history: this.props.history
                });
            if (this.props.localStorageKeyPostfix) {
                this.protonState.localStorageStoreProvider.storageKey = `_ps_${this.props.localStorageKeyPostfix}` 
            }
            if (this.props.storeInAddressBar === false) {
                this.protonState.storeProviders = [this.protonState.storeProviders[0]];
            }
        }
    }

    getAction = (propName, internalAction) => {
        if (!this.editable) return null;
        const prop = this.props[propName];
        if (prop !== undefined) {
            if (prop === false) return null;
            if (prop instanceof Function) {
                return {
                    action: prop
                }
            } else {
                return {
                    ...prop,
                    action: prop.action || internalAction
                }
            }
        } else if (internalAction) {
            return {
                action: internalAction
            }
        } else {
            return null;
        }
    }

    formRef = React.createRef();

    newKeyValue = 0;
    system_copy_id = '_system_copy_id';

    isEditing = (record) => {
        return record[this.rowKey] === this.state.editingKey;
    }

    isEditingAny = () => {
        return this.state.editingKey !== undefined && this.state.editingKey !== null;
    }

    copy = (record) => {
        let r = cloneDeep(record);
        if (this.props.beforeCopyData) r = this.props.beforeCopyData({ record: r });
        this.setState({ [this.system_copy_id]: record[this.rowKey] })
        this.create(r);
    }

    add = () => {
        if (this.props.onAdd) this.props.onAdd();
        else {
            let r = {};
            if (this.props.beforeAddData) r = this.props.beforeAddData({ record: r });
            this.create(r);
        }
    }

    create = (record) => {
        let newRecord = record ? cloneDeep(record) : {};
        newRecord[this.rowKey] = this.newKeyValue;
        this.editInternal(newRecord, true);
        // из-за баги в antd приходится чтобы вставить новую запись с превышением кол-ва записей на страницу сначала удалить, а потом вставить снова
        if (this.state.dataSource.length >= this.state.pageSize) {
            this.setState({ lastRecord: this.state.dataSource[this.state.dataSource.length - 1], dataSource: [newRecord, ...this.state.dataSource.slice(0, this.state.dataSource.length - 1)], editingKey: this.newKeyValue });
        } else {
            this.setState({ lastRecord: undefined, dataSource: [newRecord, ...this.state.dataSource], editingKey: this.newKeyValue });
        }
    }

    cancelEdit = () => {
        const editingKey = this.state.editingKey;
        if (editingKey === undefined) return;
        if (editingKey === 0) {
            if (this.state.lastRecord) {
                this.setState({ [this.system_copy_id]: undefined, dataSource: [...this.state.dataSource.slice(1), this.state.lastRecord], lastRecord: undefined, editingKey: undefined });
            } else {
                this.setState({ [this.system_copy_id]: undefined, dataSource: [...this.state.dataSource.slice(1)], lastRecord: undefined, editingKey: undefined });
            }
        } else {
            this.setState({ [this.system_copy_id]: undefined, lastRecord: undefined, editingKey: undefined });
        }
        if (this.props.onEditEnd) this.props.onEditEnd({ editingKey: editingKey })
    }

    save = async () => {
        let row = null;
        try {
            row = await this.formRef.current.validateFields();
        } catch (e) {
            return;
        }
        const editingKey = this.state.editingKey;
        let copyFromId = this.state[this.system_copy_id];
        this.cancelEdit();
        let creating = editingKey === this.newKeyValue;
        let json = cloneDeep(row)
        saveData({
            metadata: this.metadata,
            value: json, creating,
            editingKey,
            beforeSaveData: this.props.beforeSaveData,
            onSave: (result, pars) => {
                let onSaveResult = this.props.onSave ? this.props.onSave(result, pars) : false;
                if (!onSaveResult) this.refresh();
            },
            afterGetPath: (path) => creating && copyFromId ? `${path}?copyFromId=${copyFromId}` : path
        })
    }

    edit = (record, notSetState) => {
        this.editInternal(record, notSetState);
    };

    editInternal = (record, notSetState) => {
        let r = cloneDeep(record);
        /*for (let c of this.dataColumns) {
            if (c.type === 'date') {
                changeRecordValue(r, c.dataIndex, value => !value ? value : moment(value))
            }
        }*/
        this.formRef.current.resetFields()
        this.formRef.current.setFieldsValue({
            ...r,
        });
        if (!notSetState) this.setState({ editingKey: record[this.rowKey], dataSource: [...this.state.dataSource] });
        if (this.props.onEditBegin) this.props.onEditBegin({ editingKey: record[this.rowKey] })
    };

    delete = (record) => {
        const deletedKeys = record ? [record[this.rowKey]] : this.state.selectedRowKeys;
        if (deletedKeys.length === 0) {
            message.warning("Не выделено ни одной записи для удаления");
            return;
        }
        const isSingle = deletedKeys.length === 1;
        confirm({
            title: `Вы действительно хотите удалить ${isSingle ? "запись" : `${deletedKeys.length} записей`}?`,
            onOk: () => {
                post({
                    url: `${window.APPCFG.apiPath}/${this.props.odataEntity}/Delete`,
                    json: {
                        Ids: deletedKeys
                    },
                    callback: () => {
                        const afterDeleteResult = this.props.afterDelete ? this.props.afterDelete({ records: record ? [record] : this.state.selectedRows }) : false;
                        if (!afterDeleteResult) {
                            this.refresh();
                            message.success(isSingle ? "Запись удалена" : "Записи удалены");
                        }
                    }
                });
            }
        });
    }
    // ------------

    onCellClick = ({ record, rowIndex, column, event }) => {
        if (!event.altKey) return;
        let value = getRecordValue(record, column.dataIndex);
        let columnKey = column.key;
        if (!value) return;
        let filterValue = column.type === 'date'
            ? { value: { from: value, till: value } }
            : column.type === 'entity'
                ? (column.multiple === true
                    ? { value: {ids: value} }
                    : { value: {ids: [value]} })
                : column.type === 'number'
                    ? { value: {values: [value.toString()]} }
                    : { value: [value] };
        this.setState({ filters: { ...this.state.filters, [columnKey]: [filterValue] }, total: null }, this.refresh);
    }

    state = {
        dataSource: [],
        loading: false,
        total: null,
        current: 1,
        pageSize: 20,
        sorter: [],
        filters: {},
        selectedRowKeys: [],
        selectedRows: [],
        forceUpdateRequired: 1
    }

    protonState;

    onChange = this.props.onChange;
    onColumnChange = this.props.onColumnChange;

    getMainContentMenuItems = () => {
        let items = [];
        if (this.getAction('onDelete', this.delete)) items.push({ label: 'Удалить выбранные', key: 'delete', onClick: () => this.delete() });
        if (this.getAction('onAdd', this.add)) items.push({ label: 'Создать', key: 'add', onClick: this.add });
        return items;
    }

    getSelectedRows = () => {
        return this.state.selectedRows;
    }

    setSelectedRows = (selectedRowKeys) => {
        if (!selectedRowKeys) {
            this.setState({
                selectedRowKeys: [],
                selectedRows: []
            });
            return;
        }
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: this.state.dataSource.filter(_ => selectedRowKeys.indexOf(_[this.rowKey]) >= 0),
        });
    }

    getUrlSearchParams = (url) => {
        let parts = url.split('?');
        return parts.length > 1
            ? '?' + parts.slice(1).join('?')
            : '';
    }

    getSorters = () => {
        return this.getSorterArray(this.state.sorter).map(s => ({
            key: s.columnKey,
            order: s.order
        }))
    }

    refresh = () => {
        if (this.props.dataSource) return;
        this.setLoading(true);
        this.cancelEdit();
        let path = getPath({
            metadata: this.metadata,
            sorters: this.getSorters(),
            filters: this.state.filters,
            extFilter: this.props.odataFilter,
            afterGetPath: this.props.afterGetPath,
            pageSize: this.state.pageSize,
            currentPage: this.state.current,
            count: true,//this.state.total === undefined || this.state.total === null,
            total: null//this.state.total
        });
        this.lastUrl = path;
        this.lastUrlSearchParams = this.getUrlSearchParams(this.lastUrl);
        getData({
            metadata: this.metadata,
            path: path,
            callback: (data, { total }) => {
                this.setLoading(false);
                this.setDataSource(data);
                //this.setDataSource(this.convertData(data.value));
                //this.refreshInternals(this.convertData(data.value));
                const keys = data.map(_ => _[this.rowKey]);
                const selectedRowKeys = this.state.selectedRowKeys.filter(_ => keys.indexOf(_) >= 0);
                this.setState({
                    selectedRowKeys: selectedRowKeys,
                    selectedRows: data.filter(_ => selectedRowKeys.indexOf(_[this.rowKey]) >= 0),
                });
                if (total && total !== (this.state.total || 0)) {
                    this.setTotal(total);
                }
                if (this.props.onLoad) {
                    this.props.onLoad({ data, total });
                }
            },
            errorCallback: () => {
                this.setLoading(false);
                this.setDataSource(null);
                this.setTotal(null);
            },
            asyncInternalCallback: (value) => { this.setState({ dataSource: cloneDeep(value) }); }
        })
    };

    setDataSource = (dataSource) => {
        this.setState({ dataSource });
    }

    setLoading = (loading) => {
        this.setState({ loading });
    }

    setCurrentPage = (current) => {
        this.setState({ current }, this.refresh)
    }

    setPageSize = (pageSize) => {
        this.setState({ pageSize }, this.refresh)
    }

    setTotal = (total) => {
        this.setState({ total })
    }

    setSorter = (sorter) => {
        this.setState({ sorter }, this.refresh)
    }

    onChangeInternal = (newPagination, filters, sorter) => {
        this.cancelEdit();
        const isFilterChanged = this.state.filters != null && !equal(this.state.filters, filters);

        let current = this.state.current;
        let pageSize = this.state.pageSize;

        if (newPagination.pageSize !== this.state.pageSize) {
            pageSize = newPagination.pageSize;
            current = 1;
        } else if (newPagination.current !== this.state.current) {
            current = newPagination.current;
        }

        this.setState({
            pageSize, current, sorter, filters,
            total: isFilterChanged || newPagination.pageSize !== this.state.pageSize ? null : this.state.total
        }, () => {
            this.refresh();
            if (this.onChange) this.onChange(newPagination, filters, sorter);
        })
    };

    componentDidMount() {
        if (this.selectorRef.current) {
            let top1 = this.selectorRef.current.getBoundingClientRect().top;
            let top2 = this.selectorRef.current.offsetTop;
            let top = Math.min(Math.round(top1) || 1000, Math.round(top2) || 1000);
            this.setState({ tableClientTop: top });
        }

        if (this.protonState) {
            this.protonState.addStateProvider(new AntdTableStateProvider({ api: this }))
            this.protonState.updateState();
        } else {
            this.refresh()
        }
        if (this.props.onInit) this.props.onInit(this);
    }

    componentDidUpdate() {
        if (this.protonState) this.protonState.updateState();
    }

    getSorterArray = (sorter) => {
        let s = (sorter == null
            ? []
            : Array.isArray(sorter)
                ? sorter
                : [sorter])
            .filter(_ => _.order);
        return s;//.sort((a, b) => this.columnsMap[a.columnKey].sorter.multiple > this.columnsMap[b.columnKey].sorter.multiple);
    }

    onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
        this.setState({ selectedRowKeys: newSelectedRowKeys, selectedRows: newSelectedRows })
        if (this.props.rowSelection && this.props.rowSelection.onChange)
            this.props.rowSelection.onChange(newSelectedRowKeys, newSelectedRows);
    }

    submit = () => {
        this.formRef.current.submit();
    }

    getRenderColumns = () => {
        const editAction = this.getAction('onEdit', this.edit);
        const copyAction = this.getAction('onCopy', this.copy);
        const deleteAction = this.getAction('onDelete', this.delete);
        let width = 17 + 24 * ([editAction, copyAction, deleteAction].filter(_ => _).length + (this.props.actions ? this.props.actions.length : 0));
        let titleActions = (this.getAction('onAdd', this.add)) && !this.isEditingAny()
            ? [<Button type='text' size='small' className='iconbutton' style={{ height: 22, verticalAlign: 0 }} icon={<PlusOutlined />} key="create" title="Создать" onClick={() => this.add()} />]
            : [];
        if (this.props.titleActions) titleActions = [...titleActions, ...this.props.titleActions.map((action, idx) => <Button key={idx} style={{ height: 22, verticalAlign: 0 }} disabled={action.disabled} type={action.icon && 'text'} size='small' className={action.icon && 'iconbutton'} icon={action.icon} title={action.label} onClick={action.onClick}>{action.icon ? undefined : action.label}</Button>)];
        const actionColumn = {
            _initialized: true,
            hideable: false,
            title: () => titleActions.length > 0 ? <center>{titleActions}</center> : null,
            width: width,
            fixed: true,
            filterable: false,
            sortable: false,
            resizable: false,
            render: (_, record) => {
                const editable = this.isEditing(record);
                let allActions = this.editable
                    ? editable
                        ? [
                            <Button key='apply' type='text' size='small' className='iconbutton' icon={<CheckOutlined />} title="Применить изменения" onClick={this.submit} />,
                            <Button key='cancel' type='text' size='small' className='iconbutton' icon={<CloseOutlined />} title="Отменить изменения" onClick={this.cancelEdit} />
                        ]
                        : [
                            editAction && <Button key='edit' type='text' size='small' className='iconbutton' icon={<EditOutlined />} disabled={this.state.editingKey !== undefined || (editAction.disabled && editAction.disabled({ record }))} title="Редактировать" onClick={() => editAction.action(record)} />,
                            copyAction && <Button key='copy' type='text' size='small' className='iconbutton' icon={<CopyOutlined />} disabled={this.state.editingKey !== undefined || (copyAction.disabled && copyAction.disabled({ record }))} title="Копировать" onClick={() => copyAction.action(record)} />,
                            deleteAction && <Button key='delete' type='text' size='small' className='iconbutton' icon={<DeleteOutlined />} disabled={this.state.editingKey !== undefined || (deleteAction.disabled && deleteAction.disabled({ record }))} title="Удалить" onClick={() => deleteAction.action(record)} />
                        ]
                    : [];
                if (this.props.actions) allActions = [...allActions,
                ...this.props.actions.map((action, idx) => action instanceof Function
                    ? action({ record, idx })
                    : action.visible && action.visible({ record }) !== true ? null : <Button key={idx} disabled={action.disabled && action.disabled({ record })} type='text' size='small' className='iconbutton' icon={action.icon} title={action.title} onClick={() => action.onClick(record)} />)
                ];
                allActions = allActions.filter(_ => _);
                return <React.Fragment>{allActions}</React.Fragment>;
            },
        };
        const columns = this.editable || this.props.actions
            ? [actionColumn, ...this.columns]
            : [...this.columns];

        for (let column of this.dataColumns) {

            // сортировка
            column.sortOrder = undefined;
            if (this.state.sorter) {
                const sorter = this.getSorterArray(this.state.sorter);
                const sort = sorter.filter(_ => _.columnKey === column.key)[0]
                if (sort) {
                    column.sortOrder = sort.order;
                }
            }

            // фильтры
            column.filteredValue = this.state.filters[column.key] || null;
        }
        return columns;
    }

    render() {
        let columns = this.getRenderColumns();
        const rowSelection = this.props.selectable === false || this.props.rowSelection === false ? undefined : {
            ...(this.props.rowSelection || {}),
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            fixed: true,
            selections: [
                AntdTable.SELECTION_ALL,
                AntdTable.SELECTION_INVERT,
                AntdTable.SELECTION_NONE,
                (this.getAction('onDelete', this.delete)) && {
                    key: 'delete',
                    text: 'Удалить выбранные',
                    onSelect: (changableRowKeys) => {
                        this.delete();
                    },
                },
            ].filter(_ => _),
        };

        const viewAction = this.getAction('onView');
        let onRow = this.props.onRow;
        if (viewAction) {
            if (!onRow) onRow = (record, rowIndex) => {
                return {
                    onClick: event => viewAction.action(record)
                };
            }
        }

        return <div ref={this.selectorRef}>
            {!this.state.tableClientTop
                ? null
                : <Form ref={this.formRef} component={false} onFinish={this.save} >
                    <Table
                        forceUpdateRequired={this.state.forceUpdateRequired}
                        className={this.props.fullScreen !== false ? 'full-screen' : undefined}
                        columns={columns}
                        dataSource={this.props.dataSource || this.state.dataSource}
                        loading={this.state.loading}
                        onChange={this.onChangeInternal}
                        pagination={this.props.pagination === false ? false : {
                            position: 'bottomRight',
                            current: this.state.currentPage,
                            pageSize: this.state.pageSize,
                            total: this.state.total,
                            showTotal: (total) => `Всего: ${total}`,
                            showSizeChanger: true,
                            pageSizeOptions: [10,20,50,100,1000]
                        }}
                        rowKey={this.rowKey}
                        //{...this.props}
                        onColumnChange={this.onColumnChange}
                        onResetColumnSettings={() => { if (this.protonState) this.protonState.clear(); window.location.reload(); }}
                        rowClassName="editable-row"
                        components={{
                            body: {
                                cell: EditableCell
                            }
                        }}
                        showSorterTooltip={false}
                        scroll={this.props.scroll
                            ? this.props.scroll
                            : this.props.fullScreen !== false
                                ? { x: 100, y: `calc(100vh - ${55 + this.state.tableClientTop + this.headers * 38}px)` }
                                : { x: 100, y: true }
                        }
                        rowSelection={rowSelection}
                        defaults={{
                            resizable: true,
                            movable: true,
                            hideable: true,
                            editable: true,
                            sortable: true,
                            filterable: true,
                            ellipsis: true,
                            ...this.props.defaults
                        }}
                        resizable={this.props.resizable}
                        movable={this.props.movable}
                        hideable={this.props.hideable}
                        editable={this.props.editable}
                        sortable={this.props.sortable}
                        filterable={this.props.filterable}
                        onRow={onRow}
                    />
                    {(this.getAction('onAdd', this.add) && !this.isEditingAny()) ? <div style={this.props.pagination === false || !this.state.total ? { marginTop: '10px' } : { marginTop: '-30px' }}><Button key="create" onClick={this.add}>Создать</Button></div> : null}
                </Form >}
        </div>
    }
};

const EditableCell = ({
    editing,
    column,
    record,
    editableDefault,
    creating,
    index,
    children,
    ...restProps
}) => {
    const notEditable = !editing
        || (!editableDefault && !column.editable)
        || (editableDefault && column.editable === false)
        || (creating && column.editable && (column.editable.creating === false || (column.editable.creating instanceof Function && column.editable.creating({ record }) === false)))
        || (!creating && column.editable && (column.editable.updating === false || (column.editable.updating instanceof Function && column.editable.updating({ record }) === false)))

    if (notEditable)
        return <td {...restProps}>{children}</td>

    return (
        <td {...restProps}>
            <FormItem
                errorOnTooltip={true}
                type={column.type}
                name={column.dataIndex}
                label={column.title}
                {...column}
                style={{
                    margin: 0,
                }}
                record={record}
            />
        </td>
    );
};