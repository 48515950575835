import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

// eslint-disable-next-line
export default ({ className, height, width, type, data, options, style = {} }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            const chart = new Chart(chartRef.current.getContext("2d"), {
                type,
                data,
                options,
            });
            return () => chart.destroy();
        }
    }, [type, data, options]);

    return (
        <div className={className} style={{...style, position: 'relative', width: width, height: height}}>
            <canvas ref={chartRef} width={width} height={height} style={{maxWidth: width, maxHeight: height}} />
        </div>
    );
}