import React from 'react';
import { FilterOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Menu, Dropdown } from 'antd';

const ClearFilterButton = (props) => {
    let { onClearAll, onClearGrid, onClearGridSettings, onClearExt, clearAllTitle, clearGridTitle, clearExtTitle, clearGridSettingsTitle, gridApi, filterApi, protonState } = props;
    if (!onClearAll && !onClearGrid && !onClearExt && !gridApi && !filterApi) return null;
    if (gridApi && filterApi) {
        onClearAll = () => {gridApi.setFilterModel(null);filterApi.clearAllFilters()}
        onClearGrid = () => gridApi.setFilterModel(null);
        if (protonState) onClearGridSettings = () => {protonState.clear('LocalStorage'); window.location.reload();}
        onClearExt = () => filterApi.clearAllFilters();
    } else if (gridApi) {
        onClearAll = () => gridApi.setFilterModel(null);
    } else if (filterApi) {
        onClearAll = () => filterApi.clearAllFilters();
    }
    const menu = (!onClearGrid && !onClearExt)
        ? null
        : <Menu>
            <Menu.Item key={'clearAll'} onClick={onClearAll}>{clearAllTitle || 'Очистить все фильтры'}</Menu.Item>
            {!onClearGrid ? null : <Menu.Item key={'clearGrid'} onClick={onClearGrid}>{clearGridTitle || 'Только фильтры в гриде'}</Menu.Item>}
            {!onClearExt ? null : <Menu.Item key={'clearExt'} onClick={onClearExt}>{clearExtTitle || 'Только расширенные фильтры'}</Menu.Item>}
            {!onClearGridSettings ? null : <Menu.Item key={'clearGridSettings'} onClick={onClearGridSettings}>{clearGridSettingsTitle || 'Настройки грида (колонки и т.п.)'}</Menu.Item>}
        </Menu>
    const button = <Button shape="circle" type="text" icon={<FilterOutlined style={{ fontSize: '20px' }} />}
        title={clearAllTitle || 'Очистить все фильтры'}
        onClick={onClearAll}>
        <CloseOutlined style={{ fontSize: 16, position: 'absolute', margin: '6px -10px' }} />
    </Button>
    return menu
        ? <Dropdown overlay={menu}>{button}</Dropdown>
        : <React.Fragment>{button}</React.Fragment>
}

export default ClearFilterButton;