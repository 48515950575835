import React from 'react';
import { Form } from 'antd';
import FieldEntity from './FieldEntity';
import FieldDate from './FieldDate';
import FieldBoolean from './FieldBoolean';
import FieldNumber from './FieldNumber';
import FieldString from './FieldString';
import FieldText from './FieldText';
import FieldFile from './FieldFile';
import FieldExtended from './FieldExtended';
import FieldTreeEntity from './FieldTreeEntity';
import { DATETIMEFORMAT } from '../../../_common/tables/date';

const FormItem = ({ title: propsTitle, type, children, rules: propsRules, errorOnTooltip, description, context, entityFilter, sourceRecord, ...rest }) => {
    let entityFilterValue = () => entityFilter && typeof entityFilter === 'function'
        //? entityFilter({...context, record: {...record, ...(context && context.form && context.form.getFieldsValue() || {})}})
        ? entityFilter({...context, record: context && context.form && context.form.getFieldsValue(), sourceRecord: sourceRecord})
        : entityFilter;
    const inputNode = () => children
        ? children
        : type === 'date'
            ? <FieldDate allowClear {...rest} />
            : type === 'datetime'
                ? <FieldDate allowClear showTime={{ format: 'HH:mm', ...(rest.showTime || {}) }} format={rest.format || DATETIMEFORMAT} {...rest} />
                : type === 'entity'
                    ? <FieldEntity allowClear mode={rest.multiple ? 'multiple' : undefined} context={context} {...rest} entityFilter={entityFilterValue()} />
                    : type === 'number'
                        ? <FieldNumber allowClear {...rest} />
                        : type === 'boolean'
                            ? <FieldBoolean allowClear {...rest} />
                            : type === 'string'
                                ? <FieldString allowClear {...rest} />
                                : type === 'text'
                                    ? <FieldText allowClear {...rest} />
                                    : type === 'file'
                                        ? <FieldFile allowClear {...rest} />
                                        : type === 'extended'
                                            ? <FieldExtended allowClear {...rest} />
                                            : type === 'treeentity'
                                                ? <FieldTreeEntity allowClear {...rest} />
                                                : <FieldString allowClear {...rest} />;
    const ruleTypes = ['string', 'number', 'date', 'boolean', 'email'];
    let rule = {};
    rule.required = rest.required;
    if (ruleTypes.indexOf(type) >= 0) rule.type = type;
    const rules = propsRules || [rule];
    const formItemProps = {
        title: propsTitle,
        rules: rules,
        hasFeedback: true,
        tooltip: description,
        name: rest.name,
        label: rest.label,
        style: rest.style
    }

    return errorOnTooltip
        ? <Form.Item noStyle shouldUpdate>
            {(props) => {
                let errors = props.getFieldError(rest.name);
                const visible = errors.length > 0;
                const title = visible ? errors[0] : propsTitle;
                return <Form.Item {...formItemProps} title={title}>
                    {inputNode()}
                </Form.Item>;
            }}
        </Form.Item>
        : <Form.Item noStyle shouldUpdate>
            {() => {
                return <Form.Item {...formItemProps}>
                    {inputNode()}
                </Form.Item>;
            }}
        </Form.Item>;
}

export default FormItem;