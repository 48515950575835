import React, { useState } from 'react';
import { Modal, Input, Space } from 'antd';
import FileSelectionPanel from './FileSelectionPanel';

const { TextArea } = Input;

export default function FileDialog(props) {
    const [file, setFile] = useState();
    const [pars, setPars] = useState();
    return props.open
        ? <Modal title={props.title} open={props.open} onCancel={props.onClose} onOk={() => props.onOk(file, pars)}>
            <Space direction="vertical" style={{width: '100%'}}>
                <FileSelectionPanel onChange={(file) => {setFile(file)}} file={file} />
                {props.children}
            </Space>
        </Modal>
        : null;
}