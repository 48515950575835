import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import { prepareMetadata } from '../_common/odata/entityodata';
import { Button, Modal } from 'antd';
const { confirm } = Modal;

const metadata = {
    entity: 'position',
    key: 'id',
    properties: [
        {
            title: 'ФИО',
            dataIndex: 'user',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name'
        },
        {
            title: 'Email',
            dataIndex: ['user','email'],
            width: 150
        },
        {
            title: 'Группа',
            dataIndex: 'academicGroup',
            type: 'entity',
            entityName: 'academicGroup',
            entityKey: 'id',
            entityLabel: 'name',
            width: 150
        },
        {
            title: 'Год начала обучения',
            dataIndex: 'startYear',
            width: 150,
            type: 'number'
        },
        {
            title: 'Направления подготовки',
            dataIndex: 'specialties',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'specialty',
            entityKey: 'id',
            entityLabel: ['code','name'],
            editable: false
        },
        {
            title: 'Образовательные программы',
            dataIndex: 'educationalPrograms',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'educationalProgram',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false
        }
    ]
};

const UsersSelector = ({open, onCancel, onOk, type}) => {
    const [tableApi, setTableApi] = useState();
    const [env] = useEnv();
    const [selectedRows, setSelectedRows] = useState();
    const [total, setTotal] = useState(0);
    prepareMetadata({metadata});
    useEffect(() => {
        if (tableApi) tableApi.refresh();
    }, [type])

    const onOkInternal = () => {
        confirm({
            title: `Вы действительно хотите добавить всех (${total})?`,
            onOk: () => onOk({filter: tableApi.lastUrlSearchParams})
        });
    }

    return <Modal title={`Выберите ${type === 'EMPLOYEE' ? 'сотрудников' : 'студентов'}`} open={open} onOk={onOkInternal} onCancel={onCancel}
        okText={`Добавить всех (${total})`}
        width={'90%'}
        heigth={'400px'}
        bodyStyle={{overflowY: 'scroll', height: '60vh'}}>
        <div>Отберите нужных участников с помощью фильтров в таблице</div>
        <div>
            Всего выбрано по критериям: <b>{total}</b><br/>
            {total > 0 && <Button onClick={onOkInternal} style={{margin: '10px 0px'}}>Добавить всех ({total})</Button>}
        </div>
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            editable={false}
            onInit={setTableApi}
            odataFilter={`clientId eq ${env.userClientId} and typeId eq '${type === 'EMPLOYEE' ? 'EMPLOYEE' : 'STUDENT'}'`}
            onLoad={({data, total}) => setTotal(total)}
            fullScreen={false}
            selectable={false}
            rowSelection={{
                onChange: (newSelectedRowKeys, newSelectedRows) => setSelectedRows(newSelectedRows)
            }}
        />
    </Modal>
}

export default withRouter(UsersSelector);