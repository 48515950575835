import React, { useEffect, useState } from 'react';
import { withRouter, Link } from "react-router-dom";
import TableWithCard from '../_common/TableWithCard';
import { useEnv } from '../_common/hooks/useEnv';
import { prepareMetadata } from '../_common/odata/entityodata';
import { get } from '../_common/utils/fetch';
import { useViewRoot } from '../_common/hooks/useViewRoot';
import { Button } from 'antd';

const metadata = {
    entity: 'PartnershipSetting',
    key: 'id',
    properties: [
        {
            title: 'Организация',
            dataIndex: 'client',
            width: 150,
            editable: false,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            link: true
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            type: 'text',
            width: 250,
            editable: true,
            showInTable: false
        },
        {
            title: 'Шаблоны документов',
            dataIndex: 'documentTemplates',
            type: 'file',
            multiple: true,
            editable: true,
            showInTable: false
        },
        {
            title: 'Email',
            dataIndex: 'email',
            type: 'string',
            editable: true,
            showInTable: false
        },
        {
            title: 'Адрес',
            dataIndex: 'address',
            type: 'string',
            editable: true,
            showInTable: false
        }
    ]
};

const PartnershipSetting = (props) => {
    const [env] = useEnv();
    const [isPartner, setIsPartner] = useState(false);
    let { id, isCurrent } = useViewRoot();
    const isOwn = isCurrent || (env && id === env.userClientId);
    useEffect(() => {
        get({
            url: `${window.APPCFG.odataPath}/Partnership?$filter=clientId eq ${env.userClientId} and partnerId eq ${id} and stateId eq 'ACTIVE'`,
            callback: (data) => setIsPartner(data.value && data.value.length > 0)
        })
    }, [id, env.userClientId])
    prepareMetadata({metadata});
    return <TableWithCard
        title="Настройки партнерства"
        titles="Настройки партнерства"
        metadata={metadata}
        entityTitleFunc={(entityData) => (entityData && entityData.client.ShortName) || 'не заполнены'}
        layoutProps={props.layoutProps}
        editableFunc={({entityData}) => entityData 
            ? entityData.client.id === env.userClientId
            : true}
        childrenFunc={({regime}) => {return isOwn || regime !== 'view'
            ? null
            : isPartner
                ? "Ваш партнер"
                : <Link to={`/PartnershipRequest/0/create?PartnerId=${id}`}><Button>Создать заявку на партнерство</Button></Link>}
        }
    />
}

export default withRouter(PartnershipSetting);
