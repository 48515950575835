import { useState, useEffect } from "react";
var equal = require('deep-equal');

const getLocalStorageParOrInitial = ({ code, deserialize, initinalState }) => {
    const param = localStorage.getItem(code);
    try {
        const result = param === null || param === undefined
            ? initinalState
            : deserialize(param);
        return result
    } catch {
        return initinalState;
    }
}

export function useLocalStorageState(initinalState, { code, exact, serialize = JSON.stringify, deserialize = JSON.parse}) {
    const [state, setState] = useState(getLocalStorageParOrInitial({ initinalState, code, deserialize }));

    useEffect(() => {
        const par = getLocalStorageParOrInitial({ code, deserialize, initinalState });
        if (!equal(par, state)) {
            if (state == null || state == undefined) {
                localStorage.removeItem(code)
            } else {
                localStorage.setItem(code, serialize(state));
            }
        }
    }, [state])

    return [state, setState];
}

export const getLocalStorageKey = (prefix) => `_ps_${window.location.pathname.endsWith('/') ? window.location.pathname : `${window.location.pathname}/`}${prefix ? `_${prefix}` : ''}`