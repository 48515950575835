import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tag } from 'antd';
import './style.css';
import { useHistory } from 'react-router-dom';
import { toDefaultString } from '../_common/tables/date';


const ProjectSimpleCard = ({ project, editable }) => {
    const history = useHistory();
    return <div className='card'>
        <Link to={`/Project/${project.id}`}>
            <div className='card-header'></div>
            <div className='card-name'><Typography.Paragraph ellipsis={{ rows: 4, expandable: true, onExpand: (e) => {e.stopPropagation(); e.preventDefault()} }}>{project.name}</Typography.Paragraph></div>
            <div className='card-description'><Typography.Paragraph ellipsis={{ rows: 8, expandable: true, onExpand: (e) => {e.stopPropagation(); e.preventDefault()} }}>{project.description}</Typography.Paragraph></div>
            <div className='card-info'>
                <Tag color='purple'className='custom-tag'>Вакансий: {project.availableQuantity} из {project.quantity}</Tag>
                {project.startDate && <Tag color='volcano'className='custom-tag'>Старт: {toDefaultString(project.startDate)}</Tag>}
                <Tag color='blue'className='custom-tag'>Состояние: {project.state.name}</Tag>
                <Tag color='green'className='custom-tag'>Заказчик: {project.customer.shortName}</Tag>
                <Tag color='lime'className='custom-tag'>Исполнитель: {project.executor.shortName}</Tag>
            </div>
        </Link>
    </div>
}

export default ProjectSimpleCard;
