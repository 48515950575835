import moment from 'moment';

export const DATEFORMAT = 'DD.MM.YYYY'; 
export const DATETIMEFORMAT = 'DD.MM.YYYY HH:mm'; 

export function toDefaultString(date, format) {
    return moment(date).format(format || DATEFORMAT);
};

export function fromODataString(str) {
    // 2022-07-14T20:46:25.214867+03:00
    const date = new Date(str.substring(0, 4), str.substring(5, 7) - 1, str.substring(8, 10), str.substring(11, 13), str.substring(14, 16), str.substring(17, 19))
    return date;
}

export function toODataString(date) {
    // 2022-07-14T20:46:25.214867+03:00
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return `${date.getFullYear()}-${(mm > 9 ? '' : '0')}${mm}-${(dd > 9 ? '' : '0')}${dd}T00:00:00Z`;
}

export function toDate(date) {
    return isDate(date) 
        ? date 
        : date instanceof moment 
            ? date.toDate() 
            : moment(date).toDate();
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function isDate(date) {
    return date instanceof Date && !isNaN(date);
}