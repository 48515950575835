import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";

const metadata = {
    entity: 'clientSize',
    key: 'id',
    properties: [
        {
            title: 'Код',
            dataIndex: 'code',
            width: 150,
            editable: true
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true
        }
    ],
    default: {
        //required: true,
        //editable: true,
        //sortable: true
    }
};

const ClientSize = (props) => {
    const [tableApi, setTableApi] = useState();

    return <MainContent layoutProps={props.layoutProps}
        title="Размеры организации"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
            //{ label: 'Редактировать в карточке', key: "editInCard", onClick: () => setFormVisible(true) },
            ]}
    >
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
        />
    </MainContent>
}

export default withRouter(ClientSize);
