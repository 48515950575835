import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
var equal = require('deep-equal');

const getLocationParOrInitial = ({ location, code, deserialize, initialState }) => {
    const params = new URLSearchParams(location.search);
    const param = params.get(code);
    const result = param === null || param === undefined
        ? initialState
        : deserialize(param);
    return result;
}

export function useLocationState(initialState, { code = 'state', serialize = JSON.stringify, deserialize = JSON.parse }) {
    const history = useHistory();
    const [state, setState] = useState(getLocationParOrInitial({ location: history.location, code, deserialize, initialState }));;

    useEffect(() => {
        const searchPar = getLocationParOrInitial({ location: history.location, code, deserialize, initialState });
        if (!equal(searchPar, state)) {
            setState(searchPar);
        }
    }, [history, history.location]);

    useEffect(() => {
        const searchPar = getLocationParOrInitial({ location: history.location, code, deserialize, initialState });
        if (!equal(searchPar, state)) {
            const params = new URLSearchParams(history.location.search);
            if (state == null || state == undefined || equal(state, initialState)) {
                params.delete(code);
            } else {
                params.set(code, serialize(state));
            }
            history.push(`${history.location.pathname.endsWith('/') ? history.location.pathname : `${history.location.pathname}/`}?${params.toString()}`);
        }
    }, [state]);

    return [state, setState];
}