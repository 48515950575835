import { message, Upload } from 'antd';
import React, { useState } from 'react';
const { Dragger } = Upload;

const FieldFile = ({ value, onChange, disabled, style = {}, multiple, maxCount, action = `${window.APPCFG.apiPath}/File/Upload`, fileFieldName = 'File', ...rest }) => {
    const [fileList, setFileList] = useState(value);
    const change = (info) => {
        const { status } = info.file;
        if (status === 'done') {
            message.success(`Файл ${info.file.name} успешно загружен`);
            info.file.uid = info.file.response.id;
            info.file.url = info.file.response.url;
            onChange(info.fileList.map(file => ({
                uid: file.uid,
                url: file.url,
                size: file.size,
                name: file.name
            })));
        } else if (status === 'error') {
            message.error(`Ошибка загрузки файла ${info.file.name}`);
        }
        setFileList(info.fileList);
    };
    const remove = (file) => {
        const index = fileList.indexOf(file);
        if (index > -1) {
            fileList.splice(index, 1);
        }
        onChange([...fileList]);
    }
    return <React.Fragment>
        <Dragger 
            style={{ ...style, width: '100%' }}
            name={fileFieldName}
            multiple={multiple}
            maxCount={!multiple ? 1 : maxCount}
            action={action}
            fileList={fileList}
            onChange={change}
            onRemove={remove}
            disabled={disabled}
        >
            Перетащите или выберите файл
        </Dragger>
    </React.Fragment>
}

export default FieldFile;