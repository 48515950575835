import React from "react";
import { Button, Row, Col, Select } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, CloseOutlined, SearchOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import './style.css';

const Sorters = ({ metadata, value, onChange, onSort, onReset }) => {
    const onChangeInternal = (key, order) => {
        let v = value.filter(_ => _.key === key)[0];
        let index = value.indexOf(v);
        if (!order) value.splice(index, 1)
        else v.order = order;
        onChange([...value]);
    }

    const resetSorters = () => {
        if (onReset) onReset();
        onChange(null);
    }

    const add = (key) => {
        if (!value) value = [];
        value.push({ key, order: 'ascend' });
        onChange([...value]);
    }

    const up = (key) => {
        let v = value.filter(_ => _.key === key)[0];
        let index = value.indexOf(v);
        if (index <= 0) return;
        value.splice(index, 1);
        value.splice(index - 1, 0, v);
        onChange([...value]);
    }

    const down = (key) => {
        let v = value.filter(_ => _.key === key)[0];
        let index = value.indexOf(v);
        if (index >= value.length - 1) return;
        value.splice(index, 1);
        value.splice(index + 1, 0, v);
        onChange([...value]);
    }

    const remove = (key) => {
        let v = value.filter(_ => _.key === key)[0];
        let index = value.indexOf(v);
        value.splice(index, 1);
        onChange([...value]);
    }

    const getOrder = (key) => {
        return !value
            ? null
            : (value.filter(_ => _.key === key)[0] || {}).order;
    }

    const getOrderIcon = (key) => {
        const order = getOrder(key);
        return !order
            ? <CloseOutlined onClick={() => onChangeInternal(key, 'ascend')} title="Нет сортировки, нажмите, чтобы сортировать по возрастанию" />
            : order === 'ascend'
                ? <CaretUpOutlined onClick={() => onChangeInternal(key, 'descend')} title="Сортировка по возрастанию, нажмите, чтобы сортировать по убыванию" />
                : <CaretDownOutlined onClick={() => onChangeInternal(key, null)} title="Сортировка по убыванию, нажмите, чтобы отменить сортировку" />
    }

    const renderSorters = () => {
        return !value || !Array.isArray(value)
            ? null
            : value.map(s => ({ ...s, property: metadata.map[s.key] }))
                .map((s, idx) => <div key={idx}>
                    <Row><Col flex="auto">{s.property.title}</Col><Col flex="none">
                        <span className='protonus-sort'>{getOrderIcon(s.property.key)}</span>
                        <ArrowUpOutlined title="Поднять выше" onClick={() => up(s.property.key)} className='protonus-up' />
                        <ArrowDownOutlined title="Опустить ниже" onClick={() => down(s.property.key)} className='protonus-down' />
                        <DeleteOutlined title="Убрать из списка" onClick={() => remove(s.property.key)} className='protonus-remove' />
                    </Col></Row>
                </div>)
    }

    const propertiesToAdd = metadata.dataProperties.filter(_ => !value || !value.some(s => s.key === _.key));

    return <div className='protonus-params'>
        {renderSorters()}
        <Row style={{ marginTop: 10 }} wrap={false}>
            <div className='protonus-add'>Добавьте сортировку<div style={{ paddingLeft: 18 }}>
                <Select size="small" style={{ width: 284 }} onChange={add} options={propertiesToAdd.map(_ => ({ label: _.title, value: _.key }))} />
            </div></div>
        </Row>
        {(onSort || onReset) && <Row style={{ marginTop: 10 }} wrap={false}>
            <Col span={12} align="left">
                {onSort && <Button className="b" type="primary" onClick={(e) => { onChange([...value]); if (onSort) onSort(); }} onKeyDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onChange([...value]);
                    if (onSort) onSort();
                }} icon={<SearchOutlined />} size="small">
                    Сортировать
                </Button>}
            </Col>
            <Col span={12} align="right" style={{ paddingLeft: 30 }}>
                {onReset && <Button onClick={resetSorters} size="small">
                    Очистить
                </Button>}
            </Col>
        </Row>}
    </div>
}

export default Sorters;