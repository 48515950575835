import cloneDeep from 'clone-deep';
import React, { useState, useMemo } from 'react';
import { withRouter, Link } from "react-router-dom"
import MainContent from '../../_common/layout/MainContent';
import Table from '../../_common/tables/TableWithCard';
import {addTo, getInitialRecord} from '../projectgradecampaigns/GradeCompanySettings';
import { useMetadata } from '../../_common/hooks/useMetadata';
import { addTo as addStatsTo } from '../projectgradecampaigns/GradeCompanyStats';

export const meta = {
    entity: 'globalGradeCampaign',
    key: 'id',
    properties: [
        {
            title: 'Дата начала',
            dataIndex: 'startDate',
            width: 150,
            type: 'date',
            editable: true,
            required: true,
            defaultSortOrder: 'descend',
            render: (text, record, index, {renderedText}) => <Link to={`/GlobalGradeCampaign/${record.id}`}>{renderedText}</Link>
        },
        {
            title: 'Дата окончания',
            dataIndex: 'finishDate',
            width: 150,
            type: 'date',
            editable: true,
            required: true,
            responsive: ["sm"]
        },
        {
            title: 'Название',
            dataIndex: 'name',
            description: 'Если не задано, то будет равно дате начала',
            width: 150,
            editable: true,
            //render: (text, record) => <Link to={`/GlobalGradeCampaign/${record.id}`}>{text}</Link>
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'gradeCompanyState',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            editable: false,
            responsive: ["sm"],
            render: (text, record, index, {renderedText}) => {
                return record.state.code === 'NOTACTIVE'
                    ? <div style={{ color: 'gray' }}>{renderedText}</div>
                    : record.state.code === 'OPEN'
                        ? <div style={{ color: 'green' }}>{renderedText}</div>
                        : <div style={{ color: 'black' }}>{renderedText}</div>
            },
        },
        {
            title: 'Контекст',
            dataIndex: 'context',
            width: 150,
            type: 'entity',
            entityName: 'gradeCampaignContext',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            editable: {
                updating: false
            },
            responsive: ["sm"]
        },
        {
            title: 'Мероприятие',
            dataIndex: 'event',
            width: 150,
            type: 'entity',
            entityName: 'event',
            entityKey: 'id',
            entityLabel: 'name',
            editable: {
                updating: false
            },
            responsive: ["sm"]
        }
    ]
}

const prepareMetadata = (metadata) => {
    let index = metadata.properties.indexOf(metadata.properties.filter(_ => _.dataIndex === 'state')[0]);
    let ins = metadata.properties.filter(_ => _.title === 'Оценка')[0];
    let insIndex = metadata.properties.indexOf(ins);
    metadata.properties.splice(insIndex, 1);
    metadata.properties.splice(index + 1, 0, ins);
    return metadata;
}

export const metadata = prepareMetadata(addStatsTo({metadata: addTo({metadata: meta, clone: true}), clone: false, global: true}));

const GlobalGradeCampaigns = (props) => {
    const [tableApi, setTableApi] = useState();

    return <MainContent layoutProps={props.layoutProps}
        title="Глобальные оценочные кампании"
        onClickRefresh={tableApi && tableApi.refresh}
    >
        {metadata && <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={'id'}
            onInit={setTableApi}
            getDefaultData={() => {
                return {...getInitialRecord(), context: {code: 'COMMON'}};
            }}
        />}
    </MainContent>;
}

export default withRouter(GlobalGradeCampaigns);