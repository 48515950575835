export function treeConvert(values, idKey = 'id', parentIdKey = 'parentId', childrenKey = 'children', sortKey = 'displayOrd') {
    let all = {};
    let root = [];
    for (let v of values) {
        all[v[idKey]] = v;
    }
    for (let v of values) {
        let p = !v[parentIdKey] ? null : all[v[parentIdKey]];
        if (!p) root.push(v);
        else {
            if (!p[childrenKey]) p[childrenKey] = []
            p[childrenKey].push(v);
        }
    }
    return treeSort(root, childrenKey, sortKey);
}

export function treeSort(nodes, childrenKey = 'children', sortKey = 'displayOrd')
{
    if (!nodes || nodes.length === 0) return nodes;
    for (let n of nodes) {
        if (n[childrenKey] && n[childrenKey].length > 0) {
            n[childrenKey] = treeConvert(n[childrenKey], childrenKey, sortKey);
        }
    }
    return nodes.sort((a, b) => a[sortKey] - b[sortKey])
}