import React, { Component } from 'react';
import { post, get } from '../_common/utils/fetch';
import { Checkbox, message, Button } from 'antd';
import { Form } from 'antd';

export default class ClientAgreements extends Component {
    state = {
        agreements: {}
    };

    componentDidMount() {
        this.getAgreements();
    }

    getAgreements = () => {
        get({
            url: `${window.APPCFG.apiPath}/Client/Agreements`,
            callback: (data) => {
                let receivedAgreements = {};
                data.forEach(d => receivedAgreements[d.id] = d);
                this.setState({agreements: receivedAgreements});
            }
        });
    }

    createAgreementLogs = () => {
        post({
            url: `${window.APPCFG.apiPath}/Client/Agreements`,
            json: Object.values(this.state.agreements).map(a => {return {agreementId: a.id, isSigned: a.isSigned}}),
            callback: () => {
                message.success(`Данные сохранены`);
            }
        });
    }

    render() {
        return <React.Fragment>
            <Form>
                {
                    Object.values(this.state.agreements).map(a => 
                        <Form.Item
                            style={{
                                margin: 0,
                            }}>
                            <Checkbox checked={this.state.agreements[a.id].isSigned} onChange={(e) => {
                                this.state.agreements[a.id].isSigned = e.target.checked;
                                this.forceUpdate();
                                }}>Я согласен с <a target="_blank" href={a.url}>{a.name}</a></Checkbox>
                        </Form.Item>
                    )
                }
                <Form.Item>
                    <Button onClick={() => this.createAgreementLogs()}>Сохранить</Button>
                </Form.Item>
            </Form>
        </React.Fragment>
    }
}