import React, { PureComponent } from 'react';
import { Modal } from 'antd';
import SystemInfoPanel from './SystemInfoPanel'

document.addEventListener('keydown', (e) => {
    // CTRL+ALT+I
    if (showSystemInfo && e.altKey && e.ctrlKey && e.keyCode === 73) showSystemInfo();
}, true);

export var showSystemInfo = null;

export default class SystemInfoDialog extends PureComponent {
    constructor(props) {
        super(props);
        showSystemInfo = () => this.showSystemInfo();
        this.state = {
            open: false
        }
    }

    showSystemInfo = () => {
        this.setState({ open: true });
    }

    render() {
        return !this.state.open ? null :
            (
                <Modal
                    title={'Информация о системе'}
                    footer={false}
                    open={this.state.open}
                    onCancel={() => this.setState({ open: false, info: null })}
                    tabindex="-1"
                    onKeyUp={(event) => { if (event.key === 'Escape') this.setState({ open: false, info: null }); }}
                >
                    <SystemInfoPanel />
                </Modal>
            );
    }
}