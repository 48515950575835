import { useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import useDataProvider from '../dataProviders/useDataProvider';
import { setStateToStorage, getStateFromStorage } from "./tableStateUtils";
var equal = require('deep-equal');

export function useList(props) {
    const [refreshInternal, forceRefreshInternal] = useReducer(x => x + 1, 0);
    const history = useHistory();
    const dataProvider = useDataProvider(props);
    const { metadata, refresh, initial = {} } = props || {};
    const [state, setState] = useState({});

    useEffect(() => {
        if (!metadata) return;
        let stateFromStorage = getStateFromStorage(metadata, {}, history);
        let currentState = {
            filters: state.filters,
            sorters: state.sorters
        };
        if (equal(stateFromStorage, currentState)) return;
        setState({
            current: initial.current || 1,
            pageSize: initial.pageSize || 20,
            ...stateFromStorage
        });
    }, [history, history.location, metadata])

    useEffect(() => {
        if (!state.pageSize) return;
        setState(state => { state.loading = true; return { ...state }; });
        if (refresh) {
            dataProvider.getList({
                isGlobalProcessing: true,
                metadata: metadata,
                sorters: state.sorters,
                filters: state.filters,
                extFilter: undefined,
                afterGetPath: undefined,
                pageSize: state.pageSize,
                currentPage: state.current,
                count: !Boolean(state.total),
                total: state.total,
                callback: (data, { total }) => {
                    setState(state => {
                        state.loading = false;
                        state.dataSource = data;
                        state.total = total;
                        return { ...state };
                    })
                },
                errorCallback: () => {
                    setState(state => {
                        state.loading = false;
                        state.dataSource = null;
                        state.total = null;
                        return { ...state };
                    })
                },
                asyncInternalCallback: undefined,
                path: undefined
            })
        }
    }, [refresh, refreshInternal, state.filters, state.sorters, state.current, state.pageSize])

    useEffect(() => {
        if (!state.pageSize) return;
        setStateToStorage(metadata, {}, state, history);
    }, [state.filters, state.sorters, state.current, state.pageSize]);

    const applyLocal = ({ record, newRecord, callback }) => {
        setState(state => {
            if (state.editingKey === 0) {
                if (!state.nextNewKey) state.nextNewKey = -1;
                newRecord[metadata.key] = state.nextNewKey--;
                state.dataSource = [newRecord, ...state.dataSource.slice(1)];
            } else {
                state.dataSource = state.dataSource.map(r => r[metadata.key] === state.editingKey ? newRecord : r);
            }
            state.editingKey = null;
            return { ...state }
        });
        if (callback) callback();
    }

    const applyRemote = ({ record, newRecord, callback }) => {
        setState(state => {
            if (state.editingKey === 0) {
                state.dataSource = state.dataSource.slice(1);
            }
            state.editingKey = null;
            return { ...state }
        });
        let editingKey = record[metadata.key];
        let creating = editingKey <= 0;
        if (creating) editingKey = undefined;
        dataProvider.changeData({
            metadata: metadata,
            value: newRecord,
            creating: creating,
            editingKey: editingKey,
            onSave: () => {
                forceRefreshInternal();
                if (callback) callback();
            },
        })
    }

    const deleteLocal = ({ keys, callback }) => {
        setState(state => {
            state.dataSource = state.dataSource.filter(r => keys.indexOf(r[metadata.key]) < 0);
            return { ...state }
        });
        if (callback) callback();
    }

    const deleteRemote = ({ keys, callback }) => {
        dataProvider.deleteData({
            keys: keys,
            onDelete: () => {
                forceRefreshInternal();
                if (callback) callback();
            },
        })
    }

    return {
        state,
        setState,
        applyLocal,
        applyRemote,
        deleteLocal,
        deleteRemote
    };
}