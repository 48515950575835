import { useState, useEffect, useCallback } from "react";

const debounce = require('lodash.debounce');

const isReactState = (state) => {
    if (!state || !Array.isArray(state) || state.length !== 2) return false;
    if (typeof state[1] !== 'function') return false;
    return true;
}

export function useDebounceState(initialState, {wait} = {}) {
    const isReactStateInitial = isReactState(initialState);
    const internalState = useState(isReactStateInitial ? undefined : initialState);
    const [debounceState, setDebounceState] = isReactStateInitial ? initialState : internalState;
    const [state, setState] = useState(debounceState);

    // eslint-disable-next-line
    const debounceCallback = useCallback(debounce((state) => setDebounceState(state), wait ? wait : 500), []);

    useEffect(() => {
        debounceCallback(state);
    }, [state, debounceCallback]);

    useEffect(() => {
        setState(debounceState);
    }, [debounceState])

    return [state, setState, debounceState, setDebounceState];
}