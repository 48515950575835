import React from "react";
import { Button } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined, CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

export const getAction = (extAction, intAction) => {
    if (extAction !== undefined) {
        if (extAction === false) return null;
        if (extAction instanceof Function) {
            return {
                ...intAction,
                action: extAction
            }
        } else {
            return {
                ...intAction,
                ...extAction
            }
        }
    } else if (intAction) {
        return intAction;
    } else {
        return null;
    }
}

export const getRowActions = ({editable, onEditExt, onEditInt, onCopyExt, onCopyInt, onDeleteExt, onDeleteInt, extActions}) => {
    let actions = [];
    if (editable) {
        let onEdit = getAction(onEditExt, {
            key: 'edit',
            title: 'Редактировать',
            icon: <EditOutlined />,
            action: onEditInt
        });
        if (onEdit) actions.push(onEdit);
        let onCopy = getAction(onCopyExt, {
            key: 'copy',
            title: 'Копировать',
            icon: <CopyOutlined />,
            action: onCopyInt
        });
        if (onCopy) actions.push(onCopy);
        let onDelete = getAction(onDeleteExt, {
            key: 'delete',
            title: 'Удалить',
            icon: <DeleteOutlined />,
            action: onDeleteInt
        });
        if (onDelete) actions.push(onDelete);
    }
    actions = [...actions, ...(extActions || [])];
    return actions;
}

const createButton = ({action, record, actionPropsGetter, idx}) => {
    const visible = action.visible === undefined || !record || (action.visible instanceof Function && action.visible({record}));
    const disabled = action.disabled === undefined || !record
        ? false
        : (action.disabled instanceof Function && action.disabled({record}));
    return visible && <Button key={action.key || idx} type='text' size='small' icon={action.icon} title={action.title} disabled={disabled} onClick={() => action.action((actionPropsGetter && actionPropsGetter()) || {record})} />
}

export const getActionsColumn = ({editingKey, form, editable, metadata, onEditExt, onEditInt, onCopyExt, onCopyInt, onDeleteExt, onDeleteInt, 
    onCreateExt, onCreateInt,
    onApplyExt, onApplyInt,
    onCancelInt,
    extActions}) => {
    const rowActions = getRowActions({editable, onEditExt, onEditInt, onCopyExt, onCopyInt, onDeleteExt, onDeleteInt, extActions});
    const onCreate = editable && getAction(onCreateExt, {
        key: 'create',
        title: 'Создать',
        icon: <PlusOutlined />,
        action: onCreateInt
    });
    const onApply = editable && getAction(onApplyExt, {
        key: 'apply',
        title: 'Применить изменения',
        icon: <CheckOutlined />,
        action: onApplyInt
    });
    const onCancel = {
        key: 'cancel',
        title: 'Отменить изменения',
        icon: <CloseOutlined />,
        action: onCancelInt
    };
    const rowActionsCnt = rowActions.length;
    if (!onCreate && rowActionsCnt === 0) return null;
    const width = (rowActionsCnt === 0 ? 1 : rowActionsCnt) * 24 + 17;

    let titleActions = onCreate
        ? [createButton({action: onCreate})]
        : [];

    const column = {
        hideable: false,
        fixed: true,
        filterable: false,
        sortable: false,
        resizable: false,
        title: () => titleActions.length > 0 ? <center>{titleActions}</center> : null,
        width: width,
        render: (_, record) => {
            const editing = record[metadata.key] === editingKey;
            let actionButtons = editing
                ? [
                    createButton({action: onApply, actionPropsGetter: () => ({record, newRecord: form.getFieldsValue()})}),
                    createButton({action: onCancel})
                ]
                : rowActions.map((action, idx) => createButton({action, idx, record, actionPropsGetter: () => ({record})})).filter(_ => _);
            return <>{actionButtons}</>;
        },
    };
    return column;
}