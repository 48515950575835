import React, { useState } from 'react';
import { Input, Modal, Select, Row, Col } from 'antd';
import Table from '../_common/tables/TableOData';
import { useEnv } from '../_common/hooks/useEnv';
import MainContent from '../_common/layout/MainContent';
import meta from './meta';
import { getViewRootPath } from '../_common/hooks/useViewRoot';
import { useStoredState } from '../_common/hooks/useStoredState';
import EventCalendar from './EventCalendar';
import fileExport from '../_common/utils/fileExport';
import Actions from '../_common/layout/Actions';

const Events = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env, { hasRoles }] = useEnv();
    const [viewRegime, setViewRegime] = useStoredState(props.env.hasRoles('ADMIN') ? 'list' : 'calendar', { code: 'viewRegime' });
    const [selected, setSelected] = useState();

    const getExcel = () => {
        fileExport(`${window.APPCFG.apiPath}/Event/GetExcel${tableApi.lastUrlSearchParams}`);
    }

    const getExcelAggr = () => {
        fileExport(`${window.APPCFG.apiPath}/Event/GetExcelAggr${tableApi.lastUrlSearchParams}`);
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Мероприятия"
        onClickRefresh={tableApi && tableApi.refresh}
    >
        <Row style={{ paddingBottom: 5 }}>
            <Col>
                {env && viewRegime === 'list' && hasRoles('ADMIN') && <Actions items={[
                    hasRoles('ADMIN') && {
                        key: 'getExcelAggr',
                        label: 'Выгрузить в Excel',
                        onClick: () => getExcelAggr()
                    },
                    /*hasRoles('ADMIN') && {
                        key: 'getExcelAggr',
                        label: <a href={`${window.APPCFG.apiPath}/Event/GetICal?${(selected || []).map(s=>`ids=${s}`).join('&')}`}>Добавить выбранные в свой календарь</a>,
                        disabled: !selected || selected.length === 0
                    }*/
                ]}
                    style={{ paddingRight: 20 }}
                />}
            </Col>
            <Col>
                <Select value={viewRegime} onChange={setViewRegime}>
                    <Select.Option value="calendar">Календарь</Select.Option>
                    <Select.Option value="list">Таблица</Select.Option>
                </Select>
            </Col>
        </Row>
        {env && viewRegime === 'list' &&
            <React.Fragment>
                <Table
                    columns={meta.properties}
                    odataEntity={meta.entity}
                    rowKey={meta.key}
                    onInit={setTableApi}
                    history={props.history}
                    odataFilter={`clientId eq ${env.userClientId}`}
                    editable={hasRoles('ADMIN')}
                    onAdd={() => props.history.push(getViewRootPath({ mainRoot: 'Event', regime: 'create' }))}
                    onEdit={(record) => props.history.push(getViewRootPath({ mainRoot: 'Event', id: record[meta.key], regime: 'edit' }))}
                    onCopy={(record) => props.history.push(getViewRootPath({ mainRoot: 'Event', id: record[meta.key], regime: 'copy' }))}
                    rowSelection={hasRoles('ADMIN')
                    ? {
                        onChange: (newSelectedRowKeys, newSelectedRows) => setSelected(newSelectedRowKeys)
                    } : false}
                /></React.Fragment>}
        {env && viewRegime === 'calendar' && <EventCalendar />}
    </MainContent>
}

export default Events;
