import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import { post } from '../_common/utils/fetch';
import { message } from 'antd';
import FileDialog from '../_common/FileDialog';

const metadata = {
    entity: 'department2',
    key: 'id',
    properties: [
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true,
            required: true 
        },
        {
            title: 'Институт',
            dataIndex: 'institute',
            width: 150,
            type: 'entity',
            entityName: 'institute',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true
        },
        {
            title: 'Руководитель',
            dataIndex: 'head',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true
        },
        {
            title: 'Код',
            dataIndex: 'code',
            width: 150,
            editable: true
        }
    ]
};

const Department = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env, {}] = useEnv();
    const [uploadOpen, setUploadOpen] = useState(false);

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Department2/UploadExcel`,
            formData: {
                File: file
            },
            callback: () => {
                tableApi.refresh();
                message.success("Файл загружен");
            },
            errorCallback: () => {
                tableApi.refresh()
            }
        });
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Кафедры"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
            { label: 'Загрузить из Excel', key: 'uploadExcel', onClick: () => setUploadOpen(true) }
            ]}
    >
        {env && <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
            odataFilter={`clientId eq ${env.userClientId}`}
        />}
        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel} />
    </MainContent>
}

export default withRouter(Department);
