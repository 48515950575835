import React, { useState } from 'react';
import { Link } from "react-router-dom"
import Actions from '../../_common/layout/Actions';
import Table from '../../_common/tables/TableWithCard';
import { meta } from '../projectgradecampaigns/GradeCompany';
import ProjectsSelector from '../../projects/ProjectsSelector';
import { post } from '../../_common/utils/fetch';
import { message, Modal, Row, Col } from 'antd';
import Chart from '../../_common/utils/Chart';
import { red, yellow, green } from '../../selectioncompanies/colors';

const cloneDeep = require('clone-deep');

const ProjectGradeCampaignsTab = ({ globalGradeCampaign, forceUpdate, forceUpdateRequired, hasRoles }) => {
    const [tableApi, setTableApi] = useState();
    const [selectProjectDialogOpen, setSelectProjectDialogOpen] = useState(false);
    const [selected, setSelected] = useState();

    const metadata = {
        entity: 'gradeCompany',
        properties: [
            {
                title: '',
                dataIndex: 'id',
                width: 100,
                filterable: false,
                sortable: false,
                render: (text, record) => <Link to={`/Project/${record.project.id}/view/gradeCompany/?selected=${record.id}`}>Посмотреть</Link>,
            },
            {
                title: 'Проект',
                dataIndex: 'project',
                width: 150,
                type: 'entity',
                entityName: 'project',
                entityKey: 'id',
                entityLabel: 'name'
            },
            ...cloneDeep(meta).properties
        ]
    }

    const addProjects = (ids) => {
        setSelectProjectDialogOpen(false);
        if (!ids || ids.length === 0) return;
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/GlobalGradeCampaign/AddProjects`,
            json: {
                objectId: globalGradeCampaign.id,
                ids: ids
            },
            callback: () => {
                tableApi.refresh();
                forceUpdate();
                message.success("Кампании в проекты добавлены");
            }
        })
    }

    const removeProjects = (ids) => {
        setSelectProjectDialogOpen(false);
        if (!ids || ids.length === 0) return;
        Modal.confirm({
            title: `Вы действительно хотите удалить (${ids.length})?`,
            onOk: () => post({
                isGlobalProcessing: true,
                url: `${window.APPCFG.apiPath}/GlobalGradeCampaign/RemoveCampaigns`,
                json: {
                    objectId: globalGradeCampaign.id,
                    ids: ids
                },
                callback: () => {
                    tableApi.refresh();
                    forceUpdate();
                    message.success("Проекты удалены");
                }
            })
        });
    }

    return <React.Fragment>
        <Row style={{ padding: '10px 0px' }} wrap={true}>
            <Col>
                <Actions items={[
                    hasRoles('ADMIN') && {
                        key: 'addProjects',
                        label: 'Добавить кампании в проекты',
                        onClick: () => setSelectProjectDialogOpen(true)
                    },
                    hasRoles('ADMIN') && {
                        key: 'removeProjects',
                        disabled: !selected || selected.length === 0,
                        label: 'Удалить кампании из проектов',
                        onClick: () => removeProjects(selected)
                    }
                ]} />
            </Col>
            <Col style={{ textAlign: 'right' }} flex="auto">
                <div style={{ float: 'right', height: '100px' }}>
                    {true && <Chart
                        type='doughnut'
                        width={300}
                        height={100}
                        data={{
                            labels: [
                                `Не сделали (${globalGradeCampaign.stats.projectNoneQuantity})`,
                                `Частично (${globalGradeCampaign.stats.projectPartlyQuantity})`,
                                `Полностью (${globalGradeCampaign.stats.projectFullyQuantity})`
                            ],
                            datasets: [{
                                data: [globalGradeCampaign.stats.projectNoneQuantity, globalGradeCampaign.stats.projectPartlyQuantity, globalGradeCampaign.stats.projectFullyQuantity],
                                backgroundColor: [
                                    red,
                                    yellow,
                                    green
                                ],
                                hoverOffset: 4
                            }]
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    position: 'right',
                                    title: {
                                        display: true,
                                        text: `Проекты (всего: ${globalGradeCampaign.stats.projectQuantity})`
                                    }
                                }
                            },
                        }} />}
                </div>
            </Col>
            <Col style={{ textAlign: 'right' }} flex="auto">
                <div style={{ float: 'right', height: '100px' }}>
                    {true && <Chart
                        type='doughnut'
                        width={300}
                        height={100}
                        data={{
                            labels: [
                                `Не сделали (${globalGradeCampaign.stats.memberSetNoneQuantity})`,
                                `Частично (${globalGradeCampaign.stats.memberSetPartlyQuantity})`,
                                `Полностью (${globalGradeCampaign.stats.memberSetFullyQuantity})`
                            ],
                            datasets: [{
                                data: [globalGradeCampaign.stats.memberSetNoneQuantity, globalGradeCampaign.stats.memberSetPartlyQuantity, globalGradeCampaign.stats.memberSetFullyQuantity],
                                backgroundColor: [
                                    red,
                                    yellow,
                                    green
                                ],
                                hoverOffset: 4
                            }]
                        }}
                        options={{
                            plugins: {
                                legend: {
                                    position: 'right',
                                    title: {
                                        display: true,
                                        text: `Участники (всего: ${globalGradeCampaign.stats.memberSetQuantity})`
                                    }
                                }
                            },
                        }} />}
                </div>
            </Col>
        </Row>
        <Row>
            <Col>
                <Table
                    columns={metadata.properties}
                    odataEntity={metadata.entity}
                    rowKey={'id'}
                    onInit={setTableApi}
                    editable={false}
                    odataFilter={`globalGradeCampaignId eq ${globalGradeCampaign.id}`}
                    rowSelection={hasRoles('ADMIN')
                        ? {
                            onChange: (newSelectedRowKeys, newSelectedRows) => setSelected(newSelectedRowKeys)
                        } : false}
                />
            </Col>
        </Row>
        {selectProjectDialogOpen && <ProjectsSelector
            open={selectProjectDialogOpen}
            onCancel={() => setSelectProjectDialogOpen(false)}
            onOk={({ selectedIds }) => addProjects(selectedIds)}
            onlyChecked={true}
        />}
    </React.Fragment>;
}

export default ProjectGradeCampaignsTab;