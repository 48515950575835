import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { Link, withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import Actions from '../_common/layout/Actions';
import { message, Modal } from 'antd';
import { post } from '../_common/utils/fetch';
import { systemInfo } from '../_common/utils/systemInfo';
import moment from 'moment';
const { confirm } = Modal;

const renderText = (record) => {
    const text = record.url 
        ? record.url.startsWith('http')
            ? <a href={record.url} target="_blank" rel="noopener noreferrer">{record.text}</a>
            : <Link to={record.url}>{record.text}</Link> 
        : record.text
    return <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>
}

const metadata = {
    entity: 'notification',
    key: 'id',
    properties: [
        {
            title: 'Тип',
            dataIndex: 'notificationTemplate',
            width: 150,
            type: 'entity',
            entityName: 'notificationTemplate',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            responsive: ["sm"]
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'notificationState',
            entityKey: 'code',
            entityLabel: 'name',
            required: true,
            loadAll: true,
            defaultFilteredValue: [
                { value: { ids: [{ code: 'NOTREAD' }] } }
            ],
            responsive: ["sm"]
        },
        {
            title: 'Дата уведомления',
            dataIndex: 'createDate',
            type: 'date',
            format: 'DD.MM.YYYY HH:mm:ss',
            defaultSortOrder: 'descend',
            responsive: ["sm"]
        },
        {
            title: 'Дата пометки о прочтении',
            dataIndex: 'readDate',
            type: 'date',
            format: 'DD.MM.YYYY HH:mm:ss',
            responsive: ["sm"]
        },
        {
            title: 'Текст',
            sortable: false,
            filterable: false,
            ellipsis: false,
            width: 300,
            dataIndex: 'text',
            link: ({ record, text }) => renderText(record),
            responsive: ["sm"]
        },
        {
            title: 'url',
            dataIndex: 'url',
            showInTable: false,
        },
        {
            title: 'Текст',
            sortable: false,
            filterable: false,
            ellipsis: false,
            width: 300,
            link: ({ record }) => <React.Fragment>
                Дата: {moment(record.createDate).format('DD.MM.YYYY HH:mm:ss')}<br />
                Тип: {record.notificationTemplate.name}<br />
                {renderText(record)}
            </React.Fragment>,
            responsive: ["xs"]
        },
    ]
};

const Notifications = (props) => {
    const [tableApi, setTableApi] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState();
    const [env] = useEnv();

    const changeStateToRead = () => {
        if (!selectedRowKeys || selectedRowKeys.length === 0) {
            message.warning("Выберите уведомления, чтобы пометить их как прочитанные");
        }
        confirm({
            title: `Вы действительно хотите пометить как прочитанные ${selectedRowKeys.length} уведомлений?`,
            onOk: () => {
                post({
                    url: `${window.APPCFG.apiPath}/Notification/ChangeStateToRead`,
                    json: {
                        Ids: selectedRowKeys
                    },
                    callback: (updated) => {
                        systemInfo.updateNotifications();
                        tableApi.refresh();
                        message.success(`Уведомления успешно помечены как прочитанные (${updated})`);
                    }
                });
            }
        });
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Уведомления"
        onClickRefresh={tableApi && tableApi.refresh}
    >
        <Actions items={[
            { label: 'Пометить выбранные как прочтенные', onClick: changeStateToRead },
        ]} />
        {env && <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
            editable={false}
            rowSelection={{
                onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys)
            }}
        />}
    </MainContent>
}

export default withRouter(Notifications);

