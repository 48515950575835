
export default class AntdTableStateProvider {
    constructor(props) {
        this.props = props;
        const { api } = props;
        this.protonStateApi = null;
        this.api = api;
        this.api.onChange = this.api.onChange
            ? (newPagination, filters, sorter) => {this.onStateChanged(); this.api.onChange(newPagination, filters, sorter)}
            : this.onStateChanged;
        this.api.onColumnChange = this.api.onColumnChange
            ? (props) => {this.onStateChanged(); this.api.onColumnChange(props)}
            : this.onStateChanged;
    }
    getFilterDefs = () => {
        return this.api.allColumns
            .map(column => {
                return {
                    provider: this,
                    name: column.key
                }
            });
    }
    getState = () => {
        const sort = !this.api.state.sorter 
            ? null 
            : Array.isArray(this.api.state.sorter)
                ? this.api.state.sorter
                : [this.api.state.sorter];
        return {
            filters: this.api.state.filters,
            sort: !sort 
                ? [] 
                : sort.filter(_ => _.order).map(_ => ({colId: _.columnKey, sort: _.order})),
            columnState: this.api.allColumns,
            //columnGroupState: this.columnApi.getColumnGroupState(),
            //openedToolPanel: this.api.getOpenedToolPanel()
        };
    }
    onStateChanged = (event) => {
        const state = this.getState();
        this.protonStateApi.changeState({
            stateProvider: this,
            ...state
        });
    }
    onCellClicked = (event) => {
        if (!event.event.altKey) return;
        let value = event.value;
        let colId = event.column.colId;
        let filterInstance = this.getFilterInstance(colId);
        if (!filterInstance) return;
        filterInstance.setModel({
            filterType: 'text',
            type: 'equals',
            filter: value
        });
        filterInstance.onFilterChanged();
    }
    getFilterInstance = (colId) => {
        const column = this.api.columnController.gridColumns.filter(c => c.colId === colId)[0];
        if (!column || !column.colDef || !column.colDef.filter) return null;
        let filterInstance = this.api.getFilterInstance(colId);
        if (!filterInstance || !filterInstance.providedFilterParams.colDef.filter) return null;
        return filterInstance;
    }
    changeState = (props) => {
        let { sort, filters, state } = props;
        let newState = null;
        let currentState = this.api.state;
        // TODO все переделать
        if (props.stateProvider && props.stateProvider.protonStateApi.storeProviders.length !== 1) {
            if (filters) {
                let s = {}
                for (let name in filters) {
                    if (!this.api.columnsMap[name]) continue;
                    if (filters[name]) s[name] = filters[name];
                }
                newState = {...(newState || {}), filters: {...(currentState.filters || {}), ...s}}
                //newState = {...(newState || {}), filters: s}
            }
            if (sort) {
                let ss = []
                let i = 0
                for (let s of sort)
                {
                    if (!this.api.columnsMap[s.colId]) continue;
                    if (ss.filter(_ => _.columnKey === s.colId)[0]) continue;
                    ss.push({columnKey: s.colId, order: s.sort})
                    this.api.columnsMap[s.colId].sorter.multiple = ++i;
                }
                newState = {...(newState || {}), sorter: [...(!currentState.sorter ? [] : Array.isArray(currentState.sorter) ? currentState.sorter : [currentState.sorter]), ...ss || []]}
            };
        }
        /*if (state) {
            this.setState({state: state});
        }*/
        if (state.columnState) {
            for (let col of state.columnState) {
                if (!this.api.columnsMap[col.key]) continue;
                this.api.columnsMap[col.key].width = col.width;
                this.api.columnsMap[col.key].index = col.index;
                this.api.columnsMap[col.key].hidden = col.hidden;
                this.api.columnsMap[col.key].fixed = col.fixed;
            }
        }
        //if (newState) 
        this.api.setState(newState, this.api.refresh);
    }
    getKeyValue = (value, entityKeyType) => {
        return entityKeyType === 'string' || isNaN(value)
            ? value
            : parseInt(value)
    }
    serialize = ({filterDef, value}) => {
        let v = value[0];
        const property = this.api.allColumns.filter(_ => _.key === filterDef.name)[0];
        if (property && property.type === 'entity') {
            let arr = [];
            if (v.empty) arr.push('%%%%');
            if (v.value && v.value.tags && v.value.tags.length > 0) arr = [...arr, ...v.value.tags.map(_ => `%${_}`)];
            if (v.value && v.value.ids && v.value.ids.length > 0) arr = [...arr, ...v.value.ids.map(_ => `${_[property.entityKey]}`)];
            return JSON.stringify(arr)
        }
        return v.empty
            ? JSON.stringify(v)
            : JSON.stringify(v.value.length === 1 ? v.value[0] : v.value);
    }
    deserialize = ({filterDef, value}) => {
        let v = JSON.parse(value);
        const property = this.api.allColumns.filter(_ => _.key === filterDef.name)[0];
        if (property && property.type === 'entity' && v && Array.isArray(v)) {
            let obj = {
                empty: undefined,
                value: {
                    ids: undefined,
                    tags: undefined
                }
            };
            if (v.indexOf('%%%%') >= 0) obj.empty = true;
            obj.value.tags = v.filter(_ => _ !== '%%%%' && _[0] === '%').map(_ => _.substring(1));
            obj.value.ids = v.filter(_ => _ !== '%%%%' && _[0] !== '%').map(_ => ({[property.entityKey]: this.getKeyValue(_, property.entityKeyType)}))
            return [obj]
        }
        return v.empty
            ? [v]
            : [{value: v}]
    }

    setState = (props) => {
        /*const { state } = props;
        if (state.columnState) {
            this.columnApi.setColumnState(state.columnState);
        }
        if (state.columnGroupState) {
            this.columnApi.setColumnGroupState(state.columnGroupState);
        }
        if (state.filterModel) {
            this.api.setFilterModel(state.filterModel);
        }
        if (state.openedToolPanel) {
            this.api.openToolPanel(state.openedToolPanel);
        } else {
            this.api.closeToolPanel();
        }*/

        //var filtersToolPanel = this.api.getToolPanelInstance('filters');
        //filtersToolPanel.expandFilters();
    }
}