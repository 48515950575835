import React from 'react';
import Table from '../_common/tables/TableOData';
import { withRouter } from "react-router-dom";
import Actions from '../_common/layout/Actions';
import { post } from '../_common/utils/fetch';
import { message } from 'antd';
import { useEnv } from '../_common/hooks/useEnv';

const metadata = {
    entity: 'ProjectApprovalStep',
    key: 'id',
    properties: [
        {
            title: 'Номер',
            dataIndex: 'ord',
            width: 100,
            type: 'number'
        },
        {
            title: 'Название',
            dataIndex: 'name',
            width: 150
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'projectApprovalStepState',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true
        },
        {
            title: 'Согласовано',
            dataIndex: 'acceptedCnt',
            width: 150,
            type: 'number',
            render: (text, record, index) => `${text}/${record.acceptedCntSetting === 0 ? (record.memberCnt !== null ? record.memberCnt : 'Все') : record.acceptedCntSetting}`,
            extSelect: ['acceptedCntSetting', 'memberCnt']
        },
        {
            title: 'Отклонено',
            dataIndex: 'rejectedCnt',
            width: 150,
            type: 'number',
            render: (text, record, index) => `${text}${record.rejectedCntSetting === 0 ? (record.memberCnt !== null ? `/${record.memberCnt}` : '/Все') : (!record.rejectedCntSetting ? '' : `/${record.rejectedCntSetting}`)}`,
            extSelect: ['rejectedCntSetting', 'memberCnt']
        },
        {
            title: 'Плановая дата согласования',
            dataIndex: 'planFinishDate',
            width: 150,
            type: 'date'
        },
        {
            title: 'Фактическая дата согласования',
            dataIndex: 'factFinishDate',
            width: 150,
            type: 'date'
        },
        {
            title: 'Комментарии',
            dataIndex: 'memo',
            width: 200,
            type: 'text'
        },
    ]
};

const ProjectApprovalStep = ({ onInit, id, history, project, refresh, userPermissions }) => {
    const [env, {hasRoles}] = useEnv();

    if (!project) return null;

    const toReadyForAgreed = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/ToReadyForAgreed`,
            json: {
                ids
            },
            callback: () => {
                message.success("Проект отправлен на согласование");
                refresh();
            }
        })
    }

    const stepToAgreed = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/StepToAgreed`,
            json: {
                ids
            },
            callback: () => {
                message.success("Шаг взят на согласование");
                refresh();
            }
        })
    }

    const stepToAccepted = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/StepToAccepted`,
            json: {
                ids
            },
            callback: () => {
                message.success("Шаг согласован");
                refresh();
            }
        })
    }

    const stepToRejected = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/StepToRejected`,
            json: {
                ids
            },
            callback: () => {
                message.success("Шаг отклонен");
                refresh();
            }
        })
    }

    const stepToReadyForAgreed = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/Project/StepToReadyForAgreed`,
            json: {
                ids
            },
            callback: () => {
                message.success("Состояние изменено");
                refresh();
            }
        })
    }

    let actions = []
    if (project.state.code === 'DRAFT') actions = [...actions, { label: 'Начать согласование проекта', key: "toReadyForAgreed", onClick: () => toReadyForAgreed({ ids: [id] }) }];
    if (userPermissions.approvalStepsIsResponsibleCurrent && (project.state.code === 'READYFORAGREED' || project.state.code === 'AGREED')) actions = [...actions,
        { label: 'Начать согласование шага', key: "stepToAgreed", onClick: () => stepToAgreed({ ids: [id] }) },
        { label: 'Согласовать шаг', key: "stepToAccepted", onClick: () => stepToAccepted({ ids: [id] }) },
        { label: 'Отклонить шаг', key: "stepToRejected", onClick: () => stepToRejected({ ids: [id] }) },
    ];
    if (hasRoles('PROJECT_CREATOR', 'ADMIN') && (project.state.code === 'READYFORAGREED' || project.state.code === 'AGREED')) actions = [...actions, { label: 'Начать пересогласование шага заново', key: "stepToReadyForAgreed", onClick: () => stepToReadyForAgreed({ ids: [id] }) }];

    return <React.Fragment>
        <Actions items={actions} />
        <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={onInit}
            history={history}
            editable={false}
            fullScreen={false}
            odataFilter={`projectId eq ${id}`}
            pagination={false}
            selectable={false}
            sortable={false}
            ellipsys={true}
        />
    </React.Fragment>
}

export default withRouter(ProjectApprovalStep);