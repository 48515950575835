import { groupByToArray } from "../utils/array";

const getDynamicFieldType = (dynamicAttribute) => {
    if (dynamicAttribute.typeId === 'STRING') return 'string';
    if (dynamicAttribute.typeId === 'TEXT') return 'text';
    if (dynamicAttribute.typeId === 'DATE') return 'date';
    if (dynamicAttribute.typeId === 'INTEGER') return 'number';
    if (dynamicAttribute.typeId === 'FRACTIONAL') return 'number';
    if (dynamicAttribute.typeId === 'BOOLEAN') return 'boolean';
    if (dynamicAttribute.typeId === 'FILE') return 'file';
    if (dynamicAttribute.typeId === 'LIST') return 'entity';
    return 'string';
}

const getDynamicMetaColumn = (dynamicAttribute) => {
    if (dynamicAttribute.typeId === 'STRING') return 'stringValue';
    if (dynamicAttribute.typeId === 'TEXT') return 'stringValue';
    if (dynamicAttribute.typeId === 'DATE') return 'dateValue';
    if (dynamicAttribute.typeId === 'INTEGER') return 'numberValue';
    if (dynamicAttribute.typeId === 'FRACTIONAL') return 'numberValue';
    if (dynamicAttribute.typeId === 'BOOLEAN') return 'booleanValue';
    if (dynamicAttribute.typeId === 'FILE') return 'stringValue';
    if (dynamicAttribute.typeId === 'LIST') return 'numberValue';
    return 'stringValue';
}

const keyPrefix = "_da";
export const dynamicAttributesCode = "dynamicAttributes";
export const dynamicAttributesSelect = "id,metaId,ord,stringValue,dateValue,numberValue,booleanValue,stringValue2"

export const getProperties = (dynamicAttributes) => {
    return dynamicAttributes
        .sort((a, b) => a.ord - b.ord)
        .map(dynamicAttribute => getProperty(dynamicAttribute));
}

const getProperty = (dynamicAttribute) => {
    let property = {
        isDynamic: true,
        metaId: dynamicAttribute.id,
        metaColumn: getDynamicMetaColumn(dynamicAttribute),
        dataIndex: `${keyPrefix}${dynamicAttribute.id}`,
        title: dynamicAttribute.name,
        type: getDynamicFieldType(dynamicAttribute),
        required: dynamicAttribute.required,
        showInTable: dynamicAttribute.showInTable,
        editable: true,
        multiple: dynamicAttribute.multiple,
        description: dynamicAttribute.description,
        responsive: ["sm"],
        after: dynamicAttribute.after,
        accessLevel: dynamicAttribute.accessLevel
    };
    if (dynamicAttribute.typeId === 'LIST') {
        property.entityName = 'dynamicListItem';
        property.entityPath = `${window.APPCFG.odataPath}/dynamicListItem?$filter=listId eq ${dynamicAttribute.listId}&`;
        property.entityKey = 'id';
        property.entityLabel = 'name';
        property.loadAll = true;
    }
    return property;
}

// переложить объект с динамическими атрибутами в линейный объект
export const toEntity = ({ entity, properties }) => {
    if (!properties) return entity;
    if (!entity) {
        var hasInitValueProperties = properties.filter(_ => _.initValue)
        if (hasInitValueProperties.length > 0) {
            entity = {};
            for (let property of hasInitValueProperties)
            {
                entity[property.dataIndex] = property.initValue;
            }
        }
        return entity;
    }
    
    if (entity[dynamicAttributesCode]) for (let dynamicAttributeGroup of groupByToArray(entity[dynamicAttributesCode], _ => _.metaId)) {
        let metaId = dynamicAttributeGroup[0];
        let values = dynamicAttributeGroup[1];
        let dataIndex = `${keyPrefix}${metaId}`;
        let property = properties.filter(_ => _.dataIndex === dataIndex)[0];
        if (property) {
            if (property.type === 'file') {
                let dynamicAttributes = values.filter(_ => _['numberValue']);
                if (dynamicAttributes.length > 0) entity[dataIndex] = dynamicAttributes.map(dynamicAttribute => ({
                    uid: dynamicAttribute['numberValue'],
                    url: dynamicAttribute['stringValue'],
                    name: dynamicAttribute['stringValue2'],
                    status: 'done'
                }));
            } else if (property.type === 'entity') {
                values = values.filter(_ => _[property.metaColumn]);
                if (values.length > 0) {
                    let v = values.map(v => ({
                        [property.entityKey]: v[property.metaColumn]
                    }))
                    entity[dataIndex] = property.multiple === true ? v : v[0];
                }
            } else {
                // todo multiple!
                let dynamicAttribute = values[0];
                entity[dataIndex] = dynamicAttribute[property.metaColumn];
            }
        }
    }
    for (var p of properties.filter(_ => _.type === 'entity' && !_.isDynamic && _.multiple === true && !_.newMultiple)) {
        if (entity[p.dataIndex] && entity[p.dataIndex].length > 0) {
            for (let i in entity[p.dataIndex]) {
                entity[p.dataIndex][i] = entity[p.dataIndex][i] ? entity[p.dataIndex][i][p.entityName] : entity[p.dataIndex][i];
            }
        }
    }
    return entity;
}

// переложить линейный объект в объект с динамическими атрибутами
export const fromEntity = ({ entity, properties }) => {
    if (!entity || !properties) return entity;

    for (let k of Object.keys(entity)) {
        let field = properties.filter(_ => _.dataIndex === k)[0];
        if (!field) continue;
        if (field.isDynamic) {
            if (!entity[dynamicAttributesCode]) entity[dynamicAttributesCode] = [];
            let value = entity[field.dataIndex];
            if (value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0)) 
            {} else {
                if (field.type === 'file') {
                    if (value && value.length > 0) {
                        for (var f of value) {
                            entity[dynamicAttributesCode].push({
                                metaId: field.metaId,
                                'stringValue': f.url,
                                'stringValue2': f.name,
                                'numberValue': f.uid
                            });
                        }
                    } else {
                        entity[dynamicAttributesCode].push({
                            metaId: field.metaId,
                            'stringValue': null,
                            'stringValue2': null,
                            'numberValue': null
                        });
                    }
                }
                else if (field.type === 'entity') {
                    let values = Array.isArray(value) ? value : [value];
                    for (let v of values) {
                        entity[dynamicAttributesCode].push({
                            metaId: field.metaId,
                            [field.metaColumn]: !value ? null : v[field.entityKey]
                        })
                    }
                } else {
                    entity[dynamicAttributesCode].push({
                        metaId: field.metaId,
                        [field.metaColumn]: value
                    })
                }
            }
            entity[k] = undefined;
        }  
    }
    return entity;
}