import { red, yellow, green } from '../../selectioncompanies/colors';
const cloneDeep = require('clone-deep');

export const meta_ = {
    entity: 'stats',
    properties: [
        {
            title: 'Оценивающих уч-ов',
            dataIndex: 'memberSetQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Оцениваемых уч-ов',
            dataIndex: 'memberGetQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Полностью оценили',
            dataIndex: 'memberSetFullyQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Частично оценили',
            dataIndex: 'memberSetPartlyQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Не оценили',
            dataIndex: 'memberSetNoneQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Полностью оценены',
            dataIndex: 'memberGetFullyQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Частично оценены',
            dataIndex: 'memberGetPartlyQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Не оценены',
            dataIndex: 'memberGetNoneQuantity',
            type: 'number',
            width: 150,
            editable: true,
        },
        {
            title: 'Оценка',
            dataIndex: 'progressState',
            width: 150,
            editable: true,
            type: 'entity',
            entityName: 'progressState',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            render: (text, record, index, {renderedText}) => {
                return record.state.code === 'NOTACTIVE'
                    ? <div style={{ color: 'gray' }}>{renderedText}</div>
                    : record.stats.progressState.code === 'NONE'
                        ? <div style={{ color: red }}>{renderedText}</div>
                        : record.stats.progressState.code === 'PARTLY'
                            ? <div style={{ color: yellow }}>{renderedText}</div>
                            : <div style={{ color: green }}>{renderedText}</div>
            },
        }
    ]
};

const globalGradeCampaignProperties = [
    {
        title: 'Проекты, всего',
        dataIndex: 'projectQuantity',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        title: 'Проекты, полностью оценили',
        dataIndex: 'projectFullyQuantity',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        title: 'Проекты, частично оценили',
        dataIndex: 'projectPartlyQuantity',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        title: 'Проекты, не оценили',
        dataIndex: 'projectNoneQuantity',
        type: 'number',
        width: 150,
        editable: true,
    },
]

export const addTo = ({metadata, dataSub = 'stats', clone = true, global}) => {
    let metaSub = cloneDeep(meta_);
    let metaSubGlobal = cloneDeep(globalGradeCampaignProperties);
    let meta = clone ? cloneDeep(metadata) : metadata;
    if (global) {
        for (let p of metaSubGlobal) {
            p.dataIndex = [dataSub, p.dataIndex];
            p.isNavigation = false;
            p.editable = false;
            p.responsive = ["sm"];
            meta.properties.push(p);
        }
    }
    for (let p of metaSub.properties) {
        p.dataIndex = [dataSub, p.dataIndex];
        p.isNavigation = false;
        p.editable = false;
        p.responsive = ["sm"];
        meta.properties.push(p);
    }
    return meta;
}