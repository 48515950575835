import React, { useEffect, useState } from 'react';
import PropertyPanel from './PropertyPanel';
import "./style.css"

const SystemInfoPanel = () => {
    const [systemInfo, setSystemInfo] = useState();
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('api/System/GetSystemInfoForUser');
            const info = await response.json();
            setSystemInfo(info);
        };
        fetchData();
    }, [])
    return systemInfo ? <PropertyPanel groups={systemInfo.groups}  /> : <p><em>Загружается...</em></p>
}

export default SystemInfoPanel;