import { getData, saveData } from "./entityodata";
import types from "./types";

const getMetadata = (metadata) => {
    return {...metadata, properties: metadata.dataProperties};
}

export class odataProvider {
    path = window.APPCFG.odataPath;
    types = types;
    getList = (props) => getData({globalPath: this.path, ...props, metadata: getMetadata(props.metadata)});
    getSearchByTextPath = ({ entityPath, entityName, entityKey = 'id', entityLabel = 'name', entityFilter, top, value, exact }) => {
        const entityLabels = Array.isArray(entityLabel) ? entityLabel : [entityLabel];
        const expandPars = entityLabels.filter(_ => _.indexOf('/') > 0).map(_ => {
            let parts = _.split('/');
            return `${parts[0]}($select=${parts[1]})`;
        })
        const selectPar = [entityKey, ...entityLabels.filter(_ => _.indexOf('/') <= 0)].join(',');
        const values = Array.isArray(value) ? value : [value];
        let filterPar = exact
            ? entityLabels.map(l => values.map(v => `${l} eq '${v.trim().replaceAll('\'', '\'\'')}'`).join(' or ')).join(' or ')
            : entityLabels.map(l => values.map(v => `contains(tolower(${l}),'${v.toLowerCase().trim().replaceAll('\'', '\'\'')}')`).join(' or ')).join(' or ');
        if (entityFilter) filterPar = `(${filterPar}) and (${entityFilter})`;
        const pathPar = `${entityPath ? entityPath : `${this.path}/${entityName}?`}`;
        const topPar = top || 20;
        return `${pathPar}$select=${selectPar}${expandPars.length > 0 ? `&$expand=${expandPars.join(',')}` : ''}&$filter=(${filterPar})&$top=${topPar}`;
    }
    
    getAllPath = ({ entityPath, entityName, entityKey = 'id', entityLabel = 'name', entityFilter, entityOrder }) => {
        const entityLabels = Array.isArray(entityLabel) ? entityLabel : [entityLabel];
        const selectPar = [entityKey, ...entityLabels].join(',');
        const pathPar = `${entityPath ? entityPath : `${this.path}/${entityName}?`}`;
        const filter = entityFilter ? `&$filter=${entityFilter}` : '';
        const order = entityOrder ? `&$orderby=${entityOrder} asc` : '';
        return `${pathPar}$select=${selectPar}${filter}${order}`;
    }
    
    getByIdPath = ({ entityPath, entityName, entityKey = 'id', entityLabel = 'name', ids }) => {
        const entityLabels = Array.isArray(entityLabel) ? entityLabel : [entityLabel];
        const selectPar = [entityKey, ...entityLabels].join(',');
        const filterPar = ids.map(v => `${entityKey} eq ${v}`).join(' or ');
        const pathPar = `${entityPath ? entityPath : `${this.path}/${entityName}?`}`;
        return `${pathPar}$select=${selectPar}&$filter=(${filterPar})`;
    }

    changeData = ({ metadata, value, creating, editingKey, beforeSaveData, onSave, afterGetPath }) => 
        saveData({ globalPath: this.path, metadata: getMetadata(metadata), value, creating, editingKey, beforeSaveData, onSave, afterGetPath });
}