import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { Tabs, message, Button, Modal, Input } from 'antd';
import { withRouter } from "react-router-dom";
import { post } from '../_common/utils/fetch';
import { useEnv } from '../_common/hooks/useEnv';
import { useStoredState } from '../_common/hooks/useStoredState';
import { useViewRoot, getViewRootPath } from '../_common/hooks/useViewRoot';
import { useEntity } from '../_common/hooks/useEntity';
import { EntityForm, EntityViewer } from '../_libs/antd-ext';
import { ArrowLeftOutlined, CreditCardOutlined } from '@ant-design/icons';
import { prepareMetadata } from '../_common/odata/entityodata';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import cloneDeep from 'clone-deep';

const { TextArea } = Input;

const metadata = {
    entity: 'PartnershipRequest',
    key: 'id',
    properties: [
        {
            title: 'Организация',
            dataIndex: 'client',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            editable: false
        },
        {
            title: 'Партнер',
            dataIndex: 'partner',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            editable: {
                updating: false
            }
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'partnershipRequestState',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            loadAll: true,
            required: false
        },
        {
            title: 'Документы',
            dataIndex: 'documents',
            type: 'file',
            multiple: true,
            editable: true,
            showInTable: false
        },
        {
            title: 'Попытка',
            width: 150,
            dataIndex: 'attemptNumber',
            type: 'number'
        },
        {
            title: 'Комментарии организации',
            dataIndex: 'clientMemo',
            width: 300,
            type: 'text',
            editable: true
        },
        {
            title: 'Комментарии партнера',
            dataIndex: 'partnerMemo',
            width: 300,
            type: 'text',
            editable: false
        }
    ]
};

const PartnershipRequest = (props) => {
    const [tableToMeApi, setTableToMeApi] = useState();
    const [tableFromMeApi, setTableFromMeApi] = useState();
    const [tabKey, setTabKey] = useStoredState('toMe', { code: 'tab' });
    const [toMeSelectedRows, setToMeSelectedRows] = useState();
    const [fromMeSelectedRows, setFromMeSelectedRows] = useState();
    const [env] = useEnv();
    const { mainRoot, id, regime } = useViewRoot();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [entityData] = useEntity({ metadata, id, forceUpdateRequired });
    const [actionDialogOpen, setActionDialogOpen] = useState(false);
    const [memo, setMemo] = useState();
    const [actionType, setActionType] = useState();
    const [actionIds, setActionIds] = useState();

    const propertiesToMe = metadata.properties;
    const propertiesFromMe = metadata.properties;

    const [partnerId] = useStoredState(undefined, { code: 'PartnerId' });

    prepareMetadata({ metadata });

    const tabToMe = {
        label: 'Заявки ко мне', key: 'toMe', children: <Table
            columns={propertiesToMe}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableToMeApi}
            history={props.history}
            editable={false}
            fullScreen={false}
            odataFilter={`partnerId eq ${env && env.userClientId} and stateId ne 'DRAFT'`}
            rowSelection={{
                onChange: (newSelectedRowKeys, newSelectedRows) => setToMeSelectedRows(newSelectedRows)
            }}
            actions={[
                { key: 'view', icon: <CreditCardOutlined />, title: 'Просмотреть', onClick: (record) => props.history.push(getViewRootPath({ mainRoot, id: record[metadata.key] })) }
            ]}
        />
    }

    const tabFromMe = {
        label: 'Заявки от меня', key: 'fromMe', children: <Table
            columns={propertiesFromMe}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableFromMeApi}
            history={props.history}
            editable={true}
            fullScreen={false}
            odataFilter={`clientId eq ${env && env.userClientId}`}
            rowSelection={{
                onChange: (newSelectedRowKeys, newSelectedRows) => setFromMeSelectedRows(newSelectedRows)
            }}
            onAdd={() => props.history.push(getViewRootPath({ mainRoot, regime: 'create' }))}
            onEdit={(record) => props.history.push(getViewRootPath({ mainRoot, id: record[metadata.key], regime: 'edit' }))}
            onCopy={(record) => props.history.push(getViewRootPath({ mainRoot, id: record[metadata.key], regime: 'copy' }))}
        />
    }

    const refresh = () => {
        if (tableFromMeApi) tableFromMeApi.refresh();
        if (tableToMeApi) tableToMeApi.refresh();
        forceUpdate();
    }

    const action = () => {
        setActionDialogOpen(false);
        post({
            url: `${window.APPCFG.apiPath}/PartnershipRequest/${actionType}`,
            json: {
                ids: actionIds,
                text: memo
            },
            callback: () => {
                message.success("Успешно");
                refresh();
            }
        })
    }

    const startAction = ({ ids, rows, action }) => {
        setActionIds(ids);
        setActionType(action);
        setActionDialogOpen(true);
        if (rows.length === 1) setMemo(rows[0].PartnerMemo);
        else setMemo(null);
    }

    const toReadyForAgreed = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/PartnershipRequest/ToReadyForAgreed`,
            json: {
                ids
            },
            callback: () => {
                message.success("Успешно");
                refresh();
            }
        })
    }

    const toDraft = ({ ids }) => {
        post({
            url: `${window.APPCFG.apiPath}/PartnershipRequest/ToDraft`,
            json: {
                ids
            },
            callback: () => {
                message.success("Успешно");
                refresh();
            }
        })
    }

    const getSelectedIds = (tableApi) => {
        return tableApi.getSelectedRows().map(_ => _.id);
    }

    const isSelectedInState = (selectedRows, state) => {
        const selected = selectedRows;
        if (!selected || selected.length === 0) return false;
        const stateArr = Array.isArray(state) ? state : [state];
        return selected.every(_ => stateArr.indexOf(_.state.code) >= 0);
    }

    let menu = [];
    if (regime === 'list') {
        if (tabKey === 'toMe') {
            menu = [...menu,
            { label: 'Начать согласование', disabled: !isSelectedInState(toMeSelectedRows, 'READYFORAGREED'), key: "toAgreed", onClick: () => startAction({ action: 'ToAgreed', ids: getSelectedIds(tableToMeApi), rows: tableToMeApi.getSelectedRows() }) },
            { label: 'Согласовать', disabled: !isSelectedInState(toMeSelectedRows, ['READYFORAGREED', 'AGREED']), key: "toAccepted", onClick: () => startAction({ action: 'ToAccepted', ids: getSelectedIds(tableToMeApi), rows: tableToMeApi.getSelectedRows() }) },
            { label: 'Отклонить', disabled: !isSelectedInState(toMeSelectedRows, ['READYFORAGREED', 'AGREED']), key: "toRejected", onClick: () => startAction({ action: 'ToRejected', ids: getSelectedIds(tableToMeApi), rows: tableToMeApi.getSelectedRows() }) },
            ]
        } else if (tabKey === 'fromMe') {
            if (tableFromMeApi) menu = [...menu,
            ...tableFromMeApi.getMainContentMenuItems(),
            { label: 'Передать на согласование', disabled: !isSelectedInState(fromMeSelectedRows, 'DRAFT'), key: "toReadyForAgreed", onClick: () => toReadyForAgreed({ ids: getSelectedIds(tableFromMeApi) }) },
            { label: 'Вернуть в черновик', disabled: !isSelectedInState(fromMeSelectedRows, ['READYFORAGREED', 'REJECTED']), key: "toDraft", onClick: () => toDraft({ ids: getSelectedIds(tableFromMeApi) }) },
            ];
        }
    } else if (regime === 'view' && entityData && env && entityData.partner.id === env.userClientId) {
        menu = [{ label: 'Начать согласование', disabled: !(entityData.state.code === 'READYFORAGREED'), key: "toAgreed", onClick: () => startAction({ action: 'ToAgreed', ids: [id], rows: [entityData] }) },
        { label: 'Согласовать', key: "toAccepted", disabled: !(entityData.state.code === 'READYFORAGREED' || entityData.state.code === 'AGREED'), onClick: () => startAction({ action: 'ToAccepted', ids: [id], rows: [entityData] }) },
        { label: 'Отклонить', key: "toRejected", disabled: !(entityData.state.code === 'READYFORAGREED' || entityData.state.code === 'AGREED'), onClick: () => startAction({ action: 'ToRejected', ids: [id], rows: [entityData] }) },
        ]
    }

    const changeMetadata = (metadata, action) => {
        let meta = cloneDeep(metadata);
        action(meta);
        return meta;
    }

    return <React.Fragment>
        {regime === 'view'
            ? <MainContent layoutProps={props.layoutProps} title="Заявка на партнерство"
                headerRightButtons={
                    <React.Fragment>
                        <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => props.history.push(getViewRootPath({ mainRoot }))} />
                    </React.Fragment>
                }
                menu={menu}>
                <EntityViewer
                    metadata={metadata}
                    value={entityData}
                />
            </MainContent>
            : (regime === 'edit' || regime === 'create')
                ? <MainContent layoutProps={props.layoutProps} title="Заявка на партнерство"
                    headerRightButtons={
                        <React.Fragment>
                            <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => props.history.push(getViewRootPath({ mainRoot }))} />
                        </React.Fragment>
                    }>
                    <EntityForm
                        card={false}
                        regime={regime === 'edit' ? 'updating' : 'creating'}
                        metadata={changeMetadata(metadata, metadata => {
                            if (partnerId) metadata.properties.filter(_ => _.dataIndex == 'partner')[0].editable = false;
                        })}
                        title={'Заявка на партнерство'}
                        visible={true}
                        value={regime === 'create' ? { Partner: partnerId && { Id: partnerId } } : entityData}
                        defaults={metadata.defaults}
                        editable={true}
                        beforeSaveData={({ record }) => { if (partnerId) record.partnerId = partnerId; return record; }}
                        onSave={() => {
                            refresh();
                            props.history.push(getViewRootPath({ mainRoot }) + '?tab="fromMe"');
                        }}
                    />
                </MainContent>
                : <MainContent layoutProps={props.layoutProps}
                    title="Заявки на партнерство"
                    onClickRefresh={() => {
                        tableToMeApi && tableToMeApi.refresh();
                        tableFromMeApi && tableFromMeApi.refresh();
                    }}
                    menu={menu}
                >
                    <Tabs activeKey={tabKey} items={[tabToMe, tabFromMe]}
                        onChange={setTabKey} />
                </MainContent>}
        <Modal title="Комментарий" open={actionDialogOpen} onOk={action} onCancel={() => setActionDialogOpen(false)}>
            <TextArea value={memo} onChange={e => setMemo(e.target.value)} />
        </Modal>
    </React.Fragment>
}

export default withRouter(PartnershipRequest);