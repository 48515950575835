import React, { useState } from "react";
import { Menu, Button, Dropdown, Row, Col } from 'antd';
import { SearchOutlined, ControlOutlined } from "@ant-design/icons";
import FieldDatePeriod from '../form/FieldDatePeriod';
import FieldBoolean from '../form/FieldBoolean';
import FilterEntity from '../table/FilterEntity';
import FilterString from '../table/FilterString';
import FilterNumber from '../table/FilterNumber';

const merge = require('deepmerge')

const FilterPanel = ({ properties, onConfirm }) => {
    const [filters, setFilters] = useState();
    const [filtersLength, setFilterLength] = useState(0);
    const [open, setOpen] = useState(false);

    const changeFilter = ({property, value}) => {
        let newFilters = { ...(filters || {}), [property.dataIndex]: value };
        setFilters(newFilters);
        setFilterLength(Object.entries(newFilters).filter(_ => _[1]).length)
    }

    const filterComponent = ({ type, property, value, onChange }) => {
        return type === 'number'
                ? <FilterNumber value={value} onChange={onChange} />
                : type === 'date'
                    ? <FieldDatePeriod value={value} onChange={onChange} allowClear={true} />
                    : type === 'boolean'
                        ? <FieldBoolean value={value} onChange={onChange} />
                        : type === 'entity'
                            ? <FilterEntity {...property} tagsVisible={false} value={value} onChange={onChange} />
                            : <FilterString value={value} onChange={onChange} />
    }

    const renderFilter = ({ property }) => {
        return filterComponent({
            type: property.type,
            property,
            value: filters && filters[property.dataIndex],
            onChange: (value) => changeFilter({property, value })
        });
    };

    const renderFilters = () => {
        return properties
            .map((property, idx) => <div key={idx}>{property.title}<div style={{paddingLeft: 10}}>{renderFilter({ property })}</div></div>)
    }

    return <Dropdown open={open} onOpenChange={() => setOpen(!open)} trigger={['click']} placement="bottomRight" overlay={<div style={{width: '300px', maxWidth: '300px', minWidth: '300px', backgroundColor: 'white', padding: 10, border: '1px solid #b1b1b1', borderRadius: 5}}>
        {renderFilters()}
        <Row style={{ marginTop: 10 }} wrap={false}>
            <Col span={12} align="left">
                <Button className="b" type="primary" onClick={(e) => { onConfirm(filters); setOpen(false); }} onKeyDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onConfirm(filters);
                    setOpen(false);
                }} icon={<SearchOutlined />} size="small">
                    Фильтровать
                </Button>
            </Col>
            <Col span={12} align="right" style={{paddingLeft: 30}}>
                <Button onClick={() => { setOpen(false); setFilters(); setFilterLength(0); onConfirm() }} size="small">
                    Очистить
                </Button>
            </Col>
        </Row>
        </div>}>
            <Button style={filtersLength > 0 ? {color: '#85c6ff', borderColor: '#85c6ff'} : undefined} icon={<ControlOutlined/>} title={`Дополнительные фильтры${filtersLength > 0 ? ` (${filtersLength})` : ''}`} />
        </Dropdown>
}

export default FilterPanel;