import React from 'react';
import { withRouter } from "react-router-dom";
import { Modal, Form, Input, Select } from 'antd';
import FieldEntity from '../../_libs/antd-ext/form/FieldEntity';
const { Option } = Select;
const { confirm } = Modal;

const ChangeCuratorDialog = ({ open, onCancel, onOk, selectedRecords }) => {
    const [form] = Form.useForm();
    form.setFieldsValue({
        whenExisting: 'refresh',
        roleIfRoleNone: 'none',
        roleIfNone: 'none',
        roleIfMany: 'first',
        ephIfNone: 'none',
        ephIfMany: 'first',
    })

    const onOkInternal = async () => {
        let row = await form.validateFields();
        confirm({
            title: `Вы действительно хотите изменить куратора в проектах (${selectedRecords.length})?`,
            onOk: () => {
                let json = { ...row };
                if (json.member) {
                    json.memberId = json.member.id;
                    json.member = undefined;
                }
                onOk(json, selectedRecords);
            }
        });
    }

    if (!selectedRecords || selectedRecords.length === 0) return null;

    return <Modal open={open} onOk={onOkInternal} onCancel={onCancel}
        title={`Изменение куратора для всех выбранных проектов (${selectedRecords.length})`}
        okText={`Изменить куратора в проектах (${selectedRecords.length})`}
        width={'90%'}
        heigth={'400px'}
        bodyStyle={{ overflowY: 'scroll', height: '60vh' }}>
        <Form
            form={form}
            autoComplete="off"
            layout="horizontal"
        >
            <Form.Item name="whenExisting" label="Если куратор задан в проекте">
                <Select>
                    <Option value="refresh">Изменить</Option>
                    <Option value="none">Оставить без изменений</Option>
                </Select>
            </Form.Item>
            <Form.Item name="changeTo" label="Изменить на" rules={[{ required: true }]}>
                <Select>
                    <Option value="eph">Руководителя ОП</Option>
                    <Option value="role">Участника с заданной ролью</Option>
                    <Option value="member">Заданного пользователя</Option>
                </Select>
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.changeTo !== currentValues.changeTo}
            >
                {({ getFieldValue, setFieldValue }) =>
                    getFieldValue('changeTo') === 'role' ? (
                        <React.Fragment>
                            <Form.Item name="name" label="Название роли">
                                <Input />
                            </Form.Item>
                            <Form.Item name="roleIfRoleNone" label="Если нет такой роли в проекте">
                                <Select>
                                    <Option value="empty">Изменить куратора на пустое значение</Option>
                                    <Option value="none">Оставить куратора без изменений</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="roleIfNone" label="Если нет участника с ролью">
                                <Select>
                                    <Option value="empty">Изменить куратора на пустое значение</Option>
                                    <Option value="none">Оставить куратора без изменений</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="roleIfMany" label="Если несколько участников с ролью">
                                <Select>
                                    <Option value="empty">Изменить куратора на пустое значение</Option>
                                    <Option value="none">Оставить куратора без изменений</Option>
                                    <Option value="first">Изменить куратора на первого участника</Option>
                                </Select>
                            </Form.Item>
                        </React.Fragment>
                    ) : getFieldValue('changeTo') === 'eph' ? (
                        <React.Fragment>
                            <Form.Item name="ephIfNone" label="Если в проекте нет РОП">
                                <Select>
                                    <Option value="empty">Изменить куратора на пустое значение</Option>
                                    <Option value="none">Оставить куратора без изменений</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="ephIfMany" label="Если в проекте несколько РОП">
                                <Select>
                                    <Option value="empty">Изменить куратора на пустое значение</Option>
                                    <Option value="none">Оставить куратора без изменений</Option>
                                    <Option value="first">Изменить куратора на первого РОП</Option>
                                </Select>
                            </Form.Item>
                        </React.Fragment>
                    ) : getFieldValue('changeTo') === 'member' ? (
                        <React.Fragment>
                            <Form.Item name="member" label="Пользователь">
                                <FieldEntity value={getFieldValue('member')}
                                    entityName='position'
                                    entityKey='id'
                                    entityLabel={['user/name']}
                                    entityLabelFunc={(record) => record['user']['name']}
                                    onChange={value => setFieldValue('member', value)} />
                            </Form.Item>
                        </React.Fragment>
                    ) : null
                }
            </Form.Item>
        </Form>
    </Modal>
}

export default withRouter(ChangeCuratorDialog);