import React from 'react';
import { InputNumber, Space } from 'antd';
import FilterString from './FilterString';

const FilterNumber = ({ value, onChange, confirm, style = {} }) => {
    const getValue = (value) => {
        if (!value) return value;
        if (value.values && value.values.length > 0) {
            value.values = value.values.map(_ => _.replace(',','.')).filter(_ => !Number.isNaN(Number.parseFloat(_)))
                .map(_ => Number.parseFloat(_).toString());
        }
        if (!value.values || value.values.length === 0) value.values = undefined;
        if (!value.from) value.from = undefined;
        if (!value.till) value.till = undefined;
        return (!value.values && !value.from && !value.till) ? null : { ...value };
    }
    return <React.Fragment>
        <FilterString value={value && value.values} onChange={values => onChange(getValue({ ...value, values: values }))} 
            tokenSeparators={[' ',';']}
        /><br/>
        или задайте интервал<br/>
        <Space>
        От <InputNumber value={value && value.from} onChange={from => onChange(getValue({ ...value, from: from }))} />
        До <InputNumber value={value && value.till} onChange={till => onChange(getValue({ ...value, till: till }))} />
        </Space>
    </React.Fragment>
}

export default FilterNumber;