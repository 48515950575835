let _flags = {};
let _prefix = 'flag_';
let _storage = 'location'; // localStorage

export function useFlag(code) {
    return _flags[code];
}

export function getFlag(code) {
    return _flags[code];
}

export function initFlags({flags, prefix, storage}) {
    _flags = flags || {}
    if (prefix !== undefined) _prefix = prefix;
    if (storage !== undefined) _storage = storage;

    if (_storage === 'localStorage') {
        const keys = Object.keys(localStorage).filter(_ => _.startsWith(_prefix));
        for (const key of keys) {
            _flags[key.substring(_prefix.length)] = localStorage.getItem(key);
        }
    } else if (_storage === 'location') {
        const params = new URLSearchParams(window.location.search);
        const keys = Object.keys(Object.fromEntries(params.entries())).filter(_ => _.startsWith(_prefix));
        for (const key of keys) {
            _flags[key.substring(_prefix.length)] = params.get(key);
        }
    }
}