import React, { useState, useEffect } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter, Link } from "react-router-dom";
import { EntityForm, EntityViewer } from '../_libs/antd-ext';
import { EditOutlined, ArrowLeftOutlined, CreditCardOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { getEntityNew } from '../_common/odata/entityodata';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { useEnv } from '../_common/hooks/useEnv';
import { useMetadata } from '../_common/hooks/useMetadata';
import UserPositions from './UserPositions';
import UserProjects from './UserProjects';
import cloneDeep from 'clone-deep';
import { post } from '../_common/utils/fetch';
import NotifyCommon from '../notifications/NotifyCommon';

export const meta = {
    entity: 'user',
    key: 'id',
    properties: [
        {
            title: 'ФИО',
            dataIndex: 'name',
            width: 150,
            editable: true,
            required: true,
            render: (text, record) => record ? <Link to={`/User/${record.id}`}>{text}</Link> : text,
        },
        {
            title: 'Логин',
            dataIndex: 'login',
            width: 150,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            type: 'email',
            width: 150,
            required: true,
            editable: true
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            width: 150,
            editable: true
        },
        {
            title: 'Ув. email',
            dataIndex: 'emailNotification',
            type: 'boolean',
            width: 150,
            editable: true
        },
        {
            title: 'Ув. telegram',
            dataIndex: 'telegramNotification',
            type: 'boolean',
            width: 150,
            editable: true,
            description: 'Кроме выставления этой галочки также необходимо в поле Телефон указать номер телефона, к которому привязан ваш telegram-аккаунт и подключить Telegram-бота, для этого справа под иконкой (?) выбираем "Бот в Telegram", внутри Telegram нажимаем "Представиться", отсылаем контакт, если бот вас правильно идентифицирует, то вы получите ответ в виде "Здравствуйте, ФИО", это все, вы подключены.'
        },
        {
            title: 'Суперадмин',
            dataIndex: 'isSuperAdmin',
            type: 'boolean',
            width: 150,
            editable: false
        },
        {
            title: 'Ссылка',
            dataIndex: 'url',
            type: 'string',
            width: 150,
            editable: true
        },
        {
            title: 'Ссылка на аватар',
            dataIndex: 'avatarUrl',
            type: 'string',
            width: 150,
            editable: true
        },
        {
            title: 'Основная организация',
            dataIndex: 'mainOrganization',
            type: 'string',
            width: 150,
            editable: true
        },
        {
            title: 'Основная позиция/должность',
            dataIndex: 'mainPosition',
            type: 'string',
            width: 150,
            editable: true
        },
        {
            title: 'Виден в организациях',
            dataIndex: 'userVisibilities',
            type: 'entity',
            multiple: true,
            //newMultiple: true,
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            //entityLabelFunc: (data) => data.client.shortName,
            editable: true
        },
    ],
    default: {
        //required: true,
        //editable: true,
        //sortable: true
    }
};

const User = (props) => {
    const [tableApi, setTableApi] = useState();
    const [metadataReady, setMetadataReady] = useState(false);
    const [entityData, setEntityData] = useState();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [env] = useEnv();
    const [metadata, changeMetadata] = useMetadata(meta);
    const [notifyCommonIds, setNotifyCommonIds] = useState();
    let pathParts = props.history.location.pathname.split('/');
    let regime = !pathParts[2]
        ? 'list'
        : pathParts[3] === 'edit'
            ? 'edit'
            : pathParts[3] === 'create'
                ? 'create'
                : pathParts[3] === 'copy'
                    ? 'copy'
                    : 'view';
    let id = pathParts[2] ? parseInt(pathParts[2]) : null;
    let onlyCurrent = false

    if ((regime === 'edit' || regime === 'view') && id === 0) {
        id = env && env.userId;
        onlyCurrent = true
    }

    const editable = onlyCurrent || (env && env.isSuperAdmin);

    if (!editable && regime === 'edit') regime = 'view';

    useEffect(() => {
        if (metadata && env && !metadataReady) {
            if (env.isSuperAdmin && !metadata.propertyMap.isSuperAdmin.editable) {
                metadata.propertyMap.isSuperAdmin.editable = true;
                changeMetadata({ ...metadata });
            }
            setMetadataReady(true);
        }
    }, [env, metadata, metadataReady, changeMetadata])

    useEffect(() => {
        if (metadata && id) getEntityNew({ metadata, id, callback: setEntityData, ext: '?expand=userVisibilities($expand=client($select=id,shortName))' });
        else setEntityData();
    }, [id, forceUpdateRequired, metadata])

    const getViewMetadata = (metadata) => {
        let hidden = ['id', 'login', 'isSuperAdmin', 'phone', 'emailNotification', 'telegramNotification'];
        let meta = cloneDeep(metadata);
        meta.properties = meta.properties.filter(_ => hidden.indexOf(_.dataIndex) < 0);
        return meta;
    }

    const getEditMetadata = (metadata) => {
        let hidden = ['id', 'login'];
        if (!env.isSuperAdmin) hidden.push('isSuperAdmin');
        let meta = cloneDeep(metadata);
        meta.properties = meta.properties.filter(_ => hidden.indexOf(_.dataIndex) < 0);
        return meta;
    }

    const resetTelegram = () => {
        post({
            url: `${window.APPCFG.apiPath}/User/ResetTelegram`,
            json: {
                Ids: [id]
            },
            callback: () => message.success("Регистрация сброшена")
        })
    }

    const impersonatePost = (id) => {
        if (id) post({
            url: `${window.APPCFG.apiPath}/System/Impersonate`,
            json: id,
            callback: () => window.location = '/Projects'
        })
    }

    const impersonate = () => {
        let ids = tableApi.getSelectedRows().map(_ => _.id);
        if (ids.length !== 1) {
            message.warning('Должен быть выделен только один пользователь');
            return;
        }

        impersonatePost(ids[0]);
    }

    const notifyCommon = (id) => {
        let ids = id
            ? [id]
            : (tableApi ? tableApi.getSelectedRows().map(_ => _.id) : null);
        if (!ids || !ids.length) {
            message.warning('Должен быть выделен хотя бы один пользователь');
            return;
        }

        setNotifyCommonIds(ids);
    }

    return !metadata || !metadataReady
        ? null
        : regime !== 'list'
            ? <MainContent layoutProps={props.layoutProps}
                title={`Пользователь: ${regime === 'view' ? (entityData && entityData.name) : regime === 'edit' && id ? `${entityData && entityData.name} - Редактирование` : 'Создание'}`}
                headerRightButtons={
                    <React.Fragment>
                        {env.isSuperAdmin && <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => props.history.push(`/${pathParts[1]}`)} />}
                        {regime === 'view' && editable && <Button key="edit" shape="circle" type="text" icon={<EditOutlined style={{ fontSize: '20px' }} />} title="Редактировать" onClick={() => props.history.push(`/${pathParts[1]}/${pathParts[2]}/edit`)} />}
                        {regime !== 'view' && editable && <Button key="edit" shape="circle" type="text" icon={<CreditCardOutlined style={{ fontSize: '20px' }} />} title="Просмотреть" onClick={() => props.history.push(`/${pathParts[1]}/${pathParts[2]}`)} />}
                    </React.Fragment>
                }
                menu={regime === 'edit'
                    ? [
                        { label: 'Сбросить регистрацию в Telegram', key: 'resetTelegram', onClick: resetTelegram },
                    ]
                    : [
                        env.isSuperAdmin && { label: 'Войти под пользователем', key: 'impersonate', onClick: () => impersonatePost(id) },
                        env.isSuperAdmin && { label: 'Послать уведомление', key: 'notifyCommon', onClick: () => notifyCommon(id) },
                    ]}
            >
                {regime === 'view'
                    ? <EntityViewer
                        metadata={getViewMetadata(metadata)}
                        title={'Пользователь'}
                        visible={true}
                        value={entityData}
                    />
                    : <EntityForm
                        card={false}
                        regime={regime === 'edit' ? 'updating' : 'creating'}
                        metadata={getEditMetadata(metadata)}
                        title={'Пользователь'}
                        visible={true}
                        value={entityData}
                        defaults={metadata.defaults}
                        editable={regime !== 'view'}
                        onSave={() => {
                            if (!onlyCurrent) {
                                props.history.push(`/${pathParts[1]}/`);
                            } else {
                                forceUpdate();
                                props.history.push(`/${pathParts[1]}/0`);
                            }
                        }}
                    />}
                {onlyCurrent && env && env.userRoles && <div style={{ marginTop: 20 }}>Роли: {env.userRoles.join(', ')}</div>}
                <div style={{ marginTop: 20 }}>
                    Позиции:<br />
                    <UserPositions user={entityData} />
                </div>
                <div style={{ marginTop: 20 }}>
                    Проекты:<br />
                    <UserProjects user={entityData} />
                </div>
                {notifyCommonIds && <NotifyCommon ids={notifyCommonIds} open={Boolean(notifyCommonIds)} onOk={() => setNotifyCommonIds(null)} onCancel={() => setNotifyCommonIds(null)} />}
            </MainContent>
            : !env.isSuperAdmin ? null :
                <MainContent layoutProps={props.layoutProps}
                    title="Пользователи"
                    onClickRefresh={tableApi && tableApi.refresh}
                    menu={[
                        env.isSuperAdmin && { label: 'Войти под пользователем', key: 'impersonate', onClick: impersonate },
                        env.isSuperAdmin && { label: 'Послать уведомления выбранным участникам', key: 'notifyCommon', onClick: () => notifyCommon() },
                    ]}
                >
                    <Table
                        columns={metadata.properties}
                        odataEntity={metadata.entity}
                        rowKey={metadata.key}
                        onInit={setTableApi}
                        history={props.history}
                        onAdd={false}
                    />
                    {notifyCommonIds && <NotifyCommon ids={notifyCommonIds} open={Boolean(notifyCommonIds)} onOk={() => setNotifyCommonIds(null)} onCancel={() => setNotifyCommonIds(null)} />}
                </MainContent>
}

export default withRouter(User);
