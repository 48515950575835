import React from 'react';
import { Form } from 'antd';

const Field = ({ fieldRender, title: propsTitle, type, children, rules: propsRules, errorOnTooltip, description, context, entityFilter, ...rest }) => {
    /*let entityFilterValue = () => entityFilter && typeof entityFilter === 'function'
        ? entityFilter({...context, record: context && context.form && context.form.getFieldsValue()})
        : entityFilter;*/
    const inputNode = fieldRender({size: 'small', ...rest});
    const ruleTypes = ['string', 'number', 'date', 'boolean'];
    let rule = {};
    rule.required = rest.required;
    if (ruleTypes.indexOf(type) >= 0) rule.type = type;
    const rules = propsRules || [rule];
    const formItemProps = {
        title: propsTitle,
        rules: rules,
        hasFeedback: true,
        tooltip: description,
        name: rest.name,
        label: rest.label,
        style: rest.style
    }

    return errorOnTooltip
        ? <Form.Item noStyle shouldUpdate>
            {(props) => {
                let errors = props.getFieldError(rest.name);
                const visible = errors.length > 0;
                const title = visible ? errors[0] : propsTitle;
                return <Form.Item {...formItemProps} title={title}>
                    {inputNode}
                </Form.Item>;
            }}
        </Form.Item>
        : <Form.Item noStyle shouldUpdate>
            {() => {
                return <Form.Item {...formItemProps}>
                    {inputNode}
                </Form.Item>;
            }}
        </Form.Item>;
}

export default Field;