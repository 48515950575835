import React from 'react';
import Table from '../_common/tables/TableOData';
import { withRouter, Link } from "react-router-dom";
import { prepareMetadata } from '../_common/odata/entityodata';

const metadata = {
    entity: 'project',
    key: 'id',
    properties: [
        {
            title: 'Название',
            required: true,
            dataIndex: 'name',
            width: 250,
            link: ({record, text}) => <Link to={`/Project/${record['id']}`}>{text}</Link>
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            width: 150,
            type: 'entity',
            entityName: 'projectType',
            entityKey: 'id',
            entityLabel: 'name',
            loadAll: true,
            required: true,
            responsive: ["sm"]
        },
        {
            title: 'Куратор проекта',
            dataIndex: 'projectCurator',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'projectState',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            loadAll: true,
            required: false,
            editable: false,
            responsive: ["sm"]
        },
        {
            title: 'Заказчик',
            dataIndex: 'customer',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            editable: false,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Исполнитель',
            dataIndex: 'executor',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            editable: false,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Начало',
            dataIndex: 'startDate',
            width: 150,
            type: 'date',
            editable: true,
            responsive: ["sm"]
        },
        {
            title: 'Окончание',
            dataIndex: 'finishDate',
            width: 150,
            type: 'date',
            editable: true,
            responsive: ["sm"]
        }
    ]
};

const UserProjects = ({ user, history }) => {
    prepareMetadata({ metadata });

    return !user
        ? null
        : <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            history={history}
            odataFilter={`(projectMembers/any(a: a/userId eq ${user.id} and a/stateId eq 'ACTIVE')) or (projectCuratorId eq ${user.id})`}
            editable={false}
            selectable={false}
            pagination={false}
            fullScreen={false}
        />
}

export default withRouter(UserProjects);
