import React from 'react';
import { Typography } from 'antd';
import { UserOutlined, LikeOutlined, UnorderedListOutlined } from '@ant-design/icons';
import './style.css'

const GradeCompanyDetailResults = ({ gradeCompany, gradeCompanyResult, env, onSave }) => {
    const memberMapByUser = {}
    for (var member of gradeCompanyResult.members) {
        memberMapByUser[member.userId] = member;
    }
    const getEvaluatingUser = (memberMapByUser, grade, isGrade) => {
        if (!grade.evaluatingUserId) return null;
        let name = memberMapByUser[grade.evaluatingUserId] ? memberMapByUser[grade.evaluatingUserId].name : 'Куратор проекта';
        name = isGrade
            ? ` (${name})`
            : `${name}: `;
        return <span className={grade.userId === grade.evaluatingUserId ? 'self' : 'userName'} title={grade.userId === grade.evaluatingUserId ? 'Самооценка' : undefined}>{name}</span>;
    }

    return <div className='panel'>
        {gradeCompanyResult.members.map((member, idx) => <div className='memberGrade' key={idx}>
            <div className='memberName'><UserOutlined style={{ color: 'purple' }} /> {member.name}</div>
            <div className='memberGrades'><LikeOutlined /> Оценки: {gradeCompanyResult.grades.filter(_ => _.userId === member.userId)
                .map((grade, idx) => <span key={idx}>{grade.grade}{getEvaluatingUser(memberMapByUser, grade, true)}; </span>)}
            </div>
            <div className='memberComments'><UnorderedListOutlined /> Комментарии: <br/>
                {gradeCompanyResult.comments.filter(_ => _.userId === member.userId)
                .map((comment, idx) => <Typography.Paragraph style={{paddingLeft: 15}} ellipsis={{ rows: 4, expandable: true}} key={idx}>{getEvaluatingUser(memberMapByUser, comment, false)}{comment.comment}; </Typography.Paragraph>)}
            </div>
        </div>)}
    </div>
}

export default GradeCompanyDetailResults;
