import React, { useEffect, useState } from 'react';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useMetadata } from '../_common/hooks/useMetadata';
import meta from './meta';
import { useForceUpdate } from '../_common/hooks/useForceUpdate';
import { useEntity } from '../_common/hooks/useEntity';
import { Tabs, Button } from 'antd';
import { useStoredState } from '../_common/hooks/useStoredState';
import EventProjects from './EventProjects';
import { useViewRoot } from '../_common/hooks/useViewRoot';
import EventCommon from './EventCommon';
import { useEnv } from '../_common/hooks/useEnv';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getData } from '../_common/odata/entityodata';
import EventExtMembers from './EventExtMembers';

const eventExtMembersMeta = {
    name: 'eventExtMember',
    entity: 'eventExtMember',
    key: 'id',
    properties: [
        {
            title: 'ФИО',
            dataIndex: 'user',
            width: 250,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            link: true
        },
        {
            title: 'Тип участия',
            dataIndex: 'attendanceType',
            width: 250,
            type: 'entity',
            entityName: 'attendanceType',
            entityKey: 'code',
            entityLabel: 'name'
        }
    ]
}

const Event = (props) => {
    const { regime, id } = useViewRoot();
    const [forceUpdateRequired, forceUpdate] = useForceUpdate();
    const [metadata] = useMetadata(meta);
    const [event, setEvent] = useEntity({
        metadata, id: id, forceUpdateRequired, defaults: {
            //startDate: moment().startOf('day').add({days:1,hours:10}),
            attendanceType: { code: 'OFFLINE' },
            visibility: { code: 'ALL' },
            isTimeCuratorApprovedRequired: true,
            heldAuto: true,
            notificationInterval1: 3,
            notificationInterval2: 1
        }
    });
    const [eventExtMembers, setEventExtMembers] = useState();
    const [tabKey, setTabKey] = useStoredState(undefined, { code: 'tab' });
    const [env, { hasRoles }] = useEnv();

    useEffect(() => {
        if (event && event.id) getData({
            metadata: eventExtMembersMeta,
            count: false,
            callback: (data) => setEventExtMembers(data),
            afterGetPath: path => `${path}&eventId=${event.id}`
        });
    }, [event])

    if (!metadata) return null;

    const commonTab = event && <EventCommon env={env} hasRoles={hasRoles} metadata={metadata} event={event} regime={regime} forceUpdate={forceUpdate} forceUpdateRequired={forceUpdateRequired} />

    const projectsTab = event && event.id && <EventProjects env={env} hasRoles={hasRoles} event={event} forceUpdate={forceUpdate} forceUpdateRequired={forceUpdateRequired} />

    const extMembersTab = eventExtMembers && eventExtMembers.length > 0 && <EventExtMembers extMembers={eventExtMembers} event={event} forceUpdate={forceUpdate} metadata={eventExtMembersMeta} />

    const tabs = [
        { label: 'Описание', key: 'common', children: commonTab },
        event && event.id && { label: 'Проекты-участники', key: 'projects', children: projectsTab },
        eventExtMembers && eventExtMembers.length > 0 && { label: `Внешние эксперты (${eventExtMembers.length})`, key: 'extMembers', children: extMembersTab },
    ]

    return <MainContent layoutProps={props.layoutProps}
        title={`Мероприятие: ${(regime === 'create' ? 'Создание' : event ? event.name : '?')}`}
        headerRightButtons={
            <React.Fragment>
                <Button key="back" shape="circle" type="text" icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />} title="Назад к списку" onClick={() => props.history.push(`/Events`)} />
            </React.Fragment>
        }
    >
        <Tabs items={tabs} activeKey={tabKey} onChange={setTabKey} />
    </MainContent>
}

export default withRouter(Event);