import React, { useEffect, useState } from 'react';
import Table from '../_common/tables/TableOData';
import { Button, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
const { confirm } = Modal;

const EntitySelector = ({metadata, open, onCancel, onOk, filter, title, onlyChecked = false, afterGetPath}) => {
    const [tableApi, setTableApi] = useState();
    const [selectedRows, setSelectedRows] = useState();
    const [total, setTotal] = useState(0);
    const history = useHistory();
    useEffect(() => {
        if (tableApi) tableApi.refresh();
    }, [filter])
    const selectedLength = onlyChecked ? (selectedRows ? selectedRows.length : 0) : total;

    const onOkInternal = () => {
        if (selectedLength === 0) {
            message.warning('Не выделено ни одной записи');
            return;
        }
        confirm({
            title: `Вы действительно хотите выбрать (${selectedLength})?`,
            onOk: () => onOk({filter: tableApi.lastUrlSearchParams, selectedRows: selectedRows, selectedIds: selectedRows ? selectedRows.map(_ => _[metadata.key]) : null})
        });
    }

    return <Modal title={title} open={open} onOk={onOkInternal} onCancel={onCancel}
        okText={`Выбрать (${selectedLength})`}
        width={'90%'}
        heigth={'400px'}
        bodyStyle={{overflowY: 'scroll', height: '60vh', padding: 10}}>
        <div style={{marginBottom: 5}}>Отберите нужные записи с помощью фильтров в таблице{onlyChecked ? ' и выделите нужные чекбоксами' : ''}</div>
        <Table
            history={history}
            localStorageKeyPostfix={`_selector_${metadata.entity}`}
            storeInAddressBar={false}
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            editable={false}
            onInit={setTableApi}
            odataFilter={filter}
            afterGetPath={afterGetPath}
            onLoad={({data, total}) => setTotal(total)}
            fullScreen={false}
            scroll={{ x: 100, y: 300 }}
            selectable={onlyChecked}
            rowSelection={{
                onChange: (newSelectedRowKeys, newSelectedRows) => setSelectedRows(newSelectedRows)
            }}
        />
    </Modal>
}

export default EntitySelector;