import React from 'react';
import Table from '../_common/tables/TableOData';
import { withRouter } from "react-router-dom";
import { prepareMetadata } from '../_common/odata/entityodata';

const metadata = {
    entity: 'position',
    key: 'id',
    properties: [
        {
            title: 'Позиция',
            sortable: false,
            filterable: false,
            width: 250,
            render: (text, record) => <React.Fragment>{record.client.shortName} {record.type.name}<br/>
                {record.academicGroup ? record.academicGroup.name : ''} {record.startYear}<br/>
                {!record.departments || record.departments.length === 0 ? null : <React.Fragment>{record.departments.map(_ => `${_.name}`).join(', ')}<br/></React.Fragment>}
                {!record.specialties || record.specialties.length === 0 ? null : <React.Fragment>{record.specialties.map(_ => `${_.code} ${_.name}`).join(', ')}<br/></React.Fragment>}
                {!record.educationalPrograms || record.educationalPrograms.length === 0 ? null : <React.Fragment>{record.educationalPrograms.map(_ => `${_.name}`).join(', ')}<br/></React.Fragment>}
                </React.Fragment>,
            responsive: ["xs"]
        },
        {
            title: 'Организация',
            dataIndex: 'client',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            responsive: ["sm"]
        },
        {
            title: 'Тип позиции',
            dataIndex: 'type',
            width: 150,
            type: 'entity',
            entityName: 'type',
            entityKey: 'code',
            entityLabel: 'name',
            responsive: ["sm"]
        },
        {
            title: 'Группа',
            dataIndex: 'academicGroup',
            type: 'entity',
            entityName: 'academicGroup',
            entityKey: 'id',
            entityLabel: 'name',
            width: 150,
            responsive: ["sm"]
        },
        {
            title: 'Год начала обучения',
            dataIndex: 'startYear',
            width: 150,
            type: 'number',
            responsive: ["sm"]
        },
        {
            title: 'Кафедры',
            dataIndex: 'departments',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'department2',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false,
            responsive: ["sm"]
        },
        {
            title: 'Направления подготовки',
            dataIndex: 'specialties',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'specialty',
            entityKey: 'id',
            entityLabel: ['code','name'],
            editable: false,
            responsive: ["sm"]
        },
        {
            title: 'Образовательные программы',
            dataIndex: 'educationalPrograms',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'educationalProgram',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false,
            responsive: ["sm"]
        }
    ]
};

const UserPositions = ({ user, history }) => {
    prepareMetadata({ metadata });

    return !user
        ? null
        : <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            history={history}
            odataFilter={`userId eq ${user.id}`}
            editable={false}
            selectable={false}
            pagination={false}
            fullScreen={false}
        />
}

export default withRouter(UserPositions);
