import { inList } from "../_common/odata/odata";
const metaRole = {
    name: 'projectRole',
    entity: 'projectRole',
    key: 'id',
    properties: [
        {
            title: 'Название',
            required: true,
            dataIndex: 'name',
            width: 250,
            editable: true
        },
        {
            title: 'Количество',
            required: true,
            type: 'number',
            dataIndex: 'quantity',
            width: 150,
            editable: true
        },
        {
            title: 'Доступно к записи',
            type: 'number',
            editable: false,
            dataIndex: 'availableQuantity',
            width: 200
        },
        {
            title: 'Ценность',
            description: 'Условная ценность за участие в проекте в этой роли. Для студента это могут быть зеты или кредиты, для преподавателя нагрузка в условных единицах или академических часах',
            type: 'number',
            dataIndex: 'workload',
            width: 200,
            editable: true,
            required: true
        },
        {
            title: 'Описание',
            type: 'text',
            dataIndex: 'description',
            width: 250,
            editable: true
        },
        {
            title: 'Уровни образования',
            dataIndex: 'educationLevels',
            type: 'entity',
            multiple: true,
            editable: true,
            showInTable: true,
            entityName: 'educationLevel',
            entityKey: 'id',
            entityOrder: 'ord',
            entityLabel: 'name',
            loadAll: true
        },
        {
            title: 'Курсы',
            dataIndex: 'studyYears',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            editable: true,
            entityName: 'studyYear',
            entityKey: 'id',
            entityLabel: 'name',
            entityOrder: 'educationLevelId, num',
            entityFilter: ({record}) => record && inList('educationLevelId', record.educationLevels, list => list.map(_ => _.id)),
            loadAll: true
        },
        {
            title: 'Кафедры',
            dataIndex: 'departments',
            type: 'entity',
            editable: true,
            multiple: true,
            newMultiple: true,
            entityName: 'department2',
            entityKey: 'id',
            entityLabel: 'name'
        },
        {
            title: 'Направления подготовки',
            dataIndex: 'specialties',
            type: 'entity',
            multiple: true,
            editable: true,
            showInTable: true,
            entityName: 'specialty',
            entityKey: 'id',
            entityLabel: ['code', 'name'],
            entityFilter: ({ record }) => record && inList('educationLevelId', record.educationLevels, list => list.map(_ => _.id))
        },
        {
            title: 'Образовательные программы',
            dataIndex: 'educationalPrograms',
            type: 'entity',
            multiple: true,
            editable: true,
            showInTable: true,
            entityName: 'educationalProgram',
            entityKey: 'id',
            entityLabel: ['name', 'specialty/Code', 'specialty/Name'],
            entityLabelFunc: (entity) => entity['specialty']
                ? `${entity['specialty']['code']} ${entity['specialty']['name']} (${entity['name']})`
                : entity['name'],
            entityFilter: ({ record, env }) => record && env && [inList('specialtyId', record.specialties, list => list.map(_ => _.id)), `clientId eq ${env.userClientId}`].filter(_ => _).join(' and ')
        },
    ]
}

export default metaRole;