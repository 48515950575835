import React, { useState, useEffect } from 'react';
import Table from '../_common/tables/TableOData';
import { Col, Row, Avatar, Menu, Dropdown, message } from 'antd';
import { getData } from '../_common/odata/entityodata';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Actions from '../_common/layout/Actions';
import './style.css'
import { post, get } from '../_common/utils/fetch';

const ProjectMembers = ({ projectId, refresh, onInit, regime, project, refreshRequired }) => {
    const metadata = {
        entity: 'projectMember',
        filter: `projectId eq ${projectId}`,

        properties: [
            {
                title: 'Участник',
                dataIndex: 'user',
                width: 150,
                type: 'entity',
                entityName: 'user',
                entityKey: 'id',
                entityLabel: 'name',
                select: ['avatarUrl', 'email', 'gravatarUrl'],
                editable: false,
                link: true
            },
            {
                title: 'Роль',
                dataIndex: 'projectRole',
                width: 150,
                type: 'entity',
                entityName: 'projectRole',
                entityKey: 'id',
                entityLabel: 'name',
                editable: false
            },
            {
                title: 'Состояние',
                dataIndex: 'state',
                width: 150,
                type: 'entity',
                entityName: 'projectMemberState',
                entityKey: 'code',
                entityLabel: 'name',
                editable: false,
                loadAll: true
            },
            {
                title: 'Системная роль',
                dataIndex: 'systemRole',
                width: 150,
                type: 'entity',
                entityName: 'projectMemberSystemRole',
                entityKey: 'code',
                entityLabel: 'name',
                editable: true,
                loadAll: true
            }
        ]
    }
    const [data, setData] = useState();
    const [selected, setSelected] = useState();
    const [tableApi, setTableApi] = useState();

    const onInitInternal = (tableApi) => {
        setTableApi(tableApi);
        if (onInit) onInit(tableApi);
    }

    useEffect(() => {
        if (regime === 'view') get({
            url: `${window.APPCFG.apiPath}/ProjectMember/GetByProject?projectId=${projectId}`,
            callback: setData
        })
        // eslint-disable-next-line
    }, [refreshRequired, regime])

    const exclude = () => {
        post({
            url: `${window.APPCFG.apiPath}/ProjectMember/Exclude`,
            json: {
                Ids: selected.map(_ => _.id)
            },
            callback: () => {
                tableApi.refresh();
                message.success("Действие выполнено");
                if (refresh) refresh();
            }
        })
    }

    const include = () => {
        post({
            url: `${window.APPCFG.apiPath}/ProjectMember/Include`,
            json: {
                Ids: selected.map(_ => _.id)
            },
            callback: () => {
                tableApi.refresh();
                message.success("Действие выполнено");
                if (refresh) refresh();
            }
        })
    }

    return regime === 'view'
        ? !data
            ? null
            : <Row>{data.sort((a, b) => (a.state.code === 'EXCLUDED' ? 1 : 0) - (b.state.code === 'EXCLUDED' ? 1 : 0)).map((member, idx) => <Col><ProjectMemberCard key={idx} data={member} /></Col>)}</Row>
        : <React.Fragment>
            <Actions items={[
                {
                    key: 'exclude',
                    label: 'Исключить выбранные',
                    disabled: !selected || selected.length === 0,
                    onClick: exclude
                },
                {
                    key: 'include',
                    label: 'Включить выбранные',
                    disabled: !selected || selected.length === 0,
                    onClick: include
                },
            ]} />
            <Table
                columns={metadata.properties}
                odataEntity={'projectMember'}
                rowKey={'id'}
                onInit={onInitInternal}
                editable={true}
                onAdd={false}
                onDelete={false}
                onCopy={false}
                fullScreen={false}
                odataFilter={`projectId eq ${projectId}`}
                pagination={false}
                rowSelection={{
                    onChange: (newSelectedRowKeys, newSelectedRows) => setSelected(newSelectedRows)
                }}
            />
        </React.Fragment>;
}

export default ProjectMembers;

const ProjectMemberCard = ({ data }) => {
    const avatarUrl = data.user.avatarUrl || data.user.gravatarUrl;
    return <div className='project-member-card' style={data.state.code === 'EXCLUDED' ? { opacity: 0.3 } : undefined}>
        <div className='icon'>
            <Avatar size={80} icon={!avatarUrl && <UserOutlined />} src={avatarUrl} />
        </div>
        <div className='name'><Link to={`/User/${data.user.id}`}>{data.user.name}</Link></div>
        <div className='role'>{data.projectRole ? data.projectRole.name : null}</div>
        <div className='email'><a href={`mailto:${data.user.Email}`}>{data.user.Email}</a></div>
    </div>;
}
