import React from 'react';
import './style.css'

const GradeCompanyAggregateResults = ({ gradeCompany, gradeCompanyResult, env, onSave }) => {
    const memberMapByUser = {}
    for (var member of gradeCompanyResult.members) {
        memberMapByUser[member.userId] = member;
    }
    const toStr = (num) => {
        return num.toLocaleString(undefined, { maximumFractionDigits: 2 }).replace(',','.');
    }
    return <div className='panel'>
        <table className='gradeTable'>
            <tr><td>Участник</td><td>Оценка</td></tr>
            {gradeCompanyResult.aggregate.map((r, idx) => <tr key={idx}>
                <td>{memberMapByUser[r.userId].name}</td>
                <td className='right'>{toStr(r.grade)}</td>
            </tr>)}
        </table>
    </div>
}

export default GradeCompanyAggregateResults;
