import moment from 'moment';

export const DATEFORMAT = 'DD.MM.YYYY'; 

export function toDefaultString(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return `${(dd > 9 ? '' : '0')}${dd}.${(mm > 9 ? '' : '0')}${mm}.${date.getFullYear()}`;
};

export function toDate(date) {
    return isDate(date) 
        ? date 
        : date instanceof moment 
            ? date.toDate() 
            : moment(date).toDate();
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function isDate(date) {
    return date instanceof Date && !isNaN(date);
}