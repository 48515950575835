import React, { useEffect, useState } from "react";
import { Modal, notification } from "antd";
import StepRegime from "./StepRegime";
import StepRegister from "./StepRegister";
import Wizard from "../../_common/layout/Wizard";
import { post } from "../../_common/utils/fetch";
import { useEnv } from "../../_common/hooks/useEnv";
import { prepareToSave } from "../../_common/odata/entityodata";
import meta from "../_meta";
import { prepareMetadata } from "../../_common/odata/entityodata";
import { systemInfo } from "../../_common/utils/systemInfo";
const { confirm } = Modal;

export default function ClientWizard() {
    const [open, setOpen] = useState(false);
    const [env] = useEnv();
    useEffect(() => {
        if (env && !env.registerPassed) setOpen(true);
    }, [env])

    const onlyView = () => {
        setOpen(false);
        notification.success({message: `Вы можете зарегистрироваться как Университет или Партнер в любой момент из меню Настройки/Профиль организации`});
        post({
            url: `${window.APPCFG.apiPath}/Client/CreateFromWizardMiss`,
        });
    }

    const onNext = ({ step, data, stepData }) => {
        if (step.key === 'regime') {
            if (stepData.regime === 'view') {
                onlyView();
            }
            else if (stepData.regime === 'regUnivercity') return 'regUnivercity';
            else if (stepData.regime === 'regBusiness') return 'regBusiness';
        }
        if (step.key === 'regUnivercity' || step.key === 'regBusiness') {
            stepData.TypeId = step.key === 'regUnivercity' ? 'UNIVERCITY' : 'BUSINESS';
            let type = step.key === 'regUnivercity' ? 'Университет' : 'Партнер';

            post({
                url: `${window.APPCFG.apiPath}/Client/CreateFromWizard`,
                json: prepareToSave({metadata: prepareMetadata({metadata: meta}), value: stepData}),
                callback: (data) => {
                    setOpen(false);
                    notification.success({message: `Вы зарегистрировались как ${type}, для полного доступа вам необходимо дождаться активации`});
                    let id = data && data.id;
                    if (id) systemInfo.changeClient({
                        clientId: id,
                        callback: () => {
                            window.location.reload();
                        }
                    });
                }
            });
            return step.key;
        }
    }

    const onClose = () => {
        confirm({
            title: `Данные не будут сохранены, вы уверены, что хотите выйти из мастера настройки?`,
            onOk: () => {
                onlyView();
            }
        });
    }

    return !open
        ? null
        : <Wizard onNext={onNext} onClose={onClose}
            steps={[
                {key: 'regime', title: 'Что будете делать дальше?', component: <StepRegime />},
                {key: 'regUnivercity', title: 'Заполните поля', component: <StepRegister type='Univercity' />},
                {key: 'regBusiness', title: 'Заполните поля', component: <StepRegister type='Business' />},
            ]}>
        </Wizard>
}
