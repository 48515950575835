import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { AntdExtContext } from '..';

const DATEFORMAT = 'DD.MM.YYYY';

const FieldDate = ({value, onChange, format, style={}, allowClear, ...rest}) => {
    const context = React.useContext(AntdExtContext);
    const dateFormat = (context && context.dateFormat) || DATEFORMAT;
    let v = !value ? value : value instanceof moment ? value : moment(value);
    if (value && !rest.showTime) v = v.startOf('day');
    return <DatePicker 
        style={{...style, width: '100%'}}
        value={v}
        onChange={v => onChange(v instanceof moment ? v.toDate() : v)}
        format={format || dateFormat} 
        allowClear={allowClear}
        {...rest}
    />
}

export default FieldDate;