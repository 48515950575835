import { useState, useEffect, useCallback } from "react";
import { systemInfo } from "../utils/systemInfo";

export function useEnv() {
    const [env, setEnv] = useState(systemInfo.systemInfo);
    const updateEnv = useCallback(() => setEnv(systemInfo.systemInfo), []);
    const hasRolesInternal = useCallback(({roles, withoutSuperadmin}) => {
        if (!env) return false;
        if (!withoutSuperadmin && env.isSuperAdmin) return true;
        if (!env.userRoles) return false;
        if (!roles || roles.length === 0) return true;
        for (let role of roles) {
            if (env.userRoles.indexOf(role) >= 0) return true;
        }
        return false;
    }, [env])
    // eslint-disable-next-line
    const hasRolesWithoutSuperadmin = useCallback(function () {
        return hasRolesInternal({roles: arguments, withoutSuperadmin: true});
    }, [hasRolesInternal])
    // eslint-disable-next-line
    const hasRoles = useCallback(function () {
        return hasRolesInternal({roles: arguments, withoutSuperadmin: false});
    }, [hasRolesInternal])
    useEffect(() => {
        systemInfo.subscribe(updateEnv);
        return function cleanup() {
            systemInfo.unsubscribe(updateEnv)
        };
    }, [updateEnv]);
    return [env, {hasRoles, hasRolesWithoutSuperadmin}];
}