import React, { useState } from 'react';
import { Input, Switch, Select, Row, Col, Space, TreeSelect } from 'antd';
import FilterPanel from '../_libs/antd-ext/filters/FilterPanel';
import { useLocationState } from '../_common/hooks/useLocationState';

let subAllOptions = ['participationRequest', 'member', 'curator', 'request'];
const filterOnlyMyOptions = [
    {
        value: 'all',
        title: 'Все мои',
        children: [
            {
                value: 'participationRequest',
                title: 'Заявки на участие',
            },
            {
                value: 'member',
                title: 'Участник',
            },
            {
                value: 'curator',
                title: 'Куратор',
            },
            {
                value: 'request',
                title: 'По заявкам на проект',
            },
        ],
    },
    {
        value: 'available',
        title: 'Доступные для выбора'
    },
    {
        value: 'open',
        title: 'Открытые для выбора'
    }
];

const ProjectsFilterPanel = ({ env, search, setSearch, listRegime, setListRegime, onlyMy, setOnlyMy, total, metadata, onAdditionalConfirm }) => {
    //const [onlyMy, setOnlyMy] = useLocationState(['available'], {code: 'onlyMy'});
    let additionalFilterProperties = !metadata
        ? null
        : metadata.properties.filter(_ => _.showInTable !== false &&
            (['type', 'projectCurator', 'educationalPrograms'].indexOf(_.dataIndex) >= 0 || _.isDynamic));
    return <div style={{ padding: '10px 0px' }}>
        <Space direction="vertical">
            <Space wrap>
                <Input allowClear={true} placeholder="Введите строку поиска" value={search} onChange={e => setSearch(e.target.value === "" ? null : e.target.value)} />
                <Select value={listRegime} onChange={setListRegime}>
                    <Select.Option value="card">Галерея</Select.Option>
                    <Select.Option value="list">Таблица</Select.Option>
                </Select>
                <span style={{ whiteSpace: 'nowrap' }}>
                    Мои: <TreeSelect multiple={true} showArrow={true} maxTagCount={1} value={onlyMy} allowClear={true}
                        onChange={(value) => {
                            if (!value || value.length == 0) setOnlyMy([]);
                        }}
                        onSelect={(value) => {
                            if (subAllOptions.indexOf(value) < 0) setOnlyMy([value]);
                            else if (onlyMy && subAllOptions.indexOf(onlyMy[0]) >= 0) 
                                setOnlyMy(state => {let ns = [...state]; ns.push(value); return ns;})
                            else setOnlyMy([value]);
                        }} onDeselect={(value) => {
                            setOnlyMy(state => {let ns = [...state]; ns.splice(state.indexOf(value), 1); return ns;})
                        }} style={{ width: '270px' }}
                        treeData={filterOnlyMyOptions}
                    />
                </span>
                {metadata && <FilterPanel properties={additionalFilterProperties} onConfirm={onAdditionalConfirm} />}
            </Space>
            <Space wrap>
                <span style={{ whiteSpace: 'nowrap' }}>Найдено проектов: {total || 0}</span>
            </Space>
        </Space>
    </div>
}

export default ProjectsFilterPanel;
