import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { post } from '../_common/utils/fetch';
import { message, Space, Input, Switch } from 'antd';
import fileExport from '../_common/utils/fileExport';
import FileDialog from '../_common/FileDialog';
import { getViewRootPath } from '../_common/hooks/useViewRoot';
import { useStoredState } from '../_common/hooks/useStoredState';
import { useDebounceState } from '../_common/hooks/useDebounceState';
import { useEnv } from '../_common/hooks/useEnv';
import metadata from './meta';

const Requests = (props) => {
    const [tableApi, setTableApi] = useState();
    const [uploadOpen, setUploadOpen] = useState();
    const [search, setSearch, debounceSearch, setDebounceSearch] = useDebounceState(useStoredState(undefined, { code: 'search' }));
    const [env, { hasRoles }] = useEnv();
    const [onlyMy, setOnlyMy] = useStoredState(false, { code: 'onlyMy', deserialize: (value) => value === 'true' });

    const getExcel = () => {
        fileExport(`${window.APPCFG.apiPath}/Request/GetExcel${tableApi.lastUrlSearchParams}`);
    }

    const uploadExcel = (file, pars) => {
        setUploadOpen(false);
        post({
            isGlobalProcessing: true,
            url: `${window.APPCFG.apiPath}/Request/UploadExcel`,
            formData: {
                File: file,
                Pars: pars ? JSON.stringify(pars.split(/[\s,]+/).filter(n => n)) : undefined
            },
            callback: () => {
                tableApi.refresh();
                message.success("Файл загружен");
            }
        });
    }

    const canRecordEdit = (record) => {
        return record.customer.id === env.userClientId &&
            (hasRoles('PROJECT_CREATOR')
                || (record.customerUser.id === env.userId && (record.state.code === 'DRAFT' || record.state.code === 'READYFORAGREED')));
    }

    return <MainContent layoutProps={props.layoutProps}
        title="Заявки на проекты"
        onClickRefresh={tableApi && tableApi.refresh}
        menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
        hasRoles('PROJECT_CREATOR') && { label: 'Выгрузить данные в Excel', key: 'getExcel', onClick: getExcel },
        hasRoles('PROJECT_CREATOR') && { label: 'Загрузить новые данные из Excel', key: 'uploadExcel', onClick: () => setUploadOpen(true) }]}
    >
        <React.Fragment>
            <div style={{ padding: '10px 0px' }}>
                <Space wrap>
                    <Input allowClear={true} placeholder="Введите строку поиска" value={search} onChange={e => setSearch(e.target.value === "" ? null : e.target.value)} />
                    <span style={{ whiteSpace: 'nowrap' }}>
                        Только мои: <Switch checked={onlyMy} onChange={setOnlyMy} />
                    </span>
                </Space>
            </div>
            <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={props.history}
                editable={true}
                onAdd={env.userActiveClientId
                    ? () => props.history.push(getViewRootPath({ mainRoot: 'Request', regime: 'create' }))
                    : false}
                onEdit={{
                    action: (record) => props.history.push(getViewRootPath({ mainRoot: 'Request', id: record[metadata.key], regime: 'edit' })),
                    disabled: ({ record }) => !canRecordEdit(record)
                }}
                onCopy={hasRoles('PROJECT_CREATOR') ? {
                    action: (record) => props.history.push(getViewRootPath({ mainRoot: 'Request', id: record[metadata.key], regime: 'copy' })),
                    disabled: ({ record }) => record.customer.id !== env.userClientId || !hasRoles('PROJECT_CREATOR'),
                } : false}
                onDelete={{
                    disabled: ({ record }) => !canRecordEdit(record)
                }}
                afterGetPath={(path) => `${path}${search ? `&fullTextSearch=${search}` : ''}${onlyMy ? `&onlyMy=${onlyMy}` : ''}`}
            />
        </React.Fragment>
        <div></div>
        <FileDialog title="Загрузить Excel" open={uploadOpen} onClose={() => setUploadOpen(false)} onOk={uploadExcel} />
    </MainContent>
}

export default withRouter(Requests);
