import Filter from "../filters/Filter";

const TableFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, ...rest }) => {
    return <Filter
        value={selectedKeys && selectedKeys[0] ? selectedKeys[0] : undefined}
        onChange={(value) => setSelectedKeys(value === null || value === undefined ? value : [value])}
        onOk={confirm}
        onClear={clearFilters}
        style={{ padding: 8, width: 300 }}
        size='middle'
        {...rest}
    />
}

export default TableFilter;