import { Link } from "react-router-dom";

const meta = {
    entity: 'selectionCompany',
    key: 'id',
    properties: [
        {
            title: 'Название',
            dataIndex: 'name',
            width: 150,
            type: 'string',
            editable: true,
            render: (text, record) => record ? <Link to={`/SelectionCompany/${record.id}`}>{text}</Link> : text,
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'selectionCompanyState',
            entityKey: 'code',
            entityLabel: 'name',
            loadAll: true,
            editable: false
        },
        {
            title: 'Дата начала',
            dataIndex: 'selectionStartDate',
            width: 150,
            type: 'date',
            editable: true,
            required: true
        },
        {
            title: 'Дата окончания',
            dataIndex: 'selectionFinishDate',
            width: 150,
            type: 'date',
            editable: true,
            required: true
        },
        {
            title: 'Автооткрытие',
            dataIndex: 'startAuto',
            width: 150,
            type: 'boolean',
            editable: true
        },
        {
            title: 'Автозакрытие',
            dataIndex: 'finishAuto',
            width: 150,
            type: 'boolean',
            editable: true
        },
        {
            title: 'Требуемая для участника ценность',
            type: 'number',
            dataIndex: 'requiredMemberWorkload',
            width: 200,
            editable: true
        }
    ]
};

export default meta;