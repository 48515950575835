import React from 'react';
import EntitySelector from '../_common/EntitySelector';
import metadata from './meta';
import cloneDeep from 'clone-deep';

const ProjectsSelector = ({title, ...rest}) => {
    const meta = cloneDeep(metadata);
    return <EntitySelector 
        metadata={meta}
        title={title || 'Выберите проекты'}
        {...rest}
    />
}

export default ProjectsSelector;