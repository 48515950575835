import React, { useRef } from 'react';
import FieldEntity from '../form/FieldEntity';
import FilterString from './FilterString';
import { AntdExtContext } from '..';
import locale_en_US from '../locale/en_US';
import { triggerFocus } from 'antd/lib/input/Input';

const FilterEntity = ({ value, onChange, confirm, idsVisisble = true, tagsVisible = true, style = {}, ...rest }) => {
    const context = React.useContext(AntdExtContext);
    const locale = (context && context.locale) || locale_en_US;
    const ref = useRef();
    const getValue = (value) => {
        if (!value) return value;
        if (!value.tags || value.tags.length === 0) value.tags = undefined;
        if (!value.ids || value.ids.length === 0) value.ids = undefined;
        return (!value.tags && !value.ids) ? null : { ...value };
    }
    return <div>
        {!rest.loadAll && tagsVisible && <React.Fragment>
            <FilterString
                value={value && value.tags}
                onChange={tags => onChange(getValue({ ...value, tags: tags }))}
            />
            <br />
            {locale.Table.orTryToFind}
        </React.Fragment>
        }
        {idsVisisble && <FieldEntity {...rest}
            mode="multiple"
            value={value && value.ids}
            onChange={ids => { onChange(getValue({ ...value, ids: ids })); ref.current.blur(); }}
            ref={ref}
        />}
    </div>
}

export default FilterEntity;