import React from 'react';
import { Modal } from 'antd';
import PropertyViewer from './PropertyViewer';
import CardBlock from './CardBlock';
import './viewer.css'

const Viewer = ({ value, metadata, showNull = false, card, title, open, onCancel }) => {
    if (!value || (card && !open)) return null;
    const component = <div className='entity'><CardBlock properties={metadata.properties} metadata={metadata} record={value}
        showNull={showNull}
        singlePropertyRender={(props) => <PropertyViewer {...props} />} /></div>
    return card
        ? <Modal title={title}
            open={open}
            onCancel={onCancel}
            footer={[]}>
            {component}
        </Modal>
        : component;
}

export default Viewer;