import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';

const metadata = {
    entity: 'Partnership',
    key: 'id',
    properties: [
        {
            title: 'Партнер',
            dataIndex: 'partner',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName'
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'partnershipState',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            loadAll: true,
            required: false
        }
    ],
    default: {
        //required: true,
        //editable: true,
        //sortable: true
    }
};

const Partnership = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env] = useEnv();

    return <MainContent layoutProps={props.layoutProps}
            title="Партнеры"
            onClickRefresh={tableApi && tableApi.refresh}
        >
            {env && <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={props.history}
                editable={false}
                odataFilter={`clientId eq ${env.userClientId}`}
            />}
        </MainContent>
}

export default withRouter(Partnership);
