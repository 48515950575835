import React from 'react';
import { Collapse } from 'antd'
import "./style.css"

const { Panel } = Collapse;

const PropertyPanel = ({ groups }) => {
    if (!groups) return null;
    const renderPropertyGroup = (group) => {
        return <div className='propertyPanel'><table>
            <thead>
                <tr>
                    <th>Параметр</th>
                    <th>Значение</th>
                </tr>
            </thead>
            <tbody>
                {group.properties.map((property, idx) =>
                    <tr key={idx}>
                        <td>{property.key}</td>
                        <td>{property.url ? <a href={property.url} rel="noopener noreferrer" target="_blank">{property.value}</a> : property.value}</td>
                    </tr>
                )}
            </tbody>
        </table></div>
    }
    return groups.length === 1
        ? renderPropertyGroup(groups[0])
        : <Collapse bordered={false} defaultActiveKey={[groups[0].key || '0']}>
            {groups.map((group, idx) =>
                <Panel header={group.title} key={group.key || idx}>
                    {renderPropertyGroup(group)}
                </Panel>
            )}
        </Collapse>
}

export default PropertyPanel;