import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';

const metadata = {
    entity: 'academicGroup',
    key: 'id',
    properties: [
        {
            title: 'Название',
            dataIndex: 'name',
            width: 250,
            editable: true,
            required: true 
        }
    ]
};

const AcademicGroups = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env, {}] = useEnv();

    return <MainContent layoutProps={props.layoutProps}
        title="Академические группы"
        onClickRefresh={tableApi && tableApi.refresh}
    >
        {env && <Table
            columns={metadata.properties}
            odataEntity={metadata.entity}
            rowKey={metadata.key}
            onInit={setTableApi}
            history={props.history}
            editable={false}
            odataFilter={`clientId eq ${env.userClientId}`}
        />}
    </MainContent>
}

export default withRouter(AcademicGroups);
