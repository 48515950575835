import React from 'react';
import Table from './Table';
import types from '../types'

const TypedTable = ({ forwardedRef, columns, types: customTypes = {}, ...rest }) => {
    return <Table
        columns={columns}
        types={{...types, ...customTypes}}
        ref={forwardedRef}
        {...rest}
    />
}

export default React.forwardRef((props, ref) => { return <TypedTable {...props} forwardedRef={ref} />; });