import React from 'react';
import { withRouter } from "react-router-dom";
import { prepareMetadata } from '../../_common/odata/entityodata';
import { Modal, Form, Select } from 'antd';
import { FormItems } from '../../_libs/antd-ext';
import metaRole from '../metaRole';
import { prepareToSave } from '../../_common/odata/entityodata';
const { Option } = Select;
const { confirm } = Modal;

const CreateRoleDialog = ({ open, onCancel, onOk, selectedRecords }) => {
    const [form] = Form.useForm();
    form.setFieldsValue({ whenExisting: 'refresh', workload: 1 })
    let metadata = prepareMetadata({ metadata: metaRole });

    const onOkInternal = async () => {
        let row = await form.validateFields();
        confirm({
            title: `Вы действительно хотите создать роль в проектах (${selectedRecords.length})?`,
            onOk: () => {
                let json = prepareToSave({ metadata, value: row, creating: true, editingKey: null });
                onOk(json, selectedRecords);
            }
        });
    }

    if (!selectedRecords || selectedRecords.length === 0) return null;

    return <Modal open={open} onOk={onOkInternal} onCancel={onCancel}
        title={`Создание роли для всех выбранных проектов (${selectedRecords.length})`}
        okText={`Создать роль в проектах (${selectedRecords.length})`}
        width={'90%'}
        heigth={'400px'}
        bodyStyle={{ overflowY: 'scroll', height: '60vh' }}>
        <Form
            form={form}
            autoComplete="off"
            layout="horizontal"
        >
            <Form.Item name="whenExisting" label="Если роль существует в проекте">
                <Select>
                    <Option value="refresh">Обновить настройки роли</Option>
                    <Option value="none">Ничего не делать</Option>
                </Select>
            </Form.Item>
            <FormItems
                metadata={metadata}
                editable={true}
            />
        </Form>
    </Modal>

}

export default withRouter(CreateRoleDialog);