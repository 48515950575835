import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import { useMetadata } from '../_common/hooks/useMetadata';
import { post } from '../_common/utils/fetch';
import { message } from 'antd';
import { useViewRoot, getViewRootPath } from '../_common/hooks/useViewRoot';
import meta from './meta';
import SelectionCompany from './SelectionCompany';
import { CreditCardOutlined } from '@ant-design/icons';

const SelectionCompanies = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env, {hasRoles}] = useEnv();
    const [selectedRows, setSelectedRows] = useState();
    const [metadata] = useMetadata(meta);
    let { regime, id } = useViewRoot();

    const isSelectedInState = (state) => {
        const selected = selectedRows;
        if (!selected || selected.length === 0) return false;
        const stateArr = Array.isArray(state) ? state : [state];
        return selected.every(_ => stateArr.indexOf(_.state.code) >= 0);
    }

    const changeState = (action) => {
        let ids = selectedRows.map(_ => _.id);
        if (ids.length === 0) return;
        post({
            url: `${window.APPCFG.apiPath}/SelectionCompany/${action}`,
            json: {
                ids
            },
            callback: () => {
                message.success("Успешно");
                tableApi.refresh();
                tableApi.setSelectedRows();
                setSelectedRows();
            }
        })
    }

    return !metadata
        ? null
        : regime === 'view'
            ? <SelectionCompany id={id} layoutProps={props.layoutProps} />
            : <MainContent layoutProps={props.layoutProps}
                title="Выборные кампании"
                onClickRefresh={tableApi && tableApi.refresh}
                menu={[...((tableApi && tableApi.getMainContentMenuItems()) || []),
                { label: 'Открыть', disabled: !isSelectedInState(['NOTACTIVE', 'CLOSED']), key: "toOpen", onClick: () => changeState('ToOpen') },
                { label: 'Вернуть в неактивные', disabled: !isSelectedInState(['OPEN']), key: "toNotActive", onClick: () => changeState('ToNotActive') },
                { label: 'Закрыть', disabled: !isSelectedInState(['OPEN']), key: "toClosed", onClick: () => changeState('ToClose') },
                ]}
            >
                {env && <Table
                    columns={metadata.properties}
                    odataEntity={metadata.entity}
                    rowKey={metadata.key}
                    onInit={setTableApi}
                    history={props.history}
                    odataFilter={`clientId eq ${env.userClientId}`}
                    rowSelection={hasRoles('ADMIN') ? {
                        onChange: (newSelectedRowKeys, newSelectedRows) => setSelectedRows(newSelectedRows)
                    } : false}
                    onCopy={false}
                    editable={hasRoles('ADMIN') ? undefined : false}
                />}
            </MainContent>
}

export default withRouter(SelectionCompanies);
