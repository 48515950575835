import { renderToString } from 'react-dom/server'

const htmlToElement = (html) => {
    var template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
}

export const getBlog = (component) => { 
    const el = htmlToElement(renderToString(component));
    el.childNodes[0].setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const svg = el.innerHTML;
    return new Blob([svg], { type: 'image/svg+xml' });
}

export const getObjectUrl = (component) => { 
    return URL.createObjectURL(getBlog(component));
}

export const toFavicon = (component) => {
    const url = getObjectUrl(component);
    document.getElementById("favicon").href = url;
}