import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU';
import 'moment/locale/ru';
import locale_ext from './_libs/antd-ext/locale/ru_RU';
import { AntdExtContext } from './_libs/antd-ext';

import { systemInfo } from './_common/utils/systemInfo';
import { getCookie } from './_common/utils/cookie';
import { get } from './_common/utils/fetch';
import AutoLoginPanel from './_common/AutoLoginPanel'

import Layout from './_common/layout/Layout';
import SystemInfoDialog from './_common/layout/SystemInfoDialog';
import ErrorDialog from './_common/ErrorDialog';

import 'antd/dist/antd.min.css';
import './custom.css'

import SystemInfo from './_common/layout/SystemInfo';
import Instructions from './_common/layout/Instructions';

import Client from './clients/Client';
import Clients from './clients/Clients';
import Department from './clients/Department';
import EducationalProgram from './clients/EducationalProgram';
import Department2 from './clients/Department2';
import Institute from './clients/Institute';
import AcademicGroups from './clients/AcademicGroups';
import ClientSize from './clients/ClientSize';
import ClientOwnershipForm from './clients/ClientOwnershipForm';

import Request from './requests/Request';
import Requests from './requests/Requests';

import Projects from './projects/Projects';
import Project from './projects/Project';
import ProjectType from './projects/ProjectType';
import ProjectApprovalStepSetting from './projects/ProjectApprovalStepSetting';

import DynamicAttributeMeta from './dynamicattributes/DynamicAttributeMeta';
import DynamicList from './dynamicattributes/DynamicList';
import DynamicListItem from './dynamicattributes/DynamicListItem';

import Partnership from './partnerships/Partnership';
import PartnershipRequest from './partnerships/PartnershipRequest';
import PartnershipSetting from './partnerships/PartnershipSetting';

import File from './files/File';
import Specialty from './common/Specialty';
import EducationLevel from './common/EducationLevel';
import StudyYear from './common/StudyYear';
import SystemEnv from './systemenvs/SystemEnv';

import User from './users/User';
import UserRole from './users/UserRole';
import Student from './users/Student';
import Employee from './users/Employee';

import SelectionCompanies from './selectioncompanies/SelectionCompanies';

import { consoleLogOnOff } from './_common/utils/console';

import Tests from './_common/tables/tests/Tests';
import ExampleAntdTable from './_libs/antd-table-ext/examples/EditableRows';
import Northwind from './_libs/protonus/examples/Northwind';
import ProtonusProjects from './_libs/protonus/examples/Projects';
import ProtonusPartnershipSetting from './_libs/protonus/examples/PartnershipSetting';

import { initFlags, getFlag } from './_common/hooks/useFlag';
import ClientWizard from './clients/wizard/ClientWizard';
import Notifications from './notifications/Notifications';

import EventType from './events/EventType';
import Events from './events/Events';
import Event from './events/Event';

import PrintDocumentTypes from './printdocuments/PrintDocumentTypes';
import PrintDocumentType from './printdocuments/PrintDocumentType';

import GlobalGradeCampaigns from './grades/globalgradecampaigns/GlobalGradeCampaigns';
import GlobalGradeCampaign from './grades/globalgradecampaigns/GlobalGradeCampaign';
import ProjectGradeCampaigns from './grades/globalgradecampaigns/ProjectGradeCampaigns';

// TODO надо разобраться с таймзонами, пока подменяю сериализацию
Date.prototype.toJSON = function(){
    const hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
    this.setHours(hoursDiff);
    return this.toISOString();
};

const globalflags = {

}

class App extends Component {
    state = {
        checkingAuth: true,
        checkedAuth: false
    };

    componentDidMount() {
        if (window.location.search === '?LogoutSpecial=1') {
            window.location.href = '/api/System/LogoutSpecial';
            return;
        }
        initFlags({
            flags: globalflags
        });
        if (process.env.NODE_ENV !== 'development') {
            consoleLogOnOff(false);
            window.ym(91098847, "init", {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true
            });
        }
        if (getFlag('ConsoleLog')) consoleLogOnOff(true);
        systemInfo.subscribe(this.updateSystemInfo);
        var cookie = getCookie(".AspNet.SharedCookie");
        if (cookie) {
            this.setState({ checkingAuth: true });
            this.checkAuth();
        } else {
            this.setState({ checkingAuth: false });
        }
    }

    componentWillUnmount() {
        systemInfo.unsubscribe(this.updateSystemInfo)
    }

    updateSystemInfo = (systemInfo) => {
        if (window.FS) window.FS.identify(systemInfo.userId, {
            displayName: systemInfo.userLogin
        });
    }

    checkAuth = () => {
        get({
            url: `${window.APPCFG.apiPath}/System/CheckAuth`,
            callback: (data) => {
                this.setState({ checkedAuth: data === 'Ok', checkingAuth: false });
            }
        });
    }

    render() {
        if (window.location.search === '?LogoutSpecial=1') {
            window.location.href = '/api/System/LogoutSpecial';
            return null;
        }

        if (this.state.checkingAuth) {
            return '...';
        }

        if (!this.state.checkedAuth) {
            return <AutoLoginPanel />;
        }

        return <BrowserRouter basename={window.APPCFG.basePath}>
            <ConfigProvider locale={ru_RU}>
                <AntdExtContext.Provider value={{ locale: locale_ext }}>
                    <ClientWizard />
                    <Layout>
                        {
                            (layoutProps) => <React.Fragment>
                                <Route exact path="/"><Redirect to="/Projects" /></Route>
                                <Route path='/SystemInfo' render={(props) => <SystemInfo {...props} layoutProps={layoutProps} />} />
                                <Route path='/SystemEnv' render={(props) => <SystemEnv {...props} layoutProps={layoutProps} />} />
                                <Route path='/Instructions' render={(props) => <Instructions {...props} layoutProps={layoutProps} />} />

                                <Route path='/Clients' render={(props) => <Clients {...props} layoutProps={layoutProps} />} />
                                <Route path='/Client' render={(props) => <Client {...props} layoutProps={layoutProps} />} />
                                <Route path='/Department' render={(props) => <Department {...props} layoutProps={layoutProps} />} />
                                <Route path='/Department2' render={(props) => <Department2 {...props} layoutProps={layoutProps} />} />
                                <Route path='/EducationalProgram' render={(props) => <EducationalProgram {...props} layoutProps={layoutProps} />} />
                                <Route path='/ClientSize' render={(props) => <ClientSize {...props} layoutProps={layoutProps} />} />
                                <Route path='/ClientOwnershipForm' render={(props) => <ClientOwnershipForm {...props} layoutProps={layoutProps} />} />
                                <Route path='/ProjectApprovalStepSetting' render={(props) => <ProjectApprovalStepSetting {...props} layoutProps={layoutProps} />} />
                                <Route path='/AcademicGroups' render={(props) => <AcademicGroups {...props} layoutProps={layoutProps} />} />
                                <Route path='/Institute' render={(props) => <Institute {...props} layoutProps={layoutProps} />} />

                                <Route path='/Request' render={(props) => <Request {...props} layoutProps={layoutProps} />} />
                                <Route path='/Requests' render={(props) => <Requests {...props} layoutProps={layoutProps} />} />

                                <Route path='/Projects' render={(props) => <Projects {...props} layoutProps={layoutProps} env={systemInfo && systemInfo.systemInfo} />} />
                                <Route path='/Project' render={(props) => <Project {...props} layoutProps={layoutProps} />} />
                                <Route path='/ProjectType' render={(props) => <ProjectType {...props} layoutProps={layoutProps} />} />

                                <Route path='/DynamicAttributeMeta' render={(props) => <DynamicAttributeMeta {...props} layoutProps={layoutProps} />} />
                                <Route path='/DynamicList' render={(props) => <DynamicList {...props} layoutProps={layoutProps} />} />
                                <Route path='/DynamicListItem' render={(props) => <DynamicListItem {...props} layoutProps={layoutProps} />} />

                                <Route path='/Partnership' render={(props) => <Partnership {...props} layoutProps={layoutProps} />} />
                                <Route path='/PartnershipSetting' render={(props) => <PartnershipSetting {...props} layoutProps={layoutProps} />} />
                                <Route path='/PartnershipRequest' render={(props) => <PartnershipRequest {...props} layoutProps={layoutProps} />} />

                                <Route path='/User' render={(props) => <User {...props} layoutProps={layoutProps} />} />
                                <Route path='/UserRole' render={(props) => <UserRole {...props} layoutProps={layoutProps} />} />
                                <Route path='/Student' render={(props) => <Student {...props} layoutProps={layoutProps} />} />
                                <Route path='/Employee' render={(props) => <Employee {...props} layoutProps={layoutProps} />} />

                                <Route path='/File' render={(props) => <File {...props} layoutProps={layoutProps} />} />
                                <Route path='/Specialty' render={(props) => <Specialty {...props} layoutProps={layoutProps} />} />
                                <Route path='/EducationLevel' render={(props) => <EducationLevel {...props} layoutProps={layoutProps} />} />
                                <Route path='/StudyYear' render={(props) => <StudyYear {...props} layoutProps={layoutProps} />} />
                                
                                <Route path='/SelectionCompany' render={(props) => <SelectionCompanies {...props} layoutProps={layoutProps} />} />
                            
                                <Route path='/Notifications' render={(props) => <Notifications {...props} layoutProps={layoutProps} />} />
                            
                                <Route path='/EventTypes' render={(props) => <EventType {...props} layoutProps={layoutProps} />} />
                                <Route path='/Events' render={(props) => <Events {...props} layoutProps={layoutProps} env={systemInfo.systemInfo} />} />
                                <Route path='/Event' render={(props) => <Event {...props} layoutProps={layoutProps} />} />

                                <Route path='/Tests' render={(props) => <Tests {...props} layoutProps={layoutProps} />} />
                                <Route path='/Northwind' render={(props) => <Northwind {...props} layoutProps={layoutProps} />} />
                                <Route path='/ProtonusProjects' render={(props) => <ProtonusProjects {...props} layoutProps={layoutProps} />} />
                                <Route path='/ProtonusPartnershipSetting' render={(props) => <ProtonusPartnershipSetting {...props} layoutProps={layoutProps} />} />
                                <Route path='/ExampleAntdTable' render={(props) => <ExampleAntdTable {...props} layoutProps={layoutProps} />} />
                            
                                <Route path='/PrintDocumentTypes' render={(props) => <PrintDocumentTypes {...props} layoutProps={layoutProps} />} />
                                <Route path='/PrintDocumentType' render={(props) => <PrintDocumentType {...props} layoutProps={layoutProps} />} />
                            
                                <Route path='/GlobalGradeCampaigns' render={(props) => <GlobalGradeCampaigns {...props} layoutProps={layoutProps} />} />
                                <Route path='/GlobalGradeCampaign' render={(props) => <GlobalGradeCampaign {...props} layoutProps={layoutProps} />} />
                                <Route path='/ProjectGradeCampaigns' render={(props) => <ProjectGradeCampaigns {...props} layoutProps={layoutProps} />} />
                            </React.Fragment>
                        }
                    </Layout>
                    <SystemInfoDialog />
                    <ErrorDialog />
                </AntdExtContext.Provider>
            </ConfigProvider>
        </BrowserRouter>;
    }
}

export default App;