import { inList } from "../_common/odata/odata";
import { Link } from "react-router-dom";

const meta = {
    name: 'project',
    entity: 'project',
    key: 'id',
    properties: [
        {
            title: 'Название',
            required: true,
            dataIndex: 'name',
            width: 250,
            render: (text, record) => record ? <Link to={`/Project/${record.id}`}>{text}</Link> : text,
            description: 'Укажите название проекта, раскрывающее его суть',
        },
        {
            title: 'Тип',
            description: 'Тип проекта является важным полем для процессов системы, поэтому он не изменяется. Если вам все-таки необходимо поменять тип проекта, то поменяйте его через меню Действия',
            dataIndex: 'type',
            width: 150,
            type: 'entity',
            entityName: 'projectType',
            entityKey: 'id',
            entityLabel: 'name',
            loadAll: true,
            required: true,
            responsive: ["sm"],
            editable: {
                updating: false
            }
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            type: 'text',
            editable: true,
            required: true,
            showInTable: false,
            description: 'Полно и подробно опишите суть проекта, откуда появилась потребность, какие проблемы он решает',
        },
        {
            title: 'Цель',
            dataIndex: 'goal',
            type: 'text',
            editable: true,
            showInTable: false,
            description: 'Опишите цели и задачи проекта. Целью проекта может быть разработка продукта или услуги, прирост научного знания и т.п.'
        },
        {
            title: 'Результат/продукт',
            dataIndex: 'result',
            type: 'text',
            editable: true,
            showInTable: false,
            description: 'Опишите требования к продукту/результату'
        },
        {
            title: 'Куратор проекта',
            dataIndex: 'projectCurator',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Куратор от заказчика',
            dataIndex: 'customerProjectCurator',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Информация о заказчике',
            dataIndex: 'customerInfo',
            type: 'text',
            editable: true,
            showInTable: false,
            description: 'Если заказчик заведен в системе, то нужно заполнить поле Куратор от заказчика, в противном случае информация указывается просто в тексовом виде',
        },
        {
            title: 'Начало',
            dataIndex: 'startDate',
            width: 150,
            type: 'date',
            editable: true,
            responsive: ["sm"]
        },
        {
            title: 'Окончание',
            dataIndex: 'finishDate',
            width: 150,
            type: 'date',
            editable: true,
            responsive: ["sm"]
        },
        {
            title: 'Выборная кампания',
            dataIndex: 'selectionCompany',
            width: 150,
            type: 'entity',
            entityName: 'selectionCompany',
            entityKey: 'id',
            entityLabel: 'name',
            editable: true,
            responsive: ["sm"]
        },
        {
            title: 'Начало записи на проект',
            dataIndex: 'selectionStartDate',
            width: 150,
            type: 'date',
            editable: true,
            showInTable: false
        },
        {
            title: 'Окончание записи на проект',
            dataIndex: 'selectionFinishDate',
            width: 150,
            type: 'date',
            editable: true,
            showInTable: false
        },
        {
            title: 'Уровни образования',
            dataIndex: 'educationLevels',
            type: 'entity',
            multiple: true,
            editable: true,
            showInTable: false,
            entityName: 'educationLevel',
            entityKey: 'id',
            entityOrder: 'ord',
            entityLabel: 'name',
            loadAll: true,
            responsive: ["sm"]
        },
        {
            title: 'Курсы',
            dataIndex: 'studyYears',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'studyYear',
            entityKey: 'id',
            entityLabel: 'name',
            entityOrder: 'educationLevelId, num',
            entityFilter: ({record}) => record && inList('educationLevelId', record.educationLevels, list => list.map(_ => _.id)),
            loadAll: true,
            showInTable: false,
            responsive: ["sm"]
        },
        {
            title: 'Кафедры',
            dataIndex: 'departments',
            type: 'entity',
            multiple: true,
            newMultiple: true,
            entityName: 'department2',
            entityKey: 'id',
            entityLabel: 'name',
            responsive: ["sm"]
        },
        {
            title: 'Направления подготовки',
            dataIndex: 'specialties',
            type: 'entity',
            multiple: true,
            editable: true,
            //showInTable: true,
            entityName: 'specialty',
            entityKey: 'id',
            entityLabel: ['code', 'name'],
            entityFilter: ({record}) => record && inList('educationLevelId', record.educationLevels, list => list.map(_ => _.id)),
            responsive: ["sm"]
        },
        {
            title: 'Образовательные программы',
            dataIndex: 'educationalPrograms',
            type: 'entity',
            multiple: true,
            editable: true,
            //showInTable: false,
            entityName: 'educationalProgram',
            entityKey: 'id',
            entityLabel: ['name', 'specialty/code', 'specialty/name'],
            entityLabelFunc: (entity) => entity['specialty'] 
                ? `${entity['specialty']['code']} ${entity['specialty']['name']} (${entity['name']})`
                : entity['name'],
            entityFilter: ({record, env}) => record && env && [inList('specialtyId', record.specialties, list => list.map(_ => _.id)), `clientId eq ${env.userClientId}`].filter(_ => _).join(' and '),
            responsive: ["sm"]
        },
        {
            title: 'Состояние',
            dataIndex: 'state',
            width: 150,
            type: 'entity',
            entityName: 'projectState',
            entityKey: 'code',
            entityLabel: 'name',
            entityOrder: 'order',
            loadAll: true,
            required: false,
            editable: false,
            responsive: ["sm"],
            defaultFilteredValue: [
                { value: { ids: [{ code: 'DEFERRED' }, { code: 'CLOSED' }] }, exclude: true }
            ],
            render: (text, record) => {
                let color = record.state.code === 'DEFERRED'
                    ? 'lightgray'
                    : record.state.code === 'DRAFT'
                        ? 'lightgreen'
                        : record.state.code === 'CLOSED'
                            ? 'lightgray'
                            : record.state.code === 'ACCEPTED'
                                ? 'green'
                                : record.state.code === 'AGREED' || record.state.code === 'READYFORAGREED'
                                    ? 'brown'
                                    : record.state.code === 'REJECTED'
                                        ? 'lightgray'
                                        : 'black';
                return <div style={{ color: color }}>{text.name}</div>
            }
        },
        {
            title: 'Заказчик',
            dataIndex: 'customer',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            editable: false,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Исполнитель',
            dataIndex: 'executor',
            width: 150,
            type: 'entity',
            entityName: 'client',
            entityKey: 'id',
            entityLabel: 'shortName',
            editable: false,
            link: true,
            responsive: ["sm"]
        },
        {
            title: 'Кол-во вакансий',
            type: 'number',
            dataIndex: 'quantity',
            width: 150,
            editable: false,
            responsive: ["sm"]
        },
        {
            title: 'Кол-во участников',
            type: 'number',
            dataIndex: 'quantityUser',
            width: 150,
            editable: false,
            responsive: ["sm"]
        },
        {
            title: 'Доступно к записи',
            type: 'number',
            editable: false,
            dataIndex: 'availableQuantity',
            width: 150,
            responsive: ["sm"]
        },
        {
            title: 'Критерии приемки',
            dataIndex: 'acceptanceCriteria',
            type: 'text',
            editable: true,
            showInTable: false,
            description: 'Опишите необходимый формат представления результатов, который подлежит оцениванию'
        },
        {
            title: 'Название команды',
            showInTable: false,
            dataIndex: 'teamName',
            width: 150,
            description: 'Укажите название команды, создающей проект',
        },
        {
            title: 'Заявка',
            dataIndex: 'request',
            width: 150,
            showInTable: false,
            type: 'entity',
            entityName: 'request',
            entityKey: 'id',
            entityLabel: 'name',
            editable: false,
            link: true
        },
        {
            title: 'Проект в таскменеджере',
            dataIndex: 'issueTrackerUrl',
            width: 150,
            editable: false,
            render: (text) => !text ? "" : <a href={text}>{text}</a>, 
            showInTable: false
        }
    ],
    defaults: {
        //required: true,
        editable: true,
        //sortable: true
    }
};

export default meta;