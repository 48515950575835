import React, { useState } from 'react';
import Table from '../_common/tables/TableOData';
import MainContent from '../_common/layout/MainContent';
import { withRouter } from "react-router-dom";
import { useEnv } from '../_common/hooks/useEnv';
import { systemInfo } from '../_common/utils/systemInfo';

const metadata = {
    entity: 'userRole',
    key: 'id',
    properties: [
        {
            title: 'Пользователь',
            dataIndex: 'user',
            width: 150,
            type: 'entity',
            entityName: 'user',
            entityKey: 'id',
            entityLabel: 'name',
            required: true,
            editable: {
                updating: false
            }
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            width: 150,
            type: 'entity',
            entityName: 'role',
            entityKey: 'code',
            entityLabel: 'name',
            required: true,
            editable: true,
            loadAll: true
        }
    ]
};

const UserRole = (props) => {
    const [tableApi, setTableApi] = useState();
    const [env] = useEnv();

    const onChange = (records) => {
        const needRefresh = records && records.some(_ => _.userId === env.userId || (_.user && _.user.id === env.userId));
        if (!needRefresh) return false;
        systemInfo.changeClient({
            clientId: env.userClientId, 
            callback: () => {
                window.location.reload();
            }
        });
        return true;
    }

    return <MainContent layoutProps={props.layoutProps}
            title="Роли и права"
            onClickRefresh={tableApi && tableApi.refresh}
        >
            {env && <Table
                columns={metadata.properties}
                odataEntity={metadata.entity}
                rowKey={metadata.key}
                onInit={setTableApi}
                history={props.history}
                editable={true}
                onEdit={false}
                odataFilter={`clientId eq ${env.userClientId}`}
                onSave={(result, {request}) => onChange([request])}
                afterDelete={({records}) => onChange(records)}
            />}
        </MainContent>
}

export default withRouter(UserRole);

