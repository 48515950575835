import React from 'react';
import { UserOutlined, LogoutOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Dropdown, Select } from 'antd';
import moment from 'moment';
import { showSystemInfo } from './SystemInfoDialog';
import { systemInfo } from '../utils/systemInfo';
import { isTouchDevice } from '../utils/browserCapabilities';
import { post } from '../utils/fetch';

export default function SystemInfoButton(props) {
    let color = (props.systemInfo && props.systemInfo.mainUserId) ? 'red' : undefined;
    const clientChange = (value) => {
        systemInfo.changeClient({
            clientId: value,
            callback: () => {
                window.location.reload();
            }
        });
    }

    const getClientName = (client) => {
        return `${client.shortName}${client.stateId === 'NOTACTIVE' ? ' (Не активна)' : client.stateId === 'DRAFT' ? ' (Черновик)' : ''}`
    }

    const impersonate = () => {
        if (props.systemInfo.mainUserId) post({
            url: `${window.APPCFG.apiPath}/System/Impersonate`,
            json: props.systemInfo.mainUserId,
            callback: () => window.location.reload()
        })
    }

    return <React.Fragment>
        <Dropdown trigger={isTouchDevice() ? ['click'] : ['hover']} overlay={
            <React.Fragment>
                <div style={{ backgroundColor: '#FFFFFF', border: '1px solid #f1f1f1', minWidth: 230, padding: '10px' }}>
                    <div style={{ paddingBottom: '10px' }}>
                        {props.systemInfo.userClients && props.systemInfo.userClientId &&
                            <div>Организация: {props.systemInfo.userClients.length > 1
                                ? <Select style={{ minWidth: 100 }} size="small" value={props.systemInfo.userClientId} onChange={clientChange} options={props.systemInfo.userClients.map(_ => ({ label: getClientName(_), value: _.id }))} />
                                : getClientName(props.systemInfo.userClients[0])}
                            </div>}
                        <div>Пользователь: {props.systemInfo.userName || props.systemInfo.userId}</div>
                        {props.systemInfo.mainUserId && <div style={{ color: 'red', cursor: 'pointer' }} onClick={impersonate}>
                            Суперпользователь: {props.systemInfo.mainUserName || props.systemInfo.mainUserId}
                        </div>}
                        <div>Стенд: {props.systemInfo.envName} ({props.systemInfo.version || '?'}) {systemInfo && systemInfo.systemInfo && systemInfo.systemInfo.isSuperAdmin && <InfoCircleOutlined style={{ fontSize: 16 }} title="Информация о системе" onClick={showSystemInfo} />}</div>
                        {(props.systemInfo.viewDate)
                            ? <span style={{ color: 'orange' }}><br />Системная дата: {moment(props.systemInfo.viewDate).format('DD.MM.YYYY')}</span>
                            : null
                        }
                    </div>
                    <Button icon={<LogoutOutlined />} onClick={props.onLogout}>Выйти</Button>
                </div>
            </React.Fragment>
        } placement="bottomRight" >
            <Button shape="circle" type="text" icon={<UserOutlined style={{ fontSize: '20px' }} />} style={{ color: color }} />
        </Dropdown >

    </React.Fragment >
}