import React from "react";
import Field from "../fields/Field";

const EditableCell = ({
    metadata,
    editing,
    column,
    record,
    editableDefault,
    creating,
    index,
    children,
    ...restProps
}) => {
    if (!editing)
        return <td {...restProps}>{children}</td>

    let disabled = metadata.getPropertyDisabled({record, property: column, creating});

    return (
        <td {...restProps}>
            <Field
                errorOnTooltip={true}
                type={column.type}
                name={column.dataIndex}
                label={column.title}
                {...column}
                style={{
                    margin: 0,
                }}
                disabled={disabled}
                record={record}
            />
        </td>
    );
};

export default EditableCell;