import React, { useState, useCallback } from "react";
import { Modal, Form, Button } from "antd";

export default function Wizard({ steps, onNext, onClose }) {
    const [step, setStep] = useState(steps[0]);
    const [form] = Form.useForm();
    const [data, setData] = useState({});
    const [stepHistory, setStepHistory] = useState([steps[0]]);

    const getStepByKeyOrNext = (key) => {
        let nextStep = !key ? null : steps.filter(_ => _.key === key)[0];
        if (!nextStep) {
            let stepIndex = steps.indexOf(step);
            nextStep = steps[stepIndex + 1];
        }
        return nextStep;
    }

    const handleNextStep = useCallback((stepData) => {
        let newData = {...data, ...stepData};
        setData(newData);
        let nextStep = onNext
            ? getStepByKeyOrNext(onNext({step, data: newData, stepData}))
            : getStepByKeyOrNext();
        if (nextStep.key !== step.key)
        {
            setStep(nextStep);
            setStepHistory([...stepHistory, nextStep]);
        }
    }, [step]);

    const handlePrevStep = useCallback((stepData) => {
        let prevStep = stepHistory[stepHistory.length - 1];
        setStep(prevStep);
        setStepHistory(stepHistory.slice(0, stepHistory.length));
    }, [step]);

    return (
        <Form
            form={form}
            autoComplete="off"
            layout="horizontal"
        >
            <Modal title={step.title}
                open={true}
                onCancel={onClose}
                footer={<React.Fragment>
                    {step != steps[0] && <Button onClick={handlePrevStep}>Назад</Button>}
                    <Button type="primary" htmlType="submit" onClick={() => form.validateFields().then(handleNextStep)}>Вперед</Button>
                </React.Fragment>}>
                {step.component}
            </Modal>
        </Form>
    );
}
