import React from 'react';
import MainContent from './MainContent';
import { withRouter } from "react-router-dom";
import SystemInfoPanel from './SystemInfoPanel';

const SystemInfo = (props) => {
    return <MainContent layoutProps={props.layoutProps}
        title="Информация о системе"
    >
        <div style={{width: '100%', height: 'calc(100vh-)'}}>
            <SystemInfoPanel />
        </div>
    </MainContent>
}

export default withRouter(SystemInfo);
