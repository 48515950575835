import { fromODataString, toODataString } from "./odata";
import { toDate, addDays } from "../../utils/date";
import { getRecordValue } from "../../utils/array";

const getKeyValue = (value, entityKeyType) => {
    return entityKeyType === 'string'
        ? `'${value}'`
        : entityKeyType === 'number'
            ? value
            : typeof value === 'string' || value instanceof String
                ? `'${value}'`
                : value;
}

/*const getValueFromEntity = ({ entity, dataIndex }) => {
    let dataIndexArray = dataIndex.split('/');
    return getRecordValue(entity, dataIndexArray);
}

const renderSingleEntity = ({ entity: propsEntity, property, record, index }) => {
    if (!propsEntity) return null;
    const entity = property.multipleWrapperIndex
        ? getValueFromEntity({ entity: propsEntity, dataIndex: property.multipleWrapperIndex })
        : propsEntity;*/

const getMultipleWrapperIndexValue = (property, value) => {
    return value.map(v => getRecordValue(v, property.multipleWrapperIndex));
}

export let types = {
    string: {
    },
    number: {
        dataProviderProps: {
            filter: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
                const filterValue = filter.value;
                const values = filterValue && filterValue.values ? filterValue.values.map(v => `${filterPrefix}${odataName} eq ${v.replace(',', '.')}${filterPostfix}`).join(' or ') : '';
                const from = filterValue && filterValue.from ? `${filterPrefix}${odataName} ge ${filterValue.from}${filterPostfix}` : '';
                const till = filterValue && filterValue.till ? `${filterPrefix}${odataName} lt ${filterValue.till}${filterPostfix}` : '';
                const fromtill = (from || till) ? `(${[from, till].filter(_ => _).join(' and ')})` : null;
                if (fromtill || values) columnFilterPars.push(`(${[values, fromtill].filter(_ => _).join(' or ')})`);
                if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
            }
        }
    },
    boolean: {
        dataProviderProps: {
            filter: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
                const filterValue = filter.value;
                if (filterValue === true || filterValue === false) columnFilterPars.push(`${filterPrefix}${odataName} eq ${filterValue}${filterPostfix}`);
                if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
            },
        }
    },
    date: {
        dataProviderProps: {
            filter: ({ property, filter, filterPrefix, filterPostfix, odataName, columnFilterPars }) => {
                const filterValue = filter.value;
                const from = filterValue && filterValue.from ? `${filterPrefix}${odataName} ge ${toODataString(toDate(filterValue.from))}${filterPostfix}` : '';
                const till = filterValue && filterValue.till ? `${filterPrefix}${odataName} lt ${toODataString(addDays(toDate(filterValue.till), 1))}${filterPostfix}` : '';
                if (from || till) columnFilterPars.push(`(${[from, till].filter(_ => _).join(' and ')})`);
                if (filter.empty) columnFilterPars.push(`${filterPrefix}${odataName} eq null${filterPostfix}`);
            },
            deserialize: ({ property }) => value => !value ? value : fromODataString(value)
        }
    },
    file: {
        dataProviderProps: {
            deserialize: ({ property }) => property.isDynamic
                ? value => value
                : property.multiple === true
                    ? value => !value ? value : value.list.map(file => ({
                        name: file.name,
                        url: file.url,
                        size: file.length,
                        uid: file.fileId
                    }))
                    : value => !value ? value : {
                        name: value.name,
                        url: value.url,
                        size: value.length,
                        uid: value.fileId
                    },
            serialize: ({ property, json, key }) => {
                if (property.multiple) {
                    json[key] = {
                        List: json[key].map(file => ({
                            Name: file.name,
                            Url: file.url,
                            Length: file.size,
                            FileId: file.uid
                        }))
                    };
                } else {
                    let file = json[key][0];
                    json[key] = {
                        Name: file.name,
                        Url: file.url,
                        Length: file.size,
                        FileId: file.uid
                    };
                }
            }
        }
    },
    entity: {
        dataProviderProps: {
            select: ({ pars, property, name }) => {
                pars.select.push(name)
                if (!pars.expand[name]) {
                    pars.expand[name] = { select: [], expand: {} }
                }
                //($select=EducationalProgram;$expand=EducationalProgram($select=Id,Name))
                let selects = [
                    property.entityKey,
                    ...(Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel]),
                    ...(property.select || [])
                ]
                let expandValues = selects.filter(_ => _.indexOf('/') > 0);
                let expands = null;
                if (expandValues.length > 0) {
                    expands = {}
                    for (let e of expandValues) {
                        let parts = e.split('/');
                        if (!expands[parts[0]]) expands[parts[0]] = { select: [], expand: {} };
                        expands[parts[0]].select = [...(expands[parts[0]].select || []), parts[1]];
                    }
                }
                selects = selects.filter(_ => _.indexOf('/') <= 0)

                let entityPar = pars.expand[name];
                /*if (property.multiple && !property.newMultiple) {
                    entityPar.expand[property.entityName] = { select: [], expand: {} };
                    if (!entityPar.select.includes(property.entityName)) entityPar.select.push(property.entityName);
                    entityPar = entityPar.expand[property.entityName];
                }*/
                for (var s of selects) if (!entityPar.select.includes(s)) entityPar.select.push(s);
                if (expands) {
                    entityPar.expand = expands;
                }
            },
            filter: ({ property, filter, filterPrefix, filterPostfix, odataName: odataName2, columnFilterPars }) => {
                const filterValue = filter.value;
                if (filterValue) {
                    if (!filterPrefix) filterPrefix = !property.isDynamic && property.multiple ? `${odataName2}/any(a: ` : '';
                    if (!filterPostfix) filterPostfix = !property.isDynamic && property.multiple ? `)` : '';
                    let odataName = property.multiple
                        ? property.multipleWrapperIndex
                            ? `a/${property.multipleWrapperIndex}`
                            : 'a'
                        : odataName2;
                    if (filterValue.tags && filterValue.tags.length > 0) {
                        const entityLabels = Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel];
                        columnFilterPars.push(`(${entityLabels.map(l => filterValue.tags.map(fv => `${filterPrefix}contains(tolower(${odataName}/${l}),'${fv.toLowerCase()}'${filterPostfix})`).join(' or ')).join(' or ')})`);
                    }
                    if (filterValue.ids && filterValue.ids.length > 0) {
                        const entityKeys = [property.entityKey];
                        columnFilterPars.push(`(${entityKeys.map(l => filterValue.ids.map(fv => `${filterPrefix}${odataName}${property.isDynamic ? '' : `/${l}`} eq ${getKeyValue(fv[property.entityKey], property.entityKeyType)}${filterPostfix}`).join(' or ')).join(' or ')})`);
                    }
                }
                if (filter.empty) columnFilterPars.push(property.isDynamic
                    ? `not ${filterPrefix}true${filterPostfix}`
                    : property.multiple
                        ? `not ${odataName2}/any()`
                        : `${filterPrefix}${odataName2}${Array.isArray(property.dataIndex) ? '/' : ''}${property.isDynamic ? '' : property.entityKey} eq null${filterPostfix}`);
            },
            order: ({ property, odataName }) => property.entityOrder
                ? (Array.isArray(property.entityOrder) ? property.entityOrder : [property.entityOrder]).map(_ => `${odataName}/${_}`)
                : (Array.isArray(property.entityLabel) ? property.entityLabel : [property.entityLabel]).map(_ => `${odataName}/${_}`),
            deserialize: ({ property }) => value => !value
                ? value
                : property.multipleWrapperIndex
                    ? getMultipleWrapperIndexValue(property, value)
                    : value,
            serialize: ({ property, json, key }) => {
                if (property.multiple) {
                    if (!property.multipleWrapperIndex) {
                        json[key] = json[key].map(ei => ei[property.entityKey]);
                    } else {
                        for (let ei in json[key]) {
                            json[key][ei] = { [`${property.multipleWrapperIndex}Id`]: json[key][ei][property.entityKey] }
                        }
                    }
                } else {
                    json[`${key}Id`] = json[key][property.entityKey]
                    json[key] = undefined;
                }
            },
        }
    }
}
types.text = types.string;

export default types;